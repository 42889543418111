import { getTypeObjectByCode } from "@/util-modules/itemTypes";

const searchTabs = [
  {
    index: "1",
    category: "Accommodation",
    icon: "hotel",
    iconColor: "#4AA7B0",
    truncated: "Acc",
    component: "SearchAccommodation",
  },
  {
    index: "2",
    category: "Cars & Campers",
    icon: "directions_car",
    iconColor: "#AB906A",
    truncated: "Car",
    component: "SearchCarsAndCampers",
  },
  {
    index: "3",
    category: "Tours & Activities",
    icon: "local_see",
    iconColor: "#EDC644",
    truncated: "Act",
    component: "SearchToursAndActivities",
  },
  {
    index: "4",
    category: "Travel & Transfers",
    icon: "airport_shuttle",
    iconColor: "#DDDBBA",
    truncated: "Tra",
    component: "SearchTravelAndTransfers",
  },
  {
    index: "5",
    category: "Packages",
    icon: "work",
    iconColor: "#65b32e",
    truncated: "Pac",
    component: "SearchPackages",
  },
  {
    index: "6",
    category: "Ski",
    icon: "terrain",
    iconColor: "#80909A",
    truncated: "Ski",
    component: "SearchSkiing",
  },
];

export function getAllSearchTabs() {
  return searchTabs;
}

export function getSearchResultsHeaderTitle(activeSearchTabObj, searchParms) {
  switch (activeSearchTabObj.truncated) {
    case "Car":
      return getTypeObjectByCode(searchParms.searchType).pluralName;
    case "Act":
      return getTypeObjectByCode(searchParms.type).pluralName;
    default:
      return activeSearchTabObj.category;
  }
}

export default {
  getAllSearchTabs,
  getSearchResultsHeaderTitle,
};
