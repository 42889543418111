<template>
  <v-container pa-0 fluid>

    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destinations-new-zealand-hero-1.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">New Zealand</h1>
                          <h3 class="white--text title mb-4">Discover the diverse attractions and stunning landscapes of New Zealand</h3>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Marlborough Sounds, Marlborough - Credit: MarlboroughNZ</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex>
        <v-card flat tile class="pb-5">
          <v-container grid-list-xl fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 pt-0 mb-4 mt-3>
                <v-card class="pa-0 elevation-0 dash-rounded">
                  <v-card-text>
                    <span
                      class="subheading d-block mb-3"
                    >New Zealand offers an extensive variety of memorable experiences. Man-made or natural, the highlights your clients want on their next holiday are seemingly around every corner on beautiful <em>Aotearoa</em>.</span>
                    <span
                      class="subheading d-block mb-4"
                    >Learn more about these visiting the links below.</span>
                    <v-divider class="my-4"></v-divider>
                    <v-layout row wrap>
                      <v-flex xs12 sm6 md4 d-flex>
                        <v-select
                          :items="filterOptions"
                          v-model="filter"
                          box
                          label="Filter"
                          hide-details
                        ></v-select>
                      </v-flex>
                    </v-layout>
                    <v-divider class="my-4"></v-divider>
                    <h4 class="title mb-3" v-if="filter!='South Island Only'">North Island</h4>
                    <v-layout row wrap v-if="filter!='South Island Only'">
                      <template v-for="(destination, index) in destinationsNorth">
                        <v-flex xs12 sm6 lg4 :key="index" v-if="!destination.hidden">
                          <v-card class="elevation-0 v-card--btn" :to="`/destinations/new-zealand${destination.path}`" :color="lightGrey">
                            <v-img aspect-ratio="1.5" :src="destination.image" cover gradient="to top, rgba(0,0,0,0.7), rgba(0,0,0,0) 35%">
                              <v-layout column fill-height class="lightbox white--text" ma-0>
                                <v-spacer></v-spacer>
                                <v-flex shrink>
                                  <div class="subheading d-block font-weight-medium">{{destination.name}}</div>
                                  <!--<div class="body-1 text-truncate d-block" >{{destination.area}}</div>-->
                                </v-flex>
                              </v-layout>
                            </v-img>
                          </v-card>
                        </v-flex>
                      </template>
                    </v-layout>
                    <v-divider class="my-4" v-if="filter=='All Destinations'"></v-divider>
                    <h4 class="title mb-3" v-if="filter!='North Island Only'">South Island</h4>
                    <v-layout row wrap v-if="filter!='North Island Only'">
                      <template v-for="(destination, index) in destinationsSouth">
                        <v-flex xs12 sm6 lg4 :key="index" v-if="!destination.hidden">
                          <v-card class="elevation-0 v-card--btn" :to="`/destinations/new-zealand${destination.path}`" :color="lightGrey">
                            <v-img aspect-ratio="1.5" :src="destination.image" cover gradient="to top, rgba(0,0,0,0.7), rgba(0,0,0,0) 35%">
                              <v-layout column fill-height class="lightbox white--text" ma-0>
                                <v-spacer></v-spacer>
                                <v-flex shrink>
                                  <div class="subheading d-block font-weight-medium">{{destination.name}}</div>
                                  <!--<div class="body-1 text-truncate d-block" >{{destination.area}}</div>-->
                                </v-flex>
                              </v-layout>
                            </v-img>
                          </v-card>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    destinationsNorth: [
      {
        name: 'Auckland',
        area: 'North Island',
        path: '/north-island/auckland/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-auckland.jpg',
        hidden: false,
      },
      {
        name: 'Wellington',
        area: 'North Island',
        path: '/north-island/wellington/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-wellington.jpg',
        hidden: false,
      },
      {
        name: 'Tairāwhiti Gisborne',
        area: 'North Island',
        path: '/north-island/tairawhiti-gisborne/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-gisborne.jpg',
        hidden: false,
      },
      {
        name: 'Hawke\'s Bay',
        area: 'North Island',
        path: '/north-island/hawkes-bay/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-hawkes-bay.jpg',
        hidden: false,
      },
      {
        name: 'Taranaki',
        area: 'North Island',
        path: '/north-island/taranaki/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-taranaki.jpg',
        hidden: false,
      },
      {
        name: 'Manawatu',
        area: 'North Island',
        path: '/north-island/manawatu/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-manawatu.jpg',
        hidden: false,
      },
      {
        name: 'Wairarapa',
        area: 'North Island',
        path: '/north-island/wairarapa/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-wairarapa.jpg',
        hidden: false,
      },
      {
        name: 'Whanganui',
        area: 'North Island',
        path: '/north-island/whanganui/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-whanganui.jpg',
        hidden: false,
      },
      {
        name: 'Bay of Plenty',
        area: 'North Island',
        path: '/north-island/bay-of-plenty/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-bay-of-plenty.jpg',
        hidden: false,
      },
      {
        name: 'The Coromandel',
        area: 'North Island',
        path: '/north-island/the-coromandel/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-coromandel.jpg',
        hidden: false,
      },
      {
        name: 'Ruapehu',
        area: 'North Island',
        path: '/north-island/ruapehu/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-ruapehu.jpg',
        hidden: false,
      },
      {
        name: 'Taupo',
        area: 'North Island',
        path: '/north-island/taupo/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-taupo.jpg',
        hidden: false,
      },
      {
        name: 'Waikato',
        area: 'North Island',
        path: '/north-island/waikato/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-waikato.jpg',
        hidden: false,
      },
      {
        name: 'Rotorua',
        area: 'North Island',
        path: '/north-island/rotorua/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-rotorua.jpg',
        hidden: false,
      },
      {
        name: 'Northland',
        area: 'North Island',
        path: '/north-island/northland/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-northland.jpg',
        hidden: false,
      },
    ],
    destinationsSouth: [
      {
        name: 'Queenstown',
        area: 'South Island',
        path: '/south-island/queenstown/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-queenstown.jpg',
        hidden: false,
      },
      {
        name: 'Christchurch and Canterbury',
        area: 'South Island',
        path: '/south-island/christchurch/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-christchurch.jpg',
        hidden: false,
      },
      {
        name: 'Central Otago',
        area: 'South Island',
        path: '/south-island/central-otago/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-central-otago.jpg',
        hidden: false,
      },
      {
        name: 'Dunedin',
        area: 'South Island',
        path: '/south-island/dunedin/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-dunedin.jpg',
        hidden: false,
      },
      {
        name: 'Fiordland',
        area: 'South Island',
        path: '/south-island/fiordland/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-fiordland.jpg',
        hidden: false,
      },
      {
        name: 'Nelson Tasman',
        area: 'South Island',
        path: '/south-island/nelson-tasman/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-nelson-tasman.jpg',
        hidden: false,
      },
      {
        name: 'Southland',
        area: 'South Island',
        path: '/south-island/southland/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-southland.jpg',
        hidden: false,
      },
      {
        name: 'Wanaka',
        area: 'South Island',
        path: '/south-island/wanaka/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-wanaka.jpg',
        hidden: false,
      },
      {
        name: 'Marlborough',
        area: 'South Island',
        path: '/south-island/marlborough/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-marlborough.jpg',
        hidden: false,
      },
      {
        name: 'West Coast',
        area: 'South Island',
        path: '/south-island/west-coast/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-west-coast.jpg',
        hidden: false,
      },
      {
        name: 'Clutha',
        area: 'South Island',
        path: '/south-island/clutha/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-clutha.jpg',
        hidden: false,
      },
      {
        name: 'Kaikōura',
        area: 'South Island',
        path: '/south-island/kaikoura/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-kaikoura.jpg',
        hidden: false,
      },
      {
        name: 'Timaru',
        area: 'South Island',
        path: '/south-island/timaru/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-marlborough.jpg',
        hidden: true,
      },
      {
        name: 'Hurunui',
        area: 'South Island',
        path: '/south-island/hurunui/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-hurunui.jpg',
        hidden: false,
      }
    ],
    filterOptions: [
      'All Destinations',
      'North Island Only',
      'South Island Only'
    ],
    filter: 'All Destinations'
  }),
  mounted: function() {
    document.documentElement.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
</style>
