import store from '@/vuex/index';
import { HandledError } from '@/error/model/handled-error';
import { AnyTypeToFix } from '@/model/AnyType';
export default {
  install(vue: AnyTypeToFix) {
    vue.prototype.$handleError = function(err: HandledError | Error) {
      if (!(err instanceof HandledError)) {
        if (err instanceof Error) {
          store.dispatch('errorModule/setError', err.message, {
            root: true,
          });
        } else {
          store.dispatch('errorModule/setError', err, {
            root: true,
          });
        }
      }
    };
  },
};
