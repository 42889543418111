<template>
    <v-container pa-0 fluid>
      <v-layout row>
        <v-flex>
          <v-card flat tile dark class="hero-home-slider">
            <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
              <v-carousel-item>
                <v-img
                  src="https://cdn.anzcro.com.au/wp-content/uploads/2023/03/hero-australia-book-23-24-scaled.jpg"
                  gradient="to bottom, rgba(22,22,22,0.65), rgba(22,22,22,.45) 15%, rgba(22,22,22,0.35) 65%"
                  class="home-hero"
                >
                  <v-container fill-height>
                    <v-layout dark column fill-height justify-space-between pt-5>
                      <v-flex text-xs-center class="home-hero-cta">
                        <v-layout row align-center fill-height class="fill-height-fix-ios">
                          <v-flex>
                            <img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/03/the-australian-book-fit-stacked.png" alt="The Australia Book 2023/24 Independent Holidays" class="campaign-title stacked" width="480" height="300" />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div class="hero-caption hidden-xs-only">
                    <small>Uluru before sunset, Red Centre, Australia</small>
                  </div>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="gradient-bar"></div>
  
      <v-layout row>
        <v-flex mb-5>
            <v-container grid-list-lg fill-height>
              <v-layout row wrap fill-height>
                <v-flex xs12 py-0 my-0>
                  <v-card tile class="pa-0 elevation-0 deals-content">
                    <v-card-text class="pa-5">
                      <v-layout row wrap>
                        <v-flex xs12 md10 py-0 my-0>
                          <span class="headline font-weight-medium kiwiGreen d-block mb-3">Travel Australia Your Way</span>
                          <p>Australia offers a diverse range of experiences, from its stunning natural beauty to its unique wildlife and vibrant cities. Its world-renowned beaches, iconic landmarks such as the Sydney Opera House and Uluru, and multicultural cuisine make it a must-visit destination. See it at your own pace, your way with these 4 suggested holidays to help with your Aussie holiday planning.</p>
                        </v-flex>
                        <v-flex xs12 md2 py-0 my-0 text-xs-center>
                          <a target="_blank" href="https://issuu.com/holidayexperts/docs/issuu_ausbook_uk_23_24?fr=sZDE4YzU3MTE2OTE"><img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/03/brochure-mini-au-book-2023-24.jpg" alt="Australia Book 2023-2024" class="campaign-sticker kirra-early-bird" width="124" height="174" /></a>
                          <small class="kiwiGreen d-block mt-2" style="line-height:1.2;"><a target="_blank" href="https://issuu.com/holidayexperts/docs/issuu_ausbook_uk_23_24?fr=sZDE4YzU3MTE2OTE"><b>View the Australia Book 2023-24</b></a></small>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                  <v-card tile dark class="pa-0 ma-0 elevation-0">
                    <v-card-text class="px-3 py-2">
                      <span
                        class="headline font-weight-medium d-block"
                      >{{section.title}}</span>
                    </v-card-text>
                  </v-card>
                  <v-card tile class="px-3 py-3 elevation-0">
                    <v-layout row wrap ma-0>
                      <v-flex xs12 pa-0 hidden-sm-and-down>
                        <v-layout row wrap my-0>
                          <v-flex xs12 md7 lg8>
                            <span class="caption grey--text">Package Deal</span>
                          </v-flex>
                          <v-flex xs12 md3 lg2 text-md-right>
                            <span class="caption grey--text">From Price (GBP)</span>
                          </v-flex>
                          <v-flex xs12 md2 lg2 text-md-right>
                            <span class="caption grey--text">Inclusions & Terms</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                        <v-divider></v-divider>
                        <v-layout row wrap align-center my-3>
                          <v-flex xs12 md7 lg8>
                            <v-layout row align-center>
                              <v-flex shrink hidden-md-and-down>
                                <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                              </v-flex>
                              <v-flex>
                                <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                                <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                                <span class="d-block package-data">
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Start location</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-if="!deal.call">
                                    <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Package Code</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-else>
                                    <b>Call ANZCRO to Book</b><v-icon class="mx-2" size="14" :color="kiwiGreen">phone</v-icon>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm6 md3 lg2 text-md-right>
                            <span class="headline d-block font-weight-medium mb-2 kiwiGreen">£{{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                            <span class="caption d-block grey--text">{{deal.complement}}</span>
                          </v-flex>
                          <v-flex xs12 sm6 md2 lg2 text-sm-right>
                            <v-btn large class="card-with-border elevation-0 mx-0 px-2" :color="lightGrey" :href="deal.itinerary" target="_blank"><v-icon color="grey" size="18" class="mr-1">launch</v-icon> View</v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
      </v-layout>
  
  
    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      copied: false,
      copiedText: '',
      sections : [
        /*
        {
          title : 'Deals',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/deal-au-book-port-douglas.jpg',
              name : '13 Days Pacific Coast Explorer',
              price : '1343',
              complement : 'per person (twin share)',
              code : 'AUB2313DPCE',
              location : 'Port Douglas (PTD)',
              valid: 'Apr 2023 - Mar 2024',
              itinerary : 'https://issuu.com/holidayexperts/docs/issuu_ausbook_uk_23_24/24?fr=sZDE4YzU3MTE2OTE',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/deal-au-book-uluru.jpg',
              name : '11 Days City, Rock & Reef',
              price : '2060',
              complement : 'per person (twin share)',
              code : 'AUB2311DCRR',
              location : 'Sydney (SYD)',
              valid: 'Apr 2023 - Mar 2024',
              itinerary : 'https://issuu.com/holidayexperts/docs/issuu_ausbook_uk_23_24/19?fr=sZDE4YzU3MTE2OTE',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/deal-au-book-bondi.jpg',
              name : '16 Days West to East Discovery',
              price : '2371',
              complement : 'per person (twin share)',
              code : 'AUB2316DWED',
              location : 'Perth (PER)',
              valid: 'Apr 2023 - Mar 2024',
              itinerary : 'https://issuu.com/holidayexperts/docs/issuu_ausbook_uk_23_24/20?fr=sZDE4YzU3MTE2OTE',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/deal-au-book-victoria.jpg',
              name : '19 Days Australian Odyssey',
              price : '4439',
              complement : 'per person (twin share)',
              code : 'AUB2319DAO',
              location : 'Melbourne (MEL)',
              valid: 'Apr 2023 - Mar 2024',
              itinerary : 'https://issuu.com/holidayexperts/docs/issuu_ausbook_uk_23_24/22?fr=sZDE4YzU3MTE2OTE',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/deal-kimb-fish.jpg',
              name : '10 Day Kimberley Fishing Expedition',
              price : '4990',
              complement : 'per person (twin share)',
              code : 'ANZ2310DFISH',
              location : 'Broome (BME)',
              valid: 'Departure 15 Oct 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/Kimberley-Cruise-Fishing-2023-GBP.pdf',
              call : false
            },
          ]
        },
        */
      ]
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      },
      copyCode(event) {
        if(event){
          let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
          window.getSelection().selectAllChildren(copyCode);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyCode.innerHTML);
          this.copiedText = 'Package code copied to clipboard';
          this.copied = true;
        }
      },
      copyLocation(event) {
        if(event){
          let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
          window.getSelection().selectAllChildren(copyLocation);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyLocation.innerHTML);
          this.copiedText = 'Package start location copied to clipboard';
          this.copied = true;
        }
      }
    },
  };
  </script>
  
  <style>
  .gradient-bar{
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
    background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
  }
  
  .deals-content{
    margin-top: -60px;
  }
  
  .package-data .v-chip{
    color:#9e9e9e;
    margin-left: 0;
  }
  
  .package-data .v-chip .v-chip__content{
    padding-right: 6px;
  }
  
  .deal-thumbnail{
    width: 88px;
    height: 88px;
  }
  </style>
  