import { userService } from '@/components/dashboard/account/services/user.service';
import { countries } from '@/util-modules/countries';
export const registerMixin = {
  data: () => ({
    registerStepper: 1,
    formData: {
      address: '',
      agencyname: '',
      city: '',
      countryid: 15,
      emailaddress: '',
      firstname: '',
      freephone: '',
      gstnumber: '',
      lastname: '',
      phone: '',
      postcode: '',
      state: '',
      username: '',
    },
    confirmEmail: '',
    errorMsg: '',
    countries: countries,
    valid: false,
    loading: false,
    agreed: false,

    firstNameRules: [v => !!v || 'First name is required'],
    lastNameRules: [v => !!v || 'Last name is required'],
    agencyNameRules: [v => !!v || 'Agency name is required'],
    emailRules: [v => !!v || 'E-mail is required', v => /.+@.+/.test(v) || 'E-mail must be valid'],
    agencyEmailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    agencyPhoneRules: [v => !!v || 'Phone is required'],
    agencyCitySuburbRules: [v => !!v || 'City / Suburb is required'],
    agencyStreetAddressRules: [v => !!v || 'Street Address is required'],
    stateProvinceRules: [v => !!v || 'State is required'],
    agencyPostcodeRules: [v => !!v || 'Zip / Postal Code is required']
  }),
  computed: {
    emailConfirmationRules() {
      return [
        v => !!v || 'Confirm E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
        () => this.formData.username.trim() === this.confirmEmail.trim() || 'Confirm E-mail does not match',
      ];
    },
  },
  mounted: function () {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
    gotoAccountManager() {
      if (!this.$refs.detailForm.validate()) {
        const invalidInput = this.$refs.detailForm.inputs.find(t => t.valid === false);
        if (invalidInput) {
          invalidInput.focus();
        }
        return;
      }
      this.registerStepper = 2;
      this.top();
    },
    async submit() {
      if (!this.$refs.managerForm.validate()) {
        return;
      }
      try {
        this.loading = true;
        await userService.agentRequest(
          {
            ...this.formData,
            emailaddress: this.formData.emailaddress.trim(),
            username: this.formData.username.trim(),
            wholesalerid: this.wholesalerId,
          }
        );
        this.$router.push({ path: '/agent-account-requested/' });
      } catch (e) {
        this.errorMsg = e.message;
      } finally {
        this.loading = false;
      }
    },
    goToDetails() {
      this.registerStepper = 1;
      this.top();
    },
  },
};
