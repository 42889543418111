<template>
    <v-container pa-0 fluid>
      <v-layout row>
        <v-flex>
          <v-card flat tile dark class="hero-home-slider">
            <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
              <v-carousel-item>
                <v-img
                  src="https://cdn.anzcro.com.au/wp-content/uploads/2023/02/hero-australian-new-zealand-fly-stay-scaled.jpg"
                  gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.2) 65%"
                  class="home-hero"
                >
                  <v-container fill-height>
                    <v-layout dark column fill-height justify-space-between pt-5>
                      <v-flex text-xs-center class="home-hero-cta">
                        <v-layout row align-center fill-height class="fill-height-fix-ios">
                          <v-flex>
                            <!--<h1 class="white--text display-1 font-weight-bold mb-3">
                              Hot Offers on<br/>Holidays at Home
                            </h1>-->
                            <img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/02/australian-new-zealand-fly-stay-guided-tours.png" alt="Australian and New Zealand Fly and Stay Guided Tours" class="campaign-title stacked" width="480" height="300" />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div class="hero-caption hidden-xs-only">
                    <small>Road to Mt Cook, New Zealand</small>
                  </div>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="gradient-bar"></div>
  
      <v-layout row>
        <v-flex mb-5>
            <v-container grid-list-lg fill-height>
              <v-layout row wrap fill-height>
                <v-flex xs12 py-0 my-0>
                  <v-card tile class="pa-0 elevation-0 deals-content">
                    <v-card-text class="pa-5">
                      <span
                        class="headline font-weight-medium kiwiGreen d-block mb-3"
                      >Discover the very best that Australia & New Zealand have to offer!</span>
                      <p>Discover the very best that Australia and New Zealand have to offer on a unique Guided Holiday experience. Get blown away by the mesmerizing beauty of these stunning places and their depth in culture. Embark on guided tours that are led throughout by experienced Travel Directors and Driver Guides, allowing you to delve into the regions you’re visiting and explore each destination’s incredible sights to the fullest extent, without having to worry about a thing.</p>
                      <!--<p>As always, all packages can be customised. <a href="/contact/">Contact us</a> for more info.</p>-->
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                  <v-card tile dark class="pa-0 ma-0 elevation-0">
                    <v-card-text class="px-3 py-2">
                      <span
                        class="headline font-weight-medium d-block"
                      >{{section.title}}</span>
                    </v-card-text>
                  </v-card>
                  <v-card tile class="px-3 py-3 elevation-0">
                    <v-layout row wrap ma-0>
                      <v-flex xs12 pa-0 hidden-sm-and-down>
                        <v-layout row wrap my-0>
                          <v-flex xs12 md7 lg8>
                            <span class="caption grey--text">Package Deal</span>
                          </v-flex>
                          <v-flex xs12 md3 lg2 text-md-right>
                            <span class="caption grey--text">From Price (GBP)</span>
                          </v-flex>
                          <v-flex xs12 md2 lg2 text-md-right>
                            <span class="caption grey--text">Inclusions & Terms</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                        <v-divider></v-divider>
                        <v-layout row wrap align-center my-3>
                          <v-flex xs12 md7 lg8>
                            <v-layout row align-center>
                              <v-flex shrink hidden-md-and-down>
                                <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                              </v-flex>
                              <v-flex>
                                <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                                <span class="caption d-block mb-1 grey--text"><b>Travel:</b> {{deal.valid}}</span>
                                <span class="d-block package-data">
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Flights:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                    <!--<v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Start location</span>
                                    </v-tooltip>-->
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-if="!deal.call">
                                    <b>Book:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                    <!--<v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Package Code</span>
                                    </v-tooltip>-->
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-else>
                                    <b>Call ANZCRO to Book</b><v-icon class="mx-2" size="14" :color="kiwiGreen">phone</v-icon>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm6 md3 lg2 text-md-right>
                            <span class="headline d-block font-weight-medium mb-2 kiwiGreen">£{{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                            <span class="caption d-block grey--text">{{deal.complement}}</span>
                          </v-flex>
                          <v-flex xs12 sm6 md2 lg2 text-sm-right>
                            <v-btn large class="card-with-border elevation-0 mx-0 px-2" :color="lightGrey" :href="deal.itinerary" target="_blank"><v-icon color="grey" size="18" class="mr-1">launch</v-icon> View</v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
      </v-layout>
  
  
    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      copied: false,
      copiedText: '',
      sections : [
        /*
        {
          title : 'Australian Deals',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/05/deal-fly-stay-tasmania.jpg',
              name : '13 Day Perfect Tasmania',
              price : '5855',
              complement : 'per person (twin share)',
              code : 'Contact ANZCRO',
              location : 'London → Hobart & Hobart → London',
              valid: '11 - 25 Oct 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/05/13-Day-Pefect-Tasmania-GBP.pdf',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/05/deal-fly-stay-kimberley.jpg',
              name : '21 Day Untamed Kimberley & West Coast',
              price : '11760',
              complement : 'per person (twin share)',
              code : 'Contact ANZCRO',
              location : 'London → Darwin & Perth → London',
              valid: '31 Jul - 21 Aug 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/05/21-Day-Untamed-Kimberley-West-Coast-GBP.pdf',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/deal-contrasts-aus.jpg',
              name : '9 Day Contrasts of Australia',
              price : '4326',
              complement : 'per person (twin share)',
              code : 'Contact ANZCRO',
              location : 'Melbourne (MEL)',
              valid: '12 Oct - 09 Nov 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/9-Day-Contrasts-of-Australia-GBP-Flyer.pdf',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/deal-australia-highlights.jpg',
              name : '14 Day Australian Highlights',
              price : '6010',
              complement : 'per person (twin share)',
              code : 'AATUK23MEL',
              location : 'Melbourne (MEL)',
              valid: '23 Sep - 17 Nov 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/AATUK23MEL-14-Day-Australia-Highlights-GBP-Flyer.pdf',
              call : false
            },
          ]
        },
        {
          title : 'New Zealand Deals',
          deals : [
            {
                thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/05/deal-fly-stay-kia-ora.jpg',
                name : '17 Day Kia Ora New Zealand',
                price : '5375',
                complement : 'per person (twin share)',
                code : 'Contact ANZCRO',
                location : 'London → Auckland & Christchurch → London',
                valid: '09 - 27 Nov 2023',
                itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/05/17-Day-Kia-Ora-New-Zealand-GBP.pdf'
            },
            {
                thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/05/deal-fly-stay-long-cloud.jpg',
                name : '19 Day The Long White Cloud',
                price : '9860',
                complement : 'per person (twin share)',
                code : 'Contact ANZCRO',
                location : 'London → Christchurch & Auckland → London',
                valid: '04 - 24 Nov 2023',
                itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/05/19-Day-The-Long-White-Cloud-GBP.pdf'
            },
            {
                thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/deal-contrasts-nz.jpg',
                name : '10 Day Contrasts of New Zealand',
                price : '5550',
                complement : 'per person (twin share)',
                code : 'Contact ANZCRO',
                location : 'Auckland (AKL)',
                valid: '06 Oct - 17 Dec 2023',
                itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/10-Day-Contrasts-of-New-Zealand-GBP-Flyer.pdf'
            },
            {
                thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/deal-nz-uncovered.jpg',
                name : '17 Day New Zealand Uncovered',
                price : '6930',
                complement : 'per person (twin share)',
                code : 'Contact ANZCRO',
                location : 'Auckland (AKL)',
                valid: '19 Sep - 14 Nov 2023',
                itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/17-Day-New-Zealand-Uncovered-GBP-Flyer-UPDATED.pdf'
            },
          ]
        },
        */
      ]
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      },
      copyCode(event) {
        if(event){
          let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
          window.getSelection().selectAllChildren(copyCode);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyCode.innerHTML);
          this.copiedText = 'Package code copied to clipboard';
          this.copied = true;
        }
      },
      copyLocation(event) {
        if(event){
          let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
          window.getSelection().selectAllChildren(copyLocation);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyLocation.innerHTML);
          this.copiedText = 'Package start location copied to clipboard';
          this.copied = true;
        }
      }
    },
  };
  </script>
  
  <style>
  .gradient-bar{
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
    background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
  }
  
  .deals-content{
    margin-top: -60px;
  }
  
  .package-data .v-chip{
    color:#9e9e9e;
    margin-left: 0;
  }
  
  .package-data .v-chip .v-chip__content{
    padding-right: 6px;
  }
  
  .deal-thumbnail{
    width: 88px;
    height: 88px;
  }
  </style>
  