const makeRequest = function(url, method, data = undefined) {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.onreadystatechange = function() {
      if (request.readyState !== 4) return;
      if (request.status >= 200 && request.status < 300) {
        resolve(request);
      } else {
        reject({
          status: request.status,
          statusText: request.statusText,
        });
      }
    };
    request.open(method, url, true);
    if (data) {
      request.send(JSON.stringify(data));
    } else {
      request.send();
    }
  });
};

export const post = (url, data) => {
  return makeRequest(url, 'POST', data);
};
