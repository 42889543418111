import { configureAppService } from "@/services/app.service";
import { warningService } from "@/shared/components/warning/warning.service";

export function setupAppServie({ store, router }) {
  configureAppService({
    publishCustomsError: (type, payload) => {
      switch (type) {
        case `DEPOSIT_NOT_PAID`:
          store.dispatch("utilsModule/setDepositInfo", payload.errorMessage);
          break;
        default:
          break;
      }
    },
    publishError: msg => {
      store.dispatch("errorModule/setError", msg, { root: true });
    },
    after401Error: () => {
      store.dispatch("setUser", undefined);
      router.push({ name: "login" });
    },
    publishMessages: msgs => {
      warningService.onWarning(msgs.join("<br/>"));
    },
    publishTermsOfUse: () => {
      store.dispatch("utilsModule/setTermsOfUseDialog", true);
    },
  });
}
