import { itemEditRoutes } from '@/components/itinerary/item-edit/item-edit.routing';
import { itemAddRoutes } from '@/components/itinerary/item-add/item-add.routing';
import { itemViewRoutes } from '@/components/itinerary/item-view/item-view.routing';

const Itinerary = () => import('@/components/itinerary/Itinerary');
const PreBuilder = () => import('@/components/itinerary/PreBuilder');
const SearchIndex = () => import('@/components/itinerary/SearchIndex');
const Builder = () => import('@/components/itinerary/Builder');
const SearchTabs = () => import('@/components/itinerary/SearchTabs.vue');
const SearchResults = () => import('@/components/itinerary/SearchResults.vue');
const View = () => import('@/components/itinerary/View');
const ListViewItinerary = () => import('@/components/itinerary/views/list/ListViewItinerary');
const ListViewBooking = () => import('@/components/itinerary/views/list/ListViewBooking');
const Save = () => import('@/components/itinerary/Save');
const ItineraryCalendarView = () => import('@/components/itinerary/views/calendar/ItineraryCalendarView.vue');
const BookingCalendarView = () => import('@/components/itinerary/views/calendar/BookingCalendarView.vue');

const Item = () => import('@/components/itinerary/Item');
const ItemView = () => import('@/components/itinerary/item-details/ItemView');

export const itineraryRoutes = [
  {
    path: '/itinerary-builder/',
    component: Itinerary,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: 'new',
        name: 'prebuilder',
        component: PreBuilder,
      },
      {
        path: 'search/',
        component: Builder,
        children: [
          {
            path: '',
            name: 'searchindex',
            component: SearchIndex,
          },
          {
            path: 'tabs/',
            name: 'searchtabs',
            component: SearchTabs,
          },
          {
            path: 'results/',
            name: 'searchresults',
            component: SearchResults,
          },
        ],
      },
      {
        path: 'view/',
        name: 'view',
        component: View,
        children: [
          {
            path: 'list/',
            name: 'listview',
            component: ListViewItinerary,
          },
          {
            path: 'list/booking/:id',
            name: 'listviewbooking',
            component: ListViewBooking,
          },
          {
            path: 'save/',
            name: 'save',
            component: Save,
          },
          {
            path: 'calendar/',
            name: 'calendarview',
            component: ItineraryCalendarView,
          },
          {
            path: 'calendar/booking/:id',
            name: 'calendarviewbooking',
            component: BookingCalendarView,
          },
        ],
      },
      {
        path: 'item/',
        component: Item,
        children: [
          {
            path: 'view/',
            name: 'itemview',
            component: ItemView,
          },
          ...itemAddRoutes,
          ...itemEditRoutes,
          ...itemViewRoutes,
        ],
      },
    ],
  },
];
