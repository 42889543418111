import errorSubject from '@/observers/subjects/error.subject';

export function trackErrorHandler(Vue) {
  if (process.env.NODE_ENV === 'production') {
    Vue.config.errorHandler = (err, vm, info) => {
      // err: error trace
      // vm: component in which error occured
      // info: Vue specific error information such as lifecycle hooks, events etc.
      errorSubject.next({
        event: 'error',
        eventCategory: 'component_error',
        error_message: err.message,
        error_info: info,
        error_stack_trace: err.stack,
        error_component: vm.$options ? vm.$options.name : '',
      });
    };
  }
}
