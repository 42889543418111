<template>
  <v-container pa-0 fluid>
    <!-- Hero Section -->
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-hero-qld-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">{{current}}</h1>
                          <h3 class="white--text title mb-4">{{currentArea}}</h3>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Whitehaven Beach, Whitsundays Islands, QLD - Credit: Tourism Whitsundays</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- Main -->
    <v-layout row>
      <v-flex>
        <v-card flat tile class="pb-5">
          <v-container grid-list-xl class="destination-main">
            <v-layout row wrap mt-2>
              <!-- In Page Navigation -->
              <v-flex xs12>
                <v-toolbar
                  flat
                  dense
                  tabs
                  :color="lightGrey"
                  class="card-with-border dash-rounded hidden-xs-only content-nav-toolbar"
                >
                  <v-card flat height="48">
                    <v-icon size="16" color="grey" class="mx-3 my-3">arrow_downward</v-icon>
                  </v-card>
                  <v-divider vertical></v-divider>
                  <v-toolbar-items>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#introduction', scrollToOptions )"
                      class="ma-0 hidden-sm-and-down"
                    >Intro</v-btn>
                    <v-divider vertical class="hidden-sm-and-down"></v-divider>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#gallery', scrollToOptions )"
                      class="ma-0"
                    >Gallery</v-btn>
                    <v-divider vertical></v-divider>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#the-best-of', scrollToOptions )"
                      class="ma-0"
                    >Highlights</v-btn>

                  </v-toolbar-items>
                  <v-divider vertical></v-divider>
                  <v-card flat height="48" width="100%">&nbsp;</v-card>
                </v-toolbar>
                <v-card
                  flat
                  :color="lightGrey"
                  class="card-with-border v-card--btn hidden-sm-and-up content-nav-card"
                >
                  <v-layout row wrap ma-0>
                    <v-flex xs6 pa-0>
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#introduction', scrollToOptions )"
                        class="ma-0"
                      >Intro</v-btn>
                    </v-flex>
                    <v-flex xs6 pa-0 class="border-l">
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#gallery', scrollToOptions )"
                        class="ma-0"
                      >Gallery</v-btn>
                    </v-flex>
                    <v-flex xs12 pa-0>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs6 pa-0>
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#the-best-of', scrollToOptions )"
                        class="ma-0"
                      >Highlights</v-btn>
                    </v-flex>
                    <v-flex xs6 pa-0 class="border-l">
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <!-- Content -->
              <v-flex xs12 md9 lg7>
                <v-layout row wrap>
                  <!-- Destination Content -->
                  <v-flex xs12 id="content-wrapper">
                    <!-- Introduction -->
                    <v-layout row id="introduction" mt-3>
                      <v-flex>
                        <h4
                          class="title mb-3"
                        >When it comes to sun-soaked rest and relaxation no other destination shines like Queensland. From pristine beaches to lush mountain hideaways, from gently winding rivers to the aquatic paradise of the Great Barrier Reef, Queensland is a place that is beautiful one day, and perfect the next.</h4>
                      </v-flex>
                    </v-layout>

                    <!-- Gallery -->
                    <v-layout row wrap id="gallery" mt-3>
                      <v-flex xs12 pb-1>
                        <h4 class="title">Gallery</h4>
                      </v-flex>
                      <v-flex xs12>
                        <v-card flat dark class="mb-3">
                          <v-carousel
                            height="auto"
                            class="elevation-0 destination-gallery"
                            hide-delimiters
                          >
                            <v-carousel-item v-for="(item, index) in galleryItems" :key="index">
                              <v-img
                                :src="item.image"
                                gradient="to bottom, rgba(22,22,22,.35), rgba(22,22,22,0.1) 15%, rgba(22,22,22,0.1) 85%, rgba(22,22,22,0.65)"
                                aspect-ratio="1.5"
                              >
                                <v-layout column fill-height ma-0>
                                  <v-flex text-xs-right>
                                    <b>{{index + 1}}</b>
                                    / {{galleryItems.length}}
                                  </v-flex>
                                  <v-flex shrink><small class="d-block">{{item.caption}}</small></v-flex>
                                </v-layout>
                              </v-img>
                            </v-carousel-item>
                          </v-carousel>
                        </v-card>
                      </v-flex>
                    </v-layout>

                    <!-- The Best of -->
                    <v-layout row id="the-best-of" mt-3>
                      <v-flex>
                        <h4 class="title mb-3">Queensland Highlights</h4>
                        <ul class="subheading mb-3">
                          <li>
                            <b>Admire the incredible Great Barrier Reef</b> – The living masterpiece that is the Great Barrier Reef stretches for more than 2,300 kilometres (1,200 miles) along the Queensland coastline. There are hundreds of tours and experiences available along the Great Barrier Reef coastline – most notably from the towns of Cairns, Port Douglas, Townsville, Airlie Beach, Hamilton Island, Seventeen Seventy, Bundaberg, Gladstone and Mackay – but whether you snorkel, scuba dive, fly, motor or sail over it, you simply must experience the colours and textures of this natural wonder.
                          </li>
                          <li>
                            <b>The Gold Coast</b> – From the iconic Surfers Paradise beach and the dining precincts of Main Beach and Broadbeach to the lush, green hinterland, there’s an experience waiting for you at every turn on the Gold Coast. In Australia's "theme park capital" see native Australian wildlife, meet your favourite movie characters and superheroes, or swim with dolphins.
                          </li>
                          <li>
                            <b>Daintree Rainforest</b> – The Daintree, a two hour drive north of Cairns, is the world's oldest tropical rainforest and so beautiful that it provided inspiration for the movie Avatar. Stand in awe under the branches of the 600-million-year-old Zamia Fern, which has an underground trunk system evolved in defence against dinosaurs. Cruise the Daintree River, where you might spot a crocodile waiting in the mangroves or glimpse a turtle in the clear, sparkling streams below. Stay at one of the luxury eco lodges nestled in the rainforest such as Silky Oaks Lodge, or in the lovely beach village of Port Douglas nearby.
                          </li>
                          <li>
                            <b>Visit tropical Cairns</b> – With its international airport and excellent shopping, the tropical city of Cairns in Queensland's far north is a convenient access point for the Great Barrier Reef and Daintree Rainforest, but don't miss the great things to do in and around town. You'll find brilliant cafés, bustling markets and plenty of beaches nearby. Relax by one of the pools at a five-star resort, day trip to the nearby stylish villages of Port Douglas and Palm Cove, or spend your days out and about exploring this city oasis.
                          </li>
                          <li>
                            <b>Explore Brisbane</b> – Soak up the subtropical climate, explore rivers, parks and outdoor spaces or delve into its arts and culture at South Bank. Take a paddle steamer or ferry down the Brisbane River or bike ride through the City Botanic Gardens. Brisbane’s heritage Howard Smith Wharves new precinct brings the best of Brisbane together with abundant green spaces, contemporary restaurants and new boutique hotels. Brisbane is the perfect gateway from which to explore the wonder and beauty of Queensland.
                          </li>
                          <li>
                            <b>Whitsunday Islands</b> – Made up of 74 tropical islands, the Whitsundays archipelago lies just off the Queensland coast next to the Great Barrier Reef. Here you’ll find some of the world’s best island resorts, including the Luxury Lodge of Australia qualia, as well as Whitehaven Beach – regularly ranked one of the world's most beautiful beaches for its powder fine sands, dazzling aqua water, and proximity to the incredibly shaped Heart Reef. In 2019 one of Australia’s most lavish islands, Hayman Island, will re-open with a new look luxury resort.
                          </li>
                          <li>
                            <b>Watch whales at Hervey Bay</b> – The safe, sheltered waters of Hervey Bay make it one of the best places on Earth for whale watching. In winter months (July to November), humpback whales travel north along the Queensland coastline from Antarctica to the warm waters of the Whitsundays, stopping in calm Hervey Bay to breed and nurse their young.
                          </li>
                          <li>
                            <b>See the world’s largest sand island</b> – A short ferry ride from Hervey Bay is World Heritage-listed Fraser Island, the world’s largest sand island. It's easy to see why the traditional Aboriginal owners called Fraser Island K'gari – paradise. Four-wheel drive next to the coloured cliffs of The Cathedrals, take a joy flight over 75 Mile Beach, bush walk through rainforest growing from the sand and swim in mirrored lakes ringed with golden sand. There's also kayaking, jet skiing and windsurfing to enjoy.
                          </li>
                        </ul>
                      </v-flex>
                    </v-layout>

                    <!-- Map
                    <v-layout wrap row id="map" mt-3>
                      <v-flex xs12 pb-1>
                        <h4 class="title">Map</h4>
                      </v-flex>
                      <v-flex xs12>
                        <v-card flat class="v-card--btn">
                          <v-img
                            src="https://cdn.anzcro.com.au/wp-content/uploads/2019/12/map-thumbnail-auckland.jpg"
                            height="240"
                          >
                            <v-layout column fill-height ma-0 align-end>
                              <v-flex>&nbsp;</v-flex>
                              <v-flex shrink>
                                <v-btn
                                  :color="lightGrey"
                                  @click="destinationMapDialog=true;"
                                >Expand</v-btn>
                              </v-flex>
                            </v-layout>
                          </v-img>
                        </v-card>
                      </v-flex>
                    </v-layout> -->
                  </v-flex>
                </v-layout>
              </v-flex>

              <!-- Other Destinations -->
              <v-flex xs12 md3 lg4 offset-lg1 id="other" mt-4>
                <h4 class="title mb-4 grey--text">Other Destinations</h4>
                <DestinationIndex :currentDestination="current" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- Destination Map Dialog -->
    <v-dialog v-model="destinationMapDialog" max-width="900px">
      <v-card class="pa-0">
        <v-layout row pa-3 align-center>
          <v-flex>
            <span class="d-block headline font-weight-bold">Map</span>
          </v-flex>
          <v-flex shrink>
            <v-btn
              :color="lightGrey"
              small
              fab
              class="elevation-0 ma-0 card-with-border"
              @click="destinationMapDialog=false;"
            >
              <v-icon size="24">close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-card-text class="pa-3">
          <v-responsive :aspect-ratio="800/600">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d817202.3861024495!2d174.30502620606845!3d-36.86170744554708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d47fb5a9ce6fb%3A0x500ef6143a29917!2sAuckland%2C%20New%20Zealand!5e0!3m2!1sen!2sau!4v1575248618684!5m2!1sen!2sau" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
          </v-responsive>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import DestinationIndex from '@/components/public/destinations/components/DestinationIndexAustralia.vue';
export default {
  data: () => ({
    current: 'Queensland',
    currentArea: 'Australia',
    galleryItems: [
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-qld-001.jpg',
        caption: 'Gold Coast - Credit: Tourism Australia',
      },
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-qld-002.jpg',
        caption: 'Scenic Flight over Great Barrier Reef - Credit: Tourism Australia',
      },
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-qld-003.jpg',
        caption: 'Brisbane - Credit: Tourism Australia',
      },
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-qld-004.jpg',
        caption: 'Fraser Island - Credit: Tourism Australia',
      },
    ],
    destinationMapDialog: false,
    scrollToOptions: {
      easing: 'easeInOutCubic',
      duration: 300,
      offset: 50,
    },
  }),
  components: {
    DestinationIndex,
  },
  mounted: function() {
    document.documentElement.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
.container.destination-main {
  padding: 24px;
}

.content-nav-toolbar .v-toolbar__content {
  padding: 0 !important;
}

.content-nav-card .flex.border-l {
  border-left: solid 1px rgba(0, 0, 0, 0.12) !important;
}

#content-wrapper .title {
  line-height: 1.3 !important;
}

#content-wrapper li {
  margin-bottom: 16px;
}

#content-wrapper li:last-child {
  margin-bottom: 0;
}

.v-avatar.summer {
  border: solid 4px #ffaa00 !important;
}
.v-avatar.winter {
  border: solid 4px #adc9d7 !important;
}

@media (min-width: 600px) {
  .container.destination-main {
    padding: 24px 32px;
  }
}

@media (min-width: 960px) {
  .container.destination-main {
    padding: 24px;
  }
}
</style>
