//belongs to /itinerary-builder/item/
const ItemEditLayout = () => import("./ItemEditLayout.vue");

const ItineraryItemEditAccommodation = () => import("./ItineraryItemEditAccommodation.vue");
const BookingItemEditAccommodation = () => import("./BookingItemEditAccommodation.vue");

const ItineraryItemEditCarCamper = () => import("./ItineraryItemEditCarCamper.vue");
const BookingItemEditCarCamper = () => import("./BookingItemEditCarCamper.vue");

const ItineraryItemEditTravelTransfers = () => import("./ItineraryItemEditTravelTransfers.vue");
const BookingItemEditTravelTransfers = () => import("./BookingItemEditTravelTransfers.vue");

const ItineraryItemEditDayTours = () => import("./ItineraryItemEditDayTours.vue");
const BookingItemEditDayTours = () => import("./BookingItemEditDayTours.vue");

const ItineraryItemEditMultidayTours = () => import("./ItineraryItemEditMultidayTours.vue");
const BookingItemEditMultidayTours = () => import("./BookingItemEditMultidayTours.vue");

const ItineraryItemEditSkiing = () => import("./ItineraryItemEditSkiing.vue");
const BookingItemEditSkiing = () => import("./BookingItemEditSkiing.vue");

export const itemEditRoutes = [
  {
    path: "edit/",
    name: "itemedit",
    component: ItemEditLayout,
    children: [
      {
        path: "accommodation/:seq",
        name: "item-edit-accommodation",
        component: ItineraryItemEditAccommodation,
      },
      {
        path: "booking/:id/accommodation/:seq",
        name: "item-edit-booking-accommodation",
        component: BookingItemEditAccommodation,
      },
      {
        path: "car/:seq",
        name: "item-edit-car",
        component: ItineraryItemEditCarCamper,
      },
      {
        path: "booking/:id/car/:seq",
        name: "item-edit-car-booking",
        component: BookingItemEditCarCamper,
      },
      {
        path: "camperrental/:seq",
        name: "item-edit-camperrental",
        component: ItineraryItemEditCarCamper,
      },
      {
        path: "booking/:id/camperrental/:seq",
        name: "item-edit-camperrental-booking",
        component: BookingItemEditCarCamper,
      },
      {
        path: "ferry/:seq",
        name: "item-edit-ferry",
        component: ItineraryItemEditTravelTransfers,
      },
      {
        path: "booking/:id/ferry/:seq",
        name: "item-edit-ferry-booking",
        component: BookingItemEditTravelTransfers,
      },
      {
        path: "coach/:seq",
        name: "item-edit-coach",
        component: ItineraryItemEditTravelTransfers,
      },
      {
        path: "booking/:id/coach/:seq",
        name: "item-edit-coach-booking",
        component: BookingItemEditTravelTransfers,
      },
      {
        path: "rail/:seq",
        name: "item-edit-rail",
        component: ItineraryItemEditTravelTransfers,
      },
      {
        path: "booking/:id/rail/:seq",
        name: "item-edit-rail-booking",
        component: BookingItemEditTravelTransfers,
      },
      {
        path: "transfer/:seq",
        name: "item-edit-transfer",
        component: ItineraryItemEditTravelTransfers,
      },
      {
        path: "booking/:id/transfer/:seq",
        name: "item-edit-transfer-booking",
        component: BookingItemEditTravelTransfers,
      },
      {
        path: "sightseeing/:seq",
        name: "item-edit-sightseeing",
        component: ItineraryItemEditDayTours,
      },
      {
        path: "booking/:id/sightseeing/:seq",
        name: "item-edit-sightseeing-booking",
        component: BookingItemEditDayTours,
      },
      {
        path: "multidaytour/:seq",
        name: "item-edit-multidaytour",
        component: ItineraryItemEditMultidayTours,
      },
      {
        path: "booking/:id/multidaytour/:seq",
        name: "item-edit-multidaytour-booking",
        component: BookingItemEditMultidayTours,
      },
      // Skiing
      {
        path: "ski/:seq",
        name: "item-edit-ski",
        component: ItineraryItemEditSkiing,
      },
      {
        path: "booking/:id/ski/:seq",
        name: "item-edit-ski-booking",
        component: BookingItemEditSkiing,
      },
    ],
  },
];
