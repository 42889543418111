import { cmsService } from '@/services/cms.service';
import { delay } from '@/util-modules/promise';
import { getResourceMapper, getCMSRegionByCode } from '@/components/dashboard/resources/model/resource'
export const resourceMixin = {
  data() {
    return {
      loaded: false,
      resources: []
    }
  },
  mounted() {
    this.getResources();
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0
  },
  methods: {
    async getResources() {
      try {
        const response = await cmsService.getResources({
          resource_types: this.resource_type,
          regions: getCMSRegionByCode(this.$region)
        })
        this.resources = response.data.map(getResourceMapper(this.resource_type))
      } catch (error) {
        this.$handleError(`${this.view} are not available at the moment. Please try again later`);
      } finally {
        delay(500).then(() => {
          this.loaded = true
        })
      }
    }
  }
};
