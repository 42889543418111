import moment from "moment";

export interface PostSearch {
  _embed?: string,
  slug?: string,
  order?: string,
  orderby?: string,
  page?: number,
  per_page?: number;
}

export interface WpTitle {
  rendered: string;
}

export interface WpContent {
  rendered: string;
  protected: boolean;
}

export interface WpExcerpt {
  rendered: string;
  protected: boolean;
}

export interface WpTerm {
  name: string;
  taxonomy: string;
}

export interface WpFeaturedMediaSize {
  source_url: string;
}

export interface WpFeaturedMediaSizes {
  full: WpFeaturedMediaSize;
}

export interface WpFeaturedMediaDetails {
  sizes: WpFeaturedMediaSizes;
}

export interface WpFeaturedMedia {
  media_details: WpFeaturedMediaDetails;
}

export interface WpAuthor {
  name: string;
}

export interface WpEmbedded {
  author: WpAuthor[];
  "wp:featuredmedia": WpFeaturedMedia[];
  "wp:term": WpTerm[][];
}

export interface WpPost {
  id: number;
  date_gmt: string;
  modified_gmt: string;
  slug: string;
  title: WpTitle;
  content: WpContent;
  excerpt: WpExcerpt;
  categories: number[];
  _embedded: WpEmbedded;
}

export interface WpPostsResponseHeaders {
  "x-wp-total": string;
  "x-wp-totalpages": string;
}

export interface WpPostsResponse {
  data: WpPost[];
  headers: WpPostsResponseHeaders;
}

export interface Post {
  id: number,
  date: string,
  modified: string;
  slug: string;
  title: string;
  content: string;
  excerpt: string;
  categories: string;
  featuredMedia: string;
  author: string;
}

export interface PostsResponseMeta {
  totalPosts: number;
  totalPostsPages: number;
  page: number;
  perPage: number;
}

export interface PostsResponse {
  data: Post[];
  meta: PostsResponseMeta;
}

export const mapToPost = (wpPost: WpPost) : Post => ({
  id: wpPost.id,
  date: _formattoLocalDate(wpPost.date_gmt),
  modified: _formattoLocalDate(wpPost.modified_gmt),
  slug: wpPost.slug,
  title: wpPost.title.rendered,
  content: wpPost.content.rendered,
  excerpt: wpPost.excerpt.rendered,
  categories: _getCategories(wpPost),
  featuredMedia: _getFeaturedMedia(wpPost),
  author: _getAuthor(wpPost),
});

const _formattoLocalDate = (gmtDate: string): string =>
  moment.utc(gmtDate).format("MMM D, YYYY");

const _getCategories = (wpPost: WpPost): string => {
  try {
    return wpPost
            ._embedded["wp:term"][0]
            .filter(x => x.taxonomy === "category")
            .map(x => x.name)
            .join(", ");
  } catch (error) {
    return "";
  }
}

const _getFeaturedMedia = (wpPost: WpPost): string => {
  try {
    return wpPost
            ._embedded["wp:featuredmedia"][0]
            .media_details
            .sizes
            .full
            .source_url;
  } catch (error) {
    return "";
  }
}

const _getAuthor = (wpPost: WpPost): string => {
  try {
    return wpPost
            ._embedded
            .author[0]
            .name;
  } catch(e) {
    return "";
  }
}
