import { PackageSearchResult } from '@/model/searchpackage';

export const sortAlphabetically = (a: PackageSearchResult, b: PackageSearchResult) => {
  if (a.packageItem.name < b.packageItem.name) {
    return -1;
  } else if (a.packageItem.name > b.packageItem.name) {
    return 1;
  }
  return 0;
};

export const sortLowToHigh = (a: PackageSearchResult, b: PackageSearchResult) =>
  a.packageWholesalerPrices[0].grossPrice > b.packageWholesalerPrices[0].grossPrice ? 1 : -1;

export const sortHigthToLow = (a: PackageSearchResult, b: PackageSearchResult) =>
  a.packageWholesalerPrices[0].grossPrice > b.packageWholesalerPrices[0].grossPrice ? -1 : 1;
