<template>
  <v-container pa-0 fluid>
    <v-layout row class="hero-waves" v-if="showHeader">
      <v-flex>
        <v-container fill-height>
          <v-layout dark row wrap fill-height align-center pt-5>
            <v-flex xs12 text-xs-center text-md-left class="hero-title">
              <h3 class="grey--text title mb-2">ANZCRO Website</h3>
              <h1 class="white--text display-1 font-weight-bold mb-3">Terms of Use & Access</h1>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-layout row class="terms-and-conditions">
      <v-flex>
        <v-card flat tile class="pb-5">
          <v-container grid-list-xl fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 md8 pa-3 mb-4 mt-3 order-xs2 order-md1>

                <ol>
                  <li>
                    <h4 class="title my-3 mb-0 text-uppercase" id="application">Application</h4>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">1.1 Access for Product bookings</h4>

                    <ol type="a">
                      <li>These terms ("<b>Terms</b>") and our Privacy Policy apply to the access and use of ANZCRO's website (www. anzcro.com, www.anzcro.co.nz, www.anzcro.com.au and/or www.anzcro.co.uk) ("<b>Website</b>") by travel agencies and their travel agents and other Registered Users ("<b>you</b>", "<b>your</b>") to make enquiries and book travel, accommodation and other holiday products, services and experiences offered by ANZCRO (as wholesaler) on or via the Website ("<b>Products</b>").</li>
                      <li>In these Terms, "<b>ANZCRO</b>", "<b>us</b>", "<b>we</b>" or "<b>our</b>" means, as the context requires, ANZCRO New Zealand Limited (in the case of www.anzcro.com and www.anzcro.co.nz); Australian New Zealand Central Reservation Office Pty Limited (in the case of www.anzcro.com.au); or ANZCRO (UK) Limited (in the case of www.anzcro.co.uk); and/or their related bodies corporate;</li>
                    </ol>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">1.2 Registration and use constitutes acceptance</h4>

                    <ol type="a">
                      <li>By registering to use, accessing and/or using the Website, you accept these Terms and our Privacy Policy.  We may amend these Terms from time to time by publishing updated Terms on the Website. By continuing to access the Website after any such amendment is published, you are deemed to be aware of and have agreed to the amendment.</li>
                      <li>ANZCRO's standard terms and conditions and the Product terms and conditions of the relevant third party supplier of Products ("<b>Supplier</b>"), each as amended from time to time, also apply to Product bookings.</li>
                      <li>You should review all applicable terms and conditions periodically and before making any Product booking.</li>
                    </ol>

                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3 text-uppercase" id="registration">Registration</h4>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">2.1 Application for registration</h4>

                    <ol type="a">
                      <li>To access Products via the Website, you must register your entity, and each individual nominated by you who you wish to be able to make enquiries and bookings for Products, by completing the relevant application forms on the Website or otherwise provided by us.  We may accept or reject registration applications in our sole discretion.  A Website username and password must be selected by each of your nominated users whose registration application we accept ("<b>Registered User</b>").</li>
                      <li>Registered Users may access and use the Website for the sole purpose of making enquiries and bookings for Products on behalf of your customers.</li>
                      <li>Your registration (and that of your Registered Users) is for you (and the relevant Registered User respectively) only, and each username and password is for the relevant Registered User only, and are not assignable or transferable to any other person.  You must ensure that each Registered User keeps their username and password secure and confidential and does not disclose them to any other person (including any of your customers).  </li>
                      <li>You are responsible and liable for any and all activities that occur through the use of the username and password of any of your Registered Users.  When the correct username and password is provided we may act on and rely on any information or instruction provided or booking made, without the need for further enquiry. </li>
                    </ol>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">2.2 Compliance with requirements and use restrictions</h4>

                    <ol type="a">
                      <li>You must use ensure that you have the correct hardware and software configuration required for use of the Website. </li>
                      <li>You must comply with any technical, access, security or other requirements specified by us from time to time for use of the Website.</li>
                      <li>You must also comply with any Website access/use restrictions, including rate limits, specified by us from time to time.</li>
                    </ol>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">2.3 Changes</h4>

                    <p>We may change any part of the Website or its content (including Products), functionality or applicable requirements and restrictions at any time.  Your continued access of the Website after any such change will be subject to these Terms and will constitute conclusive acceptance of that change.</p>

                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3 text-uppercase" id="bookings">Bookings</h4>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">3.1 ANZCRO's role as wholesaler</h4>

                    <p>You acknowledge  that ANZCRO is a wholesaler which facilitates, through the Website, your booking of Products provided by Suppliers. ANZCRO does not own or operate any Product displayed on or accessible through the Website. </p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">3.2 Booking process</h4>

                    <p>You may make bookings for Products by completing the applicable booking application.  We and/or Suppliers may accept or reject booking applications in our/their sole discretion.  If a booking application is accepted (which may require payment of a deposit), we will issue you with a booking confirmation. It is your responsibility to check and ensure that the details of the booking confirmation are correct and meet your and your customers' requirements. </p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">3.3 Supplier and ANZCRO terms and conditions apply</h4>

                    <p>The relevant Supplier's applicable Product terms and conditions (accessible through the Website if provided by the Supplier), together with ANZCRO's standard <a href="/terms-and-conditions/">terms and conditions</a>, including terms and conditions for price, payment, quotes, deposits, refunds, amendments and cancellations, apply to all Product bookings.  You acknowledge that different terms and conditions may apply to different Products, and that both the Supplier terms and conditions and ANZCRO's standard terms and conditions may be varied from time to time.  By completing a booking application, you confirm that you have read and understood (and ensured that your customer has read and understood) all applicable terms and conditions.</p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">3.4	Payment</h4>

                    <ol type="a">
                      <li>You will pay all applicable Product fees and charges to us in accordance with our standard terms and conditions.  We may cancel Product bookings not paid for in full in accordance with our standard terms and conditions.  You are liable for any applicable cancellation fee.</li>
                      <li>You acknowledge that we may receive and retain a commission, rebate or other remuneration or benefit from bookings made using the Website.  We are not required to disclose to you the nature or amount of, nor account to you for, any such remuneration or benefit.</li>
                    </ol>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">3.5 Legal compliance warranty</h4>

                    <ol type="a">
                      <li>You warrant that you hold and will maintain all necessary licences, permits, authorisations, consents and approvals required, and will comply with all applicable laws and legal requirements, in relation to your (and your Registered Users') activities on and through the Website.</li>
                      <li>You must not use the Website or any content in any manner or for any purpose which is unlawful or in any manner which violates any of our rights or which is otherwise prohibited by these Terms. </li>
                    </ol>

                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3 text-uppercase" id="liability">Liability and Indemnity</h4>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">4.1 Links</h4>

                    <p>The Website may contain links to third party websites which are not under our control. We are not responsible for the contents of any linked website or any hyperlink contained in a linked website other than our own websites. Hyperlinks are provided to you as convenience only, and the inclusion of any link does not imply any endorsement of the linked website by us or our employees.</p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">4.2 As is, as available</h4>

                    <p>You use the Website at your own risk.  Access to the Website is provided on an "as is" and "as available" basis.  We do not guarantee access to or availability of the Website (or any content or functionality), that information in or accessed through the Website is current, accurate or complete, or that your use will be uninterrupted, timely, secure or error, fault or virus free.  </p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">4.3 No warranties</h4>

                    <p>We do not make any representations or warranties whatsoever in relation to the Products (including their nature, content, quality, timeliness, legal compliance or fitness or suitability for any particular purpose or customer) or the Website (or its contents or functionality), including as to the accuracy or reliability of any information, data, opinions, advice or statements accessible through the Website.  All warranties (whether express, implied or statutory) are excluded to the maximum extent permitted by applicable law.</p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">4.4 Exclusion of liability</h4>

                    <p>To the maximum extent permitted by applicable law, ANZCRO will not have any liability whatsoever (whether in contract, tort (including negligence) or otherwise) for any damage, liability, loss, injury (including death), cost, claim or expense suffered or incurred by you (or any of your customers) in connection with Products; your use of the Website; or your inability to access or use the Website or any content or functionality.  If for any reason ANZCRO cannot rely on this exclusion of liability, our total aggregate liability will be limited to the lesser of: (i) the cost of re-performing (or, at ANZCRO's discretion, refunding) the relevant Product booking facilitation services; and (ii) NZ$100. In no event will ANZCRO be liable to you (or any of your customers) for any loss of data, profits, savings, goodwill or business opportunity or for any indirect, consequential, special or exemplary loss or damage (howsoever arising). </p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">4.5 Consumer Guarantees Act does not apply</h4>

                    <p>You acknowledge and agree that where your access to and use of the Website or provision of our booking facilitation services, is supplied and acquired in trade, that the Consumer Guarantees Act 1993 (NZ) (or any analogous consumer protection legislation in any other jurisdiction) does not apply to such access or use, and that such exclusion is fair and reasonable. Nothing in these Terms excludes, restricts or modifies the application of any mandatorily applicable law (including the Australian Consumer Law as set out in Schedule 2 of the Competition and Consumer Act (2010)) which cannot be excluded, restricted or modified by contract.</p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">4.6 Indemnity</h4>

                    <p>You indemnify ANZCRO (and our related bodies corporate) (each an "<b>Indemnified Person</b>") from and against any loss, cost, liability, claim or expense (including legal costs on a solicitor and own client basis) suffered or incurred by an Indemnified Person in connection with: (a) your access to and use of the Website and its content and functionality; (b) Product bookings; (c) failing to meet your privacy obligations, or (d) any other breach of these Terms.</p>

                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3 text-uppercase" id="security">Security</h4>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">5.1 Unauthorised Use</h4>

                    <ol type="a">
                      <li>"<b>Unauthorised Use</b>" means: (i) any third party or unauthorised employee, agent or contractor access to, and/or use of, the Website; and/or (ii) any other interference with the Website, its content or functionality;</li>
                      <li>You will:
                        <ol type="i">
                          <li>use your best endeavours to ensure that no Unauthorised Use occurs; and </li>
                          <li>effect and maintain security measures to prevent Unauthorised Use occurring and any loss or damage to ANZCRO arising from the occurrence of Unauthorised Use by and through you (or your Registered Users) (or your (or your Registered Users') usernames and passwords).</li>
                        </ol>
                      </li>
                      <li>You will immediately notify us of any Unauthorised Use and any claims by a third party in relation to your (or your Registered Users') use of the Website.  You will, at your cost, take any action which is necessary or which we may require to prevent any further Unauthorised Use.</li>
                    </ol>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">5.2 Prevent malware</h4>

                    <p>You will use your best endeavours to ensure that no virus, Trojan horse, worm or other malware is transmitted to our (or any Supplier's) internal systems and networks, in accordance with good industry practice and standards.  You are responsible for your own boundary management security and malware software security in accordance with good industry practice and standards.</p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">5.3 No bypassing or data scraping</h4>

                    <p>You will not (and will ensure that Registered Users do not): </p>

                    <ol type="a">
                      <li>bypass or circumvent, or attempt to bypass or circumvent, any security mechanism or access restriction of, nor attempt to gain unauthorised access to, the Website or any content or functionality; or </li>
                      <li>undertake or attempt any data scraping, harvesting, extraction or mining of the Website or any content. </li>
                    </ol>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">5.4 Monitoring and reporting</h4>

                    <ol type="a">
                      <li>We may monitor and log any access and use of the Website by you and your Registered Users.  </li>
                      <li>You will promptly provide us with any information we reasonably request regarding your (and your Registered  Users') use of the Website, together with evidence of your compliance with these Terms.</li>
                    </ol>

                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3 text-uppercase" id="ip">Intellectual Property</h4>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">6.1 IP owned by ANZCRO</h4>

                    <p>All intellectual property rights in and to the Website and its contents and functionality are owned by ANZCRO (or its related bodies corporate), or its or their licensors exclusively.  Your rights are limited to access and use in accordance with this agreement.  </p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">6.2 No rights to names or marks</h4>

                    <p>Nothing in these Terms grants you or any of your Registered Users any right or interest in or to any ANZCRO name or mark (or that of any Supplier).</p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">6.3 No reverse engineering</h4>

                    <p>You may not reverse engineer, decompile, disassemble, modify, translate or make any derivative works of any part of the Website, its contents or functionality.</p>

                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3 text-uppercase" id="termination">Suspension and Termination</h4>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">7.1 Suspension or termination by ANZCRO</h4>

                    <p>We may, in our sole discretion, at any time, with or without notice, suspend or terminate your Website registration or your access to the Website (or to those pages of the Website which enable access to Products):</p>

                    <ol type="a">
                      <li>to protect the security or integrity of the Website and the content, data and functionality on or accessible through the Website;</li>
                      <li>in the event of any Unauthorised Use, breach of any Website access/use restrictions (including rate limits) or actual or suspected data scraping, harvesting, extraction or mining;</li>
                      <li>to carry out maintenance or upgrades to the Website or our systems; or</li>
                      <li>if you breach any of your obligations under these Terms or we consider (acting reasonably) that you or any of your holding companies has suffered any bankruptcy or insolvency event.</li>
                    </ol>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">7.2 Cancellation of registration by you or us</h4>

                    <p>Either of us may cancel your Website registration at any time by giving the other at least 3 months' notice in writing.</p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">7.3 Consequences of suspension or termination</h4>

                    <p>On suspension, termination or cancellation:</p>

                    <ol type="a">
                      <li>you will immediately cease accessing and using any non-public part of the Website;</li>
                      <li>in the case of termination, you will immediately remove any reference to the Website or ANZCRO in your systems, materials and documentation;</li>
                      <li>clauses 4 (liability and indemnity), 6 (intellectual property), 8 (dispute resolution), 9 (privacy) and 10 (miscellaneous), this clause 7.3 and any other term which impliedly is intended to survive suspension or termination, shall continue.</li>
                    </ol>
                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3 text-uppercase" id="resolution">Dispute resolution</h4>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">8.1 Negotiation</h4>

                    <p>If any dispute or difference of any kind whatsoever shall arise between you and us under or in connection with these Terms or your access to and use of Website (a “Dispute”), you will enter into negotiations with us in good faith to resolve the Dispute promptly following either you or us invoking this clause in writing.  </p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">8.2 Arbitration</h4>

                    <p>If the Dispute is not resolved within 14 days of the commencement of negotiations (or within such longer period as you and we may agree), the Dispute shall be referred to arbitration before a single independent arbitrator subject to and in accordance with the provisions of the Arbitration Act 1996 (NZ) (excluding clauses 4 and 5 of the Second Schedule).  If you and we are unable to agree on an arbitrator within 14 days of referral of the Dispute to arbitration, one shall be appointed by the President of the New Zealand Law Society (or his or her nominee) upon application by either you or us.  The place of arbitration will be Christchurch, New Zealand and the language of arbitration will be English.  The arbitrator’s decision shall be final and binding on you and us (subject to manifest error).</p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">8.3 Interim relief</h4>

                    <p>Nothing in this clause 8 shall prevent you or us from seeking or obtaining any order or relief by way of injunction or declaration or other equitable or statutory remedy against the other or any other person where you or we believe the order or relief is necessary for the urgent protection of that person's rights or property.</p>

                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3 text-uppercase" id="privacy">Privacy</h4>

                    <p>You will:</p>

                    <ol type="a">
                      <li>comply (and will ensure that your contractors and agents comply) with (as applicable) the Privacy Act 1993 (NZ), the Privacy Act 1988 (Australia), the General Data Protection Regulations (EU), the Data Protection Act 2018 (UK) and all other equivalent laws, regulations and codes of practice from time to time ("Data Protection Laws"), in connection with your (and your Registered Users') registration for, use of and/or access to the Website and/or your (and your Registered Users') activities on and through the Website;</li>
                      <li>ensure that you have the correct authorisations and permissions required under applicable Data Protection Laws to disclose any third party information to us for the purposes of Website registration, Product bookings, payments, amendments, cancellations and enquiries or any other purpose contemplated by these Terms; and</li>
                      <li>comply with our Privacy Policy and any changes to that policy from time to time. </li>
                    </ol>

                    <p>We may collect, store, use and disclose "personal information" in accordance with the applicable Data Protection Laws and our Privacy Policy (published on the Website). </p>

                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3 text-uppercase" id="miscellaneous">Miscellaneous</h4>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">10.1	Agreement enforceable by related bodies corporate</h4>

                    <p>These Terms are intended to confer benefits on ANZCRO and its related bodies corporate and to the maximum extent permitted by applicable law are enforceable by each of them.</p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">10.2	No liability for Force Majeure</h4>

                    <p>ANZCRO will not be liable for any loss, damage, delay or failure to perform any obligation under this agreement which is caused by any event or circumstance beyond its reasonable control.</p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">10.3	Construction</h4>

                    <p>In these Terms, any obligation not to do anything includes an obligation not to suffer, permit or cause that thing to be done.</p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">10.4	No assignment</h4>

                    <p>You shall not assign or otherwise transfer your Website registration or any rights or interests under these Terms or otherwise in relation to the Website without our prior written consent (in our sole discretion).  </p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">10.5	No agency</h4>

                    <ol type="a">
                      <li>Nothing in these Terms (or in our acceptance of your Website registration) constitutes a partnership, joint venture or relationship of principal and agent between you and us.  </li>
                      <li>You may not make any representations to your customers or anyone else in relation to Products or the Website (or its content or functionality).  </li>
                      <li>You may not make any representations on behalf of or in the name of, act or hold itself out as an agent or representative of, nor assume or create any obligations on behalf of, ANZCRO (or any of our related bodies corporate). </li>
                    </ol>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">10.6	Entire Agreement</h4>

                    <p>These Terms (and any other terms and conditions referred to in these Terms) constitute the entire agreement between you and us in respect of their subject matter and supersede all earlier negotiations, representations, warranties, understandings and agreements, whether oral or written relating to such subject matter.</p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">10.7	Severability </h4>

                    <p>If any part of these Terms are held by any court or administrative body of competent jurisdiction to be illegal, void or unenforceable, such determination will not impair the enforceability of the remaining parts of these Terms.</p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">10.8	No waiver </h4>

                    <p>A waiver of any provision of these Terms will not be effective unless given in writing, and then only to the extent that it is expressly stated to be given. A failure, delay or indulgence by either party in exercising any power or right will not operate as a waiver of that power or right</p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">10.9	Costs</h4>

                    <p>You will pay your own costs and expenses in connection with these Terms and your Website registration, access and use.</p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">10.10 Governing law and jurisdiction</h4>

                    <p>These Terms shall be governed by and construed in accordance with the laws of the relevant jurisdiction and you hereby submit to the non-exclusive jurisdiction of the Courts of the relevant jurisdiction. The relevant jurisdiction is New Zealand (in the case of bookings made via www.anzcro.com or www.anzcro.co.nz), Australia (in the case of bookings made via www.anzcro.com.au) or the United Kingdom (in the case of bookings made via www.anzcro.co.uk). </p>

                  </li>

                </ol>

              </v-flex>
              <v-flex xs12 md4  mt-3 order-xs1 order-md2>
                <v-card flat class="pa-0 elevation-0 dash-rounded mb-4 card-with-border">
                  <v-card-text class="pa-4">
                    <v-layout row>
                      <v-flex>
                        <span class="title font-weight-medium headerGrey d-block mb-2">Index</span>
                      </v-flex>
                    </v-layout>
                    <v-divider class="mb-2"></v-divider>
                    <v-layout row>
                      <v-flex>
                        <ol>
                          <li><a  href="#application">Application</a></li>
                          <li><a href="#registration">Registration</a></li>
                          <li><a href="#bookings">Bookings</a></li>
                          <li><a href="#liability">Liability and Indemnity</a></li>
                          <li><a href="#security">Security</a></li>
                          <li><a href="#ip">Intellectual Property</a></li>
                          <li><a href="#termination">Suspension and Termination</a></li>
                          <li><a href="#resolution">Dispute Resolution</a></li>
                          <li><a href="#privacy">Privacy</a></li>
                          <li><a href="#miscellaneous">Miscellaneous</a></li>
                        </ol>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  props: {
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({

  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    }
  },
};
</script>

<style>
:target::before {
  content: '';
  display: block;
  height: 100px; /* fixed header height*/
  margin: -100px 0 0; /* negative fixed header height */
}
.terms-and-conditions ol,
.terms-and-conditions ul{
  display:block;
  margin:0 0 16px;
  padding-left:18px;
}

.terms-and-conditions ol ol{
  margin:0;
}

.terms-and-conditions ol li,
.terms-and-conditions ul li{
  margin:18px 0;
  padding-left:12px;
}
</style>
