<template>
  <v-container pa-0 fluid>
    <v-layout row fill-height class="hero-waves hero-login">
      <v-flex>
        <v-container fill-height grid-list-lg>
          <v-layout dark row wrap fill-height align-center py-5>
            <v-flex xs12 sm8 offset-sm2 md6 offset-md3>
              <v-card
                dark
                :color="coalBlack"
                class="pa-3 pa-md4 elevation-0 card-with-border dash-rounded"
              >
                <v-layout row wrap>
                  <!-- If not submitted -->
                  <v-flex xs12 v-if="!submited">
                    <!-- Valid Key -->
                    <div v-if="validKey">
                      <h3 class="white--text title mt-3 mb-4">Reset your password</h3>
                    </div>
                    <!-- Invalid Key -->
                    <div v-else>
                      <h3 class="white--text title mt-3 mb-4">Invalid Key</h3>
                      <p>
                        Your reset password is invalid or it might have expired.
                        <br />Please
                        <router-link to="/recover-password/" class="kiwiGreen mx-1">request</router-link>a new one.
                      </p>
                    </div>
                  </v-flex>
                  <!-- If submitted -->
                  <v-flex v-else>
                    <h3 class="white--text title mt-3 mb-4">Password reset successful</h3>
                    <p>Your new password has been set. You can now <router-link to="/login/" class="kiwiGreen mx-1">login</router-link>to your account.</p>
                  </v-flex>
                </v-layout>

                <v-layout row wrap v-if="!submited">
                  <v-flex xs12 v-if="validKey">
                    <v-card flat class=" transparent py-2 px-0" >
                      <v-alert :value="true" type="error" v-if="errorMsg.length > 0">{{errorMsg}}</v-alert>

                      <v-form ref="resetPasswordForm">
                        <v-layout row>
                          <v-flex xs12 pb-1>
                            <v-text-field
                              v-model="formData.password"
                              :append-icon="show ? 'visibility' : 'visibility_off'"
                              :type="show ? 'text' : 'password'"
                              box
                              :rules="[rules.required, rules.min]"
                              label="New password (at least 6 characters)"
                              :disabled="resetting"
                              :color="kiwiGreen"
                              @click:append="show = !show"
                              required
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout row>
                          <v-flex xs12 pb-1>
                            <v-text-field
                              v-model="repeatPassword"
                              :append-icon="showRepeat ? 'visibility' : 'visibility_off'"
                              :type="showRepeat ? 'text' : 'password'"
                              box
                              :rules="[rules.required, v => (v === formData.password ? true : 'Password doesn\'t mach')]"
                              label="Confirm new password"
                              :disabled="resetting"
                              :color="kiwiGreen"
                              @click:append="showRepeat = !showRepeat"
                              required
                            ></v-text-field>
                          </v-flex>
                        </v-layout>

                        <v-layout row wrap>
                          <v-flex xs12 md12>
                            <v-btn
                              dark
                              :color="headerGrey"
                              large
                              class="elevation-0 ma-0"
                              @click="reset()"
                              :loading="resetting"
                              :disabled="resetting"
                            >Reset Password</v-btn>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </v-card>
                  </v-flex>
                </v-layout>


              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>


  </v-container>
</template>

<script>
import { userService } from '../dashboard/account/services/user.service';
export default {
  data: () => ({
    show: false,
    showRepeat: false,
    resetting: false,
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 6 || 'Min 6 characters',
    },
    errorMsg: '',
    formData: {
      password: '',
      key: '',
    },
    repeatPassword: '',
    submited: false,
  }),
  computed: {
    validKey() {
      return !!this.$route.query.key && typeof this.$route.query.key === 'string';
    },
  },
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
    this.formData.key = this.$route.query.key;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
    async reset() {
      if (!this.$refs.resetPasswordForm.validate()) {
        return;
      }
      this.resetting = true;
      try {
        await userService.resetPassword(this.formData);
        this.$refs.resetPasswordForm.reset();
        this.submited = true;
      } catch (e) {
        this.errorMsg = e.message;
      } finally {
        this.resetting = false;
      }
    },
  },
};
</script>

<style>
.standard-hero {
  height: 70vh;
}

.standard-hero .v-image__image {
  background-attachment: fixed;
}

.standard-content {
  margin-top: -36px !important;
  margin-bottom: 36px !important;
}

.hero-title h1,
.hero-title h3 {
  line-height: 1.2 !important;
}

@media (min-width: 600px) {
  .standard-hero {
    height: 60vh;
  }
  .hero-title .display-1 {
    font-size: 54px !important;
    line-height: 1.2 !important;
  }
}

@media (min-width: 960px) {
}
</style>
