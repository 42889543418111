<template>
  <v-container fill-height v-if="loading">
    <v-container>
      <v-layout row wrap justify-center>
          <v-progress-circular :size="50" :color="kiwiGreen" indeterminate></v-progress-circular>
      </v-layout>
      <v-layout row wrap justify-center>
        <slot/>
      </v-layout>
    </v-container>
  </v-container>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    displayedMessage: ''
  }),
  mounted() {
    this.displayedMessage = this.message
  },
  whatch: {
    loading(newVal) {
      if (!newVal) {
        this.displayedMessage = ''
      }
    }
  }
};
</script>
