<template>
    <v-container pa-0 fluid>
      <v-layout row>
        <v-flex>
          <v-card flat tile dark class="hero-home-slider">
            <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
              <v-carousel-item>
                <v-img
                  src="https://cdn.anzcro.com.au/wp-content/uploads/2023/06/hero-most-popular-au-itineraries-scaled.jpg"
                  gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.2) 65%"
                  class="home-hero"
                >
                  <v-container fill-height>
                    <v-layout dark column fill-height justify-space-between pt-5>
                      <v-flex text-xs-center class="home-hero-cta">
                        <v-layout row align-center fill-height class="fill-height-fix-ios">
                          <v-flex>
                            <img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/06/most-popular-au-itineraries-stacked.png" alt="Most Popular Australian Itineraries" class="campaign-title stacked" width="480" height="300" />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div class="hero-caption hidden-xs-only">
                    <small>Twelve Apostles, Great Ocean Road - Victoria</small>
                  </div>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="gradient-bar"></div>
  
      <v-layout row>
        <v-flex mb-5>
            <v-container grid-list-lg fill-height>
              <v-layout row wrap fill-height>
                <v-flex xs12 py-0 my-0>
                  <v-card tile class="pa-0 elevation-0 deals-content">
                    <v-card-text class="pa-5">
                      <span
                        class="headline font-weight-medium kiwiGreen d-block mb-3"
                      >A selection of our most popular Australian itineraries.</span>
                      <p>Take the road less travelled with one of these life-changing holidays in Australia. Whether it’s your first time visiting or you’re back for more, check out our most popular Australian itineraries below. As always, all packages can be customised. Contact us for more information.</p>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                  <v-card tile dark class="pa-0 ma-0 elevation-0">
                    <v-card-text class="px-3 py-2">
                      <span
                        class="headline font-weight-medium d-block"
                      >{{section.title}}</span>
                    </v-card-text>
                  </v-card>
                  <v-card tile class="px-3 py-3 elevation-0">
                    <v-layout row wrap ma-0>
                      <v-flex xs12 pa-0 hidden-sm-and-down>
                        <v-layout row wrap my-0>
                          <v-flex xs12 md7 lg8>
                            <span class="caption grey--text">Package Deal</span>
                          </v-flex>
                          <v-flex xs12 md3 lg2 text-md-right>
                            <span class="caption grey--text">From Price (GBP)</span>
                          </v-flex>
                          <v-flex xs12 md2 lg2 text-md-center>
                            <span class="caption grey--text">Agent Resources</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                        <v-divider></v-divider>
                        <v-layout row wrap align-center my-3>
                          <v-flex xs12 md7 lg8>
                            <v-layout row align-center>
                              <v-flex shrink hidden-md-and-down>
                                <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                              </v-flex>
                              <v-flex>
                                <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                                <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                                <span class="d-block package-data">
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Start location</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-if="!deal.call">
                                    <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Package Code</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-else>
                                    <b>Call ANZCRO to Book</b><v-icon class="mx-2" size="14" :color="kiwiGreen">phone</v-icon>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm6 md3 lg2 text-md-right>
                            <span class="headline d-block font-weight-medium mb-2 kiwiGreen">£{{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                            <span class="caption d-block grey--text">{{deal.complement}}</span>
                          </v-flex>
                          <v-flex xs12 sm6 md2 lg2 text-sm-right text-md-center>
                          <v-tooltip top v-if="deal.sm_tile">
                            <template v-slot:activator="{ on }">
                              <v-btn large v-on="on" icon class="card-with-border elevation-0 mr-1 px-0" color="white" :href="deal.sm_tile" target="_blank"><v-icon :color="kiwiGreen" size="22" class="">image</v-icon></v-btn>
                            </template>
                            <span>View Social Media Tile</span>
                          </v-tooltip>
                          <v-tooltip top v-if="deal.itinerary">
                            <template v-slot:activator="{ on }">
                              <v-btn large v-on="on" icon class="card-with-border elevation-0 mx-0 px-0" :color="kiwiGreen" :href="deal.itinerary" target="_blank"><v-icon color="white" size="22" class="">description</v-icon></v-btn>
                            </template>
                            <span>View Flyer</span>
                          </v-tooltip>
                        </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
      </v-layout>
  
  
    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      copied: false,
      copiedText: '',
      sections : [
        {
          title : 'Deals',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/05/deal-11d-outback-rail.jpg',
              name : '11 Day Outback Wilderness & Rail',
              price : '7528',
              complement : 'per person (twin share)',
              code : 'AUK2411DOWR',
              location : 'Darwin (DRW)',
              valid: 'May 2024 – Oct 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/05/11-Day-Outback-Wilderness-Rail.pdf',
              call : false,
              sm_tile : null
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/deal-crr.jpg',
              name : '12 Day City, Rock & Reef',
              price : '4568',
              complement : 'per person (twin share)',
              code : 'ANZ2412DCRR',
              location : 'Sydney (SYD)',
              valid: '15 Jun 2024 – 31 Mar 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/12-Day-City-Rock-Reef.pdf',
              call : false,
              sm_tile : null
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/13-day-pacific-coast-explorer.jpg',
              name : '13 Day Pacific Coast Explorer',
              price : '3125',
              complement : 'per person (twin share)',
              code : 'AUB2413DPCE',
              location : 'Cairns (CNS)',
              valid: '29 Apr 2024 – 31 Mar 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/13-Day-Pacific-Coast-Explorer.pdf',
              call : false,
              sm_tile : null
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/16-day-west-to-east-discovery.jpg',
              name : '16 Day West to East Discovery',
              price : '4418',
              complement : 'per person (twin share)',
              code : 'AUB2416DWED',
              location : 'Perth (PER)',
              valid: '01 Apr 2024 – 31 Sept 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/16-Day-West-to-East-Discovery.pdf',
              call : false,
              sm_tile : null
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/05/deal-17d-east-coast-adventure.jpg',
              name : '17 Day East Coast to Outback',
              price : '6705',
              complement : 'per person (twin share)',
              code : 'AUK2417DECT',
              location : 'Melbourne (MEL)',
              valid: 'Jun 2024 - Mar 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/05/17-Day-East-Coast-to-Outback.pdf',
              call : false,
              sm_tile : null
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/thumbnail.png',
              name : '18 Day Southern Australia Wilderness',
              price : '12213',
              complement : 'per person (twin share)',
              code : 'ANZ2418DSAW',
              location : 'Adelaide (ADL)',
              valid: '01 Aug 24 – 14 Mar 25',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/18-Day-Southern-Australia-Wilderness.pdf',
              call : false,
              sm_tile : null
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/19-day-australian-odyssey.jpg',
              name : '19 Day Australian Odyssey',
              price : '5899',
              complement : 'per person (twin share)',
              code : 'AUB2419DAO',
              location : 'Melbourne (MEL)',
              valid: '01 Apr 2024 – 31 Sept 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/19-Day-Australian-Odyssey.pdf',
              call : false,
              sm_tile : null
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/25-day-ultimate-australia-expedition.jpg',
              name : '25 Day Ultimate Australia Expedition',
              price : '7677',
              complement : 'per person (twin share)',
              code : 'AUB2425DUAE',
              location : 'Brisbane (BNE)',
              valid: '01 Apr 2024 – 31 Mar 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/25-Day-Ultimate-Australian-Expedition.pdf',
              call : false,
              sm_tile : null
            },
          ]
        },
      ]
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      },
      copyCode(event) {
        if(event){
          let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
          window.getSelection().selectAllChildren(copyCode);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();

          this.copiedText = 'Package code copied to clipboard';
          this.copied = true;
        }
      },
      copyLocation(event) {
        if(event){
          let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
          window.getSelection().selectAllChildren(copyLocation);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();

          this.copiedText = 'Package start location copied to clipboard';
          this.copied = true;
        }
      }
    },
  };
  </script>
  
  <style>
  .gradient-bar{
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
    background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
  }
  
  .deals-content{
    margin-top: -60px;
  }
  
  .package-data .v-chip{
    color:#9e9e9e;
    margin-left: 0;
  }
  
  .package-data .v-chip .v-chip__content{
    padding-right: 6px;
  }
  
  .deal-thumbnail{
    width: 88px;
    height: 88px;
  }
  </style>
  