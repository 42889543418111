<template>
  <v-btn dark :color="coalBlack" class="ml-0" v-bind="$attrs" v-on="$listeners">
    <slot/>
  </v-btn>
</template>

<script>
export default {
  data: () => ({
    coalBlack: "#30302F"
  })
};
</script>

<style>
</style>
