export default {
  coalBlack: '#30302F',
  kiwiGreen: '#65b32e',
  kiwiGreenTransparent: '#65b32e1f',
  coalBlack60: '#838382',
  headerGrey: '#595959',
  lightGrey: '#eaeaea',
  offWhite: '#fafafa',
  kiwiGreen20D: '#5A992E',
  aqua: '#4AA7B0',
  cafe: '#AB906A',
  cream: '#DDDBBA',
  yellow: '#EDC644',
  coolGrey: '#80909A',
  grey: '#808080',
  brightOrange: '#f47928',
};
