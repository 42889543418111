import { concat } from "ramda";
import { make } from "vuex-pathify";
import { searchService } from "@/services/search.service";

import { initialState } from "./state";
import { utilsService } from "@/shared/services/utils.service";
import { getAllSearchTabs } from "@/util-modules/SearchTabs";
import {
  lowestUnitRangeFilters,
  packagesRangePriceFilters,
} from "@/components/search/model/rangefilter";
import { sanitizePackages } from "@/components/search/model/results";
import { sortAlphabetically } from "@/components/search-results/model/sorting/general";
import { searchDetailService } from "@/services/searchdetail.service";
import hash from "object-hash";

const state = initialState();

const getters = {
  activeSearchTabObj: state => {
    return getAllSearchTabs().find(x => x.truncated === state.activeSearchTab);
  },

  accommodationRangePrice: state => () => {
    return lowestUnitRangeFilters(state.results);
  },

  packagesRangePrice: state => () => {
    return packagesRangePriceFilters(state.results);
  },

  carCamperRangePrice: state => () => {
    return lowestUnitRangeFilters(state.results);
  },

  travelRangePrice: state => () => {
    return lowestUnitRangeFilters(state.results);
  },

  generalRangePrice: state => () => {
    return lowestUnitRangeFilters(state.results);
  },
  itemShown: state => {
    return state.itemShown
  }
};

const actions = {
  ...make.actions(state),
  cleanUpResults(context) {
    context.commit("updateSearchResult", {
      searchResults: [],
      idSearch: "",
    });
  },

  setSearchParms(context, parms) {
    context.commit("setSearchParms", parms);
  },
  setActiveSearchTab(context, value) {
    context.commit("setActiveSearchTab", value);
  },
  async searchAccommodation(context, formData) {
    const channels = ["OZONE", "MITCHELL_CORP", "SITEMINDER", "HOTELBEDS", "ACCOR", "RMS", "TRAVELCLICK", "STAAH"];

    try {
      const data = await searchService.getAccommodations({
        ...formData,
        searchChannels: channels,
      });

      const resultsWithSearchId = data.searchResults
        .map(s => ({ ...s, idSearch: data.idSearch }))
        .sort(sortAlphabetically);
      context.commit("updateSearchResult", {
        searchResults: resultsWithSearchId,
      });
    } catch (error) {
      throw new Error("An unexpected error has occurred while searching for accommodation!");
    }
  },
  async searchEZIBEDAccommodation({ state, commit }) {
    const requestId = state.nextRequestId + 1;
    const channels = ["EZIBED"];
    try {
      commit("lastestRequestId", requestId);

      const data = await searchService.getAccommodations({
        ...state.parmsAccommodation,
        searchChannels: channels,
      });

      if (requestId !== state.lastestRequestId) return;

      const ezidebResultsWithSearchId = data.searchResults.map(s => ({
        ...s,
        idSearch: data.idSearch,
      }));

      const alphaResults = concat(state.results, ezidebResultsWithSearchId).sort(
        sortAlphabetically,
      );
      commit("lastestRequestId", requestId);
      commit("nextRequestId", requestId);
      commit("updateSearchResult", {
        searchResults: alphaResults,
      });
      commit("accomodationMerged", true);
    } catch (error) {
      throw new Error("An unexpected error has occurred while searching for accommodation!");
    }
  },
  async searchVehicle(context, formData) {
    try {
      const data = await searchService.getVehicles(formData);

      context.commit("updateSearchResult", data);
      return data.searchResults;
    } catch (err) {
      throw new Error("An unexpected error has occurred while searching for vehicles!");
    }
  },
  async searchMultidayTours(context, formData) {
    try {
      delete formData.destinationLocationId;
      const data = await searchService.getMultidayTours(formData);
      context.commit("updateSearchResult", data);
      return data.searchResults;
    } catch (err) {
      throw new Error("An unexpected error has occurred while searching for multi-day tours!");
    }
  },
  async searchSightseeing(context, formData) {
    try {
      const data = await searchService.getSightseeings(formData);
      context.commit("updateSearchResult", data);
      return data.searchResults;
    } catch (error) {
      throw new Error("An unexpected error has occurred while searching for sightseeing tours!");
    }
  },
  async searchTravel(context, formData) {
    try {
      const data = await searchService.getTravels(formData);
      context.commit("updateSearchResult", data);
      return data.searchResults;
    } catch (error) {
      throw new Error("An unexpected error has occurred while searching for travel and transfers!");
    }
  },
  async searchSki(context, formData) {
    try {
      const data = await searchService.getSkies(formData);
      context.commit("updateSearchResult", data);
      return data.searchResults;
    } catch (error) {
      throw new Error("An unexpected error has occurred while searching for skiing!");
    }
  },

  async searchDetails(context, params) {
    try {
      const data = await searchDetailService.getDetails(params);
      context.commit("updateSubItemDetailShown", data.result);
      return data;
    } catch (error) {
      throw new Error("An unexpected error has occurred while obtaining item details!");
    }
  },
  async supplierCurrency(context, currencyId) {
    try {
      const data = await utilsService.getCurrency(currencyId);
      context.commit("subItemSupplierCurrency", data.result);
      return data;
    } catch (error) {
      throw new Error("An unexpected error has occurred while obtaining supplier currency!");
    }
  },
  async searchPackages(context, formData) {
    try {
      const data = await searchService.getPackages(formData);
      data.searchResults = sanitizePackages(data.searchResults);
      context.commit("updateSearchResult", data);
      return data.searchResults;
    } catch (error) {
      throw new Error("An unexpected error has occurred while searching for packages!");
    }
  },

  async searchPackageDetails(context, params) {
    try {
      const data = await searchService.getPackagesDetails(params);
      context.commit("updateSubItemDetailShown", data.result);
      return data;
    } catch (error) {
      throw new Error("An unexpected error has occurred while obtaining item details!");
    }
  },
  updateViewDisplayed(context, value) {
    context.commit("updateViewDisplayed", value);
  },
  updateShowSubItemsDrawer(context, value) {
    context.commit("updateShowSubItemsDrawer", value);
  },
  updateItemShown(context, item) {
    context.commit("updateItemShown", item);
  },
  updateSubItemShown(context, item) {
    context.commit("updateSubItemShown", item);
  },
  updateSubItemDetailShown(context, itemDetail) {
    context.commit("updateSubItemDetailShown", itemDetail);
  },
  updateItemSubResults(context, value) {
    context.commit("updateItemSubResults", value);
  },
  reset({ commit }) {
    commit("reset");
  },
};

const mutations = {
  ...make.mutations(state),

  reset(state) {
    const s = initialState();
    for (let key in s) {
      state[key] = s[key];
    }
  },

  setSearchParms(state, parms) {
    state.searchParms = parms;
  },
  setActiveSearchTab(state, value) {
    state.activeSearchTab = value;
  },
  updateSearchResult(state, data) {
    state.results = data.searchResults.map(x => ({ hash: hash(x), ...x }));
    state.idSearch = data.idSearch;
    state.duration = data.duration;
  },

  updateViewDisplayed(state, value) {
    state.viewDisplayed = value;
  },
  updateShowSubItemsDrawer(state, value) {
    state.showSubItemsDrawer = value;
  },
  updateItemShown(state, item) {
    state.itemShown = item;
  },
  updateSubItemShown(state, subItem) {
    state.subItemShown = subItem;
  },
  updateSubItemDetailShown(state, subItemDetail) {
    state.subItemDetailShown = subItemDetail;
  },
  updateItemSubResults(state, data) {
    state.itemShown.subResults = data
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
