import { ProductSearchResponse } from '@/model/searchproduct';
import {
  AccommodationSearch,
  MultidaySearch,
  SightseeingSearch,
  SkiSearch,
  TourSearch,
  TravelSearch,
  VehicleSearch,
  PackageSearch,
  PackageDetailParms,
} from '@/model/search';
import appService from './app.service';
import { PackageSearchResponse } from '@/model/searchpackage';
import { O3ApiResponse } from '@/model/response';
import { ProductDetailsResource } from '@/components/itinerary/model/product/product';

export const searchService = {
  getAccommodations: (parms: AccommodationSearch): Promise<ProductSearchResponse> =>
    appService.post('/search/accommodation', parms),

  getMultidayTours: (parms: MultidaySearch): Promise<ProductSearchResponse> =>
    appService.post('/search/multidayTours', parms),

  getSightseeings: (parms: SightseeingSearch): Promise<ProductSearchResponse> =>
    appService.post('/search/sightseeing', parms),

  getSkies: (parms: SkiSearch): Promise<ProductSearchResponse> =>
    appService.post('/search/ski', parms),

  getTours: (parms: TourSearch): Promise<ProductSearchResponse> =>
    appService.post('/search/tours', parms),

  getTravels: (parms: TravelSearch): Promise<ProductSearchResponse> =>
    appService.post('/search/travel', parms),

  getVehicles: (parms: VehicleSearch): Promise<ProductSearchResponse> =>
    appService.post('/search/vehicle', parms),

  getPackages: (parms: PackageSearch): Promise<PackageSearchResponse> =>
    appService.post('/packages/search', parms),

  getPackagesDetails: (parms: PackageDetailParms): Promise<O3ApiResponse<ProductDetailsResource>> =>
    appService.get('/packages/details', parms),
};
