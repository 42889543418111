// @babel/polyfill was deprecated https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md#babelpolyfill
import "core-js/stable";
import "regenerator-runtime/runtime";

import 'url-polyfill';
import 'whatwg-fetch'; //https://github.com/github/fetch
import Vue from 'vue';
import './plugins/vuetify';
import VueRx from 'vue-rx';
import App from './App.vue';
import router from './router.co.uk';
import store from './vuex/index';
import { useLoading } from '@/shared/vueloading';
import smoothscroll from '@/util-modules/smoothscroll';
import '@/shared/register';
import errorHandler from '@/plugins/errorhandler';
import themeColor from '@/plugins/themeColor';
import { configureFilters } from '@/shared/filter';
import { setupAppServie } from '@/shared/configureAppService';
import { trackErrorHandler } from '@/shared/analytics';
import analyticsSubscriber from '@/observers/subscribers/analytics.subscriber';
import { RegionPlugin } from './plugins/region';
import currency from "./plugins/currency";

// initialize analytics component
analyticsSubscriber.init();

Vue.use(errorHandler);
Vue.use(themeColor);
Vue.use(VueRx);
Vue.use(RegionPlugin, { region: 'CO.UK' });
Vue.use(currency);

smoothscroll.polyfill();
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

useLoading(Vue);
Vue.config.productionTip = false;

trackErrorHandler(Vue);

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}).$mount('#app');

setupAppServie({ store, router });
configureFilters(Vue);
