import { cmsService } from "@/services/cms.service";
import { make } from 'vuex-pathify';
import hash from "object-hash";

const state = {
  posts: [],
  postsResponseMap: new Map(),
  post: undefined,
  categories: [],
};

const mutations = {
  ...make.mutations(state),
  appendPosts(state, payload) {
    let posts = state.postsResponseMap.get(payload.key);
    if (!posts) {
      posts = { data: [] };
    }
    state.postsResponseMap.set(payload.key, {
      data: posts.data.concat(payload.posts.data),
      meta: payload.posts.meta,
    })
  }
}

const actions = {
  ...make.actions("post"),
  ...make.actions("categories"),
  async loadPosts({ state, commit }, payload = {}) {
    let _payload = payload;
    const key = hash(_payload);
    const statePosts = state.postsResponseMap.get(key);
    if (statePosts) {

      if (statePosts.page === statePosts.totalPostsPages) {
        return statePosts;
      }

      _payload = Object.assign(payload, { page: statePosts.page + 1 });
    }
    const posts = await cmsService.getPostsWithMeta(_payload);
    commit("appendPosts", { key: key, posts: posts });
    return posts;
  },
  async loadPost({ dispatch, state }, payload) {
    const searchBySlug = x => x.slug === payload.slug;
    //let post = state.posts.find(searchBySlug);
    const iterator = state.postsResponseMap.entries();
    let item = iterator.next();
    let post = undefined;
    while (!item.done) {
      post = item.value[1].data.find(searchBySlug)
      if (post) {
        break;
      }
      item = iterator.next();
    }

    if (!post) {
      const result = await cmsService.getPostsWithMeta(payload);
      post = result.data.find(searchBySlug);
    }
    dispatch("setPost", post);
    return post;
  },
  async loadCategories({ dispatch, state }) {
    if (state.categories.length > 0) {
      return state.categories;
    }
    const categories = await cmsService.getCategories();
    dispatch("setCategories", categories);
    return categories;
  },
};

const getters = {
  ...make.getters(state),
  getPosts: (state) => (payload) => {
    return state.postsResponseMap.get(hash(payload))
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
