import { sortLowToHigh as packageSort } from '@/components/search-results/model/sorting/packages';
import { sortLowToHigh as generealSort } from '@/components/search-results/model/sorting/general';
import { SearchResult } from '@/model/searchproduct';
import { PackageSearchResult } from '@/model/searchpackage';

export const lowestUnitRangeFilters = (results: SearchResult[]) => {
  if (results && results.length > 0) {
    let sorted = results.slice().sort(generealSort);
    return [Math.floor(sorted[0].lowestUnitPrice), Math.ceil(sorted[sorted.length - 1].lowestUnitPrice)];
  }
  return [0, 0];
};

export const packagesRangePriceFilters = (results: PackageSearchResult[]) => {
  if (results && results.length > 0) {
    let sorted = results.slice().sort(packageSort);
    return [
      sorted[0].packageWholesalerPrices[0].grossPrice,
      sorted[sorted.length - 1].packageWholesalerPrices[0].grossPrice,
    ];
  }
  return [0, 0];
};
