import Vue from "vue";
import Vuex from "vuex";
import pathify from "./plugins/pathify";
import { make } from "vuex-pathify";
import { clone } from "ramda";

import appService from "@/services/app.service";
import cache from "@/util-modules/cache";

import utilsModule from "./utils/utils.module";
import dashboardModule from "../components/dashboard/store/dashboard.module";
import printModule from "../components/dashboard/store/print.module";
import wholesalerModule from "../components/dashboard/store/wholesaler.module";
import itineraryModule from "../components/itinerary/store/itinerary.module";
import searchModule from "../components/search/store/search.module";
import accountModule from "../components/dashboard/account/store/account.module";
import blogModule from "@/components/public/blog/store/blog.module";
import errorModule from "@/error/store/error.module";
import successModule from "@/success/store/success.module";
import { MayBe, either } from "@/util-modules/maybe";
import userLoadedSubject from "@/observers/subjects/userLoaded.subject";
import { isAgent, isAgencyAdministrator, isAdmin } from "@/shared/model/user";

Vue.use(Vuex);

const initialState = () => ({
  user: undefined,
});

const state = initialState();

const modules = {
  dashboardModule,
  printModule,
  itineraryModule,
  utilsModule,
  searchModule,
  accountModule,
  blogModule,
  errorModule,
  successModule,
  wholesalerModule,
};

const store = new Vuex.Store({
  plugins: [pathify.plugin],
  modules: clone(modules),
  state,
  getters: {
    ...make.getters(state),
    isFromAgency: state => {
      return !!state.user && (isAgent(state.user) || isAgencyAdministrator(state.user));
    },
    isAgent: state => {
      return !!state.user && isAgent(state.user);
    },
    isAgencyAdministrator: state => {
      return !!state.user && isAgencyAdministrator(state.user);
    },
    isAdmin: state => {
      return !!state.user && isAdmin(state.user);
    },
    currency: state => {
      return MayBe.of(state)
        .map(state => state.user)
        .map(user => either(user.orgCurrencyCode, user.orgCurrencySymbol))
        .fold("")(symbol => symbol.replace("$", ""));
    },
  },
  actions: {
    ...make.actions(state),
    async login(context, credentials) {
      try {
        try {
          await appService.get("/session");
        } catch (err) {
          //err
        }

        await appService.post("/session", credentials);
        preFetchLocations();
      } catch (err) {
        throw "Authentication was unsuccesful!";
      }
    },
    async logout({ commit }) {
      try {
        await appService.delete("/session");
        commit("user", undefined);
        commit("wholesalerModule/currentWholesaler", null)
      } catch (err) {
        throw "Unexpected error ocurred!";
      } finally {
        commit("user", undefined);
        commit("resetState");
      }
    },
    async loadUser({ commit }) {
      try {
        const data = await appService.get("/userDetails");
        userLoadedSubject.next(data);
        commit("user", data);

        //Need to update this when new property is added in the API
        const wholesalerKeys = Object.keys(data.wholesalerMap).map(wholesaler => Number(wholesaler))
        if(wholesalerKeys.length) {
          commit("wholesalerModule/currentWholesaler", Math.min(...wholesalerKeys).toString())
        }
        return data;
      } catch (e) {
        throw "Unexpected error ocurred!";
      }
    },
    async validate({ state }) {
      if (!state.user) return null;
      await appService.get("/session");
      return state.user;
    },
    async updateUser({ commit }, data) {
      commit("user", data);
    },
  },
  mutations: {
    user(state, user) {
      if (user === undefined) {
        state.user = undefined;
        cache.deleteCache("_oa_user");
        return;
      }
      if (window) {
        cache.setCache("_oa_user", user);
      }
      state.user = cache.getCache("_oa_user");
    },
    resetState(state) {
      for (let key in modules) {
        state[key] = clone(modules[key].state);
      }
    },
  },
});

if (window && cache.getCache("_oa_user")) {
  store.state.user = cache.getCache("_oa_user");
}

if (window && cache.getCache("_oa_wholesaler")) {
  store.state.wholesalerModule.currentWholesaler = cache.getCache("_oa_wholesaler");
}

function preFetchLocations() {
  // TODO: change with rxjs later
  Promise.all([
    store.dispatch("utilsModule/loadLocations"),
    store.dispatch("utilsModule/loadCountries"),
  ]);
}

export default store;
