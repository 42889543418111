<template>
  <v-container pa-0 fluid>
    <!-- Hero Section -->
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-hero-tas-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">{{current}}</h1>
                          <h3 class="white--text title mb-4">{{currentArea}}</h3>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Wineglass Bay - Credit: Tourism Australia</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- Main -->
    <v-layout row>
      <v-flex>
        <v-card flat tile class="pb-5">
          <v-container grid-list-xl class="destination-main">
            <v-layout row wrap mt-2>
              <!-- In Page Navigation -->
              <v-flex xs12>
                <v-toolbar
                  flat
                  dense
                  tabs
                  :color="lightGrey"
                  class="card-with-border dash-rounded hidden-xs-only content-nav-toolbar"
                >
                  <v-card flat height="48">
                    <v-icon size="16" color="grey" class="mx-3 my-3">arrow_downward</v-icon>
                  </v-card>
                  <v-divider vertical></v-divider>
                  <v-toolbar-items>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#introduction', scrollToOptions )"
                      class="ma-0 hidden-sm-and-down"
                    >Intro</v-btn>
                    <v-divider vertical class="hidden-sm-and-down"></v-divider>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#gallery', scrollToOptions )"
                      class="ma-0"
                    >Gallery</v-btn>
                    <v-divider vertical></v-divider>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#the-best-of', scrollToOptions )"
                      class="ma-0"
                    >Highlights</v-btn>

                  </v-toolbar-items>
                  <v-divider vertical></v-divider>
                  <v-card flat height="48" width="100%">&nbsp;</v-card>
                </v-toolbar>
                <v-card
                  flat
                  :color="lightGrey"
                  class="card-with-border v-card--btn hidden-sm-and-up content-nav-card"
                >
                  <v-layout row wrap ma-0>
                    <v-flex xs6 pa-0>
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#introduction', scrollToOptions )"
                        class="ma-0"
                      >Intro</v-btn>
                    </v-flex>
                    <v-flex xs6 pa-0 class="border-l">
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#gallery', scrollToOptions )"
                        class="ma-0"
                      >Gallery</v-btn>
                    </v-flex>
                    <v-flex xs12 pa-0>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs6 pa-0>
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#the-best-of', scrollToOptions )"
                        class="ma-0"
                      >Highlights</v-btn>
                    </v-flex>
                    <v-flex xs6 pa-0 class="border-l">
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <!-- Content -->
              <v-flex xs12 md9 lg7>
                <v-layout row wrap>
                  <!-- Destination Content -->
                  <v-flex xs12 id="content-wrapper">
                    <!-- Introduction -->
                    <v-layout row id="introduction" mt-3>
                      <v-flex>
                        <h4
                          class="title mb-3"
                        >Tasmania’s countless natural attractions – from the wonders of Wineglass Bay to the pristine wilderness of Cradle Mountain– are all within easy reach of the island’s two biggest cities, Hobart and Launceston. Discover a gourmet paradise, where people live with the land and sea and there is a natural flow from paddock to plate. The island’s dramatic scenery and compact size is filled with variety, and it’s the perfect place to explore your own way.</h4>
                      </v-flex>
                    </v-layout>

                    <!-- Gallery -->
                    <v-layout row wrap id="gallery" mt-3>
                      <v-flex xs12 pb-1>
                        <h4 class="title">Gallery</h4>
                      </v-flex>
                      <v-flex xs12>
                        <v-card flat dark class="mb-3">
                          <v-carousel
                            height="auto"
                            class="elevation-0 destination-gallery"
                            hide-delimiters
                          >
                            <v-carousel-item v-for="(item, index) in galleryItems" :key="index">
                              <v-img
                                :src="item.image"
                                gradient="to bottom, rgba(22,22,22,.35), rgba(22,22,22,0.1) 15%, rgba(22,22,22,0.1) 85%, rgba(22,22,22,0.65)"
                                aspect-ratio="1.5"
                              >
                                <v-layout column fill-height ma-0>
                                  <v-flex text-xs-right>
                                    <b>{{index + 1}}</b>
                                    / {{galleryItems.length}}
                                  </v-flex>
                                  <v-flex shrink><small class="d-block">{{item.caption}}</small></v-flex>
                                </v-layout>
                              </v-img>
                            </v-carousel-item>
                          </v-carousel>
                        </v-card>
                      </v-flex>
                    </v-layout>

                    <!-- The Best of -->
                    <v-layout row id="the-best-of" mt-3>
                      <v-flex>
                        <h4 class="title mb-3">Tasmania Highlights</h4>
                        <ul class="subheading mb-3">
                          <li>
                            <b>Explore Hobart’s Capital Treats</b> – Tasmania's welcoming capital city, Hobart, is pressed between a mountain and a river, and provides a diverse range of city activities and experiences. Wander among the city's beautiful sandstone colonial architecture, drive to the summit of Mount Wellington for the best of Hobart's views and dine on Tasmania's famously fresh produce in any number of outstanding restaurants.
                          </li>
                          <li>
                            <b>Meet a devil</b> – Wildlife parks across Tasmania offer opportunities to see the state's emblematic Tasmanian devil. Take a night tour at Bonorong Wildlife Sanctuary, 30 minutes drive north of Hobart, and you'll get to feed the devils in a tug-of-war game; or join a Devil Tracker Tour at the Tasmanian Devil Unzoo on the Tasman Peninsula (about a one-hour drive south-east of Hobart) to help monitor wild populations.
                          </li>
                          <li>
                            <b>Rock the cradle </b> – The most famous of Tasmania's multitude of mountains is Cradle Mountain, a dramatic cliff-lined peak rising from the shores of Dove Lake in the state's north-west. View it from the six kilometre (3.7 mile) Dove Lake Circuit walking track or, if you're feeling energetic, climb to Marions Lookout for a stunning view over mountain and lake.
                          </li>
                          <li>
                            <b>Wander the Sands of Wineglass Bay</b> – A short walk from the car park at Freycinet National Park brings you to a lookout platform above the flawless white curve of Wineglass Bay on the eastern Freycinet Peninsula (three hours drive north-east of Hobart). From here, walk down to the beach and feel the sand between your toes. You'll probably meet a kangaroo or two on the beach and see dolphins playing in the water.
                          </li>
                          <li>
                            <b>Be Seduced by Seafood</b> – Seafood can come no fresher than this, as you take a boat from Hobart's docks for a banquet pulled straight from the ocean on a Tasmanian Seafood Seduction trip run by Pennicott Wilderness Journeys. On this day-long tour, towards the coast of southern Bruny Island, you'll harvest oysters straight from a farm's leases and feast on crayfish and abalone caught by your local guide. The produce is barbecued right on the boat and paired with matching local wines.
                          </li>
                          <li>
                            <b>Visit the underground art world of MONA</b> – Hobart's amazing, subterranean Museum of Old and New Art, or MONA, is a heady combination of art and architecture. A multi-tiered labyrinth cut into sandstone cliffs in the city's northern suburbs, this world-class gallery has a collection of art designed to provoke, with a range of dining and drinking options including an onsite vineyard and brewery.
                          </li>
                          <li>
                            <b>Delve into convict history</b> – World Heritage-listed penitentiary buildings around Tasmania tell the story of almost 50 years of harsh convict life in the 19th century. Australia's most notorious convict settlement sits in the beautiful coastal setting of Port Arthur, a one hour drive south-east of Hobart. If you're feeling brave, stick around for the nightly ghost tour.
                          </li>
                          <li>
                            <b>Go nuts about Stanley</b> – One look at cute Stanley, on Tasmania's northwest coast, is usually enough to inspire love at first sight. This colourful and welcoming fishing town huddles at the base of a curious rectangular hill named the Nut. It's the plug of a former volcano. Little penguins waddle ashore each night across long Godfreys Beach.
                          </li>
                        </ul>
                      </v-flex>
                    </v-layout>

                    <!-- Map
                    <v-layout wrap row id="map" mt-3>
                      <v-flex xs12 pb-1>
                        <h4 class="title">Map</h4>
                      </v-flex>
                      <v-flex xs12>
                        <v-card flat class="v-card--btn">
                          <v-img
                            src="https://cdn.anzcro.com.au/wp-content/uploads/2019/12/map-thumbnail-auckland.jpg"
                            height="240"
                          >
                            <v-layout column fill-height ma-0 align-end>
                              <v-flex>&nbsp;</v-flex>
                              <v-flex shrink>
                                <v-btn
                                  :color="lightGrey"
                                  @click="destinationMapDialog=true;"
                                >Expand</v-btn>
                              </v-flex>
                            </v-layout>
                          </v-img>
                        </v-card>
                      </v-flex>
                    </v-layout> -->
                  </v-flex>
                </v-layout>
              </v-flex>

              <!-- Other Destinations -->
              <v-flex xs12 md3 lg4 offset-lg1 id="other" mt-4>
                <h4 class="title mb-4 grey--text">Other Destinations</h4>
                <DestinationIndex :currentDestination="current" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- Destination Map Dialog -->
    <v-dialog v-model="destinationMapDialog" max-width="900px">
      <v-card class="pa-0">
        <v-layout row pa-3 align-center>
          <v-flex>
            <span class="d-block headline font-weight-bold">Map</span>
          </v-flex>
          <v-flex shrink>
            <v-btn
              :color="lightGrey"
              small
              fab
              class="elevation-0 ma-0 card-with-border"
              @click="destinationMapDialog=false;"
            >
              <v-icon size="24">close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-card-text class="pa-3">
          <v-responsive :aspect-ratio="800/600">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d817202.3861024495!2d174.30502620606845!3d-36.86170744554708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d47fb5a9ce6fb%3A0x500ef6143a29917!2sAuckland%2C%20New%20Zealand!5e0!3m2!1sen!2sau!4v1575248618684!5m2!1sen!2sau" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
          </v-responsive>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import DestinationIndex from '@/components/public/destinations/components/DestinationIndexAustralia.vue';
export default {
  data: () => ({
    current: 'Tasmania',
    currentArea: 'Australia',
    galleryItems: [
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-tas-001.jpg',
        caption: 'Bay of Fires - Credit: Tourism Australia',
      },
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-tas-002.jpg',
        caption: 'Port Arthur Historic Site - Credit: Hype TV',
      },
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-tas-003.jpg',
        caption: 'Museum of Old and New Art, Hobart - Credit: MONA',
      },
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-tas-004.jpg',
        caption: 'Moorilla cellar door - Credit: MONA',
      },
    ],
    destinationMapDialog: false,
    scrollToOptions: {
      easing: 'easeInOutCubic',
      duration: 300,
      offset: 50,
    },
  }),
  components: {
    DestinationIndex,
  },
  mounted: function() {
    document.documentElement.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
.container.destination-main {
  padding: 24px;
}

.content-nav-toolbar .v-toolbar__content {
  padding: 0 !important;
}

.content-nav-card .flex.border-l {
  border-left: solid 1px rgba(0, 0, 0, 0.12) !important;
}

#content-wrapper .title {
  line-height: 1.3 !important;
}

#content-wrapper li {
  margin-bottom: 16px;
}

#content-wrapper li:last-child {
  margin-bottom: 0;
}

.v-avatar.summer {
  border: solid 4px #ffaa00 !important;
}
.v-avatar.winter {
  border: solid 4px #adc9d7 !important;
}

@media (min-width: 600px) {
  .container.destination-main {
    padding: 24px 32px;
  }
}

@media (min-width: 960px) {
  .container.destination-main {
    padding: 24px;
  }
}
</style>
