<template>
  <v-container pa-0 fluid>
    <v-layout row class="hero-waves">
      <v-flex>
        <v-container fill-height>
          <v-layout dark row wrap fill-height align-center pt-5>
            <v-flex xs12 text-xs-center text-md-left class="hero-title">
              <h1 class="white--text display-1 font-weight-bold mb-3">Privacy Policy</h1>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-layout row class="terms-and-conditions">
      <v-flex>
        <v-card flat tile class="pb-5">
          <v-container grid-list-xl fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 md8 pa-3 mb-4 mt-3 order-xs2 order-md1>

                <h4 class="subheading my-3 text-uppercase font-weight-medium">Our Privacy Commitment</h4>

                <p>We are committed to being open and transparent about how we manage your Personal Information. </p>

                <p>Your use of our Services generates information, including at times Personal Information about you and your Permitted Users. It is important that you read this Privacy Policy which sets out how we manage the information generated through your use of the Services. It explains what information is being collected, how it is used and who can access it. It will help you make informed decisions about sharing your Personal Information with us.</p>

                <p>We have a few fundamental principles: </p>

                <ul>
                  <li>We will <b>always</b> collect, store, use and disclose Personal Information in accordance with all applicable privacy laws. However, we have also put in place this Privacy Policy to protect Personal Information you submit or we collect.</li>
                  <li>We will <b>only</b> use your Personal Information when we have lawful grounds to do so, it is necessary for us to deliver you our Services, or to perform other necessary business functions and activities.</li>
                  <li>We will <b>not</b> use or disclose your Personal Information for purposes unrelated to our business activities and the Services, unless we first obtain your consent or there is a legitimate legal basis for doing so.</li>
                </ul>

                <ol>
                  <li>
                    <h4 class="title mt-5 mb-3 text-uppercase" id="application">Application</h4>

                    <p>This Privacy Policy explains how we and our subsidiaries and related bodies corporate and any website we operate, collect, handle and protect your Personal Information.</p>

                    <p>By using the Services or providing your Personal Information to us, you consent to our collection, storage, use and disclosure of your Personal Information in accordance with this Privacy Policy. If you do not agree with any part of this Policy, you must not provide your Personal Information to us.  </p>

                    <p>This Privacy Policy should be read together with our respective <a href="/website-terms/">website terms and conditions</a>. </p>
                  </li>

                  <li>
                    <h4 class="title  mt-5 mb-3  text-uppercase" id="terminology">Important Terminology</h4>

                    <p>Our business interests span Australia, New Zealand and the United Kingdom. "<b>We</b>", "<b>our</b>", or "<b>us</b>" collectively refers to:</p>

                    <ul>
                      <li>ANZCRO New Zealand Limited;</li>
                      <li>ANZCRO (UK) Limited;</li>
                      <li>Australian New Zealand Central Reservation Office Pty Limited; and</li>
                      <li>Ozone Technology Holdings Limited.</li>
                    </ul>

                    <p>The laws we comply with in our dealings with your Personal Information will depend on your location. We will always comply with <b>Applicable Privacy Laws</b> and <b>Applicable Anti-Spam Laws</b> which means: </p>

                    <ul>
                      <li>if you are in Australia, the Privacy Act 1988 (Cth) (Australian Privacy Act), and the Spam Act 2003 (Cth); or</li>
                      <li>if you are in New Zealand, the Privacy Act 1993 and Unsolicited Electronic Messages Act 2007; or</li>
                      <li>if you are in the United Kingdom, the Data Protection Act 2018 and the Privacy and Electronic Communications Regulations 2003. </li>
                    </ul>

                    <p><b>Personal Information</b> means: </p>

                    <ul>
                      <li>if you are in Australia, information or an opinion, whether true or not, and whether recorded in a material form or not, about an identified individual, or an individual who is reasonably identifiable; or</li>
                      <li>if you are in New Zealand, any information about an identifiable individual; or</li>
                      <li>if you are in the United Kingdom, any information which is related to an identified or identifiable natural person. </li>
                    </ul>

                    <p><b>Permitted Users</b> means people authorised to access the Services through your organisation. </p>

                  </li>

                  <li>
                    <h4 class="title  mt-5 mb-3  text-uppercase" id="dealing-anonymously">Dealing with us anonymously</h4>

                    <p>Where it is lawful and practicable to do so, you may deal with us anonymously (e.g. when enquiring about our services generally).  However, we usually need your name, contact information and other details to enable us to provide our Services to you. </p>

                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3  text-uppercase" id="what-we-collect">What information do we collect</h4>

                    <p>Information you provide to us directly: Our usual practice is to collect Personal Information directly from you, and your Permitted Users, when you subscribe to, and use, the Services. A few examples include:</p>

                    <ul>
                      <li>Personal details: given name(s).</li>
                      <li>Demographic information: date of birth; age; title. </li>
                      <li>Contact details: email address; correspondence address; telephone number.</li>
                    </ul>

                    <p><b>Information we get from third parties:</b> We may obtain Personal Information from authorised third parties (e.g. travel booking agencies). </p>

                    <p><b>Information we collect automatically:</b> We may collect Personal Information about you automatically when you visit our websites or use our Services, like your IP address, location and device type. Some of this information may be collected using cookies, our sensor technology and similar tracking technologies. </p>

                    <p><b>Information we create in the performance of the Services:</b> We may also create or obtain Personal Information, such as your interactions with us, and any interactions we have with providers of Services on your behalf. </p>

                    <p><b>Information you make public:</b> We may obtain your Personal Information that you manifestly choose to make public.</p>

                    <h4 class="subheading mt-4 mb-3 text-uppercase font-weight-medium">How we use your personal information</h4>

                    <p>Unless otherwise specified in this Privacy Policy, we process your Personal Information only to the extent necessary to protect our legitimate interests, in relation to your contractual performance of your obligations, for the effective provision of the Services, or to the extent that you consent to the processing of your Personal Information. </p>

                    <p>Primarily, we collect your Personal Information so that we can provide you with the Services and any related services you may request. In doing so, we may use the Personal Information we have collected from you for purposes related to the Services including:</p>

                    <ul>
                      <li>to facilitate the Services and business operations;</li>
                      <li>to internal purposes (such as record keeping, account and database management and other administrative purposes including keeping track of billing and payments);</li>
                      <li>for marketing and communication purposes such as making suggestions and recommendations to you about goods or services that may be of interest to you and to conduct research and produce reports;</li>
                      <li>to improve our website, Services, marketing, customer relationships and experiences (using data analytics etc.);</li>
                      <li>to administer and protect our business and keep the Services working, safe and secure (by troubleshooting, analysis, testing, system maintenance and monitoring and addressing other security/technical issues);</li>
                      <li>to notify you about changes or updates to the Services; and</li>
                      <li>for any other purpose reasonably incidental or ancillary to these purposes. </li>
                    </ul>

                    <p>By using the Services, you consent to your Personal Information being collected, stored, used and disclosed in this way and for any other use you authorise. You can always choose not to provide your Personal Information to us, but it may mean that we are unable to provide you with the Services.</p>

                    <p>We will always endeavour to be clear about the purposes for which we are processing that Personal Information and our lawful basis for doing so. </p>

                    <p>We set out below, in a table format, a description of the ways we plan to use your Personal Information. </p>

                    <p>Note that we may process your Personal Information using more than one lawful ground depending on the specific purpose for which we are using your information. Please contact us if you would like details about the specific legal ground we are relying on to process your Personal Information where more than one ground has been set out in the table below.</p>

                    <v-card class="elevation-0 card-with-border dash-rounded mt-4">
                      <v-layout row ma-0 hidden-xs-only>
                        <v-flex xs12 pa-0>
                          <v-card dark class="elevation-0 pa-3" color="#454545">
                            <v-layout row>
                              <v-flex xs6>
                                Purpose / Activity
                              </v-flex>
                              <v-flex xs6>
                                Lawful basis for processing
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <v-divider class="hidden-xs-only"></v-divider><!-- Starts Row -->
                      <v-layout row wrap ma-0>
                        <v-flex xs12 sm6 pa-0>
                          <v-card tile class="elevation-0 pa-3">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Purpose / Activity</span>
                            <p class="font-weight-bold">To facilitate the Services and business operations.</p>
                          </v-card>
                        </v-flex>
                        <v-flex xs12 sm6 pa-0>
                          <v-card tile class="elevation-0 pa-3" color="#fafafa">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Lawful basis for processing</span>
                            <p>Necessary for our legitimate interests.</p>
                            <p>Performance of a contract with you (e.g. using our online platforms or websites or making bookings with Service providers on your behalf).</p>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider><!-- Starts Row -->
                      <v-layout row wrap ma-0>
                        <v-flex xs12 sm6 pa-0>
                          <v-card tile class="elevation-0 pa-3">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Purpose / Activity</span>
                            <p class="font-weight-bold">For internal purposes (such as record keeping, account and database management and other administrative purposes including keeping track of billing and payments).</p>
                          </v-card>
                        </v-flex>
                        <v-flex xs12 sm6 pa-0>
                          <v-card tile class="elevation-0 pa-3" color="#fafafa">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Lawful basis for processing</span>
                            <p>Necessary for our legitimate interests to manage the delivery of our Services to you.</p>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider><!-- Starts Row -->
                      <v-layout row wrap ma-0>
                        <v-flex xs12 sm6 pa-0>
                          <v-card tile class="elevation-0 pa-3">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Purpose / Activity</span>
                            <p class="font-weight-bold">For marketing and communication purposes such as making suggestions and recommendations to you about goods or services that may be of interest to you and to conduct research and produce reports.</p>
                          </v-card>
                        </v-flex>
                        <v-flex xs12 sm6 pa-0>
                          <v-card tile class="elevation-0 pa-3" color="#fafafa">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Lawful basis for processing</span>
                            <p>Necessary for our legitimate interests to develop our products/services and grow our business.</p>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider><!-- Starts Row -->
                      <v-layout row wrap ma-0>
                        <v-flex xs12 sm6 pa-0>
                          <v-card tile class="elevation-0 pa-3">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Purpose / Activity</span>
                            <p class="font-weight-bold">To improve our website, products/services, marketing, customer relationships and experiences (using data analytics etc.)</p>
                          </v-card>
                        </v-flex>
                        <v-flex xs12 sm6 pa-0>
                          <v-card tile class="elevation-0 pa-3" color="#fafafa">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Lawful basis for processing</span>
                            <p>Necessary for our legitimate interests to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy</p>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider><!-- Starts Row -->
                      <v-layout row wrap ma-0>
                        <v-flex xs12 sm6 pa-0>
                          <v-card tile class="elevation-0 pa-3">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Purpose / Activity</span>
                            <p class="font-weight-bold">To administer and protect our business and keep the Service working, safe and secure (by troubleshooting, data analysis, testing, system maintenance and monitoring and addressing other security/technical issues).</p>
                          </v-card>
                        </v-flex>
                        <v-flex xs12 sm6 pa-0>
                          <v-card tile class="elevation-0 pa-3" color="#fafafa">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Lawful basis for processing</span>
                            <p>Necessary for our legitimate interests for running and protecting our business, ensuring appropriate security, investigating and helping to prevent security issues and abuse, to prevent fraud and so on.</p>
                            <p>Necessary to comply with a legal obligation.</p>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider><!-- Starts Row -->
                      <v-layout row wrap ma-0>
                        <v-flex xs12 sm6 pa-0>
                          <v-card tile class="elevation-0 pa-3">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Purpose / Activity</span>
                            <p class="font-weight-bold">To notify you about changes or updates to the Service.</p>
                          </v-card>
                        </v-flex>
                        <v-flex xs12 sm6 pa-0>
                          <v-card tile class="elevation-0 pa-3" color="#fafafa">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Lawful basis for processing</span>
                            <p>Performance of our contract with you</p>
                            <p>Necessary for our legitimate interests to develop our products/services and grow our business</p>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-card>

                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3  text-uppercase" id="disclosure">Disclosure of information</h4>

                    <p>Your Personal Information will not be sold, traded, rented or otherwise provided to others without your consent.</p>

                    <p>From time to time, third parties may submit requests to access information that has been generated from your use of our Services. This might include, for example, law enforcement or government agencies requesting information about you or Permitted Users.</p>

                    <p>We may also disclose information about you or your use of our products and services if we have a good faith belief that such action is allowed by law, for example in accordance with Applicable Privacy Laws to avoid prejudice to the maintenance of the law or to prevent or lessen a serious threat to public health or safety, or to the life or health of an individual. Where possible and appropriate, we will notify you if we are required by law to disclose your Personal Information. </p>

                    <p>Otherwise, we will not pass on your Personal Information unless this is necessary for the fulfilment of a contractual agreement or to facilitate Services bookings on your behalf, you have consented to the passing on of your Personal Information, or we are obliged to do so due to a mandatory legal provision, court decision or official order. </p>

                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3  text-uppercase" id="transfers">International data transfers</h4>

                    <p>Your Personal Information may be transferred to, and processed in, countries other than New Zealand, Australia or the United Kingdom (where our data hosting servers are currently located). There may be differences with each respective country's privacy laws. However, where we disclose Personal Information to a third party in another country, we place safeguards to ensure your Personal Information is protected and only disclose information to an organisation in a foreign country which has a substantially similar privacy regime or where the overseas organisation has agreed to comply with the Applicable Privacy Laws. </p>

                    <p>For individuals in the European Economic Area (EEA), this means that your Personal Information may be transferred outside of the EEA. Where your Personal Information is transferred outside the EEA, it will only be transferred to countries that have been identified as providing adequate protection for EEA data (like New Zealand), or to a third party where we have approved transfer mechanisms in place to protect your Personal Information (e.g. by entering into the European Commission’s Standard Contractual Clauses). For further information, please contact us using the details set out in the contact section below.</p>

                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3  text-uppercase" id="protection">How is information protected?</h4>

                    <p>We implement a variety of security measures to protect the loss, misuse, and alteration of your Personal Information when you enter, submit, or access your Personal Information.</p>

                    <p>While we endeavour to protect the privacy of your account and Personal Information we hold, we cannot guarantee complete security at all times. There are a number of measures you need to take to keep your information secure. For example, it is important that you:</p>

                    <ul>
                      <li>choose a strong password – we recommend using a phrase, rather than a word, or at least ensuring the word is a minimum of 8 characters, with at least 2 numbers or letters; and</li>
                      <li>do not write down your password or share it with anyone.</li>
                    </ul>

                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3  text-uppercase" id="links">What about links to other websites?</h4>

                    <p>Our website may contain links to other websites that are not under our control. These websites may use cookies. It is the responsibility of those third parties to collect appropriate consents from you in order to permit their own cookies (to the extent this is required by law) and to inform you about the cookies they use. You should check the privacy policy on all third party websites to ensure you are comfortable with third party cookies. </p>

                    <p>We have no responsibility for linked websites, and provide them solely for your information and convenience. We specifically disclaim responsibility for their content, privacy practices and terms of use, and we make no endorsements, representations or warranties about their accuracy, content or thoroughness. Your disclosure of Personal Information to or on third party websites is at your own risk.</p>

                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3  text-uppercase" id="communication">Email, text and telephone communications</h4>

                    <p>We may use your Personal Information to identify a product or service that you may be interested in or to contact you about an event or promotion.  We may, with your consent or where required by Applicable Anti-Spam Laws, use the contact details you have provided to contact you from time to time (whether by phone, email or SMS) to tell you about new products or services and special offers that we believe may be of interest to you.</p>

                    <p>You can withdraw your consent to receiving direct marketing communications from us at any time by unsubscribing from the mailing list by clicking ‘unsubscribe’ at the bottom of any email from us, by contacting us on the details at the end of the policy or by using the unsubscribe facility set out in any other electronic communication you receive. Once you have unsubscribed from the electronic communication, you will be removed from the corresponding marketing list as soon as is reasonably practicable and in accordance with Applicable Anti-Spam Laws.</p>

                    <p>We may occasionally engage other companies to provide marketing or advertising services on our behalf.  Those companies will be permitted to obtain only the Personal Information they need to deliver the service. If we provide those companies with any of your Personal Information, it is to provide you with a better or more relevant and personalised experience and to improve the quality of those services.  We take reasonable steps to ensure that these organisations are bound by confidentiality and privacy obligations in relation to the protection of your Personal Information.</p>

                    <p>We may also use your Personal Information and information collected about you using third parties such as Google Analytics to provide you with a better or more personalised and relevant experience when using our website. We may do this by combining behavioural data we collect by the use of cookies and combining it with the Personal Information we have collected from you. </p>

                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3  text-uppercase" id="accuracy">Keeping personal information accurate and up to date</h4>

                    <p>It is your responsibility to ensure that the Personal Information you provide us is accurate, complete and up-to-date. However, as required by Applicable Privacy Law, we will also endeavour to ensure that the Personal Information collected from you is up to date, accurate and complete. </p>

                    <p>You may request access to or correction of your Personal Information we hold about you at any time by contacting the relevant Privacy Officer using the contact details set out at the end of this policy.  We will need to verify your identity. Subject to any applicable exceptions or requirements, we will provide you with access to the Personal Information you request within a reasonable time and usually within 20 working days. If we decide to refuse your request we will tell you why in writing and how to complain.</p>

                    <p> You will not have to pay a fee to access or correct your Personal Information. However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances. </p>

                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3  text-uppercase" id="retention">Retention</h4>

                    <p>The length of time we keep your Personal Information depends on what it is and whether we have an ongoing business need to retain it - for example, to provide you with a Service you’ve requested; or to comply with applicable legal requirements such as money laundering and financial reporting legislation; or to protect our legitimate interests, in particular, the assertion, exercise and defence of legal claims.</p>

                    <p>We’ll retain your Personal Information for as long as we have a relationship with you and for a period of time afterwards where we have an ongoing business need to retain it, in accordance with our internal retention policies and practices. Following that period, we’ll make sure it’s deleted or anonymised. Otherwise, as a general rule, we only keep your Personal Information for as long as we require it for the purposes of providing you with our Services. </p>

                    <p>In some circumstances we may anonymise your Personal Information (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.</p>

                    <p>In some circumstances, Applicable Privacy Laws may grant you the right to require us to delete your data: see the “erasure” right in the section below for further information. </p>

                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3  text-uppercase" id="uk">Additional right under UK law</h4>

                    <p>The Applicable Privacy Laws in the United Kingdom, grant the following additional data protection rights to UK data subjects: </p>

                    <ul>
                      <li><b>Request access to your Personal Information</b> (commonly known as a "data subject access request"). This enables the data subject to receive a copy of the Personal Information we hold about them and to check that we are lawfully processing it. </li>
                      <li><b>Request correction of the Personal Information that we hold.</b> This enables the data subject to have any incomplete or inaccurate information corrected. </li>
                      <li><b>Request erasure of Personal Information.</b> This enables the data subject to ask us to delete or remove Personal Information where there is no good reason for us continuing to process it. Also, the right to ask us to delete or remove their Personal Information where they have exercised their right to object to processing (see below). </li>
                      <li><b>Object to processing of Personal Information.</b> This right applies where we are relying on our legitimate interests (or those of a third party) and there is something about the data subject's particular situation which makes them want to object to processing on this ground. Such data subject also has the right to object where we are processing their Personal Information for direct marketing purposes. </li>
                      <li><b>Request the restriction of processing of Personal Information.</b> This enables the data subject to ask us to suspend the processing of their Personal Information, for example if they want us to establish its accuracy or the reason for processing it. </li>
                      <li><b>Request the transfer of Personal Information to another party</b> (known as the “right to data portability”). </li>
                      <li><b>Withdraw consent.</b> This right applies only if we are relying on the data subject's consent to process any of your Personal Information. This will not affect the lawfulness of any processing carried out before the data subject withdraws their consent. </li>
                    </ul>

                    <p>If the Applicable Privacy Laws grant you such additional rights and you wish to exercise any of these rights, please contact <a href="mailto:privacy@anzcro.com.au">privacy@anzcro.com.au</a>  </p>

                    <p>You will not have to pay a fee to access your Personal Information (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances. </p>

                    <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your Personal Information (or to exercise any of your other rights). This is a security measure to ensure that Personal Information is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response. </p>

                    <p>We try to respond to all legitimate requests within 20 working days. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated. </p>

                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3  text-uppercase" id="changes">Changes to this Privacy Policy</h4>

                    <p>We may amend the terms of this Privacy Policy from time to time, and will notify you of any changes by posting an updated version on our website or by sending you a notice via email. It is your responsibility to check this Privacy Policy periodically for changes, and to keep your email address current.  </p>

                    <p>Your continued use of the Services following notification of any changes to this Privacy Policy constitutes acceptance of those changes. If you do not agree with any aspect of the updated Privacy Policy, you must immediately cease all use of the Services.</p>

                  </li>

                  <li>
                    <h4 class="title mt-5 mb-3  text-uppercase" id="contact">How to contact us</h4>

                    <p>We take your concerns seriously. If you have a question or comment regarding this policy or wish to make a complaint or exercise your privacy rights, please contact our Privacy Officer on the following details: </p>

                    <v-card class="elevation-0 card-with-border dash-rounded my-4">
                      <v-layout row ma-0 hidden-xs-only>
                        <v-flex xs12 pa-0>
                          <v-card dark class="elevation-0 pa-3" color="#454545">
                            <v-layout row>
                              <v-flex xs4>
                                Country
                              </v-flex>
                              <v-flex xs8>
                                Contact Details
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <v-divider class="hidden-xs-only"></v-divider><!-- Starts Row -->
                      <v-layout row wrap ma-0>
                        <v-flex xs12 sm4 pa-0>
                          <v-card tile class="elevation-0 pa-3">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Country</span>
                            <p class="font-weight-bold">Australia</p>
                          </v-card>
                        </v-flex>
                        <v-flex xs12 sm8 pa-0>
                          <v-card tile class="elevation-0 pa-3" color="#fafafa">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Contact Details</span>
                            <p>Phone: +61 7 5556 5556<br/>
                              Post: ANZCRO<br/>
                              Attn: Privacy Officer<br/>
                              Address: Level 1, 6 Short Street, Southport QLD 4215<br/>
                              E-mail: <a href="mailto:privacy@anzcro.com.au">privacy@anzcro.com.au</a></p>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider><!-- Starts Row -->
                      <v-layout row wrap ma-0>
                        <v-flex xs12 sm4 pa-0>
                          <v-card tile class="elevation-0 pa-3">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Country</span>
                            <p class="font-weight-bold">New Zealand</p>
                          </v-card>
                        </v-flex>
                        <v-flex xs12 sm8 pa-0>
                          <v-card tile class="elevation-0 pa-3" color="#fafafa">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Contact Details</span>
                            <p>Phone: +61 7 5556 5556<br/>
                              Post: ANZCRO<br/>
                              Attn: Privacy Officer<br/>
                              Address: Level 1, 6 Short Street, Southport QLD 4215<br/>
                              E-mail: <a href="mailto:privacy@anzcro.com.au">privacy@anzcro.com.au</a></p>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider><!-- Starts Row -->
                      <v-layout row wrap ma-0>
                        <v-flex xs12 sm4 pa-0>
                          <v-card tile class="elevation-0 pa-3">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Country</span>
                            <p class="font-weight-bold">United Kingdom</p>
                          </v-card>
                        </v-flex>
                        <v-flex xs12 sm8 pa-0>
                          <v-card tile class="elevation-0 pa-3" color="#fafafa">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Contact Details</span>
                            <p>Phone: +61 7 5556 5556<br/>
                              Post: ANZCRO<br/>
                              Attn: Privacy Officer<br/>
                              Address: Level 1, 6 Short Street, Southport QLD 4215<br/>
                              E-mail: <a href="mailto:privacy@anzcro.com.au">privacy@anzcro.com.au</a></p>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-card>

                    <p>We will need to verify you, and we will respond to you within a reasonable period of time to acknowledge your complaint and inform you of the next steps we will take in dealing with your complaint.</p>

                    <p>If you are not satisfied with our response, you may complain to:</p>

                    <v-card class="elevation-0 card-with-border dash-rounded my-4">
                      <v-layout row ma-0 hidden-xs-only>
                        <v-flex xs12 pa-0>
                          <v-card dark class="elevation-0 pa-3" color="#454545">
                            <v-layout row>
                              <v-flex xs4>
                                Country
                              </v-flex>
                              <v-flex xs8>
                                Office
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <v-divider class="hidden-xs-only"></v-divider><!-- Starts Row -->
                      <v-layout row wrap ma-0>
                        <v-flex xs12 sm4 pa-0>
                          <v-card tile class="elevation-0 pa-3">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Country</span>
                            <p class="font-weight-bold">Australia</p>
                          </v-card>
                        </v-flex>
                        <v-flex xs12 sm8 pa-0>
                          <v-card tile class="elevation-0 pa-3" color="#fafafa">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Contact Details</span>
                            <p>The Office of the Australian Information Commissioner (OAIC) via the OAIC website: <a href="http://www.oaic.gov.au" target="_blank">www.oaic.gov.au</a>.</p>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider><!-- Starts Row -->
                      <v-layout row wrap ma-0>
                        <v-flex xs12 sm4 pa-0>
                          <v-card tile class="elevation-0 pa-3">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Country</span>
                            <p class="font-weight-bold">New Zealand</p>
                          </v-card>
                        </v-flex>
                        <v-flex xs12 sm8 pa-0>
                          <v-card tile class="elevation-0 pa-3" color="#fafafa">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Contact Details</span>
                            <p>The New Zealand Privacy Commissioner via the website: <a href="https://www.privacy.org.nz/your-rights/making-a-complaint/" target="_blank">https://www.privacy.org.nz/your-rights/making-a-complaint/</a></p>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider><!-- Starts Row -->
                      <v-layout row wrap ma-0>
                        <v-flex xs12 sm4 pa-0>
                          <v-card tile class="elevation-0 pa-3">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Country</span>
                            <p class="font-weight-bold">United Kingdom</p>
                          </v-card>
                        </v-flex>
                        <v-flex xs12 sm8 pa-0>
                          <v-card tile class="elevation-0 pa-3" color="#fafafa">
                            <span class="d-block subheading font-weight-medium hidden-sm-and-up mb-2 grey--text">Contact Details</span>
                            <p>The Information Commissioner's Office (ICO) via the website  <a href="https://ico.org.uk/global/contact-us" target="_blank">https://ico.org.uk/global/contact-us</a>.</p>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-card>

                    <p>We would, however, appreciate the chance to deal with your concerns before you approach one of these entities, so please do get in touch using the details above in this section. </p>

                    <p>This represents our Privacy Policy as at 01 September 2019.  </p>

                  </li>

                </ol>



              </v-flex>
              <v-flex xs12 md4  mt-3 order-xs1 order-md2>
                <v-card flat class="pa-0 elevation-0 dash-rounded mb-4 card-with-border">
                  <v-card-text class="pa-4">
                    <v-layout row>
                      <v-flex>
                        <span class="title font-weight-medium headerGrey d-block mb-2">Index</span>
                      </v-flex>
                    </v-layout>
                    <v-divider class="mb-2"></v-divider>
                    <v-layout row>
                      <v-flex>
                        <ol>
                          <li><a @click="scrollTo()" href="#application">Application</a></li>
                          <li><a @click="scrollTo()" href="#terminology">Important terminology</a></li>
                          <li><a @click="scrollTo()" href="#dealing-anonymously">Dealing with us anonymously</a></li>
                          <li><a @click="scrollTo()" href="#what-we-collect">What information do we collect</a></li>
                          <li><a @click="scrollTo()" href="#disclosure">Disclosure of information</a></li>
                          <li><a @click="scrollTo()" href="#transfers">International data transfers</a></li>
                          <li><a @click="scrollTo()" href="#protection">How is information protected?</a></li>
                          <li><a @click="scrollTo()" href="#links">What about links to other websites?</a></li>
                          <li><a @click="scrollTo()" href="#communication">Email, text and telephone communications</a></li>
                          <li><a @click="scrollTo()" href="#accuracy">Keeping personal information accurate and up to date</a></li>
                          <li><a @click="scrollTo()" href="#retention">Retention</a></li>
                          <li><a @click="scrollTo()" href="#uk">Additional right under UK law</a></li>
                          <li><a @click="scrollTo()" href="#changes">Changes to this Privacy Policy</a></li>
                          <li><a @click="scrollTo()" href="#contact">How to contact us</a></li>
                        </ol>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({

  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    }
  },
};
</script>

<style>
:target::before {
  content: '';
  display: block;
  height: 100px; /* fixed header height*/
  margin: -100px 0 0; /* negative fixed header height */
}

.terms-and-conditions ol,
.terms-and-conditions ul{
  display:block;
  margin:0 0 16px;
  padding-left:18px;
}

.terms-and-conditions ol ol{
  margin:0;
}

.terms-and-conditions ol li,
.terms-and-conditions ul li{
  margin:18px 0;
  padding-left:12px;
}
</style>
