import { utilsService } from "@/shared/services/utils.service";
import { make } from "vuex-pathify";
import cache from "@/util-modules/cache";

const state = {
  locations: [],
  countries: [],
  options: {
    agentMode: true,
  },
  warningMsg: undefined,
  infoMessages: {
    show: false,
    data: [],
  },
  termsOfUseDialog: false,
  depositInfo: null,
};

const sortByName = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const getters = {
  formattedSearchLocations: state => searchItemTypes => {
    return formattedLocations(state);
  },
  formattedLocations: state =>
    state.locations.map(location => {
      const newLocation = { ...location };
      if (newLocation.shortcode) {
        newLocation.name = `${newLocation.name} (${newLocation.shortcode})`;
      }
      return newLocation;
    }),
  getLocationsByCountries: state => countries => {
    return getLocations(state.locations, state.countries, countries);
  },
  getLocationName: state => locationid => {
    const isValid = !!locationid;
    if (!isValid) {
      return "";
    }
    const location = state.locations.find(({ id }) => id === locationid);
    return location ? location.name : "";
  },
};

const actions = {
  ...make.actions(state),
  async loadLocations(context) {
    try {
      let locations = cache.getCache("_oa_locations_3");
      if (!locations) {
        const { result } = await utilsService.getLocations();
        cache.setCache("_oa_locations_3", result, 86400);
        locations = cache.getCache("_oa_locations_3");
      }
      context.commit("locations", locations);
    } catch (e) {
      context.dispatch("errorModule/setError", "Error loading locations.", {
        root: true,
      });
    }
  },
  async loadCountries(context) {
    try {
      let countries = cache.getCache("_oa_countries_2");
      if (!countries) {
        const { result } = await utilsService.getCountries();
        cache.setCache("_oa_countries_2", result, 86400);
        countries = cache.getCache("_oa_countries_2");
      }
      const sortedData = countries.sort(sortByName);
      context.commit("countries", sortedData);
    } catch (e) {
      context.dispatch("errorModule/setError", "Error loading countries.", {
        root: true,
      });
    }
  },
};

const mutations = {
  ...make.mutations(state),
};

const getLocations = (locations, countries, filteredCountryCodes) => {
  const filteredCountryIds = countries
    .filter(country => filteredCountryCodes.findIndex(x => x === country.countrycode) > -1)
    .map(({ countryid }) => countryid);

  const filteredLocations = locations
    .filter(location => filteredCountryIds.findIndex(x => x === location.countryid) > -1)
    .map(location => {
      const newLocation = { ...location };
      if (newLocation.shortcode) {
        newLocation.name = `${newLocation.name} (${newLocation.shortcode.toUpperCase()})`;
      }
      return newLocation;
    });

  return filteredLocations;
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
