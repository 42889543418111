import colors from './colors';
import { AnyTypeToFix } from '@/model/AnyType';
export default {
  install(vue: AnyTypeToFix) {
    for (const key in colors) {
      if (colors.hasOwnProperty(key)) {
        vue.prototype[key] = colors[key];
      }
    }
  },
};
