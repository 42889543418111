import routerChangedSubject from "@/observers/subjects/routerChanged.subject";
import errorSubject from "@/observers/subjects/error.subject";
import userLoadedSubject from "@/observers/subjects/userLoaded.subject";
import { gtmDataLayer } from "@/util-modules/gtm";
const _pageView = ({ routeTo }) => {
  gtmDataLayer.push({
    event: "VirtualPageview",
    virtualPageURL: routeTo.fullPath,
  });
};

const _reportError = payload => {
  gtmDataLayer.push(payload);
};

const _reportLogin = data => {
  gtmDataLayer.push({
    event: "login",
    eventCategory: "login_page",
    user_name: data.username,
    terms_of_use_accepted: data.termsOfUseAccepted,
    roles: JSON.stringify(data.roles),
    timespan: Date.now(),
  });
};

const analyticsSubscriber = {
  init: () => {
    routerChangedSubject.subscribe(_pageView);
    errorSubject.subscribe(_reportError);
    userLoadedSubject.subscribe(_reportLogin);
  },
};

export default analyticsSubscriber;
