<template>
    <v-container pa-0 fluid>
      <v-layout row>
        <v-flex>
          <v-card flat tile dark class="hero-home-slider">
            <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
              <v-carousel-item>
                <v-img
                  src="https://cdn.anzcro.com.au/wp-content/uploads/2024/07/hero-cottesloe-beach-perth-scaled.jpg"
                  gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.25) 15%, rgba(22,22,22,0.2) 65%"
                  class="home-hero"
                >
                  <v-container fill-height>
                    <v-layout dark column fill-height justify-space-between pt-5>
                      <v-flex text-xs-center class="home-hero-cta">
                        <v-layout row align-center fill-height class="fill-height-fix-ios">
                          <v-flex>
                            <img src="https://cdn.anzcro.com.au/wp-content/uploads/2024/07/step-into-dream-western-australia-stacked.png" alt="Step into a dream in Western Australia" class="campaign-title stacked" width="480" height="300" />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div class="hero-caption hidden-xs-only">
                    <small>Cottesloe Beach, Perth - Western Australia</small>
                  </div>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="gradient-bar"></div>
  
      <v-layout row>
        <v-flex mb-5>
            <v-container grid-list-lg fill-height>
              <v-layout row wrap fill-height>
                <v-flex xs12 py-0 my-0>
                    <v-card tile class="pa-0 elevation-0 deals-content">
                  <v-card-text class="pa-5">
                    <v-layout row wrap>
                      <v-flex xs12 md12 py-0 my-0>
                        <span class="headline font-weight-medium kiwiGreen d-block mb-3">An ancient land of wondrous, otherworldly and dreamlike adventures.</span>
                        <p><b>Leave the everyday behind, get away from the well-known and well-trodden and reconnect with something unspoilt — even a little wild — in Western Australia.</b></p>
                        <p>The country’s largest State, boasting the nation’s longest coastline and sunniest capital city of Perth / Boorloo, Western Australia is a place to recharge your spirit — outdoors in the fresh air and wide-open spaces, where it’s always summer somewhere. </p>
                        <p>With direct nonstop flights from London to Perth with Qantas, it’s now even easier to begin your Australian adventure in Western Australia.</p>
                        <p><b>All packages are fully customisable.</b></p>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
                </v-flex>

                <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                  <v-card tile dark class="pa-0 ma-0 elevation-0">
                    <v-card-text class="px-3 py-2">
                      <span
                        class="headline font-weight-medium d-block"
                      >{{section.title}}</span>
                    </v-card-text>
                  </v-card>
                  <v-card tile class="px-3 py-3 elevation-0">
                    <v-layout row wrap ma-0>
                      <v-flex xs12 pa-0 hidden-sm-and-down>
                        <v-layout row wrap my-0>
                          <v-flex xs12 md7 lg8>
                            <span class="caption grey--text">Package Deal</span>
                          </v-flex>
                          <v-flex xs12 md3 lg2 text-md-right>
                            <span class="caption grey--text">From Price (GBP)</span>
                          </v-flex>
                          <v-flex xs12 md2 lg2 text-md-right>
                            <span class="caption grey--text">Inclusions & Terms</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                        <v-divider></v-divider>
                        <v-layout row wrap align-center my-3>
                          <v-flex xs12 md7 lg8>
                            <v-layout row align-center>
                              <v-flex shrink hidden-md-and-down>
                                <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                              </v-flex>
                              <v-flex>
                                <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                                <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                                <span class="d-block package-data">
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Start location</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-if="!deal.call">
                                    <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Package Code</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-else>
                                    <b>Call ANZCRO to Book</b><v-icon class="mx-2" size="14" :color="kiwiGreen">phone</v-icon>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm6 md3 lg2 text-md-right>
                            <span class="headline d-block font-weight-medium mb-2 kiwiGreen">£{{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                            <span class="caption d-block grey--text">{{deal.complement}}</span>
                          </v-flex>
                          <v-flex xs12 sm6 md2 lg2 text-sm-right>
                            <v-btn large class="card-with-border elevation-0 mx-0 px-2" :color="lightGrey" :href="deal.itinerary" target="_blank"><v-icon color="grey" size="18" class="mr-1">launch</v-icon> View</v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
      </v-layout>
  
    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      copied: false,
      copiedText: '',
      sections : [
        {
          title : 'Deals',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/08/Ultimate-Adventure-Thumbnail.jpg',
              name : '7 Day Ultimate Adventure',
              price : '5639',
              complement : 'per person (twin share)',
              code : 'ANZ247DUA',
              location : 'Perth (PER)',
              valid: 'Aug 2024 — May 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/08/7-Day-Ultimate-Adventure-GBP.pdf',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/deal-wa-coral-coast.jpg',
              name : '12 Day Coral Coast Explorer',
              price : '3257',
              complement : 'per person (twin share)',
              code : 'ANZ2412DWAC',
              location : 'Perth (PER)',
              valid: 'Aug 2024 — Mar 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/12-Day-Coral-Coast-Explorer-GBP.pdf',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/deal-wa-tropics.jpg',
              name : '15 Day Wineries, Beaches & the Tropics',
              price : '3825',
              complement : 'per person (twin share)',
              code : 'ANZ2415DWAS',
              location : 'Perth (PER)',
              valid: 'Aug 2024 — Mar 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/15-Day-Wineries-Beaches-The-Tropics-GBP.pdf',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/deal-wa-kimberley.jpg',
              name : '16 Day Kimberley Southern Sailing',
              price : '12888',
              complement : 'per person (twin share)',
              code : 'ANZ2416DKSS',
              location : 'Perth (PER)',
              valid: 'May — Aug 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/16-Day-Kimberely-Southern-Sailing-GBP.pdf',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/deal-wa-ningaloo.jpg',
              name : '16 Day Reef, Wilderness & Beyond',
              price : '6775',
              complement : 'per person (twin share)',
              code : 'ANZ2416DWAB',
              location : 'Perth (PER)',
              valid: 'Aug 2024 — Oct 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/16-Day-Reef-Wilderness-Beyond-GBP.pdf',
              call : false
            },
          ]
        },
      ]
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      },
      copyCode(event) {
        if(event){
          let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
          window.getSelection().selectAllChildren(copyCode);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          
          this.copiedText = 'Package code copied to clipboard';
          this.copied = true;
        }
      },
      copyLocation(event) {
        if(event){
          let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
          window.getSelection().selectAllChildren(copyLocation);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          
          this.copiedText = 'Package start location copied to clipboard';
          this.copied = true;
        }
      }
    },
  };
  </script>
  
  <style>
  .gradient-bar{
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
    background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
  }
  
  .deals-content{
    margin-top: -60px;
  }
  
  .package-data .v-chip{
    color:#9e9e9e;
    margin-left: 0;
  }
  
  .package-data .v-chip .v-chip__content{
    padding-right: 6px;
  }
  
  .deal-thumbnail{
    width: 88px;
    height: 88px;
  }
  </style>
  