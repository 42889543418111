<template>
  <v-dialog persistent v-model="dialog" :max-width="options.width" @keydown.esc="cancel">
    <v-card class="pa-0">
        <v-layout row pa-3 align-center>
          <v-flex>
            <span class="d-block headline font-weight-bold"  v-if="!!title && title.length > 0">{{ title }}</span>
          </v-flex>
          <v-flex shrink>
            <v-btn
              small
              fab
              class="elevation-0 ma-0 card-with-border"
              @click.native="cancel"
            >
              <v-icon size="24">close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      <v-card-text class="px-4 pb-5 pt-4 text-xs-center subheading" v-show="!!message" v-html="message"></v-card-text>
      <v-card-actions class="pt-0 px-3 pb-4">
        <v-btn large :disabled="loading" @click.native="cancel" class="elevation-0 card-with-border px-4">
          <!--<v-icon :color="kiwiGreen" size="18" left dark class="mr-1">{{options.closeIcon}}</v-icon>-->
          {{options.closeLabel}}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn large @click.native="agree" :loading="loading" dark
            :color="kiwiGreen" class="elevation-0 px-4">
          <!--<v-icon :color="kiwiGreen" size="18" left dark class="mr-1">{{options.agreeIcon}}</v-icon>-->
          {{options.agreeLabel}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    beforeAgreedFn: null,
    cancelFn: null,
    options: {
      width: 500,
      zIndex: 200,
      closeIcon: 'close',
      closeLabel: 'Cancel',
      agreeIcon: 'check',
      agreeLabel: 'Agree',
    },
    loading: false,
  }),
  methods: {
    open({ title, message, options }, beforeAgreedFn = null) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      this.beforeAgreedFn = beforeAgreedFn;
      new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.loading = true;
      this.resolve(true);
      if (this.beforeAgreedFn) {
        const maybeAPromise = this.beforeAgreedFn(true);
        if (maybeAPromise instanceof Promise) {
          maybeAPromise.finally(() => {
            this.close();
          });
        } else {
          this.close();
        }
      } else {
        this.close();
      }
    },
    close() {
      this.dialog = false;
      this.loading = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>


