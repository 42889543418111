import { isNil, isEmpty as R_isEmpty, either } from 'ramda';

export const isEmpty = either(isNil, R_isEmpty);
export const isNullOrUndefined = (someValue: any) => isNil(someValue);

export const hashCode = (s: string): number => {
  let hash: number = 0,
    strlen: number = s.length;

  if (strlen == 0) return hash;
  for (let i = 0; i < strlen; i++) {
    let char: number = s.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return hash;
};

export const isAlpha = (str: string): boolean => /^[A-Z]+$/i.test(str);
export const isNumeric = (str: string): boolean => !/[^0-9]/.test(str);
