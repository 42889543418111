<template>
  <v-container pa-0 fluid>
    <v-layout row class="hero-waves">
      <v-flex>
        <v-container fill-height>
          <v-layout dark row wrap fill-height align-center pt-5>
            <v-flex xs12 text-xs-center text-md-left class="hero-title">
              <h3 class="grey--text title mb-2">ANZCRO</h3>
              <h1 class="white--text display-1 font-weight-bold mb-3">Terms & Conditions</h1>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-layout row class="terms-and-conditions">
      <v-flex>
        <v-card flat tile class="pb-5">
          <v-container grid-list-xl fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 md8 pa-3 mb-4 mt-3 order-xs2 order-md1>

                <h4 class="title my-3 text-uppercase" id="application">Application</h4>

                <ol type="a">
                  <li>These terms ("<b>Terms</b>") and our <a href="/privacy-policy/">Privacy Policy</a> apply to the access and use of ANZCRO's website (www.anzcro.com, www.anzcro.co.nz, www.anzcro.com.au and/or www.anzcro.co.uk) ("<b>Website</b>") to make enquiries and book travel, accommodation and other holiday products, services and experiences offered by ANZCRO (as wholesaler) on or via the Website or online platforms ("<b>Products</b>").</li>
                  <li>In these Terms
                    <ol type="i">
                      <li>"<b>ANZCRO</b>", "<b>us</b>", "<b>we</b>" or "<b>our</b>" means, as the context requires, ANZCRO New Zealand Limited (in the case of bookings made via www.anzcro.com or www.anzcro.co.nz); Australian New Zealand Central Reservation Office Pty Limited (in the case of bookings made via www.anzcro.com.au), or ANZCRO (UK) Limited (in the case of bookings made via www.anzcro.co.uk);</li>
                      <li>"<b>you</b>" and "<b>your</b>" means any person accessing and using the Website to make Product enquiries or bookings, whether as principal or as agent for any other person, and also includes every person named on any booking confirmation;</li>
                    </ol>
                  </li>
                  <li>By accessing and/or using the Website, you accept these Terms and our Privacy Policy.  Any person making a booking using the Website on behalf of any other person warrants that they have all necessary right, power and authority to bind that other person to these Terms and our Privacy Policy.</li>
                  <li>We may amend these Terms from time to time by publishing updated Terms on the Website. By continuing to access the Website after any such amendment is published, you are deemed to be aware of and have agreed to the amendment. </li>
                  <li>Separate access terms may apply to Website access by travel agents and travel service intermediaries.  The applicable Product terms and conditions (accessible through the Website if provided by the Supplier) of the relevant third party supplier of Products ("<b>Supplier</b>"), as amended from time to time, also apply to Product bookings.</li>
                  <li>You should review all applicable terms and conditions periodically and before making any Product booking.</li>
                </ol>

                <h4 class="title mt-5 mb-3 text-uppercase" id="bookings">Booking Conditions</h4>

                <ol type="a">
                  <li>ANZCRO does not own or operate any Product displayed on or accessible through the Website. ANZCRO is a wholesaler which facilitates, through the Website, your booking of Products provided by Suppliers. ANZCRO does not take responsibility for the omission or failure of individual Products or their Suppliers.</li>
                  <li>You may make bookings for Products by completing the applicable booking application.  We and/or Suppliers may accept or reject booking applications in our/their sole discretion.  If a booking application is accepted (which may require payment of a deposit), we will issue you with a booking confirmation.  It is your responsibility to check and ensure that the details of the booking confirmation are correct and meet your requirements (or those of the person on whose behalf the booking is made).</li>
                  <li>The relevant Supplier's applicable Product terms and conditions (accessible through the Website if provided by the Supplier) apply to all bookings. You acknowledge that different terms and conditions may apply to different Products, and that the applicable Supplier terms and conditions may be varied from time to time without notice to you.  By completing a booking application, you confirm that you have read and understood all applicable terms and conditions.</li>
                  <li>ANZCRO does not endorse or recommend any particular Product or Supplier.  ANZCRO has taken reasonable care that the content of the Website, including all Product information and listings is correct but ANZCRO does not warrant the accuracy or completeness of such information and listings, which are subject to amendment at any time without notice.</li>
                  <li>You may not purchase Products that you are prohibited from purchasing or possessing by any applicable local laws. The responsibility for ensuring compliance with all applicable laws is yours alone. By submitting a booking request to purchase Products, you represent and warrant that you (or the person on whose behalf you made the booking) have the legal right to purchase, utilise and/or possess such Products.</li>
                </ol>

                <h4 class="title mt-5 mb-3 text-uppercase" id="pricing">Pricing</h4>

                <ol type="a">
                  <li>All prices on the Website are expressed in New Zealand dollars (in the case of www.anzcro.com or www.anzcro.co.nz), Australian dollars (in the case of www.anzcro.com.au and UK pound sterling (in the case of www.anzcro.co.uk) and include GST and VAT (as applicable), unless otherwise stated. ANZCRO reserves the right to amend without notice the prices on the Website. We refer particularly but are not limited to amendments due to currency fluctuations, the withdrawal or adjustment of contracted rates with or by Suppliers, or applicable governmental charges. Confirmed pricing (if you pay all amounts when due) is set out in the relevant booking confirmation we issue to you.</li>
                  <li>You acknowledge that we may receive and retain a commission, rebate or other remuneration or benefit from Product bookings.  We are not required to disclose to you the nature or amount of, nor account to you for, any such remuneration or benefit.</li>
                  <li>All quotes are only valid for 7 days from the date of issue except for Products subject to dynamic or flexi rates for which the Price is subject to change at all times until a booking is confirmed.</li>
                  <li>ANZCRO reserves the right to charge a late booking fee at its prevailing rate (or such other amount required by the applicable Supplier) to cover additional costs associated with bookings made within 10 days of departure or Product commencement.</li>
                </ol>

                <h4 class="title mt-5 mb-3 text-uppercase" id="deposit">Deposit</h4>

                <p>You will be advised at the time of booking the current prices and the deposit required plus payment terms for any subsequent balance due. Deposits are non-refundable. . Additional booking fees and/or payment in full at the time of booking may be required by some Suppliers.  When all Product bookings are confirmed, we will issue you with a confirmation invoice. Failure to pay all required deposits, booking fees or other amounts advised as required by the specified due date may result in cancellation of the relevant booking(s), in which case we may retain any deposit paid by you and you will be liable for any applicable cancellation fee.</p>

                <h4 class="title mt-5 mb-3 text-uppercase" id="full-payment">Full Payment</h4>

                <p>Payment in full of the balance of all applicable Product fees and charges is due by the date specified on the booking confirmation. Some Products may require immediate payment in full at the time of booking. This will be advised at time of booking.  Failure to pay all amounts due by the specified due date may result in cancellation of the relevant booking(s), in which case we may retain any deposit paid by you and you will be liable for any applicable cancellation fee.</p>

                <h4 class="title mt-5 mb-3 text-uppercase" id="payments">Payments</h4>

                <p>All payments are to be made using the payment link www.anzcro.co.nz/payments, www.anzcro.com.au/payments or www.anzcro.co.uk/payments (as applicable). Should you wish to make a payment via direct debit, we will advise you of the applicable account details. Please use the booking number as the payment reference.</p>

                <p>Additional charges may apply for payment by credit card (if accepted by us)</p>

                <h4 class="title mt-5 mb-3 text-uppercase" id="amendments">Amendments, Cancellations and Refunds</h4>

                <p>If you want to change or cancel any details of your itinerary booked through ANZCRO (such as changing to a different hotel or Product or changing your travel date), we will do our best to help but cannot guarantee that changes will be permitted by the relevant Supplier or that any refund will be provided. Requests for a refund of monies paid for any Products booked through ANZCRO will be governed by the terms and conditions of the relevant Supplier. You should familiarise yourself with the cancellation and refund policy of the applicable supplier prior to booking.  ANZCRO cannot and will not provide any greater refund than it receives from the applicable Supplier ( if any).</p>

                <h4 class="subheading mt-5 mb-3 text-uppercase font-weight-medium">Amendments</h4>

                <ol type="a">
                  <li>Any amendments you wish to make to a confirmed booking will be subject to an amendment fee at our prevailing rate from time to time (or at such higher rate imposed by the relevant Supplier).  Some changes (including for example a change to the Product departure or commencement date after confirmation) may be treated by the relevant Supplier as a cancellation and re-booking rather than an amendment and subject to the cancellation charges referred to below. </li>
                  <li>We, or Suppliers, may amend or cancel bookings from time to time:
                    <ol type="i">
                      <li>to correct errors;</li>
                      <li>if you fail to comply with your obligations under these terms or any applicable Supplier terms and conditions;</li>
                      <li>for reasons outside our (or the Supplier's) control; or</li>
                      <li>as otherwise required by Suppliers (including if any minimum booking numbers are not achieved for that Product).</li>
                    </ol>
                  </li>
                  <li>We will notify you as soon as practicable after we become aware of any cancellation of, or what we consider to be significant changes to, your bookings.</li>
                  <li>If any significant change is made by us or a Supplier for reasons other than those referred to in paragraphs (b)(i), (ii) or (iii) above, you may either accept the change or request a cancellation and/or a re-booking of alternative Products.  We will endeavour to accommodate your request, subject to the consent of the applicable Supplier(s). </li>
                </ol>

                <h4 class="subheading mt-5 mb-3 text-uppercase font-weight-medium">Cancellations</h4>

                <p>Cancellation requests must be in writing and comply with any form, timing or other requirements stipulated by ANZCRO and the relevant Supplier. The following cancellation charges will apply as a minimum.  Any additional cancellation charges imposed by the applicable Supplier (including airline and ground operator charges) will also apply and be payable by you:</p>

                <ul>
                  <li><b>Cancellation after confirmation of booking:</b><br/>
                  Deposit is forfeited</li>
                  <li><b>Cancellation between 30-15 days prior to the Product departure/ commencement date:</b><br/>
                  Cancellation charge = 50% of the aggregate price of all cancelled Products</li>
                  <li><b>Cancellation 14 days or less prior to the Product departure/ commencement date:</b><br/>
                  Cancellation charge = 100% of the aggregate price of all cancelled Products.</li>
                </ul>

                <h4 class="subheading mt-5 mb-3 text-uppercase font-weight-medium">Refunds</h4>

                <ol type="a">
                  <li>Suppliers are unable to authorise any refund on ANZCRO’s behalf.  Refunds (including for unused Products or Product vouchers) are subject to any applicable Supplier Product terms and conditions and/or ANZCRO's discretion.  Different Products may be subject to different terms regarding refunds.  Refunds may not be provided at all for some Products.</li>
                  <li>All ski products are non-refundable. Ski conditions are an act of nature and totally beyond our control. ANZCRO urges clients to check weather conditions before departing on any ski or snow dependent holiday as conditions can change at very short notice. ANZCRO takes no responsibility for late starts or early finishes to the ski season.</li>
                  <li>Prepaid hotel reservations are non-refundable and unable to be changed.</li>
                </ol>

                <h4 class="title mt-5 mb-3 text-uppercase" id="documents">Documents</h4>

                <p>All documents are emailed unless they are requested to be posted out.  It is your responsibility to check and ensure that the details set out on the documents (including your booking confirmation, itinerary and tickets) are accurate and complete.  Names and titles must match exactly with names and titles as they appear in the passports of the relevant travellers.  </p>

                <h4 class="title mt-5 mb-3 text-uppercase" id="visas">Passports and Visa Requirements</h4>

                <p>It is your responsibility to ensure that all necessary travel documents, including passport and any required visa and vaccination certificates, are in order for all travellers and in their possession when travelling.  For further information on visa requirements see (as appropriate):</p>

                <ul>
                  <li><a href="https://www.immigration.govt.nz/new-zealand-visas/options/visit" target="_blank">https://www.immigration.govt.nz/new-zealand-visas/options/visit</a></li>
                  <li><a href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-finder/visit" target="_blank">https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-finder/visit</a></li>
                </ul>

                <h4 class="title mt-5 mb-3 text-uppercase" id="insurance">Travel Insurance</h4>

                <p>ANZCRO strongly recommends you hold comprehensive travel insurance in respect of all Products.  ANZCRO will have no liability whatsoever for any damage, liability, loss, cost, claim or expense suffered or incurred by you (or any other person) as a result of a failure to obtain or maintain adequate insurance cover. In particular, cancellation or amendment charges for Products will not be waived under any circumstances where travel insurance would have covered you.</p>

                <h4 class="title mt-5 mb-3 text-uppercase" id="baggage">Baggage</h4>

                <p>All baggage and personal effect are, at all times, your own risk. Any excess charges are the responsibility of the passenger.</p>

                <h4 class="title mt-5 mb-3 text-uppercase" id="liabilities">Liabilities</h4>

                <ol type="a">
                  <li>The Website may contain links to third party websites which are not under our control. We are not responsible for the contents of any linked website or any hyperlink contained in a linked website other than our own websites. Hyperlinks are provided to you as convenience only, and the inclusion of any link does not imply any endorsement of the linked website by us or our employees.</li>
                  <li>You use the Website at your own risk.  Access to the Website is provided on an "as is" and "as available" basis.  We do not guarantee access to or availability of the Website (or any content or functionality), that information in or accessed through the Website is current, accurate or complete, or that your use will be uninterrupted, timely, secure or error, fault or virus free.  </li>
                  <li>ANZCRO will not be liable for any loss, damage, delay or failure to perform any obligation under these Terms which is caused by any event or circumstance beyond its reasonable control.</li>
                  <li>Our role in relation to Products is limited to facilitating your booking and arranging travel documentation, payments and refunds as applicable (and subject to the terms set out above and applicable Supplier terms).  We do not make any representations or warranties whatsoever in relation to the Products (including their nature, content, quality, timeliness, legal compliance or fitness or suitability for any particular purpose or customer), including as to the accuracy or reliability of any information, data, opinions, advice or statements accessible through the Website.  All warranties (whether express, implied or statutory) are excluded to the maximum extent permitted by applicable law.</li>
                  <li>To the maximum extent permitted by applicable law, ANZCRO will not have any liability whatsoever (whether in contract, tort (including negligence) or otherwise) for any damage, liability, loss, injury (including death), cost, claim or expense suffered or incurred by you (or any of your customers) in connection with Products; your  use of the Website; or your inability to access or use the Website.  In particular but without limitation, we accept no responsibility or liability for any failure or delay on the part of any third party in providing (or failing to provide) a Product to you nor for any act or omission of any Supplier or other third party</li>
                  <li>If for any reason ANZCRO cannot rely on the above exclusions of liability, our total aggregate liability will be limited to the lesser of: (i) the cost of re-performing (or, at ANZCRO's discretion, refunding) the relevant Product booking facilitation services; and (ii) NZ$100.  In no event will ANZCRO be liable to you (or any other person) for any loss of data, profits, savings, goodwill or business opportunity or for any indirect, consequential, special or exemplary loss or damage (howsoever arising). </li>
                  <li>You acknowledge and agree that where your access to and use of the Website, or the provision of our booking facilitation services, is supplied and acquired in trade, that the Consumer Guarantees Act 1993 (NZ) (or any analogous consumer protection legislation in any other jurisdiction) does not apply to such access or use, and that such exclusion is fair and reasonable.  Nothing in these Terms excludes, restricts or modifies the application of any mandatorily applicable law (including the Australian Consumer Law as set out in Schedule 2 of the Competition and Consumer Act (2010)) which cannot be excluded, restricted or modified by contract.</li>
                  <li>You indemnify ANZCRO (and our related bodies corporate) (each an "Indemnified Person") from and against any loss, cost, liability, claim or expense (including legal costs on a solicitor and own client basis) suffered or incurred by an Indemnified Person in connection with: (a) your access to and use of the Website and its content and functionality; (b) Product bookings; (c) failing to meet your privacy obligations, or (d) any other breach of these Terms.</li>
                </ol>

                <h4 class="title mt-5 mb-3 text-uppercase" id="privacy">Privacy</h4>

                <p>You will:</p>

                <ol type="a">
                  <li>comply (and will ensure that your contractors and agents comply) with (as applicable) the Privacy Act 1993 (NZ), the Privacy Act 1988 (Australia), the General Data Protection Regulations (EU), the Data Protection Act 2018 (UK) and all other equivalent laws, regulations and codes of practice from time to time ("Data Protection Laws"), in connection with your use of and/or access to the Website and/or Product bookings and payment;</li>
                  <li>ensure that you have the correct authorisations and permissions required under applicable Data Protection Laws to disclose any third party information to us for the purposes of Product bookings, payments, amendments, cancellations and enquiries or any other purpose contemplated by these Terms; and</li>
                  <li>comply with our <a href="/privacy-policy/">Privacy Policy</a> and any changes to that policy from time to time.</li>
                </ol>

                <p>We may collect, store, use and disclose "personal information" in accordance with the applicable Data Protection Laws and our Privacy Policy (published on the Website). </p>

                <h4 class="title mt-5 mb-3 text-uppercase" id="ip">Intellectual Property</h4>

                <ol type="a">
                  <li>All intellectual property rights in and to the Website and its contents (including Product information) and functionality are owned by ANZCRO (or its related bodies corporate), or its or their licensors exclusively.  Your rights are limited to access and use in accordance with this agreement.  </li>
                  <li>Nothing in these Terms grants you or any other person any right or interest in or to any ANZCRO name or mark (or that of any Supplier).</li>
                  <li>You may not reverse engineer, decompile, disassemble, modify, translate or make any derivative works of any part of the Website, its contents or functionality.</li>
                </ol>

                <h4 class="title mt-5 mb-3 text-uppercase" id="general">General</h4>

                <ol type="a">
                  <li>These Terms are intended to confer benefits on ANZCRO and its related bodies corporate and to the maximum extent permitted by applicable law are enforceable by each of them.</li>
                  <li>In these Terms, any obligation not to do anything includes an obligation not to suffer, permit or cause that thing to be done.</li>
                  <li>You shall not assign or otherwise transfer any Product booking or any rights or interests under these Terms without our prior written consent (in our sole discretion).</li>
                  <li>Nothing in these Terms constitutes a partnership, joint venture or relationship of principal and agent between you and us.  </li>
                  <li>You may not make any representations to anyone else in relation to Products or the Website (or its content or functionality).  </li>
                  <li>You may not make any representations on behalf of or in the name of, act or hold itself out as an agent or representative of, nor assume or create any obligations on behalf of, ANZCRO (or any of our related bodies corporate). </li>
                  <li>These Terms (and any other terms and conditions referred to in these Terms) constitute the entire agreement between you and us in respect of their subject matter and supersede all earlier negotiations, representations, warranties, understandings and agreements, whether oral or written relating to such subject matter.</li>
                  <li>If any part of these Terms are held by any court or administrative body of competent jurisdiction to be illegal, void or unenforceable, such determination will not impair the enforceability of the remaining parts of these Terms.</li>
                  <li>A waiver of any provision of these Terms will not be effective unless given in writing, and then only to the extent that it is expressly stated to be given. A failure, delay or indulgence by either party in exercising any power or right will not operate as a waiver of that power or right.</li>
                  <li>You will pay your own costs and expenses in connection with these Terms and Product enquiries and bookings.</li>
                  <li>These Terms shall be governed by and construed in accordance with the laws of the relevant jurisdiction and you hereby submit to the non-exclusive jurisdiction of the Courts of the relevant jurisdiction.  The relevant jurisdiction is New Zealand (in the case of bookings made via www.anzcro.com or www.anzcro.co.nz); Australia (in the case of bookings made via www.anzcro.com.au) or the United Kingdom (in the case of bookings made via www.anzcro.co.uk).</li>
                </ol>

                <h4 class="title mt-5 mb-3 text-uppercase" id="atol">ATOL protection for UK based travellers</h4>

                <p>ANZCRO (UK) Limited (which will arrange bookings made via www.anzcro.co.uk) holds an Air Travel Organisers' License (ATOL) (ATOL 5571) granted by the UK Civil Aviation Authority.  ATOL protects your air holidays and flights.  </p>

                <p>When you buy an ATOL protected flight or flight inclusive holiday from us you will receive an ATOL certificate. This lists what is financially protected, where you can get information on what this means for you and who to contact if things go wrong. </p>

                <p>We, or the Suppliers identified on your ATOL certificate, will provide you with the Products listed on the ATOL Certificate (or suitable alternative). In some cases, where neither we nor the Supplier are able to do so for reasons of insolvency, an alternative ATOL holder may provide you with the services you have bought or a suitable alternative (at no extra cost to you). You agree to accept that in those circumstance the alternative ATOL holder will perform those obligations and you agree to pay any money outstanding in relation to your booking to that alternative ATOL holder. However, you also agree that in some cases it will not be possible to appoint an alternative ATOL holder, in which case you may be entitled to make a claim under the ATOL scheme (or your credit card issuer where applicable).</p>

                <p>If we, or the Suppliers identified on your ATOL certificate, are unable to provide the Products listed (or a suitable alternative, through an alternative ATOL holder or otherwise) for reasons of insolvency, the Trustees of the Air Travel Trust may make a payment to (or confer a benefit on) you under the ATOL scheme. You agree that in return for such a payment or benefit you assign absolutely to those Trustees any claims which you have or may have arising out of or relating to the non-provision of the Products, including any claim against us, the travel agent (or your credit card issuer where applicable). You also agree that any such claims may be reassigned to another body, if that other body has paid sums you have claimed under the ATOL scheme.</p>

                <p>For further information visit the ATOL website at <a href="http://www.atol.org.uk" target="_blank">www.atol.org.uk</a>.</p>

                <p>No equivalent scheme applies to ANZCRO New Zealand Limited or Australian New Zealand Central Reservation Office Pty Limited.</p>

              </v-flex>
              <v-flex xs12 md4  mt-3 order-xs1 order-md2>
                <v-card flat class="pa-0 elevation-0 dash-rounded mb-4 card-with-border">
                  <v-card-text class="pa-4">
                    <v-layout row>
                      <v-flex>
                        <span class="title font-weight-medium headerGrey d-block mb-2">Index</span>
                      </v-flex>
                    </v-layout>
                    <v-divider class="mb-2"></v-divider>
                    <v-layout row>
                      <v-flex>
                        <ol>
                          <li><a @click="scrollTo()" href="#application">Application</a></li>
                          <li><a @click="scrollTo()" href="#bookings">Booking Conditions</a></li>
                          <li><a @click="scrollTo()" href="#pricing">Pricing</a></li>
                          <li><a @click="scrollTo()" href="#deposit">Deposit</a></li>
                          <li><a @click="scrollTo()" href="#full-payment">Full Payment</a></li>
                          <li><a @click="scrollTo()" href="#payments">Payments</a></li>
                          <li><a @click="scrollTo()" href="#amendments">Amendments, Cancellations and Refunds</a></li>
                          <li><a @click="scrollTo()" href="#documents">Documents</a></li>
                          <li><a @click="scrollTo()" href="#visas">Passports and Visa Requirements</a></li>
                          <li><a @click="scrollTo()" href="#insurance">Travel Insurance</a></li>
                          <li><a @click="scrollTo()" href="#baggage">Baggage</a></li>
                          <li><a @click="scrollTo()" href="#liabilities">Liabilities</a></li>
                          <li><a @click="scrollTo()" href="#privacy">Privacy</a></li>
                          <li><a @click="scrollTo()" href="#ip">Intellectual Property</a></li>
                          <li><a @click="scrollTo()" href="#general">General</a></li>
                          <li><a @click="scrollTo()" href="#atol">ATOL Protection for UK based Travellers</a></li>
                        </ol>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({

  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    }
  },
};
</script>

<style>
:target::before {
  content: '';
  display: block;
  height: 100px; /* fixed header height*/
  margin: -100px 0 0; /* negative fixed header height */
}

.terms-and-conditions ol,
.terms-and-conditions ul{
  display:block;
  margin:0 0 16px;
  padding-left:18px;
}

.terms-and-conditions ol ol{
  margin:0;
}

.terms-and-conditions ol li,
.terms-and-conditions ul li{
  margin:18px 0;
  padding-left:12px;
}
</style>
