<template>
  <div>
    <v-card class="elevation-0 card-with-border dash-rounded pa-3 mb-4" :color="offWhite" v-if="destinationsAustralia.length > 0" >
      <ul class="destination-list">
        <template v-for="(item, index) in destinationsAustralia">
          <li :key="index" v-if="item.name != currentDestination && !item.hidden">
            <a :href="`/destinations/australia${item.path}`">{{item.name}}</a>
          </li>
        </template>
      </ul>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    currentDestination: {
      type: String,
    },
  },
  data: () => ({
    destinationsAustralia: [
      {
        name: 'Australian Capital Territory',
        area: 'ACT',
        path: '/australian-capital-territory/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-auckland.jpg',
        hidden: false,
      },
      {
        name: 'New South Wales',
        area: 'NSW',
        path: '/new-south-wales/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-wellington.jpg',
        hidden: false,
      },
      {
        name: 'Northern Territory',
        area: 'NT',
        path: '/northern-territory/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-gisborne.jpg',
        hidden: false,
      },
      {
        name: 'Queensland',
        area: 'QLD',
        path: '/queensland/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-hawkes-bay.jpg',
        hidden: false,
      },
      {
        name: 'South Australia',
        area: 'SA',
        path: '/south-australia/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-taranaki.jpg',
        hidden: false,
      },
      {
        name: 'Tasmania',
        area: 'TAS',
        path: '/tasmania/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-manawatu.jpg',
        hidden: false,
      },
      {
        name: 'Victoria',
        area: 'VIC',
        path: '/victoria/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-wairarapa.jpg',
        hidden: false,
      },
      {
        name: 'Western Australia',
        area: 'WA',
        path: '/western-australia/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-whanganui.jpg',
        hidden: false,
      },
    ],
  }),
};
</script>

<style>
.destination-list li{
  margin:0 0 8px;
}
</style>
