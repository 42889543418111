import _Vue from 'vue';
import { getPaymentUrl } from '@/shared/components/payments/model/payment';

export function RegionPlugin(Vue: typeof _Vue, options: RegionPluginOptions): void {
  Vue.prototype.$region = options.region;
  Vue.prototype.$paymentUrl = getPaymentUrl();
}

export interface RegionPluginOptions {
  region: string | string[];
}
