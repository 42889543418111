import hash from 'object-hash'
import cache from '@/util-modules/cache';
import { PaymentUrlRequest } from '@/shared/components/payments/payment.service'

export function getPaymentUrl(request?: PaymentUrlRequest): String {
  let baseUrl = 'http://localhost:8000';
  const path = '/payments';
  const currentObjUrl = new URL(window.location.href);
  if (currentObjUrl.origin.match(/^https:\/\/www.(uat.|int.)?anzcro/)) {
    baseUrl = currentObjUrl.origin;
  }
  if (!request) {
    return `${baseUrl}${path}/`;
  }
  if (!request.action) {
    return `${baseUrl}${path}/?bookingId=${request.bookingId}&bookingLastName=${request.bookingLastName}&amount=${request.amount}&type=${request.type}`
  }
  const aHash = hash(request.action);
  cache.setCache(aHash, request.action, 3600);
  return `${baseUrl}${path}/?_ac_ahash=${aHash}&bookingId=${request.bookingId}&bookingLastName=${request.bookingLastName}&amount=${request.amount}&type=${request.type}`
}
