import { make } from 'vuex-pathify';
const state = {
  printQuoteDialog: false,
  printItineraryDialog: false,
  statusReportDialog: false,
  printInvoiceDialog: false,
  printVouchersPaidDialog: false,
  data: null,
};

const getters = {
  ...make.getters(state),
};

const actions = {
  ...make.actions(state),
  openPrintQuoteDialog(context, data) {
    context.commit('SET_PRINT_QUOTE_DIALOG', true);
    context.commit('SET_DATA', data);
  },
  openPrintItineraryDialog(context, data) {
    context.commit('SET_PRINT_ITINERARY_DIALOG', true);
    context.commit('SET_DATA', data);
  },
  openPrintStatusReportDialog(context, data) {
    context.commit('SET_PRINT_STATUS_REPORT_DIALOG', true);
    context.commit('SET_DATA', data);
  },
  openPrintInvoiceDialog(context, data) {
    context.commit('SET_PRINT_INVOICE_DIALOG', true);
    context.commit('SET_DATA', data);
  },
  openPrintVoucherDialog(context, data) {
    context.commit('SET_PRINT_VOUCHER_DIALOG', true);
    context.commit('SET_DATA', data);
  },
};

const mutations = {
  ...make.mutations(state),
  SET_PRINT_QUOTE_DIALOG(state, value) {
    state.printQuoteDialog = value;
  },
  SET_PRINT_ITINERARY_DIALOG(state, value) {
    state.printItineraryDialog = value;
  },
  SET_PRINT_STATUS_REPORT_DIALOG(state, value) {
    state.statusReportDialog = value;
  },
  SET_PRINT_INVOICE_DIALOG(state, value) {
    state.printInvoiceDialog = value;
  },
  SET_PRINT_VOUCHER_DIALOG(state, value) {
    state.printVouchersPaidDialog = value;
  },
  SET_DATA(state, value) {
    state.data = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
