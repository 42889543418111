import { intersection } from 'ramda';
const adminRoles = [
  'W.MKTG.MANAGER',
  'W.MKTG.TEAMLEADER',
  'W.MKTG.SALES',
  'W.MKTG.ASSISTANT',
  'W.WEB',
  'W.RES.TEAMLEADER',
  'W.RES.MANAGER',
  'W.SYSADMIN',
  'W.RES.*',
  'W.MKTG.*',
]

export function isAgent(user) {
  return intersection(user.roles, ['A.AGENT']).length > 0
}

export function isAgencyAdministrator(user) {
  return intersection(user.roles, ['A.ADMIN']).length > 0
}

export function isAdmin(user) {
  return intersection(user.roles, adminRoles).length > 0
}

export function getDisplayableRoles(user) {
  const roles = []
  if (isAgent(user)) {
    roles.push('Agent')
  }
  if (isAgencyAdministrator(user)) {
    roles.push('Agency Administrator')
  }
  if (isAdmin(user)) {
    roles.push('Admin')
  }
  return roles
}
