import appService from '@/services/app.service';

/**
 * @param {*} req
 * @returns {Promise}
 */
const addAccommodation = req => {
  return appService.post('/bookings/itinerary/addAccommodation', req);
};

/**
 * @param {*} req
 * @returns {Promise}
 */
const addTravel = req => {
  return appService.post('/bookings/itinerary/addTravel', req);
};

/**
 * @param {*} req
 * @returns {Promise}
 */
const addVehicle = req => {
  return appService.post('/bookings/itinerary/addVehicle', req);
};

const addMultiday = req => {
  return appService.post('/bookings/itinerary/addMultiday', req);
};

const addSightseeing = req => {
  return appService.post('/bookings/itinerary/addSightseeing', req);
};

const addSki = req => appService.post('/bookings/itinerary/addSki', req);

const addFromPackage = req => {
  return appService.post('/bookings/itinerary/addFromPackage', req);
};

const modifyAccommodation = req => {
  return appService.post('/bookings/itinerary/modifyAccommodation', req);
};

const modifyVehicle = req => {
  return appService.post('/bookings/itinerary/modifyVehicle', req);
};

const modifyTravel = req => {
  return appService.post('/bookings/itinerary/modifyTravel', req);
};

const modifySightseeing = req => {
  return appService.post('/bookings/itinerary/modifySightseeing', req);
};

const modifyMultiday = req => {
  return appService.post('/bookings/itinerary/modifyMultiday', req);
};

const modifySki = req => appService.post('/bookings/itinerary/modifySki', req);

const convertToQuoteFronItinerary = req => {
  return appService.post('/bookings/quote/fromItinerary', req);
};

const preValidateQuoteFromItinerary = req => {
  return appService.post('/bookings/quote/preValidation', req);
};

const convertToBookingFromQuote = req => {
  return appService.post('/bookings/booking/fromQuote', req);
};

const removeItemFromItinerary = (itineraryId, sequenceId) => {
  return appService.delete(
    `/bookings/itinerary/removeItem?itineraryId=${itineraryId}&sequenceId=${sequenceId}`,
  );
};

const removeItemFromQuote = (bookingId, booklineId) =>
  appService.delete(`/bookings/${bookingId}/bookline/${booklineId}`);

// ADD BOOKING METHODS

const addAccommodationItemToBooking = req =>
  appService.post('/bookings/booking/addAccommodation', req);

const addMultidayItemToBooking = req => appService.post('/bookings/booking/addMultiday', req);

const addSightseeingItemToBooking = req => appService.post('/bookings/booking/addSightseeing', req);

const addTravelItemToBooking = req => appService.post('/bookings/booking/addTravel', req);

const addVehicleItemToBooking = req => appService.post('/bookings/booking/addVehicle', req);

const addSkiItemToBooking = req => appService.post('/bookings/booking/addSki', req);

// MODIFY BOOKING METHODS

const modifyAccommodationItemToBooking = req =>
  appService.post('/bookings/booking/modifyAccommodation', req);

const modifyMultidayItemToBooking = req => appService.post('/bookings/booking/modifyMultiday', req);
const modifySightseeingItemToBooking = req =>
  appService.post('/bookings/booking/modifySightseeing', req);

const modifyTravelItemToBooking = req => appService.post('/bookings/booking/modifyTravel', req);

const modifyVehicleItemToBooking = req => appService.post('/bookings/booking/modifyVehicle', req);

const modifySkiItemToBooking = req => appService.post('/bookings/booking/modifySki', req);

export const itineraryService = {
  addAccommodation,
  addTravel,
  addVehicle,
  addMultiday,
  addSightseeing,
  addFromPackage,
  addSki,

  modifyAccommodation,
  modifyVehicle,
  modifyTravel,
  modifySightseeing,
  modifyMultiday,
  modifySki,

  convertToQuoteFronItinerary,
  preValidateQuoteFromItinerary,
  convertToBookingFromQuote,
  removeItemFromItinerary,
  removeItemFromQuote,
  //
  addAccommodationItemToBooking,
  addMultidayItemToBooking,
  addSightseeingItemToBooking,
  addTravelItemToBooking,
  addVehicleItemToBooking,
  addSkiItemToBooking,

  modifyAccommodationItemToBooking,
  modifyMultidayItemToBooking,
  modifySightseeingItemToBooking,
  modifyTravelItemToBooking,
  modifyVehicleItemToBooking,
  modifySkiItemToBooking,
};
