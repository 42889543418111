import { render, staticRenderFns } from "./Australia.vue?vue&type=template&id=43c97fd8"
import script from "./Australia.vue?vue&type=script&lang=js"
export * from "./Australia.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VCarousel } from 'vuetify/lib'
import { VCarouselItem } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
installComponents(component, {
  VCard,
  VCardText,
  VCarousel,
  VCarouselItem,
  VContainer,
  VDivider,
  VFlex,
  VImg,
  VLayout,
  VSpacer,
})
