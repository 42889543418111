import { render, staticRenderFns } from "./ProgressCircular.vue?vue&type=template&id=6f8005f6"
import script from "./ProgressCircular.vue?vue&type=script&lang=js"
export * from "./ProgressCircular.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VProgressCircular } from 'vuetify/lib'
installComponents(component, {
  VContainer,
  VLayout,
  VProgressCircular,
})
