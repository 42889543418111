import { render, staticRenderFns } from "./Register.CO.UK.vue?vue&type=template&id=7d4f0f58"
import script from "./Register.CO.UK.vue?vue&type=script&lang=js"
export * from "./Register.CO.UK.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib'
import { VAutocomplete } from 'vuetify/lib'
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VCheckbox } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VForm } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VStepper } from 'vuetify/lib'
import { VStepperContent } from 'vuetify/lib'
import { VStepperStep } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
import { VTooltip } from 'vuetify/lib'
installComponents(component, {
  VAlert,
  VAutocomplete,
  VBtn,
  VCard,
  VCardText,
  VCheckbox,
  VContainer,
  VDivider,
  VFlex,
  VForm,
  VImg,
  VLayout,
  VStepper,
  VStepperContent,
  VStepperStep,
  VTextField,
  VTooltip,
})
