<template>
  <v-container pa-0 fluid>
    <v-layout row fill-height class="hero-waves hero-login">
      <v-flex>
        <v-container fill-height grid-list-lg>
          <v-layout dark row wrap fill-height align-center py-5>
            <v-flex xs12 sm8 offset-sm2 md6 offset-md3 v-if="!submited">
              <v-card
                dark
                :color="coalBlack"
                class="pa-3 pa-md4 elevation-0 card-with-border dash-rounded"
              >
                <h3 class="white--text title mt-3 mb-4">Password Recover</h3>
                <p>Please enter your username or email below. We will send you an email with the instructions on how to reset your password.</p>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-alert :value="true" type="error" v-if="errorMsg.length > 0">{{errorMsg}}</v-alert>
                    <v-form ref="resetPasswordForm">
                      <v-layout row wrap>
                        <v-flex xs12>
                          <v-text-field
                            v-model="username"
                            box
                            :rules="usernameRules"
                            label="Username / Email"
                            :disabled="authenticating"
                            :color="kiwiGreen"
                            required
                            hide-details
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-btn
                            type="submit"
                            large
                            dark
                            :color="headerGrey"
                            class="elevation-0 ml-0"
                            @click.prevent="sendVerificationEmail()"
                            :loading="authenticating"
                            :disabled="authenticating"
                          >Send Verification Email</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-form>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 sm8 offset-sm2 md6 offset-md3 text-xs-center v-else>
              <v-card
                dark
                :color="coalBlack"
                class="pa-4 pa-md5 elevation-0 card-with-border dash-rounded"
              >
                <v-spacer></v-spacer>
                <v-avatar size="80" :color="coalBlack">
                  <v-icon color="grey" size="44">check</v-icon>
                </v-avatar>
                <h3 class="white--text title my-4">Email Sent</h3>
                <p>Please check your inbox for an email. <br/>It contains a link that you can use to reset your password.</p>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { userService } from '../dashboard/account/services/user.service';
export default {
  data: () => ({
    coalBlack: '#30302F',
    kiwiGreen: '#65b32e',
    coalBlack60: '#838382',
    headerGrey: '#595959',
    lightGrey: '#eaeaea',
    show1: false,
    username: '',
    authenticating: false,
    usernameRules: [v => !!v || 'Username is required'],
    errorMsg: '',
    submited: false,
  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
    async sendVerificationEmail() {
      if (!this.$refs.resetPasswordForm.validate()) {
        return;
      }
      this.authenticating = true;
      try {
        await userService.forgotPassword(this.username);
        this.$refs.resetPasswordForm.reset();
        this.submited = true;
      } catch (e) {
        this.errorMsg = e.message;
      } finally {
        this.authenticating = false;
      }
    },
  },
};
</script>

<style>
.standard-hero {
  height: 70vh;
}

.standard-hero .v-image__image {
  background-attachment: fixed;
}

.standard-content {
  margin-top: -36px !important;
  margin-bottom: 36px !important;
}

.hero-title h1,
.hero-title h3 {
  line-height: 1.2 !important;
}

@media (min-width: 600px) {
  .standard-hero {
    height: 60vh;
  }
  .hero-title .display-1 {
    font-size: 54px !important;
    line-height: 1.2 !important;
  }
}

@media (min-width: 960px) {
}
</style>
