<template>
    <v-container pa-0 fluid>
      <v-layout row class="hero-waves">
        <v-flex>
          <v-container fill-height>
            <v-layout dark row wrap fill-height align-center pt-5>
              <v-flex xs12 text-xs-center text-md-left class="hero-title">
                <h3 class="grey--text title mb-2">ANZCRO</h3>
                <h1 class="white--text display-1 font-weight-bold mb-3">Sustainability Policy</h1>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
  
      <v-layout row class="terms-and-conditions">
        <v-flex>
          <v-card flat tile class="pb-5">
            <v-container grid-list-xl fill-height>
              <v-layout row wrap fill-height>
                <v-flex xs12 md8 pa-3 mb-4 mt-3 order-xs2 order-md1>

                    <p>At ANZCRO, as a leading travel company, we recognise the importance of sustainable practices in the tourism industry. We are committed to promoting and implementing sustainable principles across our operations to minimise our environmental impact, support local communities, and preserve cultural heritage. This sustainability policy outlines our commitment and sets guidelines for achieving our sustainability goals.</p>
  
                  <ol type="1">
                    <li id="environmental-responsibility">Environmental Responsibility
                        <ol type="i">
                            <li>Natural Resource Management: We will actively manage our use of natural resources such as water, energy, and paper. By adopting digital solutions, we will seek opportunities to conserve resources through efficient practices, promote water-saving initiatives, and minimize paper consumption.</li>
                            <li>Waste Management: We will implement waste reduction and recycling programs in our offices and promote responsible waste management practices among our employees, partners, and customers. We will work with our suppliers to minimise packaging waste and explore opportunities for composting and recycling materials.</li>
                            <li>Biodiversity Conservation: We will promote the protection of biodiversity in the regions where we operate. We will encourage our customers and partners to respect and preserve natural habitats, avoid activities that harm wildlife, and support conservation initiatives through partnerships and responsible travel practices.</li>
                        </ol>
                    </li>
                    <li id="social-responsibility">Social Responsibility
                        <ol type="i">
                            <li>Local Communities: We will support local communities by promoting fair and equitable tourism practices. We will prioritize working with local suppliers, hotels, and tour operators that demonstrate a commitment to social responsibility and contribute to the well-being of the communities they operate in.</li>
                            <li>Cultural Preservation: We will respect and promote the cultural heritage of the destinations we offer. We will encourage our customers to engage in culturally sensitive interactions, support local arts and crafts, and contribute to initiatives that preserve and celebrate cultural traditions.</li>
                            <li>Responsible Tourism Education: We will provide our customers and employees information and resources to promote responsible tourism practices. We will raise awareness about environmental and social issues, encourage sustainable travel behavior, and provide guidelines for respectful interactions with local communities and environments.</li>
                        </ol>
                    </li>
                    <li id="partnerships-and-suppliers">Partnerships and Suppliers
                        <ol type="i">
                            <li>Supplier Engagement: We will work closely with our suppliers to ensure they adhere to sustainable practices. We will prioritize suppliers that share our commitment to sustainability, ethical conduct, and social responsibility. We will encourage dialogue and collaboration to improve sustainability performance throughout the supply chain.</li>
                            <li>Collaboration and Certification: We will seek partnerships with organizations and initiatives that promote sustainability in the travel industry. We will actively learn from National and Regional Tourism Offices driving sustainability initiatives, collaborating with suppliers, and supporting certification programs validating our commitment to sustainable practices.</li>
                        </ol>
                    </li>                    
                  </ol>
                </v-flex>
                <v-flex xs12 md4  mt-3 order-xs1 order-md2>
                  <v-card flat class="pa-0 elevation-0 dash-rounded mb-4 card-with-border">
                    <v-card-text class="pa-4">
                      <v-layout row>
                        <v-flex>
                          <span class="title font-weight-medium headerGrey d-block mb-2">Index</span>
                        </v-flex>
                      </v-layout>
                      <v-divider class="mb-2"></v-divider>
                      <v-layout row>
                        <v-flex>
                          <ol>
                            <li><a @click="scrollTo()" href="#environmental-responsibility">Environmental Responsibility</a></li>
                            <li><a @click="scrollTo()" href="#social-responsibility">Social Responsibility</a></li>
                            <li><a @click="scrollTo()" href="#partnerships-and-suppliers">Partnerships and Suppliers</a></li>
                          </ol>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </template>
  
  <script>
  export default {
    data: () => ({
  
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      }
    },
  };
  </script>
  
  <style>
  :target::before {
    content: '';
    display: block;
    height: 100px; /* fixed header height*/
    margin: -100px 0 0; /* negative fixed header height */
  }
  
  .terms-and-conditions ol,
  .terms-and-conditions ul{
    display:block;
    margin:0 0 16px;
    padding-left:18px;
  }
  
  .terms-and-conditions ol ol{
    margin:0;
  }
  
  .terms-and-conditions ol li,
  .terms-and-conditions ul li{
    margin:18px 0;
    padding-left:12px;
  }
  </style>
  