<template>
  <!-- Recommended Site Dialog -->
  <v-dialog persistent v-model="dialog" max-width="500px" transition="slide-y-reverse-transition">
    <v-card class="pa-0">
      <v-layout row pa-3 align-center>
        <v-flex></v-flex>
        <v-flex shrink>
          <v-btn
            small
            :color="lightGrey"
            fab
            class="elevation-0 ma-0 card-with-border"
            @click="dialog = false;"
          >
            <v-icon size="24" :color="headerGrey">close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-card-text class="text-xs-center pa-3">
        <span
          class="d-block subheading mt-3"
        >Are you visiting {{ siteRegion.site }} {{ siteRegion.name !== '' ? 'from  outside ' : '' }}{{ siteRegion.name }}?
          If you are an agent based {{ userRegion.name !== '' ?  'in ' : ' overseas'  }}{{ userRegion.name }},
          visit our {{ userRegion.nationality }} site for relevant pricing.
        </span>
        <v-layout row wrap mt-5>
          <v-flex xs10 offset-xs1>
            <v-btn
              dark
              :color="coalBlack"
              large
              block
              :href="userRegion.url"
              class="elevation-0 card-with-border"
            >Visit our {{ userRegion.nationality }} site
            </v-btn>
          </v-flex>
          <v-flex xs10 offset-xs1>
            <v-btn
              :color="lightGrey"
              large
              block
              @click="stay(userRegion.code)"
              class="elevation-0 card-with-border"
            >Stay on {{ siteRegion.nationality }} site
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import cache from "@/util-modules/cache";

export default {
  data: () => ({
    dialog: false,
    currentSiteLocation: "",
    recommendedSite: "",
    recommendedSiteButtonLabel: "",
    currentSiteButtonLabel: "",
    mapRegionCountry: [
      {
        region: "COM.AU",
        code: "AU",
        name: "Australia",
        nationality: "Australian",
        site: "ANZCRO Connect Australia",
        url: "https://www.anzcro.com.au",
      },
      {
        region: "CO.NZ",
        code: "NZ",
        name: "New Zealand",
        nationality: "New Zealand",
        site: "ANZCRO Connect New Zealand",
        url: "https://www.anzcro.co.nz",
      },
      {
        region: "CO.UK",
        code: "GB",
        name: "the United Kingdom",
        nationality: "United Kingdom",
        site: "ANZCRO Connect United Kingdom",
        url: "https://www.anzcro.co.uk",
      },
      {
        region: "COM",
        code: "INT",
        name: "",
        nationality: "International",
        url: "https://www.anzcro.com",
        site: "ANZCRO Connect Inbound"
      },
    ],
    siteCountryCode: "INT",
    userCountryCode: "INT",
  }),
  computed: {
    siteRegion() {
      return this.mapRegionCountry.find((x) => x.code === this.siteCountryCode);
    },
    userRegion() {
      return this.mapRegionCountry.find((x) => x.code === this.userCountryCode);
    },
  },
  mounted() {
    this.siteCountryCode = this.mapRegionCountry.find((x) => x.region === this.$region).code;
    this.checkGeoLocation();

    // remove overflow-y-hidden when dialog is false because it cause scrollable issue
    if (!this.dialog) {
      let html = document.getElementsByTagName('html')
      html[0].classList.remove('overflow-y-hidden')
    }
  },
  methods: {
    async checkGeoLocation() {
      let countryCode = await this.getCountry();
      if (!countryCode) {
        return;
      }
      if (!this.mapRegionCountry.find((x) => x.code === countryCode)) {
        countryCode = "INT";
      }
      if (cache.getCache(`_geo_user_country_choice_${countryCode}`)) {
        return;
      }
      if (
        // this.$region !== "COM" &&
        this.mapRegionCountry.find((x) => x.code === countryCode) &&
        this.mapRegionCountry.find((x) => x.region === this.$region).code !== countryCode
      ) {
        this.userCountryCode = countryCode;
        this.dialog = true;
      }
    },
    async getCountry() {
      try {
        if (!cache.getCache("_geo_user_country_code")) {
          const location = await fetch("https://ipinfo.io?token=0fb8b130f35f92");
          const data = await location.json();
          cache.setCache("_geo_user_country_code", data.country, 24 * 3600);
        }
        return cache.getCache("_geo_user_country_code");
      } catch (e) {
        return undefined;
      }
    },
    visit(url) {
      window.location.href = url;
    },
    stay(countryCode) {
      cache.setCache(`_geo_user_country_choice_${countryCode}`, true);
      this.dialog = false;
    },
  },
};
</script>
