<template>
  <v-container pa-0 fluid>
    <v-layout row class="hero-waves">
      <v-flex>
        <v-container fill-height>
          <v-layout dark row wrap fill-height align-center pt-5>
            <v-flex xs12 text-xs-center text-md-left class="hero-title">
              <h1 class="white--text display-1 font-weight-bold mb-3">
                Thanks for your<br>registration request!
              </h1>
              <h3 class="title mb-4 grey--text">We are pleased to meet you and excited to start working together</h3>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex>
        <v-card flat tile :color="offWhite">
          <v-container grid-list-lg fill-height>
            <v-layout row wrap fill-height style="margin-top:-48px;">
              <v-flex xs12 pt-0>
                <v-card flat class="pa-5 dash-rounded">
                  <h4 class="title mb-4">Here's what comes next:</h4>
                  <ol class="subheading">
                    <li>Your request will be reviewed by our staff.</li>
                    <li>Once your request is approved, we will send you an email verification message.</li>
                    <li>Follow the instructions in the message and complete the verification.</li>
                    <li>You will be ready to login!</li>
                  </ol>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    mydata: 1
  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
</style>
