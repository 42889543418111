export interface Region {
  id: number,
  code: string
}

export interface ResourceSearch {
  resource_types: number;
  regions: number;
}

export interface ResourceTitle {
  rendered: string;
}

export interface ResourceYear {
  year: string;
}

export interface ResourceSearchResponse {
  title: ResourceTitle;
  featured_media_url: string;
  _oa_resource_uri: string;
  _oa_resource_years: ResourceYear[];
  _oa_resource_code: string;
  _oa_resource_request: boolean;
  _oa_resource_expire_date: string; // media asset
  _oa_resource_media_icon: string; // media asset
  _oa_resource_type: string; // media asset
  _oa_resource_destination: string; // media asset
}

export interface Brochure {
  title: string;
  valid: string;
  link: string;
  imageSource: string;
  code: string;
  quantity: number;
  request: boolean;
}

export interface MediaAsset {
  title: string;
  type: string;
  icon: string;
  expires: string;
  link: string;
  image: string;
  destination: string;
}

export interface Map {
  title: string;
  type: string;
  icon: string;
  link: string;
  image: string;
}

export interface Logo {
  title: string;
  type: string;
  icon: string;
  link: string;
  image: string;
}

const _brochureMapper = (x: ResourceSearchResponse) : Brochure =>  ({
  title: x.title.rendered,
  valid: x._oa_resource_years.map(x => x.year).join('-'),
  link: x._oa_resource_uri,
  imageSource: x.featured_media_url,
  code: x._oa_resource_code,
  quantity: 1,
  request: x._oa_resource_request
});

const _digitalMediaMapper = (x: ResourceSearchResponse) : MediaAsset =>  ({
  title: x.title.rendered,
  type: x._oa_resource_type,
  icon: x._oa_resource_media_icon,
  expires: x._oa_resource_expire_date,
  link: x._oa_resource_uri,
  image: x.featured_media_url,
  destination: x._oa_resource_destination
});

const _mapMapper = (x: ResourceSearchResponse) : Map =>  ({
  title: x.title.rendered,
  type: x._oa_resource_type,
  icon: x._oa_resource_media_icon,
  link: x._oa_resource_uri,
  image: x.featured_media_url
});

const _logoMapper = (x: ResourceSearchResponse) : Logo =>  ({
  title: x.title.rendered,
  type: x._oa_resource_type,
  icon: x._oa_resource_media_icon,
  link: x._oa_resource_uri,
  image: x.featured_media_url
});

export function getCMSRegionByCode(code: string) : number {
  let _code = code;
  /*
  if (_code === "COM") {
    _code = "CO.NZ";
  }
  */

  const regions: Array<Region> = [
    {
      id: 8,
      code: 'CO.NZ'
    },
    {
      id: 7,
      code: 'COM.AU'
    },
    {
      id: 9,
      code: 'CO.UK'
    },
    {
      id: 25,
      code: 'COM'
    }
  ];
  const result: Region|undefined = regions.find((x: Region): boolean => (x.code === _code));
  if (result === undefined) {
    throw new Error("CMS region code not found");
  }
  return result.id;
}

export function getResourceMapper(resourceType: number) : Function {
  if (resourceType === 2) {
    return _brochureMapper;
  } else if ( [3, 6].indexOf(resourceType) > -1 ) {
    return _digitalMediaMapper;
  } else if(resourceType === 5) {
    return _mapMapper
  } else {
    return _logoMapper
  }
}
