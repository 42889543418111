<template>
  <v-container pa-0 fluid>

    <v-layout row class="hero-waves">
      <v-flex>
        <v-container fill-height>
          <v-layout dark row wrap fill-height align-center pt-5>
            <v-flex xs12 text-xs-center text-md-left class="hero-title">
              <h1 class="white--text display-1 font-weight-bold mb-3">Destinations</h1>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex>
        <v-card flat tile class="py-4">
          <v-container grid-list-lg>
            <v-layout row wrap>
                <v-flex xs12 sm6 lg4 :key="index">
                  <v-card
                    class="elevation-0 v-card--btn"
                    to="/destinations/australia/"
                    :color="lightGrey"
                  >
                    <v-img
                      aspect-ratio="1.5"
                      src="https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-thumbnail-nsw.jpg"
                      cover
                      gradient="to top, rgba(0,0,0,0.7), rgba(0,0,0,0) 35%"
                    >
                      <v-layout column fill-height class="lightbox white--text" ma-0>
                        <v-spacer></v-spacer>
                        <v-flex shrink>
                          <div
                            class="subheading d-block mb-1 font-weight-medium"
                          >Australia</div>
                        </v-flex>
                      </v-layout>
                    </v-img>
                  </v-card>
                </v-flex>
                <v-flex xs12 sm6 lg4 :key="index">
                  <v-card
                    class="elevation-0 v-card--btn"
                    to="/destinations/new-zealand/"
                    :color="lightGrey"
                  >
                    <v-img
                      aspect-ratio="1.5"
                      src="https://cdn.anzcro.com.au/wp-content/uploads/2019/10/search-index-packages-001.jpg"
                      cover
                      gradient="to top, rgba(0,0,0,0.7), rgba(0,0,0,0) 35%"
                    >
                      <v-layout column fill-height class="lightbox white--text" ma-0>
                        <v-spacer></v-spacer>
                        <v-flex shrink>
                          <div
                            class="subheading d-block mb-1 font-weight-medium"
                          >New Zealand</div>
                        </v-flex>
                      </v-layout>
                    </v-img>
                  </v-card>
                </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data: () => ({}),
  mounted: function() {
    document.documentElement.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
</style>
