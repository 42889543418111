import appService from './app.service';
import { ProductDetailsResource, ProductDetailsResourceParms } from '@/components/itinerary/model/product/product';
import { O3ApiResponse } from '@/model/response';

export const searchDetailService = {
  getDetails: (
    params: ProductDetailsResourceParms,
  ): Promise<O3ApiResponse<ProductDetailsResource>> => {
    return appService.get('/productDetails/fromSearch', params);
  },
};
