import { flatten } from 'ramda';

export class Nothing {
  private _value;
  constructor(val) {
    this._value = val;
  }
  static of(val) {
    return new Nothing(val);
  }
  map(fn) {
    if (this._value !== null || this._value !== undefined) {
      return new Nothing(fn(this._value));
    } else {
      return new Nothing(this._value);
    }
  }
}
export class MayBe {
  private _value;
  constructor(val?: any) {
    if (val !== null || val !== undefined) {
      this._value = val;
    }
  }
  static of(val) {
    return new MayBe(val);
  }
  isNothing() {
    return this._value === null || this._value === undefined;
  }
  map(fn) {
    if (this.isNothing()) {
      return new MayBe();
    } else {
      return new MayBe(fn(this._value));
    }
  }
  ap(c) {
    return c.map(fn => this.map(fn));
  }
  join() {
    return this.isNothing() ? Nothing.of(this._value) : this._value;
  }
  chain(fn) {
    return this.map(fn).join();
  }

  fold(defaultValue) {
    return fn => {
      return this.isNothing() ? defaultValue : fn(this._value);
    };
  }

  concat(maybe) {
    const arr: any[] = [];
    if (!this.isNothing()) arr.push(this._value);
    if (!maybe.isNothing()) arr.push(maybe.join());
    return MayBe.of(flatten(arr));
  }
}

export const either = (x, y) => (x === null || x === undefined ? y : x);
