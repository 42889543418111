import appService from '@/services/app.service';
import { AnyTypeToFix } from '@/model/AnyType';

const getUser = (username: AnyTypeToFix) => {
  return appService.get(`/users/${username}`);
};

const getRequests = (req: AnyTypeToFix) => {
  return appService.get('/users/requests', req);
};

export const userQueryService = {
  getUser,
  getRequests,
};
