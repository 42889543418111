<template>
  <v-snackbar
    v-model="snackbar"
    :bottom="y === 'bottom'"
    :left="x === 'left'"
    :multi-line="mode === 'multi-line'"
    :right="x === 'right'"
    :timeout="timeout"
    :top="y === 'top'"
    :vertical="mode === 'vertical'"
    :color="'error'"
  >
    <span v-html="message"></span>
    <v-btn dark flat @click="snackbar = false">Close</v-btn>
  </v-snackbar>
</template>
<script>
import { publicErrorService } from "./publicError.service"
export default {
  data() {
    return {
      snackbar: false,
      y: 'top',
      x: null,
      mode: 'multi-line',
      timeout: 6000,
      message: '',
    };
  },
  mounted() {
    this.$subscribeTo(publicErrorService.onError$, this.show);
  },
  methods: {
    show(message) {
      this.message = message;
      this.snackbar = true;
    }
  }
};
</script>
