import { makeZapierService } from '@/services/zapier.service';
import { countries } from '@/util-modules/countries';
export const agencyRegistrationMixin = {
    data: () => ({
        registerStepper: 1,
        australianStates: ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'],
        agencyPostalAddressOption: true,
        agencyAffiliateProgram: '',
        addressLine2: '',
        postalstreetAddress: '',
        postaladdressLine2: '',
        postalcity: '',
        postalstateProvince: '',
        confirmEmail: '',
        agencyAbnAcnRules: [],
        agencyAbnAcn: '',
        fax: '',
        confirmpassword: '',
        valid: false,
        firstname: '',
        firstNameRules: [v => !!v || 'First name is required'],
        lastname: '',
        lastNameRules: [v => !!v || 'Last name is required'],
        agencyName: '',
        agencyNameRules: [v => !!v || 'Agency name is required'],
        password: '',
        passwordRules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 8 || 'Min 8 characters',
        },
        confirmPassword: '',
        confirmPasswordRules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 8 || 'Min 8 characters',
        },
        email: '',
        emailRules: [v => !!v || 'E-mail is required', v => /.+@.+/.test(v) || 'E-mail must be valid'],
        agencyEmail: '',
        agencyEmailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
        agencyPhone: '',
        agencyPhoneRules: [v => !!v || 'Phone is required'],
        agencyCitySuburb: '',
        agencyCitySuburbRules: [v => !!v || 'City / Suburb is required'],

        agencyFax: '',
        agencyFaxRules: [v => !!v || 'Fax is required'],
        agencyStreetAddress: '',
        agencyStreetAddressRules: [v => !!v || 'Street Address is required'],
        agencyState: '',
        stateProvinceRules: [v => !!v || 'State is required'],
        agencyPostcode: '',
        agencyPostcodeRules: [v => !!v || 'Zip / Postal Code is required'],
        agencyPostalAddress: '',
        agencyPostalAddressRules: [v => !!v || 'Zip / Postal Code is required'],
        agencyCountry: '',
        loading: false,
        show1: false,
        show2: false,
        requestAccount: false,
        countries: countries,
        agreed: false
    }),
    computed: {
        emailConfirmationRules() {
            return [
                v => !!v || 'Confirm E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
                () => this.email === this.confirmEmail || 'Confirm E-mail does not match',
            ];
        },
    },
    mounted: function () {
        const el = document.scrollingElement || document.documentElement;
        el.scrollTop = 0;
    },
    methods: {
        top() {
            document.documentElement.scrollTop = 0;
        },
        gotoAccountManager() {
            if (!this.$refs.detailForm.validate()) {
                const invalidInput = this.$refs.detailForm.inputs.find(t => t.valid === false);
                if (invalidInput) {
                    invalidInput.focus();
                }
                return;
            }
            this.registerStepper = 2;
            this.top();
        },
        submitWithoutAccount() {
            if (this.$refs.detailForm.validate()) {
                this.submit();
            }
        },
        submitWithAccount() {
            if (this.$refs.managerForm.validate()) {
                this.submit();
            }
        },
        async submit() {
            try {
                this.loading = true;
                await makeZapierService(this.$region).registerNewAgent({
                    agencyName: this.agencyName,
                    agencyAbnAcn: this.agencyAbnAcn,
                    agencyAffiliateProgram: this.agencyAffiliateProgram,
                    agencyEmail: this.agencyEmail,
                    agencyPhone: this.agencyPhone,
                    agencyFax: this.agencyFax,
                    agencyStreetAddress: this.agencyStreetAddress,
                    agencyCitySuburb: this.agencyCitySuburb,
                    agencyState: this.agencyState,
                    agencyPostcode: this.agencyPostcode,
                    agencyCountry: this.agencyCountry,
                    accountManagerFirstName: this.requestAccount ? this.firstname : '',
                    accountManagerLastName: this.requestAccount ? this.lastname : '',
                    accountManagerEmail: this.requestAccount ? this.email : '',
                    accountManagerConfirmEmail: this.requestAccount ? this.confirmEmail : '',
                    agencyPostalAddress: this.agencyPostalAddressOption
                        ? this.agencyPostcode
                        : this.agencyPostalAddress,
                });
                this.$router.push({ path: '/agency-registration-requested/' });
            } catch (err) {
                this.$handleError(err);
            } finally {
                this.loading = false;
            }
        },
        goToDetails() {
            this.registerStepper = 1;
            this.top();
        },
    },
};
