<template>
    <v-container pa-0 fluid>
      <v-layout row>
        <v-flex>
          <v-card flat tile dark class="hero-home-slider">
            <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
              <v-carousel-item>
                <v-img
                  src="https://cdn.anzcro.com.au/wp-content/uploads/2022/03/hero-australian-train-journeys-scaled.jpg"
                  gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.65) 15%, rgba(22,22,22,0.25) 65%"
                  class="home-hero"
                >
                  <v-container fill-height>
                    <v-layout dark column fill-height justify-space-between pt-5>
                      <v-flex text-xs-center class="home-hero-cta">
                        <v-layout row align-center fill-height class="fill-height-fix-ios">
                          <v-flex>
                            <!--<h1 class="white--text display-1 font-weight-bold mb-3">
                              Hot Offers on<br/>Holidays at Home
                            </h1>-->
                            <img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/08/great-rail-journeys-of-australia-stacked.png" alt="Great Rail Journeys of Australia" class="campaign-title stacked" width="480" height="300" />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div class="hero-caption hidden-xs-only">
                    <small>The Ghan - Photo: Journey Beyond</small>
                  </div>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="gradient-bar"></div>
  
      <v-layout row>
        <v-flex mb-5>
            <v-container grid-list-lg fill-height>
              <v-layout row wrap fill-height>
                <v-flex xs12 py-0 my-0>
                  <v-card tile class="pa-0 elevation-0 deals-content">
                    <v-card-text class="pa-5">
                      <v-layout row wrap>
                        <v-flex xs12 md12 py-0 my-0>
                          <span
                          class="headline font-weight-medium kiwiGreen d-block mb-3"
                          >Calling all railway enthusiasts!</span>
                          <p>From mountain air to ocean breeze to the rugged and vast Aussie outback, you can explore the beauty Australia on one of these great train journeys. It’s the simplest way to discover the romance of slow travel. As always, all packages can be customised. Contact us for more info.</p>
                          <p><b>Learn more:</b> <a href="/the-insider/australian-rail-journeys-insider-guide/">Your Insider Guide to Iconic Australian Rail Journeys</a></p>
                        </v-flex>
                        <!--
                        <v-flex xs12 md2 py-0 my-0 text-xs-center>
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/07/journey-beyond-logo-square.png" alt="Journey Beyond" class="campaign-sticker kirra-early-bird" width="124" height="124" />
                        </v-flex>
                        -->
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                  <v-card tile dark class="pa-0 ma-0 elevation-0">
                    <v-card-text class="px-3 py-2">
                      <span
                        class="headline font-weight-medium d-block"
                      >{{section.title}}</span>
                    </v-card-text>
                  </v-card>
                  <v-card tile class="px-3 py-3 elevation-0">
                    <v-layout row wrap ma-0>
                      <v-flex xs12 pa-0 hidden-sm-and-down>
                        <v-layout row wrap my-0>
                          <v-flex xs12 md7 lg8>
                            <span class="caption grey--text">Package Deal</span>
                          </v-flex>
                          <v-flex xs12 md3 lg2 text-md-right>
                            <span class="caption grey--text">From Price (GBP)</span>
                          </v-flex>
                          <v-flex xs12 md2 lg2 text-md-right>
                            <span class="caption grey--text">Inclusions & Terms</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                        <v-divider></v-divider>
                        <v-layout row wrap align-center my-3>
                          <v-flex xs12 md7 lg8>
                            <v-layout row align-center>
                              <v-flex shrink hidden-md-and-down>
                                <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                              </v-flex>
                              <v-flex>
                                <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                                <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                                <span class="d-block package-data">
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Start location</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Package Code</span>
                                    </v-tooltip>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm6 md3 lg2 text-md-right>
                            <span class="headline d-block font-weight-medium mb-2 kiwiGreen">£{{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                            <span class="caption d-block grey--text">{{deal.complement}}</span>
                          </v-flex>
                          <v-flex xs12 sm6 md2 lg2 text-sm-right text-md-center>
                          <v-tooltip top v-if="deal.sm_tile">
                            <template v-slot:activator="{ on }">
                              <v-btn large v-on="on" icon class="card-with-border elevation-0 mr-1 px-0" color="white" :href="deal.sm_tile" target="_blank"><v-icon :color="kiwiGreen" size="22" class="">image</v-icon></v-btn>
                            </template>
                            <span>View Social Media Tile</span>
                          </v-tooltip>
                          <v-tooltip top v-if="deal.itinerary">
                            <template v-slot:activator="{ on }">
                              <v-btn large v-on="on" icon class="card-with-border elevation-0 mx-0 px-0" :color="kiwiGreen" :href="deal.itinerary" target="_blank"><v-icon color="white" size="22" class="">description</v-icon></v-btn>
                            </template>
                            <span>View Flyer</span>
                          </v-tooltip>
                        </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
      </v-layout>
  
  
    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      copied: false,
      copiedText: '',
      sections : [
        {
          title : 'Deals',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/6-Day-Indian-Pacific-SM-GBP-150x150.jpg',
              name : '6 Day Indian Pacific Rail Journey',
              price : '2099',
              complement : 'per person (twin share)',
              code : 'ANZAU246DIPP',
              location : 'Perth (PER)',
              valid: '27 Apr - 23 Nov 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/12/6-Day-Indian-Pacific-GBP.pdf',
              sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/6-Day-Indian-Pacific-SM-GBP.jpg'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/7-Day-The-Ghan-Expedition-SM-GBP-150x150.jpg',
              name : '7 Day The Ghan Expedition',
              price : '3415',
              complement : 'per person (twin share)',
              code : 'ANZAU247DGE',
              location : 'Darwin (DRW)',
              valid: '02 May - 29 Oct 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/12/7-Day-The-Ghan-Expedition-GBP.pdf',
              sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/7-Day-The-Ghan-Expedition-SM-GBP.jpg'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/au-rail_south-to-north.jpg',
              name : '11 Day South to North Discovery',
              price : '3839',
              complement : 'per person (twin share)',
              code : 'AUK2411DSND',
              location : 'Adelaide (ADL)',
              valid: '03 Dec 2024 - 18 Feb 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/11-Day-South-to-North-Discovery-GBP-Flyer.pdf',
              sm_tile : null
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/au-rail_great-southern.jpg',
              name : '12 Day Great Southern Land',
              price : '4349',
              complement : 'per person (twin share)',
              code : 'AUK2412DGSL',
              location : 'Brisbane (BNE)',
              valid: '06 Dec 2024 - 21 Feb 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/12-Day-Great-Southern-Land-GBP-Flyer.pdf',
              sm_tile : null
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/au-rail_west-meets-east.jpg',
              name : '12 Day West Meets East Traverse',
              price : '3765',
              complement : 'per person (twin share)',
              code : 'AUK2412DWME',
              location : 'Perth (PER)',
              valid: '30 Jul - 17 Dec 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/12-Day-West-Meets-East-Traverse-GBP-Flyer.pdf',
              sm_tile : null
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/au-rail_qld-coast.jpg',
              name : '14 Day Queensland Coast Explorer',
              price : '1749',
              complement : 'per person (twin share)',
              code : 'AUK2414DQCE',
              location : 'Brisbane (BNE)',
              valid: '03 Apr 2024 - 26 Feb 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/14-Day-Queensland-Coast-Explorer-GBP-Flyer.pdf',
              sm_tile : null
            },
            /*
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/5-Day-Queensland-Rail-Journey-SM-GBP-150x150.jpg',
              name : '5 Day Queensland Rail Journey',
              price : '525',
              complement : 'per person (twin share)',
              code : 'ANZAU2415DQR',
              location : 'Brisbane (BNE)',
              valid: '01 Mar - 30 Nov 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/12/5-Day-Qld-Rail-GBP.pdf',
              sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/5-Day-Queensland-Rail-Journey-SM-GBP.jpg'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/deal-west-east.jpg',
              name : '8 Day West to East Venture',
              price : '3369',
              complement : 'per person (twin share)',
              code : 'JB248DWEV',
              location : 'Perth (PER)',
              valid: 'Departure 08 Nov 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/8-Day-West-to-East-GBP-UPDATED-AUG23-NEW.pdf',
              sm_tile : null
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/7-Day-Great-Southern-SM-GBP-150x150.jpg',
              name : '7 Day Great Southern Rail Journey',
              price : '1779',
              complement : 'per person (twin share)',
              code : 'ANZAU237DGSA',
              location : 'Adelaide (ADL)',
              valid: '19 Dec 2023 - 29 Jan 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/7-Day-Great-Southern-Adl-to-Bne-GBP.pdf',
              sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/7-Day-Great-Southern-SM-GBP.jpg'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/07/deal-uk-rail-desert.jpg',
              name : '9 Day Desert to the South',
              price : '2695',
              complement : 'per person (twin share)',
              code : 'JBUK239DD2S',
              location : 'Ayers Rock/Yulara (AYQ)',
              valid: 'Select Departures Mar 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/11/9-Day-Desert-to-the-South-GBP-EXTENDED-1.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/07/deal-uk-rail-coast-island.jpg',
              name : '10 Day Coast to Island Explorer',
              price : '3669',
              complement : 'per person (twin share)',
              code : 'JBUK2310DC2IES',
              location : 'Brisbane (BNE)',
              valid: 'Select Departures Jan-Feb 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/11/10-Day-Coast-to-Island-Explorer-Southbound-GBP-EXTENDED.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/07/deal-uk-rail-ultimate.jpg',
              name : '5 Night The Ultimate Ghan Journey',
              price : '3309',
              complement : 'per person (twin share)',
              code : 'ANZG6DGE',
              location : 'Darwin (DRW)',
              valid: 'Departures 26 May 2023 or 25 Aug 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/11/5-Night-The-Ghan-Ultimate-Journey-GBP-EXTENDED.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/deal-uk-rail-withsundays.jpg',
              name : '15 Day Rail, Reefs, Vines & Beyond',
              price : '3679',
              complement : 'per person (twin share)',
              code : 'JBUK2315DRRVBN',
              location : 'Melbourne (MEL)',
              valid: 'Select Departures Jan-Feb 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/15-Day-Rail-Reefs-Vines-Beyond-GBP.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/07/deal-uk-rail-desert-to-east.jpg',
              name : '13 Day Desert to the East Rail Odyssey',
              price : '3699',
              complement : 'per person (twin share)',
              code : 'JBUK2313DD2E',
              location : 'Ayers Rock/Yulara (AYQ)',
              valid: 'Select Departures Mar 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/13-Day-Desert-to-the-East-Rail-Odyssey-GBP.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/07/deal-uk-rail-west-reef.jpg',
              name : '12 Day West to the Reef',
              price : '5069',
              complement : 'per person (twin share)',
              code : 'JBUK2312DW2R',
              location : 'Sydney (SYD)',
              valid: 'Select Departures Jul-Oct 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/12-Day-West-to-the-Reef-GBP.pdf'
            },         
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/deal-uk-rail-seals.jpg',
              name : '10 Day Cities, Island & Beyond',
              price : '2899',
              complement : 'per person (twin share)',
              code : 'JBUK2310DCIBN',
              location : 'Melbourne (MEL)',
              valid: 'Select Departures Jan-Feb 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/10-Day-Cities-Island-Beyond-GBP.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/deal-uk-rail-wine.jpg',
              name : '9 Day Cruise, Rail & Wine',
              price : '2935',
              complement : 'per person (twin share)',
              code : 'JBUK239DCRW',
              location : 'Darwin (DRW)',
              valid: 'Select Departures Nov 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/9-Day-Cruise-Rail-Wine.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/07/deal-uk-rail-rocks.jpg',
              name : '10 Day Crocs, Rocks & Harbour',
              price : '2999',
              complement : 'per person (twin share)',
              code : 'JBUK2310DCRH',
              location : 'Darwin (DRW)',
              valid: 'Departure 20 Mar 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/10-Day-Crocs-Rocks-and-Harbour-GBP.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/deal-uk-rail-cabin.jpg',
              name : '11 Day Great Eastern Rail Journey',
              price : '3379',
              complement : 'per person (twin share)',
              code : 'JBUK2311DGERS',
              location : 'Brisbane (BNE)',
              valid: 'Select Departures Jan-Feb 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/11-Day-Great-Eastern-Rail-Journey-GBP.pdf'
            },
            */
          ]
        },
      ]
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      },
      copyCode(event) {
        if(event){
          let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
          window.getSelection().selectAllChildren(copyCode);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyCode.innerHTML);
          this.copiedText = 'Package code copied to clipboard';
          this.copied = true;
        }
      },
      copyLocation(event) {
        if(event){
          let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
          window.getSelection().selectAllChildren(copyLocation);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyLocation.innerHTML);
          this.copiedText = 'Package start location copied to clipboard';
          this.copied = true;
        }
      }
    },
  };
  </script>
  
  <style>
  .gradient-bar{
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
    background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
  }
  
  .deals-content{
    margin-top: -60px;
  }
  
  .package-data .v-chip{
    color:#9e9e9e;
    margin-left: 0;
  }
  
  .package-data .v-chip .v-chip__content{
    padding-right: 6px;
  }
  
  .deal-thumbnail{
    width: 88px;
    height: 88px;
  }
  </style>
  