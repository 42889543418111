<template>
  <v-container pa-0 fluid>
    <v-layout row class="hero-waves">
      <v-flex>
        <v-container fill-height>
          <v-layout dark row wrap fill-height align-center pt-5>
            <v-flex xs12 text-xs-center text-md-left class="hero-title">
              <h1 class="white--text display-1 font-weight-bold mb-3">Brochures</h1>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-layout row class="registration-main">
      <v-flex   v-if="loaded">
        <v-card flat tile class="pb-5">
          <v-container grid-list-lg fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 pt-0 mb-4 mt-3>
                <v-layout row wrap mb-3>
                  <v-flex xs12 sm6 md4 mb-3 v-for="(brochure, index) in resources" :key="index">
                    <v-card
                      flat
                      dark
                      class="v-card--btn"
                      target="_blank"
                      :href="brochure.link"
                      :color="coalBlack"
                    >
                      <v-img cover :src="brochure.imageSource" class="brochure-thumb"></v-img>
                      <v-card-text class="pa-3 pa-lg4">
                        <v-layout row ma-0>
                          <v-flex pa-0>
                            <h4 class="title mb-2">{{parseSpecialCharacters(brochure.title)}}</h4>
                          </v-flex>
                        </v-layout>

                        <v-layout row align-center ma-0>
                          <v-flex px-0 pb-0>
                            <span class="subheading d-block grey--text">{{brochure.valid}}</span>
                          </v-flex>
                          <v-flex shrink px-0 pb-0>
                            <v-icon color="grey">launch</v-icon>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
      <progress-circular :loading="!loaded"/>
    </v-layout>
  </v-container>
</template>
<script>
import { resourceMixin } from '@/components/dashboard/resources/mixins/resource.mixin'
export default {
  mixins: [resourceMixin],
  data: () => ({
    resource_type: 2,
    resources: []
  }),
  methods: {
    parseSpecialCharacters(s) {
      let string = s.replace('&#8211;', '-');
      string = string.replace('&#40;', '(');
      string = string.replace('&#41;', ')');
      string = string.replace('&#038;', '&');
      return string;
    }
  }
};
</script>
