import store from '@/vuex';
import NProgress from 'nprogress';
import { routeService, tempData } from '@/services/route.service';
import routerChangedSubject from '@/observers/subjects/routerChanged.subject';
import { configureRouteService } from '@/services/route.service';
NProgress.configure({ showSpinner: false });

export function setupRouter({ router }) {
  configureRouter(router);
  configureRouteService(router);
}

function configureRouter(router) {
  // Before each route evaluates...
  // Before each route evaluates...
  router.beforeEach((routeTo, routeFrom, next) => {
    // If this isn't an initial page load...
    // if (routeFrom.name !== null) {
    //   // Start the route progress bar.
    // }
    NProgress.start();
    NProgress.set(0.33);
    // Check if auth is required on this route
    // (including nested routes).
    const loginAction = tempData.get();
    tempData.remove();
    const fromLoginToDashboard = store.state.user && loginAction && loginAction.action === 'after_login_to_dashboard';
    const authRequired = routeTo.matched.some(route => route.meta.authRequired) && !fromLoginToDashboard;
    // If auth isn't required for the route, just continue.
    if (!authRequired) return next();
    // If auth is required and the user is logged in...
    if (store.state.user) {
      // Validate the local user
      return store
        .dispatch('validate', null, {
          root: true,
        })
        .then(validUser => {
          // Then continue if the token still represents a valid user,
          // otherwise redirect to login.
          validUser ? next() : redirectToLogin();
        })
        .catch(() => {
          redirectToLogin();
        });
    }
    // If auth is required and the user is NOT currently logged in,
    // redirect to login.
    redirectToLogin();
    function redirectToLogin() {
      // Pass the original route to the login component
      // next({ name: 'login', query: { redirectFrom: routeTo.fullPath } });
      let params = {
        path: 'login',
      };
      if (routeTo) {
        params.query = {
          r: routeTo.fullPath,
        };
      }
      next(params);
    }
  });
  router.beforeResolve(async (routeTo, routeFrom, next) => {
    try {
      // For each matched route...
      for (const route of routeTo.matched) {
        await new Promise((resolve, reject) => {
          // If a `beforeResolve` hook is defined, call it with
          // the same arguments as the `beforeEnter` hook.
          if (route.meta && route.meta.beforeResolve) {
            route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
              // If the user chose to redirect...
              if (args.length) {
                // If redirecting to the same route we're coming from...
                if (routeFrom.name === args[0].name) {
                  // Complete the animation of the route progress bar.
                  NProgress.done();
                }
                // Complete the redirect.
                next(...args);
                reject(new Error('Redirected'));
              } else {
                resolve();
              }
            });
          } else {
            // Otherwise, continue resolving the route.
            resolve();
          }
        });
      }
      // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
      return;
    }
    // If we reach this point, continue resolving the route.
    next();
  });
  // When each route is finished evaluating...
  // eslint-disable-next-line no-unused-vars
  router.afterEach((routeTo, routeFrom) => {
    routeService.add({
      name: routeTo.name,
      path: routeTo.path,
      fullPath: routeTo.fullPath,
    });
    NProgress.done();
    tempData.remove();
    let title = 'ANZCRO | The New Zealand Holiday Experts';
    if (routeTo.meta && routeTo.meta.title) {
      title = routeTo.meta.title;
    }
    document.title = title;
    let description = '';
    if (routeTo.meta && routeTo.meta.description) {
      description = routeTo.meta.description;
    }
    document.querySelector('meta[name="description"]').setAttribute('content', description);
    // send notification to subscribers
    routerChangedSubject.next({
      routeTo: routeTo,
      routeFrom: routeFrom,
    });
  });
  router.pushAsync = function(route) {
    return new Promise((resolve, reject) => {
      router.push(route, resolve, reject);
    });
  };
}
