<template>
  <v-container pa-0 fluid>
    <!-- Hero Section -->
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-hero-nsw-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">{{current}}</h1>
                          <h3 class="white--text title mb-4">{{currentArea}}</h3>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Sydney Opera House - Credit: Tourism Australia</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- Main -->
    <v-layout row>
      <v-flex>
        <v-card flat tile class="pb-5">
          <v-container grid-list-xl class="destination-main">
            <v-layout row wrap mt-2>
              <!-- In Page Navigation -->
              <v-flex xs12>
                <v-toolbar
                  flat
                  dense
                  tabs
                  :color="lightGrey"
                  class="card-with-border dash-rounded hidden-xs-only content-nav-toolbar"
                >
                  <v-card flat height="48">
                    <v-icon size="16" color="grey" class="mx-3 my-3">arrow_downward</v-icon>
                  </v-card>
                  <v-divider vertical></v-divider>
                  <v-toolbar-items>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#introduction', scrollToOptions )"
                      class="ma-0 hidden-sm-and-down"
                    >Intro</v-btn>
                    <v-divider vertical class="hidden-sm-and-down"></v-divider>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#gallery', scrollToOptions )"
                      class="ma-0"
                    >Gallery</v-btn>
                    <v-divider vertical></v-divider>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#the-best-of', scrollToOptions )"
                      class="ma-0"
                    >Highlights</v-btn>

                  </v-toolbar-items>
                  <v-divider vertical></v-divider>
                  <v-card flat height="48" width="100%">&nbsp;</v-card>
                </v-toolbar>
                <v-card
                  flat
                  :color="lightGrey"
                  class="card-with-border v-card--btn hidden-sm-and-up content-nav-card"
                >
                  <v-layout row wrap ma-0>
                    <v-flex xs6 pa-0>
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#introduction', scrollToOptions )"
                        class="ma-0"
                      >Intro</v-btn>
                    </v-flex>
                    <v-flex xs6 pa-0 class="border-l">
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#gallery', scrollToOptions )"
                        class="ma-0"
                      >Gallery</v-btn>
                    </v-flex>
                    <v-flex xs12 pa-0>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs6 pa-0>
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#the-best-of', scrollToOptions )"
                        class="ma-0"
                      >Highlights</v-btn>
                    </v-flex>
                    <v-flex xs6 pa-0 class="border-l">
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <!-- Content -->
              <v-flex xs12 md9 lg7>
                <v-layout row wrap>
                  <!-- Destination Content -->
                  <v-flex xs12 id="content-wrapper">
                    <!-- Introduction -->
                    <v-layout row id="introduction" mt-3>
                      <v-flex>
                        <h4
                          class="title mb-3"
                        >With endless unspoilt beaches, breathtaking natural treasures and one of the world’s most famous and vibrant cities, there’s no place quite like New South Wales. From Sydney Harbour to the World Heritage-listed Blue Mountains and the Hunter Valley, New South Wales beckons with indulgent wine regions, pristine seaside villages and high mountain country.</h4>
                      </v-flex>
                    </v-layout>

                    <!-- Gallery -->
                    <v-layout row wrap id="gallery" mt-3>
                      <v-flex xs12 pb-1>
                        <h4 class="title">Gallery</h4>
                      </v-flex>
                      <v-flex xs12>
                        <v-card flat dark class="mb-3">
                          <v-carousel
                            height="auto"
                            class="elevation-0 destination-gallery"
                            hide-delimiters
                          >
                            <v-carousel-item v-for="(item, index) in galleryItems" :key="index">
                              <v-img
                                :src="item.image"
                                gradient="to bottom, rgba(22,22,22,.35), rgba(22,22,22,0.1) 15%, rgba(22,22,22,0.1) 85%, rgba(22,22,22,0.65)"
                                aspect-ratio="1.5"
                              >
                                <v-layout column fill-height ma-0>
                                  <v-flex text-xs-right>
                                    <b>{{index + 1}}</b>
                                    / {{galleryItems.length}}
                                  </v-flex>
                                  <v-flex shrink><small class="d-block">{{item.caption}}</small></v-flex>
                                </v-layout>
                              </v-img>
                            </v-carousel-item>
                          </v-carousel>
                        </v-card>
                      </v-flex>
                    </v-layout>

                    <!-- The Best of -->
                    <v-layout row id="the-best-of" mt-3>
                      <v-flex>
                        <h4 class="title mb-3">New South Wales Highlights</h4>
                        <ul class="subheading mb-3">
                          <li>
                            <b>Fall under Sydney’s spell</b> – Sydney’s sun-drenched beaches, award-winning restaurants, iconic landmarks and unique wildlife has seen it named one of the ‘World’s Best Festival and Event City’. Dynamic, cosmopolitan and surrounded by stunning natural attractions, travellers can marvel at architectural icons in Chippendale, explore street art in Newtown or learn to surf at Bondi. Find cultural riches such as galleries, museums, theatre companies and alternative cinema, alongside a year-round jampacked event calendar
                          </li>
                          <li>
                            <b>Swim at over 100 beaches on the south coast</b> – Each with its own unique experience, the Shoalhaven region on the south coast of New South Wales boasts over 100 beaches across 170 km of coastline. Unspoilt and waiting to be explored, relax on some of the whitest sands in the world at Hyams Beach, see kangaroos in the wild or choose from a range of water sports, rock climbing and coastal walks.
                          </li>
                          <li>
                            <b>Discover an emerging wine region</b> – The well-known Hunter Valley is home to the oldest wine growing region in Australia, but is just one of a dozen wine regions around New South Wales waiting to be tasted. Sample the cool climate wines of Orange, artisan vineyards of the Southern Highlands or family-run operations in Mudgee. White, red, rosé or sparkling, find the perfect drop in New South Wales.
                          </li>
                          <li>
                            <b>Be with nature in the Blue Mountains</b> – The Blue Mountains World Heritage Area has abundant natural beauty, and offers excellent hiking, spectacular scenery and quaint towns and villages, making it a popular getaway from Sydney. A 90 minute drive west of Sydney, walk to the foot of the Three Sisters on the 998-step Giant Stairway, ride the world’s steepest passenger train through to the floor of the Jamison Valley or head into the Valley of the Waters in search of fairytale waterfalls. Stroll around picturesque villages of Leura and Blackheath for antique stores, quirky cafés and top-notch restaurants.
                          </li>
                          <li>
                            <b>Eat your way through Byron Bay</b> – Australia’s easternmost town, Byron Bay is world renowned for its beautiful beaches, lush rainforest and laid-back lifestyle. A favourite with both foodies and international celebrities, Byron Bay is also one of Australia’s great gourmet destinations. At the forefront of the paddock-to-plate movement, small farms and artisan producers offer an abundance of culinary experiences. Find exceptional eats from hatted restaurants to beachside markets and boutique breweries, served up by passionate locals. Domestic flights arrive at nearby Ballina Airport and take about one hour and 20 minutes from Sydney.
                          </li>
                          <li>
                            <b>Stargaze in Australia’s clearest skies</b> – In the Warrumbungle region in Central New South Wales find the southern hemisphere's first and only Dark Sky Park. Recognised internationally for it’s outstanding nocturnal environment and clear dark sky, stargazers are encouraged to take a guided-tour, peek through Australia’s largest optical telescope or pitch a tent under the starry night.
                          </li>
                          <li>
                            <b>Dive into an ocean pool</b> – Carved into the rock shelves at coastal beaches and fed by ocean tides, almost 100 ocean pools can be found on the coast of New South Wales. Boasting 35 in Sydney alone, start with a dip at the iconic Bondi Icebergs, then just a few beaches south, find Bronte Baths or Wiley’s Baths in Coogee. Further afield, travellers can swim at the heritage-listed Bogey Hole in Newcastle or experience the crystal clear waters of The Blue Pool at Bermagui on the Sapphire Coast.
                          </li>
                        </ul>
                      </v-flex>
                    </v-layout>

                    <!-- Map
                    <v-layout wrap row id="map" mt-3>
                      <v-flex xs12 pb-1>
                        <h4 class="title">Map</h4>
                      </v-flex>
                      <v-flex xs12>
                        <v-card flat class="v-card--btn">
                          <v-img
                            src="https://cdn.anzcro.com.au/wp-content/uploads/2019/12/map-thumbnail-auckland.jpg"
                            height="240"
                          >
                            <v-layout column fill-height ma-0 align-end>
                              <v-flex>&nbsp;</v-flex>
                              <v-flex shrink>
                                <v-btn
                                  :color="lightGrey"
                                  @click="destinationMapDialog=true;"
                                >Expand</v-btn>
                              </v-flex>
                            </v-layout>
                          </v-img>
                        </v-card>
                      </v-flex>
                    </v-layout> -->
                  </v-flex>
                </v-layout>
              </v-flex>

              <!-- Other Destinations -->
              <v-flex xs12 md3 lg4 offset-lg1 id="other" mt-4>
                <h4 class="title mb-4 grey--text">Other Destinations</h4>
                <DestinationIndex :currentDestination="current" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- Destination Map Dialog -->
    <v-dialog v-model="destinationMapDialog" max-width="900px">
      <v-card class="pa-0">
        <v-layout row pa-3 align-center>
          <v-flex>
            <span class="d-block headline font-weight-bold">Map</span>
          </v-flex>
          <v-flex shrink>
            <v-btn
              :color="lightGrey"
              small
              fab
              class="elevation-0 ma-0 card-with-border"
              @click="destinationMapDialog=false;"
            >
              <v-icon size="24">close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-card-text class="pa-3">
          <v-responsive :aspect-ratio="800/600">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d817202.3861024495!2d174.30502620606845!3d-36.86170744554708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d47fb5a9ce6fb%3A0x500ef6143a29917!2sAuckland%2C%20New%20Zealand!5e0!3m2!1sen!2sau!4v1575248618684!5m2!1sen!2sau" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
          </v-responsive>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import DestinationIndex from '@/components/public/destinations/components/DestinationIndexAustralia.vue';
export default {
  data: () => ({
    current: 'New South Wales',
    currentArea: 'Australia',
    galleryItems: [
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-nsw-001.jpg',
        caption: 'Byron Bay Lighthouse - Credit: Tourism Australia',
      },
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-nsw-002.jpg',
        caption: 'Evans Lookout, Blue Mountains - Credit: Tourism Australia',
      },
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-nsw-003.jpg',
        caption: 'Wine Tasting - Credit: Tourism Australia',
      },
    ],
    destinationMapDialog: false,
    scrollToOptions: {
      easing: 'easeInOutCubic',
      duration: 300,
      offset: 50,
    },
  }),
  components: {
    DestinationIndex,
  },
  mounted: function() {
    document.documentElement.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
.container.destination-main {
  padding: 24px;
}

.content-nav-toolbar .v-toolbar__content {
  padding: 0 !important;
}

.content-nav-card .flex.border-l {
  border-left: solid 1px rgba(0, 0, 0, 0.12) !important;
}

#content-wrapper .title {
  line-height: 1.3 !important;
}

#content-wrapper li {
  margin-bottom: 16px;
}

#content-wrapper li:last-child {
  margin-bottom: 0;
}

.v-avatar.summer {
  border: solid 4px #ffaa00 !important;
}
.v-avatar.winter {
  border: solid 4px #adc9d7 !important;
}

@media (min-width: 600px) {
  .container.destination-main {
    padding: 24px 32px;
  }
}

@media (min-width: 960px) {
  .container.destination-main {
    padding: 24px;
  }
}
</style>
