<template>
  <v-container pa-0 fluid>
    <!-- Hero Section -->
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-hero-wa-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">{{current}}</h1>
                          <h3 class="white--text title mb-4">{{currentArea}}</h3>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Vineyards at Dawn  - Credit: Leeuwin Estate</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- Main -->
    <v-layout row>
      <v-flex>
        <v-card flat tile class="pb-5">
          <v-container grid-list-xl class="destination-main">
            <v-layout row wrap mt-2>
              <!-- In Page Navigation -->
              <v-flex xs12>
                <v-toolbar
                  flat
                  dense
                  tabs
                  :color="lightGrey"
                  class="card-with-border dash-rounded hidden-xs-only content-nav-toolbar"
                >
                  <v-card flat height="48">
                    <v-icon size="16" color="grey" class="mx-3 my-3">arrow_downward</v-icon>
                  </v-card>
                  <v-divider vertical></v-divider>
                  <v-toolbar-items>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#introduction', scrollToOptions )"
                      class="ma-0 hidden-sm-and-down"
                    >Intro</v-btn>
                    <v-divider vertical class="hidden-sm-and-down"></v-divider>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#gallery', scrollToOptions )"
                      class="ma-0"
                    >Gallery</v-btn>
                    <v-divider vertical></v-divider>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#the-best-of', scrollToOptions )"
                      class="ma-0"
                    >Highlights</v-btn>

                  </v-toolbar-items>
                  <v-divider vertical></v-divider>
                  <v-card flat height="48" width="100%">&nbsp;</v-card>
                </v-toolbar>
                <v-card
                  flat
                  :color="lightGrey"
                  class="card-with-border v-card--btn hidden-sm-and-up content-nav-card"
                >
                  <v-layout row wrap ma-0>
                    <v-flex xs6 pa-0>
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#introduction', scrollToOptions )"
                        class="ma-0"
                      >Intro</v-btn>
                    </v-flex>
                    <v-flex xs6 pa-0 class="border-l">
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#gallery', scrollToOptions )"
                        class="ma-0"
                      >Gallery</v-btn>
                    </v-flex>
                    <v-flex xs12 pa-0>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs6 pa-0>
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#the-best-of', scrollToOptions )"
                        class="ma-0"
                      >Highlights</v-btn>
                    </v-flex>
                    <v-flex xs6 pa-0 class="border-l">
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <!-- Content -->
              <v-flex xs12 md9 lg7>
                <v-layout row wrap>
                  <!-- Destination Content -->
                  <v-flex xs12 id="content-wrapper">
                    <!-- Introduction -->
                    <v-layout row id="introduction" mt-3>
                      <v-flex>
                        <h4
                          class="title mb-3"
                        >Journey across fiery red soils and pearly white sands. Swim, dive, snorkel or float above the brilliant turquoise-coloured waters. With a history and culture as colourful as its landscape, there’s nothing quite like Western Australia. Combine this with fresh produce, premium wines, an abundance of sunshine and a thriving capital city and you have all the essential ingredients for an extraordinary holiday</h4>
                      </v-flex>
                    </v-layout>

                    <!-- Gallery -->
                    <v-layout row wrap id="gallery" mt-3>
                      <v-flex xs12 pb-1>
                        <h4 class="title">Gallery</h4>
                      </v-flex>
                      <v-flex xs12>
                        <v-card flat dark class="mb-3">
                          <v-carousel
                            height="auto"
                            class="elevation-0 destination-gallery"
                            hide-delimiters
                          >
                            <v-carousel-item v-for="(item, index) in galleryItems" :key="index">
                              <v-img
                                :src="item.image"
                                gradient="to bottom, rgba(22,22,22,.35), rgba(22,22,22,0.1) 15%, rgba(22,22,22,0.1) 85%, rgba(22,22,22,0.65)"
                                aspect-ratio="1.5"
                              >
                                <v-layout column fill-height ma-0>
                                  <v-flex text-xs-right>
                                    <b>{{index + 1}}</b>
                                    / {{galleryItems.length}}
                                  </v-flex>
                                  <v-flex shrink><small class="d-block">{{item.caption}}</small></v-flex>
                                </v-layout>
                              </v-img>
                            </v-carousel-item>
                          </v-carousel>
                        </v-card>
                      </v-flex>
                    </v-layout>

                    <!-- The Best of -->
                    <v-layout row id="the-best-of" mt-3>
                      <v-flex>
                        <h4 class="title mb-3">Western Australia Highlights</h4>
                        <ul class="subheading mb-3">
                          <li>
                            <b>Uncover the heart of the city</b> – Sitting pretty on the shores of the Swan River, Perth radiates a charm that sets it apart from its easterly cousins. The ever-expanding metropolis has welcomed new cultural, entertainment and sporting precincts, while its hotel, bar and restaurant scene continue to flourish. Kick off the day at one of the city’s 19 pristine beaches, followed by a meal at a new seaside venue, like Island Market. Head into Northbridge for a taste of Perth’s lively bar scene, before dining on locally foraged produce at rooftop restaurant Wildflower. End the night in the luxurious surrounds of a new inner-city hotel, such as The Westin, QT or DoubleTree by Hilton.
                          </li>
                          <li>
                            <b>Fall in love with a quokka</b> – Don't forget your selfie stick when you head to Rottnest Island. Just 19 kilometres (12 miles) off the coast of Perth, this aweinspiring island is home to one of Australia's most photographed animals. The island’s resident marsupial – the quokka – is a small, furry animal with a cuddly appearance and permanent smile. In recent years it has become a social media star, thanks partly to some famous friends capturing their own #quokkaselfie, including Hugh Jackman, Margot Robbie and Roger Federer. Complement your island visit with a stay at the newly opened Discovery Rottnest Island – an eco-glamping resort on the dunes of Pinky Beach.
                          </li>
                          <li>
                            <b>Ride a camel along Cable Beach</b> – Affectionately known as ‘pearl of the north’, Broome is a tropical oasis of striking contrasts in both colour and culture, combining white beaches, turquoise water, rust red cliffs, rich Aboriginal heritage and a melting pot of Asian flavours. Broome is home to the extraordinary Cable Beach, a 22 kilometre (14 mile) stretch of fine white sand washed by tides that rise up to nine metres (30 feet). Don't leave town without treating yourself to a sunset camel ride along the beach as the sun sets over the ocean.
                          </li>
                          <li>
                            <b>Swim with the giants of the ocean</b> – Are you up for a swim with the world’s largest fish? Whale sharks visit Ningaloo Reef each year between March and July. Swimming just metres away from these gentle giants is a remarkable experience, but it’s not Ningaloo's only attraction. Visitors can enjoy an underwater encounter with humpback whales, who migrate the waters of Ningaloo Reef annually between August and October. It doesn’t end there - the underwater playground is home to a plethora of sea creatures yearround, including manta rays, turtles, tropical fish, dolphins and colourful coral gardens.
                          </li>
                          <li>
                            <b>Sample your way through the South West</b> – Nature’s pantry awaits you in WA’s South West. With hundreds of vineyards, orchards, farms and groves, the region is a one-stop shop for all things gourmet. Make the most of your south west adventure by visiting worldrenowned wineries in Margaret River, such as Vasse Felix, Cullen and Leeuwin Estate. Between June and August, you can indulge in the region’s finest delicacy, black truffle. Grown on farms in the Southern Forest area, the rare delicacy is distributed to some of the finest restaurants worldwide.
                          </li>
                          <li>
                            <b>Discover the largest outback art gallery on Earth</b> – You don't expect to find an open-air art gallery in the outback, but internationally renowned sculptor Antony Gormley chose the dry salt flats of Lake Ballard as the backdrop for 51 of his eye-catching sculptures. The delicate figures, spread across an area of 10 kilometres (six miles), are striking at any time of day but appear particularly surreal when the dawn light sweeps across the salt flats.
                          </li>
                          <li>
                            <b>Drive the Gibb River Road</b> – It is one of Australia's great road trips - a 660 kilometre (410 mile) journey on red dirt roads through the heart of the Kimberley. Following an old cattle droving trail, the trip from Derby to Kununurra gives you access to some of the most remarkable scenery in Australia, including red rock gorges, dramatic waterfalls and ancient forests. Along the way, you can sleep in style at lodges such as El Questro Homestead and Home Valley Station’s Grass Castles, enjoy a cattle station stay, or camp under the stars in a swag.
                          </li>
                          <li>
                            <b>Witness the largest floral show on earth</b> – WA’s wildflower collection is the largest on earth. With more than 12,000 species – 60 per cent of which are found nowhere else on earth - the collections colour landscapes from coast to forest and city to outback. The wildflower season begins in the north in June on the vast outback plains of the Pilbara, Goldfields and Coral Coast. By September, it has reached Perth’s botanical gardens, nature reserves and national parks, finishing with a flurry in November in the South West.
                          </li>
                        </ul>
                      </v-flex>
                    </v-layout>

                    <!-- Map
                    <v-layout wrap row id="map" mt-3>
                      <v-flex xs12 pb-1>
                        <h4 class="title">Map</h4>
                      </v-flex>
                      <v-flex xs12>
                        <v-card flat class="v-card--btn">
                          <v-img
                            src="https://cdn.anzcro.com.au/wp-content/uploads/2019/12/map-thumbnail-auckland.jpg"
                            height="240"
                          >
                            <v-layout column fill-height ma-0 align-end>
                              <v-flex>&nbsp;</v-flex>
                              <v-flex shrink>
                                <v-btn
                                  :color="lightGrey"
                                  @click="destinationMapDialog=true;"
                                >Expand</v-btn>
                              </v-flex>
                            </v-layout>
                          </v-img>
                        </v-card>
                      </v-flex>
                    </v-layout> -->
                  </v-flex>
                </v-layout>
              </v-flex>

              <!-- Other Destinations -->
              <v-flex xs12 md3 lg4 offset-lg1 id="other" mt-4>
                <h4 class="title mb-4 grey--text">Other Destinations</h4>
                <DestinationIndex :currentDestination="current" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- Destination Map Dialog -->
    <v-dialog v-model="destinationMapDialog" max-width="900px">
      <v-card class="pa-0">
        <v-layout row pa-3 align-center>
          <v-flex>
            <span class="d-block headline font-weight-bold">Map</span>
          </v-flex>
          <v-flex shrink>
            <v-btn
              :color="lightGrey"
              small
              fab
              class="elevation-0 ma-0 card-with-border"
              @click="destinationMapDialog=false;"
            >
              <v-icon size="24">close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-card-text class="pa-3">
          <v-responsive :aspect-ratio="800/600">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d817202.3861024495!2d174.30502620606845!3d-36.86170744554708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d47fb5a9ce6fb%3A0x500ef6143a29917!2sAuckland%2C%20New%20Zealand!5e0!3m2!1sen!2sau!4v1575248618684!5m2!1sen!2sau" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
          </v-responsive>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import DestinationIndex from '@/components/public/destinations/components/DestinationIndexAustralia.vue';
export default {
  data: () => ({
    current: 'Western Australia',
    currentArea: 'Australia',
    galleryItems: [
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-wa-001.jpg',
        caption: 'Coral Coast - Credit: Tourism Australia',
      },
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-wa-002.jpg',
        caption: 'Dreamtime 2019, Perth - Credit: Tourism Australia',
      },
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-wa-003.jpg',
        caption: 'Wine tasting - Credit: Vasse Felix',
      },
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-wa-004.jpg',
        caption: 'King George River Sunrise Hike, Kimberley - Credit: True north Adventure Cruises',
      },
    ],
    destinationMapDialog: false,
    scrollToOptions: {
      easing: 'easeInOutCubic',
      duration: 300,
      offset: 50,
    },
  }),
  components: {
    DestinationIndex,
  },
  mounted: function() {
    document.documentElement.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
.container.destination-main {
  padding: 24px;
}

.content-nav-toolbar .v-toolbar__content {
  padding: 0 !important;
}

.content-nav-card .flex.border-l {
  border-left: solid 1px rgba(0, 0, 0, 0.12) !important;
}

#content-wrapper .title {
  line-height: 1.3 !important;
}

#content-wrapper li {
  margin-bottom: 16px;
}

#content-wrapper li:last-child {
  margin-bottom: 0;
}

.v-avatar.summer {
  border: solid 4px #ffaa00 !important;
}
.v-avatar.winter {
  border: solid 4px #adc9d7 !important;
}

@media (min-width: 600px) {
  .container.destination-main {
    padding: 24px 32px;
  }
}

@media (min-width: 960px) {
  .container.destination-main {
    padding: 24px;
  }
}
</style>
