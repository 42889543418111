import pathify from 'vuex-pathify'

// Mapping (simple)
// -------------------------------
//   path       : value
//   state      : value
//   getters    : value
//   actions    : setValue
//   mutations  : value

//   Settings
// -------------------------------
//   strict     : true
//   cache      : true
//   deep       : 1
pathify.options.mapping = 'simple'

export default pathify
