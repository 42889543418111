import { make } from "vuex-pathify";
import { SEARCH } from "../model/navigation";
import { itineraryService } from "../services/itinerary.service";

const getOverviewState = () => ({});

const initialState = () => ({
  overview: getOverviewState(),
  booklines: [],
  itineraryId: "",
  quoteId: undefined,
  bookingId: undefined,
  booking: undefined,
  displayOptions: ["empty-days", "canceled-items"],
  timelineLayoutCompleted: undefined,
  sideBarNavigation: SEARCH,
  itemsReviewed: [],
  view: "list",
});

const state = initialState();

const getters = {
  overview: state => {
    return state.overview;
  },
  hasBooking: state => !!state.bookingId,
};

const actions = {
  ...make.actions(state),

  reset({ commit }) {
    commit("reset");
  },
  setOverview(context, value) {
    const _value = { ...value };
    const dateFields = ["startDate", "endDate"];
    dateFields.forEach(field => {
      if (_value.hasOwnProperty(field)) {
        _value[field] = _value[field].substring(0, 10);
      }
    });
    state.overview = _value;
    context.commit("setOverview", _value);
  },
  setBooklines(context, value) {
    context.commit(
      "setBooklines",
      value.filter(x => x.item.itemtype !== "ARRUNK"),
    );
  },
  async addAccommodation(context, req) {
    const res = await itineraryService.addAccommodation(req);
    context.commit("updateItinerary", res);
  },
  async addTravel(context, req) {
    const res = await itineraryService.addTravel(req);
    context.commit("updateItinerary", res);
  },
  async addVehicle(context, req) {
    const res = await itineraryService.addVehicle(req);
    context.commit("updateItinerary", res);
  },

  async addSightseeing(context, req) {
    const res = await itineraryService.addSightseeing(req);
    context.commit("updateItinerary", res);
  },

  async addSki(context, req) {
    const res = await itineraryService.addSki(req);
    context.commit("updateItinerary", res);
  },

  async addMultiday(context, req) {
    const res = await itineraryService.addMultiday(req);
    context.commit("updateItinerary", res);
  },
  async addFromPackage(context, req) {
    const res = await itineraryService.addFromPackage(req);
    context.commit("updateItinerary", res);
  },
  async modifyAccommodation(context, req) {
    const res = await itineraryService.modifyAccommodation(req);
    context.commit("updateItinerary", res);
  },
  async modifyVehicle(context, req) {
    const res = await itineraryService.modifyVehicle(req);
    context.commit("updateItinerary", res);
  },
  async modifyTravel(context, req) {
    const res = await itineraryService.modifyTravel(req);
    context.commit("updateItinerary", res);
  },
  async modifySightseeing(context, req) {
    const res = await itineraryService.modifySightseeing(req);
    context.commit("updateItinerary", res);
  },
  async modifyMultiday(context, req) {
    const res = await itineraryService.modifyMultiday(req);
    context.commit("updateItinerary", res);
  },

  async modifySki(context, req) {
    const res = await itineraryService.modifySki(req);
    context.commit("updateItinerary", res);
  },

  async removeItemFromItinerary(context, { itineraryId, sequenceId }) {
    const res = await itineraryService.removeItemFromItinerary(itineraryId, sequenceId);
    context.commit("updateItinerary", res);
  },

  async removeItemFromQuote(context, { bookingId, sequenceId }) {
    const res = await itineraryService.removeItemFromQuote(bookingId, sequenceId);
    if (res.booking) {
      context.commit("booking", res.booking);
      context.commit("booklines", res.booking.booklines);
      context.commit("bookingId", bookingId);
    } else {
      throw new Error("An unexpected error has occurred while deleting an item!");
    }
  },

  clearItinerary(context) {
    context.commit("updateItinerary", { booklines: [], itineraryId: "" });
  },

  // BOOKING ACTIONS
  async addAccommodationItemToBooking(context, req) {
    const res = await itineraryService.addAccommodationItemToBooking(req);
    context.commit("updateBookingItinerary", res);
  },
  async addMultidayItemToBooking(context, req) {
    const res = await itineraryService.addMultidayItemToBooking(req);
    context.commit("updateBookingItinerary", res);
  },

  async addSightseeingItemToBooking(context, req) {
    const res = await itineraryService.addSightseeingItemToBooking(req);
    context.commit("updateBookingItinerary", res);
  },

  async addSkiItemToBooking(context, req) {
    const res = await itineraryService.addSkiItemToBooking(req);
    context.commit("updateBookingItinerary", res);
  },

  async addTravelItemToBooking(context, req) {
    const res = await itineraryService.addTravelItemToBooking(req);
    context.commit("updateBookingItinerary", res);
  },

  async addVehicleItemToBooking(context, req) {
    const res = await itineraryService.addVehicleItemToBooking(req);
    context.commit("updateBookingItinerary", res);
  },

  async modifyAccommodationItemToBooking(context, req) {
    const res = await itineraryService.modifyAccommodationItemToBooking(req);
    context.commit("updateBookingItinerary", res);
  },

  async modifyMultidayItemToBooking(context, req) {
    const res = await itineraryService.modifyMultidayItemToBooking(req);
    context.commit("updateBookingItinerary", res);
  },

  async modifySightseeingItemToBooking(context, req) {
    const res = await itineraryService.modifySightseeingItemToBooking(req);
    context.commit("updateBookingItinerary", res);
  },
  async modifyTravelItemToBooking(context, req) {
    const res = await itineraryService.modifyTravelItemToBooking(req);
    context.commit("updateBookingItinerary", res);
  },
  async modifyVehicleItemToBooking(context, req) {
    const res = await itineraryService.modifyVehicleItemToBooking(req);
    context.commit("updateBookingItinerary", res);
  },

  async modifySkiItemToBooking(context, req) {
    const res = await itineraryService.modifySkiItemToBooking(req);
    context.commit("updateBookingItinerary", res);
  },

  //Review Actions
  async reviewItem(context, { hash }) {
    const itemsReviewed = [...context.state.itemsReviewed];
    if (!itemsReviewed.some(item => item.hash === hash)) {
      itemsReviewed.push({ hash });
    }

    context.commit("itemsReviewed", itemsReviewed);
  },
};

const mutations = {
  ...make.mutations(state),
  reset(state) {
    const s = initialState();
    for (let key in s) {
      state[key] = s[key];
    }
  },
  setOverview(state, value) {
    state.overview = value;
  },
  setBooklines(state, value) {
    state.booklines = value;
  },
  updateItinerary(state, data) {
    state.booklines = data.booklines;
    state.itineraryId = data.itineraryId;
  },
  updateBookingItinerary(state, data) {
    state.booking = data.booking;
    state.booklines = data.booking.booklines;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
