import appService from '@/services/app.service';
import { O3ApiResponse } from '@/model/response';
import { OzoneLocation, Currency } from '../model/location';
import { Country } from '../model/country';

const getLocations = (): Promise<O3ApiResponse<OzoneLocation[]>> => {
  return appService.get('/utils/locations');
};

const getCountries = (): Promise<O3ApiResponse<Country[]>> => {
  return appService.get('/utils/countries');
};

const getCurrency = (currencyId: number): Promise<O3ApiResponse<Currency>> => {
  return appService.get(`/utils/currency/${currencyId}`);
};

export const utilsService = {
  getCountries,
  getCurrency,
  getLocations,
};
