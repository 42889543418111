import appService from '@/services/app.service';
import { AnyTypeToFix } from '@/model/AnyType';
import { AgentRequest } from '@/model/agentrequest';

const changePassword = (req: AnyTypeToFix) => {
  return appService.post('/users/change-password', req);
};

const updateUser = (username: string, payload: AnyTypeToFix) => {
  return appService.put(`/users/${username}`, payload);
};

const addUser = (payload: AnyTypeToFix) => {
  return appService.post('/users/', payload);
};

const deleteUser = (username: string) => {
  return appService.delete(`/users/${username}`);
};

const forgotPassword = (username: string) => {
  return appService.post(`/users/${username}/forgot-password`);
};

const resetPassword = (payload: AnyTypeToFix) => {
  return appService.post('/users/reset-password', payload);
};

const agentRequest = (payload: AgentRequest) => {
  return appService.post('/users/request', payload);
};

const approveReject = (username: string, payload: AnyTypeToFix) => {
  return appService.put(
    `/users/${username}/decision?agencyId=${payload.agencyId}&decision=${payload.decision}`,
  );
};

const termsAndConditionsAccepted = () => {
  return appService.post('/users/terms-conditions-accepted');
};

export const userService = {
  changePassword,
  updateUser,
  addUser,
  deleteUser,
  forgotPassword,
  resetPassword,
  agentRequest,
  approveReject,
  termsAndConditionsAccepted,
};
