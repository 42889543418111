import { bookingService } from '@/components/dashboard/services/booking.service';
import { make } from 'vuex-pathify';

const state = {
  drawer: false,
  bookings: [],
  booking: null,
  notifications: [],
};

const getters = {
  drawer: state => state.drawer,
};

const actions = {
  ...make.actions(state),
  setDrawer(context, value) {
    context.commit('setDrawer', value);
  },
  async listBookings(context, req = {}) {
    try {
      const data = await bookingService.list(req);
      context.commit('setBookings', data.result);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  },
};

const mutations = {
  ...make.mutations(state),
  setDrawer(state, value) {
    state.drawer = value;
  },
  setBookings(state, bookings) {
    state.bookings = bookings;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
