const ItineraryItemAddAccommodation = () => import('./ItineraryItemAddAccommodation.vue');
const ItineraryItemAddCarCamper = () => import('./ItineraryItemAddCarCamper.vue');
const ItineraryItemAddTravelTransfers = () => import('./ItineraryItemAddTravelTransfers.vue');
const ItineraryItemAddDayTours = () => import('./ItineraryItemAddDayTours.vue');
const ItineraryItemAddMultidayTours = () => import('./ItineraryItemAddMultidayTours.vue');
const ItineraryItemAddSkiing = () => import('./ItineraryItemAddSkiing.vue');

export const itemAddRoutes = [
  {
    path: 'add/accommodation',
    name: 'itemaddaccommodation',
    component: ItineraryItemAddAccommodation,
  },
  {
    path: 'add/carcamper',
    name: 'itemaddcarcamper',
    component: ItineraryItemAddCarCamper,
  },
  {
    path: 'add/travel',
    name: 'itemaddtraveltransfer',
    component: ItineraryItemAddTravelTransfers,
  },
  {
    path: 'add/daytours',
    name: 'itemadddaytours',
    component: ItineraryItemAddDayTours,
  },
  {
    path: 'add/multidaytours',
    name: 'itemaddmultidaytours',
    component: ItineraryItemAddMultidayTours,
  },
  {
    path: 'add/skiing',
    name: 'itemaddskiing',
    component: ItineraryItemAddSkiing,
  },
];
