import { Country } from "@/model/country";

export const countries: Country[] = [
  {
    "countryid": 15,
    "name": "Australia",
    "countrycode": "AU",
    "telephonecode": "61",
    "currencyid": 5
  },
  {
    "countryid": 39,
    "name": "China",
    "countrycode": "CHN",
    "telephonecode": "86",
    "currencyid": 5
  },
  {
    "countryid": 44,
    "name": "Cook Islands",
    "countrycode": "COK",
    "telephonecode": "682",
    "currencyid": 6
  },
  {
    "countryid": 64,
    "name": "Fiji",
    "countrycode": "FJI",
    "telephonecode": "679",
    "currencyid": 15
  },
  {
    "countryid": 93,
    "name": "New Zealand",
    "countrycode": "NZ",
    "telephonecode": "64",
    "currencyid": 6
  },
  {
    "countryid": 101,
    "name": "Papua New Guinea",
    "countrycode": "PNG",
    "telephonecode": "675",
    "currencyid": 5
  },
  {
    "countryid": 104,
    "name": "Philippines",
    "countrycode": "PHL",
    "telephonecode": "63",
    "currencyid": 5
  },
  {
    "countryid": 112,
    "name": "Samoa",
    "countrycode": "WSM",
    "telephonecode": "685",
    "currencyid": 5
  },
  {
    "countryid": 119,
    "name": "Singapore",
    "countrycode": "SGP",
    "telephonecode": "65",
    "currencyid": 5
  },
  {
    "countryid": 136,
    "name": "Taiwan",
    "countrycode": "TWN",
    "telephonecode": "886",
    "currencyid": 5
  },
  {
    "countryid": 138,
    "name": "Thailand",
    "countrycode": "THA",
    "telephonecode": "66",
    "currencyid": 2000021
  },
  {
    "countryid": 140,
    "name": "Tokelau",
    "countrycode": "TKL",
    "telephonecode": "690",
    "currencyid": 5
  },
  {
    "countryid": 141,
    "name": "Tonga",
    "countrycode": "TON",
    "telephonecode": "676",
    "currencyid": 5
  },
  {
    "countryid": 147,
    "name": "Vanuatu",
    "countrycode": "VUT",
    "telephonecode": "678",
    "currencyid": 5
  },
  {
    "countryid": 200000,
    "name": "French Polynesia",
    "countrycode": "PYF",
    "telephonecode": "689",
    "currencyid": 8
  },
  {
    "countryid": 2000003,
    "name": "Spain",
    "countrycode": "ESP",
    "telephonecode": "34",
    "currencyid": 8
  },
  {
    "countryid": 2000006,
    "name": "India",
    "countrycode": "IND",
    "telephonecode": "91",
    "currencyid": 200002
  },
  {
    "countryid": 2000007,
    "name": "United Arab Emirates",
    "countrycode": "UAE",
    "telephonecode": "971",
    "currencyid": 200003
  },
  {
    "countryid": 201,
    "name": "United Kingdom",
    "countrycode": "GB",
    "telephonecode": "44",
    "currencyid": 20
  },
  {
    "countryid": 146,
    "name": "United States",
    "countrycode": "US",
    "telephonecode": "1",
    "currencyid": 7
  },
  {
    "countryid": 2000011,
    "name": "Indonesia",
    "countrycode": "IDN",
    "telephonecode": "62",
    "currencyid": 2000002
  },
  {
    "countryid": 2000012,
    "name": "Brazil",
    "countrycode": "BZL",
    "telephonecode": "55",
    "currencyid": 5
  },
  {
    "countryid": 2000013,
    "name": "Angola",
    "countrycode": "ANG",
    "telephonecode": "244",
    "currencyid": 5
  },
  {
    "countryid": 2000014,
    "name": "Canada",
    "countrycode": "CA",
    "telephonecode": "1",
    "currencyid": 2000005
  },
  {
    "countryid": 2000015,
    "name": "Hong Kong",
    "countrycode": "HK",
    "telephonecode": "852",
    "currencyid": 2000006
  },
  {
    "countryid": 2000016,
    "name": "Malaysia",
    "countrycode": "MY",
    "telephonecode": "60",
    "currencyid": 2000007
  },
  {
    "countryid": 2000021,
    "name": "New Caledonia",
    "countrycode": "NC",
    "telephonecode": "687",
    "currencyid": 200000
  },
  {
    "countryid": 2000022,
    "name": "Saudi Arabia",
    "countrycode": "SA",
    "telephonecode": "966",
    "currencyid": 2000010
  },
  {
    "countryid": 2000023,
    "name": "American Samoa",
    "countrycode": "AS",
    "telephonecode": "684",
    "currencyid": 7
  },
  {
    "countryid": 2000024,
    "name": "Vietnam",
    "countrycode": "VN",
    "telephonecode": "84",
    "currencyid": 2000011
  },
  {
    "countryid": 2000025,
    "name": "Ireland",
    "countrycode": "IRL",
    "telephonecode": "353",
    "currencyid": 8
  },
  {
    "countryid": 2000026,
    "name": "Japan",
    "countrycode": "JP",
    "telephonecode": "81",
    "currencyid": 2000012
  },
  {
    "countryid": 2000027,
    "name": "South Africa",
    "countrycode": "SA",
    "telephonecode": "27",
    "currencyid": 2000013
  },
  {
    "countryid": 2000028,
    "name": "Sri Lanka",
    "countrycode": "LK",
    "telephonecode": "94",
    "currencyid": 2000014
  },
  {
    "countryid": 2000029,
    "name": "South Korea",
    "countrycode": "KR",
    "telephonecode": "82",
    "currencyid": 2000015
  },
  {
    "countryid": 2000030,
    "name": "Germany",
    "countrycode": "DE",
    "telephonecode": "49",
    "currencyid": 8
  },
  {
    "countryid": 2000031,
    "name": "Cambodia",
    "countrycode": "KH",
    "telephonecode": "855",
    "currencyid": 2000016
  },
  {
    "countryid": 2000032,
    "name": "Myanmar",
    "countrycode": "MM",
    "telephonecode": "95",
    "currencyid": 2000017
  },
  {
    "countryid": 2000033,
    "name": "Laos",
    "countrycode": "LA",
    "telephonecode": "856",
    "currencyid": 2000018
  },
  {
    "countryid": 2000034,
    "name": "Jamaica",
    "countrycode": "JM",
    "telephonecode": "876",
    "currencyid": 2000019
  },
  {
    "countryid": 2000035,
    "name": "Cuba",
    "countrycode": "CU",
    "telephonecode": "53",
    "currencyid": 2000020
  },
  {
    "countryid": 2000036,
    "name": "Maldives",
    "countrycode": "MV",
    "telephonecode": "960",
    "currencyid": 2000022
  },
  {
    "countryid": 2000037,
    "name": "Solomon Islands",
    "countrycode": "SB",
    "telephonecode": "677",
    "currencyid": 2000023
  },
  {
    "countryid": 2000038,
    "name": "Chile",
    "countrycode": "CL",
    "telephonecode": "56",
    "currencyid": 2000024
  },
  {
    "countryid": 2000039,
    "name": "Peru",
    "countrycode": "PE",
    "telephonecode": "51",
    "currencyid": 2000025
  },
  {
    "countryid": 2000040,
    "name": "Mexico",
    "countrycode": "MX",
    "telephonecode": "52",
    "currencyid": 2000026
  },
  {
    "countryid": 2000041,
    "name": "Argentina",
    "countrycode": "AR",
    "telephonecode": "54",
    "currencyid": 2000027
  },
  {
    "countryid": 2000042,
    "name": "Qatar",
    "countrycode": "QA",
    "telephonecode": "974",
    "currencyid": 2000028
  },
  {
    "countryid": 2000043,
    "name": "Italy",
    "countrycode": "IT",
    "telephonecode": "39",
    "currencyid": 8
  },
  {
    "countryid": 2000044,
    "name": "France",
    "countrycode": "FR",
    "telephonecode": "33",
    "currencyid": 8
  },
  {
    "countryid": 2000045,
    "name": "Netherlands",
    "countrycode": "NL",
    "telephonecode": "31",
    "currencyid": 8
  },
  {
    "countryid": 2000046,
    "name": "Bahrain",
    "countrycode": "BH",
    "telephonecode": "973",
    "currencyid": 2000029
  },
  {
    "countryid": 2000047,
    "name": "Belgium",
    "countrycode": "BE",
    "telephonecode": "32",
    "currencyid": 8
  },
  {
    "countryid": 2000048,
    "name": "Switzerland",
    "countrycode": "CH",
    "telephonecode": "41",
    "currencyid": 2000030
  },
  {
    "countryid": 2000049,
    "name": "Portugal",
    "countrycode": "PT",
    "telephonecode": "351",
    "currencyid": 8
  },
  {
    "countryid": 2000050,
    "name": "Brunei",
    "countrycode": "BN",
    "telephonecode": "673",
    "currencyid": 2000031
  },
  {
    "countryid": 2000051,
    "name": "Mauritius",
    "countrycode": "MU",
    "telephonecode": "230",
    "currencyid": 2000032
  },
  {
    "countryid": 2000052,
    "name": "Ecuador",
    "countrycode": "EC",
    "telephonecode": "593",
    "currencyid": 7
  },
  {
    "countryid": 2000053,
    "name": "Zimbabwe",
    "countrycode": "ZW",
    "telephonecode": "263",
    "currencyid": 7
  },
  {
    "countryid": 2000054,
    "name": "Tanzania",
    "countrycode": "TZ",
    "telephonecode": "255",
    "currencyid": 2000033
  },
  {
    "countryid": 2000055,
    "name": "Uruguay",
    "countrycode": "UY",
    "telephonecode": "598",
    "currencyid": 2000034
  },
  {
    "countryid": 2000057,
    "name": "Finland",
    "countrycode": "FI",
    "telephonecode": "358",
    "currencyid": 8
  },
  {
    "countryid": 2000058,
    "name": "Denmark",
    "countrycode": "DKK",
    "telephonecode": "+45",
    "currencyid": 2000036
  },
  {
    "countryid": 2000059,
    "name": "Malta",
    "countrycode": "MT",
    "telephonecode": "356",
    "currencyid": 8
  },
  {
    "countryid": 2000060,
    "name": "Costa Rica",
    "countrycode": "CR",
    "telephonecode": "506",
    "currencyid": 2000037
  },
  {
    "countryid": 2000061,
    "name": "Turkey",
    "countrycode": "TR",
    "telephonecode": "90",
    "currencyid": 2000038
  },
  {
    "countryid": 2000062,
    "name": "Oman",
    "countrycode": "OM",
    "telephonecode": "+968",
    "currencyid": 2000039
  },
  {
    "countryid": 2000063,
    "name": "Kenya",
    "countrycode": "KE",
    "telephonecode": "+254",
    "currencyid": 2000040
  },
  {
    "countryid": 2000064,
    "name": "Sweden",
    "countrycode": "SE",
    "telephonecode": "+46",
    "currencyid": 2000041
  },
  {
    "countryid": 2000065,
    "name": "Austria",
    "countrycode": "AT",
    "telephonecode": "+43",
    "currencyid": 8
  },
  {
    "countryid": 2000066,
    "name": "Israel",
    "countrycode": "IL",
    "telephonecode": "+972",
    "currencyid": 2000042
  },
  {
    "countryid": 2000067,
    "name": "British Virgin Islands",
    "countrycode": "VG",
    "telephonecode": "+1-284",
    "currencyid": 7
  },
  {
    "countryid": 2000068,
    "name": "Barbados",
    "countrycode": "BB",
    "telephonecode": "+1-246",
    "currencyid": 2000043
  },
  {
    "countryid": 2000071,
    "name": "Cayman Islands",
    "countrycode": "KY",
    "telephonecode": "1345",
    "currencyid": 2000045
  }
]

export function getCountryById(countryId: number, _countries?: Country[]): Country | undefined {
  let tmpCountries = _countries
  if (!tmpCountries) {
    tmpCountries = countries
  }
  return tmpCountries.find(x => x.countryid === countryId);
}
