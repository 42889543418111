<template>
  <v-container pa-0 fluid>
    <!-- Hero Section -->
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-hero-vic-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">{{current}}</h1>
                          <h3 class="white--text title mb-4">{{currentArea}}</h3>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>The Twelve Apostles - Credit: Tourism Australia</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- Main -->
    <v-layout row>
      <v-flex>
        <v-card flat tile class="pb-5">
          <v-container grid-list-xl class="destination-main">
            <v-layout row wrap mt-2>
              <!-- In Page Navigation -->
              <v-flex xs12>
                <v-toolbar
                  flat
                  dense
                  tabs
                  :color="lightGrey"
                  class="card-with-border dash-rounded hidden-xs-only content-nav-toolbar"
                >
                  <v-card flat height="48">
                    <v-icon size="16" color="grey" class="mx-3 my-3">arrow_downward</v-icon>
                  </v-card>
                  <v-divider vertical></v-divider>
                  <v-toolbar-items>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#introduction', scrollToOptions )"
                      class="ma-0 hidden-sm-and-down"
                    >Intro</v-btn>
                    <v-divider vertical class="hidden-sm-and-down"></v-divider>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#gallery', scrollToOptions )"
                      class="ma-0"
                    >Gallery</v-btn>
                    <v-divider vertical></v-divider>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#the-best-of', scrollToOptions )"
                      class="ma-0"
                    >Highlights</v-btn>

                  </v-toolbar-items>
                  <v-divider vertical></v-divider>
                  <v-card flat height="48" width="100%">&nbsp;</v-card>
                </v-toolbar>
                <v-card
                  flat
                  :color="lightGrey"
                  class="card-with-border v-card--btn hidden-sm-and-up content-nav-card"
                >
                  <v-layout row wrap ma-0>
                    <v-flex xs6 pa-0>
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#introduction', scrollToOptions )"
                        class="ma-0"
                      >Intro</v-btn>
                    </v-flex>
                    <v-flex xs6 pa-0 class="border-l">
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#gallery', scrollToOptions )"
                        class="ma-0"
                      >Gallery</v-btn>
                    </v-flex>
                    <v-flex xs12 pa-0>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs6 pa-0>
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#the-best-of', scrollToOptions )"
                        class="ma-0"
                      >Highlights</v-btn>
                    </v-flex>
                    <v-flex xs6 pa-0 class="border-l">
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <!-- Content -->
              <v-flex xs12 md9 lg7>
                <v-layout row wrap>
                  <!-- Destination Content -->
                  <v-flex xs12 id="content-wrapper">
                    <!-- Introduction -->
                    <v-layout row id="introduction" mt-3>
                      <v-flex>
                        <h4
                          class="title mb-3"
                        >Victoria offers a wealth of diverse experiences and attractions. Discover sweeping coastlines and rugged yet pristine beaches, national parks and forests teeming with wildlife. Find mountains where you can ski, climb and hike. Indulge your senses in wineries and natural spas. Best of all, many of Victoria’s unique and varied landscapes are easily accessible as day trips from Melbourne.</h4>
                      </v-flex>
                    </v-layout>

                    <!-- Gallery -->
                    <v-layout row wrap id="gallery" mt-3>
                      <v-flex xs12 pb-1>
                        <h4 class="title">Gallery</h4>
                      </v-flex>
                      <v-flex xs12>
                        <v-card flat dark class="mb-3">
                          <v-carousel
                            height="auto"
                            class="elevation-0 destination-gallery"
                            hide-delimiters
                          >
                            <v-carousel-item v-for="(item, index) in galleryItems" :key="index">
                              <v-img
                                :src="item.image"
                                gradient="to bottom, rgba(22,22,22,.35), rgba(22,22,22,0.1) 15%, rgba(22,22,22,0.1) 85%, rgba(22,22,22,0.65)"
                                aspect-ratio="1.5"
                              >
                                <v-layout column fill-height ma-0>
                                  <v-flex text-xs-right>
                                    <b>{{index + 1}}</b>
                                    / {{galleryItems.length}}
                                  </v-flex>
                                  <v-flex shrink><small class="d-block">{{item.caption}}</small></v-flex>
                                </v-layout>
                              </v-img>
                            </v-carousel-item>
                          </v-carousel>
                        </v-card>
                      </v-flex>
                    </v-layout>

                    <!-- The Best of -->
                    <v-layout row id="the-best-of" mt-3>
                      <v-flex>
                        <h4 class="title mb-3">Victoria Highlights</h4>
                        <ul class="subheading mb-3">
                          <li>
                            <b>Savour the world’s most liveable city, Melbourne</b> – Named the world’s most liveable city more time than you can count on one hand, Melbourne is a sporting mecca with a rich calendar of events and thriving bar and restaurant scene. Visit for the events (think The Australian Open, the Formula 1 Grand Prix, the Melbourne Cup horse race and cricket’s Boxing Day Test match) but make time to discover its hidden laneway and rooftop bars, local designers, tucked-away restaurants and cutting-edge cafés.
                          </li>
                          <li>
                            <b>Visit the Dandenong Ranges and the Yarra Valley wineries </b> – Just an hour’s drive from Melbourne, explore the forests and fern gullies of the Dandenong Ranges via car, on foot or by historic steam train and admire some of Australia’s finest cool-climate gardens. Visit Healesville Sanctuary, one of the best places in the country to interact with Australian wildlife in natural surroundings and continue to the Yarra Valley, Victoria’s finest coolclimate wine region. There you can embark on a hot-air balloon ride and a enjoy a host of winery experiences.
                          </li>
                          <li>
                            <b>See Australian wildlife at Phillip Island</b> – Watch the little penguins come home to their burrows in the sand and see fur seals and koalas in their natural habitats at Phillip Island, which has a host of wildlife experiences for the whole family. A 3½ hour round trip from Melbourne, Phillip Island is a perfect short getaway where you can relax on unspoiled ocean beaches, visit historic farms and even get an adrenalin fix on the Phillip Island Grand Prix Circuit.
                          </li>
                          <li>
                            <b>Experience the good life on the Mornington Peninsula</b> – Undulating vineyards, farmland, ocean and bay beaches can all be found on the Mornington Peninsula, just an hour’s drive from Melbourne. Enjoy long lunches at winery restaurants, swim with dolphins and seals in Port Phillip Bay, peruse country markets, pick strawberries, visit a wildlife sanctuary, ride horses along beaches and across vineyards, visit cheesemakers and distillers, farm stands and olive groves, go bushwalking and take pictures of Victoria’s colourful bathing boxes.
                          </li>
                          <li>
                            <b>Relax in spa country</b> – Invigorate body and mind in the natural mineral springs and historic villages of the Daylesford Macedon Ranges region, just an 80 minute drive northwest from Melbourne. Soak in mineral waters and find balance at spa and wellness retreats. Enjoy great food and wine in country pubs as well as finedining restaurants, such as The Lake House, a trailblazing farm-to-table restaurant and spa resort. Explore heritage gardens, buy farmgate produce, and browse art galleries and boutiques in quaint country towns.
                          </li>
                          <li>
                            <b>Immerse yourself in the history of Victoria’s gold rush towns</b> – Discover the history and culture of the Goldfields region, about 90 minutes drive from Melbourne. Spend the day at the recreated gold-mining town of Sovereign Hill, which brings to life Australia’s splendid 1850s gold rush era. Meet costumed characters, pan for real gold, chat with skilled craftsmen, and visit a one-room schoolhouse. Admire the elaborate buildings of Ballarat and Bendigo, which were built on the riches of the gold rush, visit the Gold Museum, go underground to a real gold mine, and visit the Chinese Museum and Joss House Temple.
                          </li>
                          <li>
                            <b>Experience one of the world’s great coastal drives</b> – Starting an hour outside of Melbourne, the Great Ocean Road spans Victoria’s epic, wavecrashing coastline all the way to the spectacular rocky outcrops of the 12 Apostles, and beyond to the South Australian border. Enjoy expansive views of the Southern Ocean, surrounded by golden beaches, jagged cliffs, and temperate rainforests. See animals and birds in the wild, watch pro surfers in action, relax in pretty coastal towns, savour gourmet treats, and discover Indigenous cultural sites. The Great Ocean Road is as much about a spectacular journey as it is the destination.
                          </li>
                        </ul>
                      </v-flex>
                    </v-layout>

                    <!-- Map
                    <v-layout wrap row id="map" mt-3>
                      <v-flex xs12 pb-1>
                        <h4 class="title">Map</h4>
                      </v-flex>
                      <v-flex xs12>
                        <v-card flat class="v-card--btn">
                          <v-img
                            src="https://cdn.anzcro.com.au/wp-content/uploads/2019/12/map-thumbnail-auckland.jpg"
                            height="240"
                          >
                            <v-layout column fill-height ma-0 align-end>
                              <v-flex>&nbsp;</v-flex>
                              <v-flex shrink>
                                <v-btn
                                  :color="lightGrey"
                                  @click="destinationMapDialog=true;"
                                >Expand</v-btn>
                              </v-flex>
                            </v-layout>
                          </v-img>
                        </v-card>
                      </v-flex>
                    </v-layout> -->
                  </v-flex>
                </v-layout>
              </v-flex>

              <!-- Other Destinations -->
              <v-flex xs12 md3 lg4 offset-lg1 id="other" mt-4>
                <h4 class="title mb-4 grey--text">Other Destinations</h4>
                <DestinationIndex :currentDestination="current" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- Destination Map Dialog -->
    <v-dialog v-model="destinationMapDialog" max-width="900px">
      <v-card class="pa-0">
        <v-layout row pa-3 align-center>
          <v-flex>
            <span class="d-block headline font-weight-bold">Map</span>
          </v-flex>
          <v-flex shrink>
            <v-btn
              :color="lightGrey"
              small
              fab
              class="elevation-0 ma-0 card-with-border"
              @click="destinationMapDialog=false;"
            >
              <v-icon size="24">close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-card-text class="pa-3">
          <v-responsive :aspect-ratio="800/600">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d817202.3861024495!2d174.30502620606845!3d-36.86170744554708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d47fb5a9ce6fb%3A0x500ef6143a29917!2sAuckland%2C%20New%20Zealand!5e0!3m2!1sen!2sau!4v1575248618684!5m2!1sen!2sau" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
          </v-responsive>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import DestinationIndex from '@/components/public/destinations/components/DestinationIndexAustralia.vue';
export default {
  data: () => ({
    current: 'Victoria',
    currentArea: 'Australia',
    galleryItems: [
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-vic-001.jpg',
        caption: 'Brown Brothers King Valley - Credit: Tourism Australia',
      },
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-vic-002.jpg',
        caption: 'Melbourne markets - Credit: Tourism Australia',
      },
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-vic-003.jpg',
        caption: 'Credit: Tourism Australia',
      },
    ],
    destinationMapDialog: false,
    scrollToOptions: {
      easing: 'easeInOutCubic',
      duration: 300,
      offset: 50,
    },
  }),
  components: {
    DestinationIndex,
  },
  mounted: function() {
    document.documentElement.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
.container.destination-main {
  padding: 24px;
}

.content-nav-toolbar .v-toolbar__content {
  padding: 0 !important;
}

.content-nav-card .flex.border-l {
  border-left: solid 1px rgba(0, 0, 0, 0.12) !important;
}

#content-wrapper .title {
  line-height: 1.3 !important;
}

#content-wrapper li {
  margin-bottom: 16px;
}

#content-wrapper li:last-child {
  margin-bottom: 0;
}

.v-avatar.summer {
  border: solid 4px #ffaa00 !important;
}
.v-avatar.winter {
  border: solid 4px #adc9d7 !important;
}

@media (min-width: 600px) {
  .container.destination-main {
    padding: 24px 32px;
  }
}

@media (min-width: 960px) {
  .container.destination-main {
    padding: 24px;
  }
}
</style>
