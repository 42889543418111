export enum ItemType {
  Accommodation = "Accommodation",
  Car = "Car",
  Sightseeing = "Sightseeing",
  Ferry = "Ferry",
  Rail = "Rail",
  Coach = "Coach",
  Transfer = "Transfer",
  Travel = "Travel",
  Ski = "Ski",
  CamperRental = "Camper Rental",
  KirraCoachTour = "Kirra Coach Tour",
  MultiDayTour = "Multi Day Tour",
}

export enum SearchItemType {
  Accommodation = "ACCOMODATION",
  Car = "CAR",
  Camper = "CAMPER",
  Coach = "COACH",
  Ferry = "FERRY",
  MultiDayTour = "MULTIDAYTOUR",
  Package = "PACKAGE_TEMPLATE",
  Rail = "RAIL",
  Sightseeing = "SIGHTSEEING",
  Ski = "SKI",
  Transfer = "TRANSFER"
}

export const itemTypes = [
  {
    index: 5,
    code: "ACCOMMODATION",
    name: "Accommodation",
    pluralName: "Accommodation",
    icon: "hotel",
    iconColor: "#4AA7B0",
  },
  {
    index: 6,
    code: "CAR",
    name: "Car",
    pluralName: "Cars",
    icon: "directions_car",
    iconColor: "#AB906A",
  },
  {
    index: 35,
    code: "MULTIDAYTOUR",
    name: "Multi Day Tour",
    pluralName: "Multi Day Tours",
    icon: "local_see",
    iconColor: "#EDC644",
  },
  {
    index: 36,
    code: "SIGHTSEEING",
    name: "Sightseeing",
    pluralName: "Day Tours & Activities",
    icon: "local_see",
    iconColor: "#EDC644",
  },
  {
    index: 37,
    code: "FERRY",
    name: "Ferry",
    pluralName: "Ferries",
    icon: "directions",
    iconColor: "#DDDBBA",
  },
  {
    index: 38,
    code: "RAIL",
    name: "Rail",
    pluralName: "Rails",
    icon: "directions",
    iconColor: "#DDDBBA",
  },
  {
    index: 39,
    code: "COACH",
    name: "Coach",
    pluralName: "Coach Tours",
    icon: "directions",
    iconColor: "#DDDBBA",
  },
  {
    index: 44,
    code: "TRANSFER",
    name: "Transfer",
    pluralName: "Transfers",
    icon: "directions",
    iconColor: "#DDDBBA",
  },
  {
    index: 1000005,
    code: "CAMPER",
    name: "Camper Rental",
    pluralName: "Campers",
    icon: "directions_car",
    iconColor: "#AB906A",
  },
  {
    index: 51,
    code: "KIRRA_COACH_TOUR",
    name: "Kirra Coach Tour",
    pluralName: "Kirra Coach Tours",
    icon: "local_see",
    iconColor: "#EDC644",
  },
  {
    index: 55,
    code: "SKI",
    name: "Ski",
    pluralName: "Skis",
    icon: "terrain",
    iconColor: "#AB906A",
  },
  {
    index: 99999,
    code: "Package_Template",
    name: "Package Template",
    pluralName: "Package Templates",
    icon: "work",
    iconColor: "#65b32e",
  },
  {
    index: 1000008,
    code: "PASSIVEAIR",
    name: "Passive Air",
    pluralName: "Passive Air",
    icon: "flight",
    iconColor: "#DDDBBA",
  },
];

export function getTypeObjectByName(name) {
  const obj = itemTypes.find(x => {
    return x.name === name;
  });

  if (obj) {
    return obj;
  } else {
    throw `Item type ${name} does not exist.`;
  }
}

export function getTypeObjectByCode(code) {
  const obj = itemTypes.find(x => {
    return x.code === code;
  });

  if (obj) {
    return obj;
  } else {
    throw `Item type ${code} does not exist.`;
  }
}

export function getAllTypeObjects() {
  return itemTypes;
}

export function sanitizeItemType(itemType) {
  return itemType.toLowerCase().replace(/\s/g, "");
}
