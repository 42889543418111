<template>
  <v-container pa-0 fluid>
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2022/06/hero-deals-classic-australia-hp-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,0.65), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.2) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/06/classic-australian-holidays-stacked.png" alt="Classic Australian Holidays" class="campaign-title stacked" width="480" height="300" />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Sunrise in the Hunter River - Photo: Kimberley Cruise</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="gradient-bar"></div>

    <v-layout row>
      <v-flex mb-5>
          <v-container grid-list-lg fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 py-0 my-0>
                <v-card tile class="pa-0 elevation-0 deals-content">
                  <v-card-text class="pa-5">
                    <span
                      class="headline font-weight-medium kiwiGreen d-block mb-3"
                    >If you’re a first time traveller down-under, these are the holidays for you.</span>
                    <p>From the spiritually charged Red Centre, to the turquoise waters of the sun-drenched Whitsundays in Queensland and everything in between. Here you’ll find a collection of Aussie holidays that showcase the must-see destinations of Australia.</p>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                <v-card tile dark class="pa-0 ma-0 elevation-0">
                  <v-card-text class="px-3 py-2">
                    <span
                      class="headline font-weight-medium d-block"
                    >{{section.title}}</span>
                  </v-card-text>
                </v-card>
                <v-card tile class="px-3 py-3 elevation-0">
                  <v-layout row wrap ma-0>
                    <v-flex xs12 pa-0 hidden-sm-and-down>
                      <v-layout row wrap my-0>
                        <v-flex xs12 md7 lg8>
                          <span class="caption grey--text">Package Deal</span>
                        </v-flex>
                        <v-flex xs12 md3 lg2 text-md-right>
                          <span class="caption grey--text">From Price (GBP)</span>
                        </v-flex>
                        <v-flex xs12 md2 lg2 text-md-right>
                          <span class="caption grey--text">Inclusions & Terms</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                      <v-divider></v-divider>
                      <v-layout row wrap align-center my-3>
                        <v-flex xs12 md7 lg8>
                          <v-layout row align-center>
                            <v-flex shrink hidden-md-and-down>
                              <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                            </v-flex>
                            <v-flex>
                              <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                              <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                              <span class="d-block package-data">
                                <v-chip label color="#f5f5f5" disabled class="mb-0">
                                  <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Start location</span>
                                  </v-tooltip>
                                </v-chip>
                                <v-chip label color="#f5f5f5" disabled class="mb-0" v-if="!deal.call">
                                  <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Package Code</span>
                                  </v-tooltip>
                                </v-chip>
                                <v-chip label color="#f5f5f5" disabled class="mb-0" v-else>
                                  <b>Call ANZCRO to Book</b><v-icon class="mx-2" size="14" :color="kiwiGreen">phone</v-icon>
                                </v-chip>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm6 md3 lg2 text-md-right>
                          <span class="headline d-block font-weight-medium mb-2 kiwiGreen">£{{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                          <span class="caption d-block grey--text">{{deal.complement}}</span>
                        </v-flex>
                        <v-flex xs12 sm6 md2 lg2 text-sm-right>
                          <v-btn large class="card-with-border elevation-0 mx-0 px-2" :color="lightGrey" :href="deal.itinerary" target="_blank"><v-icon color="grey" size="18" class="mr-1">launch</v-icon> View</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
      </v-flex>
    </v-layout>


  <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    copied: false,
    copiedText: '',
    sections : [
      /*
      {
        title : 'Deals',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-beaches-wine.jpg',
            name : '5 Day Beach, Wine & Adventure',
            price : '395',
            complement : 'per person (twin share)',
            code : 'ANZCH224NBWA',
            location : 'Gold Coast (OOL)',
            valid: '15 Jul 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/5-Day-Beaches-Wine-Adventure-GBP.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-top-end.jpg',
            name : '5 Day Top End Explorer',
            price : '509',
            complement : 'per person (twin share)',
            code : 'ANZCH224NTEE',
            location : 'Darwin (DRW)',
            valid: '01 Oct 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/5-Day-Top-End-Explorer-GBP.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deal-thumbnail-australia-all-time-gbp-1.jpg',
            name : '6 Day Reef & Rainforest',
            price : '769',
            complement : 'per person (twin share)',
            code : 'AUPB236DRR',
            location : 'Cairns (CNS)',
            valid: '01 Jul 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/6-Days-Reef-Rainforest-GBP-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deal-thumbnail-australia-all-time-gbp-3.jpg',
            name : '5 Day Whitsundays Getaway',
            price : '775',
            complement : 'per person (twin share)',
            code : 'AUUK225DWG',
            location : 'Hamilton Island (HTI)',
            valid: '01 Jul 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/5-Days-Whitsundays-GBP.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-classic-aussie-1.jpg',
            name : '5 Day Sail the Whitsundays',
            price : '869',
            complement : 'per person (twin share)',
            code : 'ANZ225DSTW',
            location : 'Airlie Beach (AIR)',
            valid: '15 Oct 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/5-Day-Sail-The-Whitsundays-GBP.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-classic-aussie-2.jpg',
            name : '4 Day Iconic Red Centre',
            price : '899',
            complement : 'per person (twin share)',
            code : 'ANZCH234DIRC',
            location : 'Ayers Rock/Yulara (AYQ)',
            valid: '01 Aug 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/4-Day-Iconic-Red-Centre-GBP.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-kangaroo-island.jpg',
            name : '5 Day Highlights of Kangaroo Island',
            price : '1029',
            complement : 'per person (twin share)',
            code : 'ANZCH224NHKI',
            location : 'Adelaide (ADL)',
            valid: '01 Aug 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/5-Day-Highlights-of-Kangaroo-Island-GBP.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-great-ocean.jpg',
            name : '9 Day Great Ocean Road & Beyond',
            price : '1209',
            complement : 'per person (twin share)',
            code : 'ANZCH226NGORB',
            location : 'Melbourne (MEL)',
            valid: '08 Jul 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/9-Day-Great-Ocean-Road-Beyond-GBP.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-coast-bungles.jpg',
            name : '9 Day Coast to the Bungles',
            price : '1579',
            complement : 'per person (twin share)',
            code : 'ANZCH238NC2B',
            location : 'Broome (BME)',
            valid: '06 May 2023 - 01 Oct 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/9-Day-Coast-to-the-Bungles-GBP.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-ultimate-qld.jpg',
            name : '11 Day Ultimate Queensland Odyssey',
            price : '1755',
            complement : 'per person (twin share)',
            code : 'ANZCH10NUQO',
            location : 'Brisbane (BNE)',
            valid: '01 Aug 2022 - 11 Dec 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/11-Day-Ultimate-Queensland-Odyssey-GBP.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-classic-aussie-3.jpg',
            name : '5 Day Secluded Island Indulgence',
            price : '1775',
            complement : 'per person (twin share)',
            code : 'ANZ224NSII',
            location : 'Hamilton Island (HTI)',
            valid: '20 Sep 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/5-Day-Secluded-Island-Indulgence-GBP.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deal-thumbnail-australia-all-time-gbp-2.jpg',
            name : '11 Day City, Rock & Reef',
            price : '1899',
            complement : 'per person (twin share)',
            code : 'AUB2311DCRR',
            location : 'Sydney (SYD)',
            valid: '01 Jul 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/11-Day-City-Rock-Reef-Flyer-GBP-20220207.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-arajilla-retreat.jpg',
            name : '7 Day Arajilla Retreat',
            price : '3149',
            complement : 'per person (twin share)',
            code : 'ANZCH226NAL',
            location : 'Lord Howe Island',
            valid: '22 Aug 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/6-Night-Arajilla-Lodge-GBP.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-classic-aussie-4.jpg',
            name : '11 Day Southern Kimberley Spectacular',
            price : '8855',
            complement : 'per person (twin share)',
            code : 'ANZAU2310NSKS',
            location : 'Broome (BME)',
            valid: '13 Aug 2023 - 30 Aug 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/11-Day-Southern-Kimberley-Spectacular-GBP-1.pdf',
            call : false
          },
        ]
      },
      */
    ]
  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
    copyCode(event) {
      if(event){
        let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
        window.getSelection().selectAllChildren(copyCode);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyCode.innerHTML);
        this.copiedText = 'Package code copied to clipboard';
        this.copied = true;
      }
    },
    copyLocation(event) {
      if(event){
        let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
        window.getSelection().selectAllChildren(copyLocation);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyLocation.innerHTML);
        this.copiedText = 'Package start location copied to clipboard';
        this.copied = true;
      }
    }
  },
};
</script>

<style>
.gradient-bar{
  width: 100%;
  height: 9px;
  background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
  background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
}

.deals-content{
  margin-top: -60px;
}

.package-data .v-chip{
  color:#9e9e9e;
  margin-left: 0;
}

.package-data .v-chip .v-chip__content{
  padding-right: 6px;
}

.deal-thumbnail{
  width: 88px;
  height: 88px;
}
</style>
