import { render, staticRenderFns } from "./PublicFooter.CO.UK.vue?vue&type=template&id=58ac34c6"
import script from "./PublicFooter.CO.UK.vue?vue&type=script&lang=js"
export * from "./PublicFooter.CO.UK.vue?vue&type=script&lang=js"
import style0 from "./PublicFooter.CO.UK.vue?vue&type=style&index=0&id=58ac34c6&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VFooter } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {
  VBtn,
  VCard,
  VContainer,
  VDivider,
  VFlex,
  VFooter,
  VIcon,
  VImg,
  VLayout,
})
