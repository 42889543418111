<template>
  <div>
    <v-card class="elevation-0 card-with-border dash-rounded pa-3 mb-4" :color="offWhite" v-if="destinationsNorth.length > 0" >
      <h4 class="destination-title body-2 mb-3">North Island</h4>
      <ul class="destination-list">
        <template v-for="(item, index) in destinationsNorth">
          <li :key="index" v-if="item.name != currentDestination && !item.hidden">
            <a :href="`/destinations/new-zealand/north-island${item.path}`">{{item.name}}</a>
          </li>
        </template>
      </ul>
    </v-card>
    <v-card class="elevation-0 card-with-border dash-rounded pa-3 mb-4" :color="offWhite" v-if="destinationsSouth.length > 0">
      <h4 class="destination-title body-2 mb-3">South Island</h4>
      <ul class="destination-list">
        <template v-for="(item, index) in destinationsSouth">
          <li :key="index" v-if="item.name != currentDestination && !item.hidden">
            <a :href="`/destinations/new-zealand/south-island${item.path}`">{{item.name}}</a>
          </li>
        </template>
      </ul>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    currentDestination: {
      type: String,
    },
  },
  data: () => ({

    destinationsNorth: [
      {
        name: 'Auckland',
        area: 'North Island',
        path: '/auckland/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/10/search-index-packages-001.jpg',
        hidden: false,
      },
      {
        name: 'Wellington',
        area: 'North Island',
        path: '/wellington/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/10/search-index-packages-001.jpg',
        hidden: false,
      },
      {
        name: 'Tairāwhiti Gisborne',
        area: 'North Island',
        path: '/tairawhiti-gisborne/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/10/search-index-packages-001.jpg',
        hidden: false,
      },
      {
        name: 'Hawke\'s Bay',
        area: 'North Island',
        path: '/hawkes-bay/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/10/search-index-packages-001.jpg',
        hidden: false,
      },
      {
        name: 'Taranaki',
        area: 'North Island',
        path: '/taranaki/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/10/search-index-packages-001.jpg',
        hidden: false,
      },
      {
        name: 'Manawatu',
        area: 'North Island',
        path: '/manawatu/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/10/search-index-packages-001.jpg',
        hidden: false,
      },
      {
        name: 'Wairarapa',
        area: 'North Island',
        path: '/wairarapa/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-taranaki.jpg',
        hidden: false,
      },
      {
        name: 'Whanganui',
        area: 'North Island',
        path: '/whanganui/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-manawatu.jpg',
        hidden: false,
      },
      {
        name: 'Bay of Plenty',
        area: 'North Island',
        path: '/bay-of-plenty/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-taranaki.jpg',
        hidden: false,
      },
      {
        name: 'The Coromandel',
        area: 'North Island',
        path: '/the-coromandel/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-wairarapa.jpg',
        hidden: false,
      },
      {
        name: 'Ruapehu',
        area: 'North Island',
        path: '/ruapehu/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-whanganui.jpg',
        hidden: false,
      },
      {
        name: 'Taupo',
        area: 'North Island',
        path: '/taupo/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-bay-of-plenty.jpg',
        hidden: false,
      },
      {
        name: 'Waikato',
        area: 'North Island',
        path: '/waikato/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-wairarapa.jpg',
        hidden: false,
      },
      {
        name: 'Rotorua',
        area: 'North Island',
        path: '/rotorua/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-whanganui.jpg',
        hidden: false,
      },
      {
        name: 'Northland',
        area: 'North Island',
        path: '/northland/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-bay-of-plenty.jpg',
        hidden: false,
      },
    ],
    destinationsSouth: [
      {
        name: 'Queenstown',
        area: 'South Island',
        path: '/queenstown/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/10/search-index-packages-001.jpg',
        hidden: false,
      },
      {
        name: 'Christchurch and Canterbury',
        area: 'South Island',
        path: '/christchurch/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/10/search-index-packages-001.jpg',
        hidden: false,
      },
      {
        name: 'Central Otago',
        area: 'South Island',
        path: '/central-otago/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/10/search-index-packages-001.jpg',
        hidden: false,
      },
      {
        name: 'Dunedin',
        area: 'South Island',
        path: '/dunedin/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/10/search-index-packages-001.jpg',
        hidden: false,
      },
      {
        name: 'Fiordland',
        area: 'South Island',
        path: '/fiordland/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-central-otago.jpg',
        hidden: false,
      },
      {
        name: 'Nelson Tasman',
        area: 'South Island',
        path: '/nelson-tasman/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-dunedin.jpg',
        hidden: false,
      },
      {
        name: 'Southland',
        area: 'South Island',
        path: '/southland/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-dunedin.jpg',
        hidden: false,
      },
      {
        name: 'Wanaka',
        area: 'South Island',
        path: '/wanaka/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-fiordland.jpg',
        hidden: false,
      },
      {
        name: 'Marlborough',
        area: 'South Island',
        path: '/marlborough/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-nelson-tasman.jpg',
        hidden: false,
      },
      {
        name: 'West Coast',
        area: 'South Island',
        path: '/west-coast/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-marlborough.jpg',
        hidden: false,
      },
      {
        name: 'Clutha',
        area: 'South Island',
        path: '/clutha/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-marlborough.jpg',
        hidden: false,
      },
      {
        name: 'Kaikōura',
        area: 'South Island',
        path: '/kaikoura/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-marlborough.jpg',
        hidden: false,
      },
      {
        name: 'Timaru',
        area: 'South Island',
        path: '/timaru/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-marlborough.jpg',
        hidden: true,
      },
      {
        name: 'Hurunui',
        area: 'South Island',
        path: '/hurunui/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-thumbnail-marlborough.jpg',
        hidden: false,
      }
    ],
  }),
};
</script>

<style>
.destination-list li{
  margin:0 0 8px;
}
</style>
