<template>
  <div>
    <router-view />
  </div>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'App',
});
</script>

<style>
@import url('assets/scss/custom.scss');
</style>
