import Vue from 'vue';
import Router from 'vue-router';

import { dashboardRoutes } from '@/components/dashboard/dashboard.routing.co.uk';
import { itineraryRoutes } from '@/components/itinerary/itinerary.routing';
import { publicRoutes } from '@/routes/public.routing.co.uk';

import { setupRouter } from '@/routes/setup';

const _404 = () => import('@/components/404.vue');

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...publicRoutes,
    ...dashboardRoutes,
    ...itineraryRoutes,
    {
      path: '/404',
      name: '404',
      component: _404,
      props: true,
    },
    {
      path: '*',
      redirect: '404',
    },
  ],
});

setupRouter({ router });

export default router;
