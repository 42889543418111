import appService from '@/services/app.service';
import { O3ApiResponse } from '@/model/response';
import { BookingInfo } from '../model/booking';
import { AnyTypeToFix } from '@/model/AnyType';
import { BookingNoteRequest } from '@/components/dashboard/model/notes';

const convertToBookingFromQuote = (req: AnyTypeToFix) => {
  return appService.post('/bookings/booking/fromQuote', req);
};

const modifyBookingPax = (req: AnyTypeToFix) => {
  return appService.post('/bookings/booking/modifyBookingPax', req);
};

const addNote = (bookingId: number, req: BookingNoteRequest) => {
  return appService.post(`/bookings/${bookingId}/notes`, req);
};

const closeBooking = (bookingId: number | string): Promise<any> => {
  return appService.get(`/bookings/close/${bookingId}`);
};

const list = function (req: AnyTypeToFix): Promise<O3ApiResponse<BookingInfo[]>> {
  return appService.get('/bookings/', req);
};

const addPayment = (bookingId: number, req: AnyTypeToFix) => {
  return appService.post(`/bookings/${bookingId}/add-payment`, req);
};


export const bookingService = {
  convertToBookingFromQuote,
  modifyBookingPax,
  addNote,
  list,
  closeBooking,
  addPayment
};
