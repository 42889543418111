import { make } from 'vuex-pathify';
import { userQueryService } from '@/components/dashboard/account/services/userQuery.service'
import { agencyService } from '@/components/dashboard/services/agency.service'
import { agencyQueryService } from '@/components/dashboard/services/agencyQuery.service'
const state = {
  user: null,
  agency: null,
  addAgentDialog: false,
  editAgentDialog: false,
  editUserName: '',
  removeAgentDialog: false,
  agentToRemove: null
};

const getters = {
  ...make.getters(state),
};

const actions = {
  ...make.actions(state),
  async getUser({ commit }, username) {
    const data = await userQueryService.getUser(username)
    commit('user', data.result)
  },
  async getAgency({ commit }, agencyId) {
    const data = await agencyQueryService.getAgency(agencyId)
    commit('agency', data.result)
  },
  async updateAgency({ commit }, { agencyId, payload }) {
    const data = await agencyService.updateAgency(agencyId, payload)
    commit('agency', data.result)
  },
  editUser({ commit }, username) {
    commit('editAgentDialog', true)
    commit('editUserName', username)
  },
  removeUser({ commit }, agent) {
    commit('removeAgentDialog', true)
    commit('agentToRemove', agent)
  }
};

const mutations = {
  ...make.mutations(state),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
