const ItineraryItem = () => import('./ItineraryItem.vue');
const BookingItem = () => import('./BookingItem.vue');
//belongs to /itinerary-builder/item/
export const itemViewRoutes = [
  {
    path: 'view/:category/:seq',
    name: 'item-view',
    component: ItineraryItem,
  },
  {
    path: 'view/booking/:id/:category/:seq',
    name: 'item-view-booking',
    component: BookingItem,
  },
];
