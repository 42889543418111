<template>
    <v-container pa-0 fluid>
      <v-layout row>
        <v-flex>
          <v-card flat tile dark class="hero-home-slider">
            <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
              <v-carousel-item>
                <v-img
                  src="https://cdn.anzcro.com.au/wp-content/uploads/2023/07/hero-tranzalpine-scaled.jpg"
                  gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.45) 15%, rgba(22,22,22,0.25) 65%"
                  class="home-hero"
                >
                  <v-container fill-height>
                    <v-layout dark column fill-height justify-space-between pt-5>
                      <v-flex text-xs-center class="home-hero-cta">
                        <v-layout row align-center fill-height class="fill-height-fix-ios">
                          <v-flex>
                            <img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/07/great-rail-journeys-of-new-zealand-stacked.png" alt="Great Rail Journeys of New Zealand" class="campaign-title stacked" width="480" height="300" />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div class="hero-caption hidden-xs-only">
                    <small>TranzAlpine Train Journey - Photo: KiwiRail</small>
                  </div>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="gradient-bar"></div>
  
      <v-layout row>
        <v-flex mb-5>
            <v-container grid-list-lg fill-height>
              <v-layout row wrap fill-height>
                <v-flex xs12 py-0 my-0>
                  <v-card tile class="pa-0 elevation-0 deals-content">
                    <v-card-text class="pa-5">
                      <v-layout row wrap align-end>
                        <v-flex xs12 md10 py-0 my-0>
                          <span
                            class="headline font-weight-medium kiwiGreen d-block mb-3"
                          >Discover a collection of our most popular self-guided packages <br/>and brand-new guided rail tours.</span>
                          <p>Climb aboard one of New Zealand’s Great Rail Journeys. Discover three spectacular rail journeys, each showcasing a unique view of New Zealand’s famous scenery. From sleeping volcanoes to cascading waterfalls, sparkling cities and rural farmsteads, golden beaches and snow-capped mountains, you will enjoy an immersive experience and a deeper connection with Aotearoa New Zealand.  </p>
                          <p><a target="_blank" href="https://issuu.com/holidayexperts/docs/rail_e-brochure_gbp_issuu?fr=xKAE9_zU1NQ">Download a copy of our 18-page eBrochure</a> which will highlight details of each New Zealand train journey, a collection of our most popular self-guided holiday packages and brand new guided rail tours which offer a more inclusive group experience with a local Tour Director.</p>
                        </v-flex>
                        <v-flex xs12 md2 py-0 my-0 text-xs-center>
                          <a target="_blank" href="https://issuu.com/holidayexperts/docs/rail_e-brochure_gbp_issuu?fr=xKAE9_zU1NQ"><img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/07/brochure-mini-rail-2023-AUD.jpg" alt="Great Rail Journeys of New Zealand eBrochure Cover" class="campaign-sticker kirra-early-bird" width="124" height="174" /></a>
                          <small class="kiwiGreen d-block mt-2" style="line-height:1.2;"><a target="_blank" href="https://issuu.com/holidayexperts/docs/rail_e-brochure_gbp_issuu?fr=xKAE9_zU1NQ"><b>View eBrochure</b></a></small>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                  <v-card tile dark class="pa-0 ma-0 elevation-0">
                    <v-card-text class="px-3 py-2">
                      <span
                        class="headline font-weight-medium d-block"
                      >{{section.title}}</span>
                    </v-card-text>
                  </v-card>
                  <v-card tile class="px-3 py-3 elevation-0">
                    <v-layout row wrap ma-0>
                      <v-flex xs12 pa-0 hidden-sm-and-down>
                        <v-layout row wrap my-0>
                          <v-flex xs12 md7 lg8>
                            <span class="caption grey--text">Tour / Package</span>
                          </v-flex>
                          <v-flex xs12 md3 lg2 text-md-right>
                            <span class="caption grey--text">From Price (GBP)</span>
                          </v-flex>
                          <v-flex xs12 md2 lg2 text-md-center>
                            <span class="caption grey--text">Agent Resources</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                        <v-divider></v-divider>
                        <v-layout row wrap align-center my-3>
                          <v-flex xs12 md7 lg8>
                            <v-layout row align-center>
                              <v-flex shrink hidden-md-and-down>
                                <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                              </v-flex>
                              <v-flex>
                                <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                                <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                                <span class="d-block package-data">
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Start location</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Package Code</span>
                                    </v-tooltip>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm6 md3 lg2 text-md-right>
                            <span class="headline d-block font-weight-medium mb-2 kiwiGreen">£{{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                            <span class="caption d-block grey--text">{{deal.complement}}</span>
                          </v-flex>
                          <v-flex xs12 sm6 md2 lg2 text-sm-right text-md-center>
                          <v-tooltip top v-if="deal.sm_tile">
                            <template v-slot:activator="{ on }">
                              <v-btn large v-on="on" icon class="card-with-border elevation-0 mr-1 px-0" color="white" :href="deal.sm_tile" target="_blank"><v-icon :color="kiwiGreen" size="22" class="">image</v-icon></v-btn>
                            </template>
                            <span>View Social Media Tile</span>
                          </v-tooltip>
                          <v-tooltip top v-if="deal.itinerary">
                            <template v-slot:activator="{ on }">
                              <v-btn large v-on="on" icon class="card-with-border elevation-0 mx-0 px-0" :color="kiwiGreen" :href="deal.itinerary" target="_blank"><v-icon color="white" size="22" class="">description</v-icon></v-btn>
                            </template>
                            <span>View Flyer</span>
                          </v-tooltip>
                        </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
      </v-layout>
  
  
    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      copied: false,
      copiedText: '',
      sections : [      
        {
          title : 'Self-Guided Packages',
          deals : [
            /*
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-rail-01.jpg',
              name : '7 Day NZ Rail Experience',
              price : '1899',
              complement : 'per person (twin share)',
              code : 'ANZ237DNRE',
              location : 'Auckland (AKL)',
              valid: '01 Oct 2023 - 31 Mar 2024 (select departures)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/7-Day-NZ-Rail-Experience-AUD.pdf',
              sm_tile : 'https://cdn.anzcro.com.au/'
            },*/
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-rail-03.jpg',
              name : '10 Day Southern Tracks',
              price : '1199',
              complement : 'per person (twin share)',
              code : 'ANZ2310DST',
              location : 'Christchurch (CHC)',
              valid: '01 Oct 2023 - 31 Mar 2024 (select departures)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/Southern-Tracks-GBP-Flyer.pdf',
              sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/10-Day-Southern-Tracks-SM-JPG.jpg'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-rail-02.jpg',
              name : '10 Day Northern Explorer',
              price : '1599',
              complement : 'per person (twin share)',
              code : 'ANZ2310DNE',
              location : 'Auckland (AKL)',
              valid: '01 Oct 2023 - 30 Apr 2024 (select departures)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/Northern-Explorer-GBP-Flyer.pdf',
              sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/10-Day-Northern-Explorer-SM-JPG.jpg'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-rail-04.jpg',
              name : '12 Day Grand Rail Explorer',
              price : '1799',
              complement : 'per person (twin share)',
              code : 'ANZ2412DGRE',
              location : 'Queenstown (ZQN)',
              valid: '08 Jan - 18 Mar 2024 (select departures)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/12-Day-Grand-Rail-Explorer-GBP-UPDATED-AUG23.pdf',
              sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/12-Day-Grand-Rail-Explorer-SM-JPG.jpg'
            },
          ]
        },
        {
          title : 'Guided Rail Tours',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-rail-05.jpg',
              name : '15 Day Classic New Zealand Highlights',
              price : '5029',
              complement : 'per person (twin share)',
              code : 'Call ANZCRO to Book',
              location : 'Auckland (AKL)',
              valid: 'Oct 2023 - May 2024 (select departures)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/15-Day-NZ-Highlights-Classic-Tour-GBP-FLYER.pdf',
              sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/15-Day-New-Zealand-Highlights-Tour-SM-JPG.jpg'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-rail-06.jpg',
              name : '17 Day Signature New Zealand Up Close',
              price : '8239',
              complement : 'per person (twin share)',
              code : 'Call ANZCRO to Book',
              location : 'Auckland (AKL)',
              valid: 'Sep 2023 - May 2024 (select departures)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/17-Day-NZ-Up-Close-Signature-Tour-GBP-FLYER.pdf',
              sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/17-Day-New-Zealand-Up-Close-SM-JPG.jpg'
            },
          ]
        },
        /*
        {
          title : 'Rail Journeys | Australia',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/07/deal-uk-rail-ultimate.jpg',
              name : '6 Day Last Chance Expedition',
              price : '5125',
              complement : 'per person (twin share)',
              code : 'ANZG6DGE',
              location : 'Darwin (DRW)',
              valid: 'Departure 25 Aug 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/ANZG6DGE-6-Day-Last-Chance-Expedition-AUD.pdf'
            },
          ]
        },
        {
          title : 'Rail Journeys | New Zealand',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-marlborough-sunset.jpg',
              name : '5 Day Rail & Sounds',
              price : '779',
              complement : 'per person (twin share)',
              code : 'ANZ235DRS',
              location : 'Christchurch (CHC)',
              valid: '01 Oct 2023 - 31 Mar 2024 (rail departs Thu-Sun only)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/Coastal-Pacific-Rail-Stay-AUD-Flyer.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-kaikoura-whale.jpg',
              name : '5 Day Rail & Whales',
              price : '790',
              complement : 'per person (twin share)',
              code : 'ANZ235DRW',
              location : 'Christchurch (CHC)',
              valid: '01 Oct 2023 - 31 Mar 2024 (rail departs Thu-Sun only)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/Coastal-Pacific-Rail-Stay-AUD-Flyer.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-marlborough-vines.jpg',
              name : '5 Day Rail, Wine & Dine',
              price : '1389',
              complement : 'per person (twin share)',
              code : 'ANZ235DRWD',
              location : 'Christchurch (CHC)',
              valid: '01 Oct 2023 - 31 Mar 2024 (rail departs Thu-Sun only)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/Coastal-Pacific-Rail-Stay-AUD-Flyer.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/04/deal-gre.jpg',
              name : '12 Days Grand Rail Explorer',
              price : '2999',
              complement : 'per person (twin share)',
              code : 'ANZ2412DGRE',
              location : 'Queenstown (ZQN)',
              valid: 'Jan - Mar 2024 (select start dates)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/Grand-Rail-Explorer-AUD-Flyer.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/deal-rail-luxury.jpg',
              name : '12 Days Grand Rail Explorer Luxury',
              price : '3699',
              complement : 'per person (twin share)',
              code : 'ANZ2312DGRE-LUX',
              location : 'Queenstown (ZQN)',
              valid: 'Apr - Sep 2023 (select start dates)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/11/Grand-Rail-Explorer-Luxury-Autumn-Spring-2023-AUD-20221129.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/deal-ultimate-rail-nz.jpg',
              name : '19 Days Ultimate NZ by Rail & Road',
              price : '4399',
              complement : 'per person (twin share)',
              code : 'ANZ2419DUNZ',
              location : 'Christchurch (CHC)',
              valid: 'Jan - Mar 2024 (select start dates)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/Ultimate-NZ-by-Rail-Road-AUD.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/04/deal-gre.jpg',
              name : '12 Days Grand Rail Explorer (2023)',
              price : '2899',
              complement : 'per person (twin share)',
              code : 'ANZ2312DGRE',
              location : 'Queenstown (ZQN)',
              valid: 'Oct - Dec 2023 (select start dates)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/Grand-Rail-Explorer-Spring-2023-AUD.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/deal-thumbnail-kiwi-rail-whales.jpg',
              name : '9 Nights Rail, Walks & Wine',
              price : '1769',
              complement : 'per person (twin share)',
              code : 'ANZ229NRWW',
              location : 'Christchurch (CHC)',
              valid: '01 Sep 2022 - 31 Mar 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/11/NZ-Rail-2-Deals-AUD-Flyer-2-EXTENDED.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/deal-thumbnail-kiwi-rail-explorer.jpg',
              name : '12 Nights South Island Rail Explorer',
              price : '2049',
              complement : 'per person (twin share)',
              code : 'ANZ2212NSIRE',
              location : 'Christchurch (CHC)',
              valid: '01 Sep 2022 - 31 Mar 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/11/NZ-Rail-2-Deals-AUD-Flyer-2-EXTENDED.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/deal-thumbnail-kiwi-rail-lakes.jpg',
              name : '7 Nights Rail, Glaciers & Lakes',
              price : '1199',
              complement : 'per person (twin share)',
              code : 'ANZ227NRGL',
              location : 'Christchurch (CHC)',
              valid: '01 Sep 2022 - 31 Mar 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/NZ-Rail-2-Deals-AUD-Flyer-1.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/deal-thumbnail-kiwi-rail-mountains.jpg',
              name : '8 Nights Rail, Glaciers & Mountains',
              price : '1419',
              complement : 'per person (twin share)',
              code : 'ANZ228NRGM',
              location : 'Christchurch (CHC)',
              valid: '01 Sep 2022 - 31 Mar 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/NZ-Rail-2-Deals-AUD-Flyer-1.pdf'
            },
          ]
        },
        */
      ]
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      },
      copyCode(event) {
        if(event){
          let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
          window.getSelection().selectAllChildren(copyCode);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyCode.innerHTML);
          this.copiedText = 'Package code copied to clipboard';
          this.copied = true;
        }
      },
      copyLocation(event) {
        if(event){
          let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
          window.getSelection().selectAllChildren(copyLocation);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyLocation.innerHTML);
          this.copiedText = 'Package start location copied to clipboard';
          this.copied = true;
        }
      }
    },
  };
  </script>
  
  <style>
  .gradient-bar{
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
    background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
  }
  
  .deals-content{
    margin-top: -60px;
  }
  
  .package-data .v-chip{
    color:#9e9e9e;
    margin-left: 0;
  }
  
  .package-data .v-chip .v-chip__content{
    padding-right: 6px;
  }
  
  .deal-thumbnail{
    width: 88px;
    height: 88px;
  }
  </style>
  