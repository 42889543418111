<template>
    <v-container pa-0 fluid>
      <v-layout row>
        <v-flex>
          <v-card flat tile dark class="hero-home-slider">
            <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
              <v-carousel-item>
                <v-img
                  src="https://cdn.anzcro.com.au/wp-content/uploads/2022/10/hero-australian-new-zealand-odysseys-scaled.jpg"
                  gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.2) 65%"
                  class="home-hero"
                >
                  <v-container fill-height>
                    <v-layout dark column fill-height justify-space-between pt-5>
                      <v-flex text-xs-center class="home-hero-cta">
                        <v-layout row align-center fill-height class="fill-height-fix-ios">
                          <v-flex>
                            <!--<h1 class="white--text display-1 font-weight-bold mb-3">
                              Hot Offers on<br/>Holidays at Home
                            </h1>-->
                            <img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/10/australian-new-zealand-odysseys-stacked.png" alt="Australian & New Zealand Odysseys" class="campaign-title stacked" width="480" height="300" />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div class="hero-caption hidden-xs-only">
                    <small>Twelve Apostles, Great Ocean Road - Victoria</small>
                  </div>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="gradient-bar"></div>
  
      <v-layout row>
        <v-flex mb-5>
            <v-container grid-list-lg fill-height>
              <v-layout row wrap fill-height>
                <v-flex xs12 py-0 my-0>
                  <v-card tile class="pa-0 elevation-0 deals-content">
                    <v-card-text class="pa-5">
                      <span
                        class="headline font-weight-medium kiwiGreen d-block mb-3"
                      >Make the journey count with a longer itinerary.</span>
                      <p>Take the road less travelled with one of these life-changing holidays in Australia and New Zealand. Here we invite you to take your time exploring the magnificent cities and landscapes Down Under. From a 15 days Christchurch to Christchurch loop, all the way to a truly wonderous 34-day Australian and New Zealand voyage, amongst these holiday packages you will find something to make every travel dream come true.</p>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                  <v-card tile dark class="pa-0 ma-0 elevation-0">
                    <v-card-text class="px-3 py-2">
                      <span
                        class="headline font-weight-medium d-block"
                      >{{section.title}}</span>
                    </v-card-text>
                  </v-card>
                  <v-card tile class="px-3 py-3 elevation-0">
                    <v-layout row wrap ma-0>
                      <v-flex xs12 pa-0 hidden-sm-and-down>
                        <v-layout row wrap my-0>
                          <v-flex xs12 md7 lg8>
                            <span class="caption grey--text">Package Deal</span>
                          </v-flex>
                          <v-flex xs12 md3 lg2 text-md-right>
                            <span class="caption grey--text">From Price (GBP)</span>
                          </v-flex>
                          <v-flex xs12 md2 lg2 text-md-right>
                            <span class="caption grey--text">Inclusions & Terms</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                        <v-divider></v-divider>
                        <v-layout row wrap align-center my-3>
                          <v-flex xs12 md7 lg8>
                            <v-layout row align-center>
                              <v-flex shrink hidden-md-and-down>
                                <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                              </v-flex>
                              <v-flex>
                                <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                                <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                                <span class="d-block package-data">
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Start location</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-if="!deal.call">
                                    <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Package Code</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-else>
                                    <b>Call ANZCRO to Book</b><v-icon class="mx-2" size="14" :color="kiwiGreen">phone</v-icon>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm6 md3 lg2 text-md-right>
                            <span class="headline d-block font-weight-medium mb-2 kiwiGreen">£{{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                            <span class="caption d-block grey--text">{{deal.complement}}</span>
                          </v-flex>
                          <v-flex xs12 sm6 md2 lg2 text-sm-right>
                            <v-btn large class="card-with-border elevation-0 mx-0 px-2" :color="lightGrey" :href="deal.itinerary" target="_blank"><v-icon color="grey" size="18" class="mr-1">launch</v-icon> View</v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
      </v-layout>
  
  
    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      copied: false,
      copiedText: '',
      sections : [
        
        /*
        {
          title : 'Australian Itineraries',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/deal-crr.jpg',
              name : '12 Day City, Rock & Reef',
              price : '2375',
              complement : 'per person (twin share)',
              code : 'ANZ2312DCRR',
              location : 'Sydney (SYD)',
              valid: '14 Apr 2023 - 20 Mar 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/12-Day-City-Rock-Reef-GBP.pdf',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/deal-long-itinerary-1.jpg',
              name : '16 Day East Coast & Red Centre Snapshot',
              price : '3435',
              complement : 'per person (twin share)',
              code : 'ANZUK16DECRCS',
              location : 'Cairns (CNS)',
              valid: '16 Mar 2023 - 15 Mar 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/ANZUK16DECRCS-East-Coast-Red-Centre-Snapshot-GBP-MAR23.pdf',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/deal-long-itinerary-3.jpg',
              name : '30 Day Discover Down Under',
              price : '5055',
              complement : 'per person (twin share)',
              code : 'ANZUK30DDD',
              location : 'Cairns (CNS)',
              valid: '01 Apr - 26 Nov 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/ANZUK30DDD-Discover-Down-Under-GBP-MAR23.pdf',
              call : false
            },
          ]
        },
        {
          title : 'New Zealand Itineraries',
          deals : [
            {
                thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deal-thumbnail-classic-new-zealand-gbp-2.jpg',
                name : '22 Days Best of Both Islands',
                price : '2509',
                complement : 'per person (twin share)',
                code : 'NZB2322BOBI',
                location : 'Auckland (AKL)',
                valid: '01 Feb - 30 Sep 2023',
                itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/NZB2322BOBI-Best-of-Both-Islands-GBP-MAR23.pdf'
            },
            {
                thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/05/deal-south-island.jpg',
                name : '15 Days Christchurch to Christchurch',
                price : '2639',
                complement : 'per person (twin share)',
                code : 'ANZOD15CTC',
                location : 'Christchurch (CHC)',
                valid: '01 Apr 2023 - 31 Mar 2024',
                itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/ANZIB2315DSA-Christchurch-to-Christchurch-GBP-MAR23.pdf'
            },
            {
                thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/05/deal-both-islands.jpg',
                name : '25 Days Auckland to Christchurch',
                price : '2930',
                complement : 'per person (twin share)',
                code : 'ANZOD25ATC',
                location : 'Auckland (AKL)',
                valid: '01 May - 30 Nov 2023',
                itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/ANZOD25ATC-Auckland-to-Christchurch-GBP-MAR23.pdf'
            },
          ]
        },
        {
          title : 'Australia + New Zealand',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/deal-long-itinerary-2.jpg',
              name : '34 Day Australian & New Zealand Voyage',
              price : '6059',
              complement : 'per person (twin share)',
              code : 'ANZUK34DOZNZV',
              location : 'Melbourne (MEL)',
              valid: '15 Jan 2023 - 31 Mar 2023',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/34-Day-Australia-and-New-Zealand-Voyage-GBP.pdf',
              call : false
            },
          ]
        },
        */
      ]
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      },
      copyCode(event) {
        if(event){
          let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
          window.getSelection().selectAllChildren(copyCode);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyCode.innerHTML);
          this.copiedText = 'Package code copied to clipboard';
          this.copied = true;
        }
      },
      copyLocation(event) {
        if(event){
          let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
          window.getSelection().selectAllChildren(copyLocation);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyLocation.innerHTML);
          this.copiedText = 'Package start location copied to clipboard';
          this.copied = true;
        }
      }
    },
  };
  </script>
  
  <style>
  .gradient-bar{
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
    background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
  }
  
  .deals-content{
    margin-top: -60px;
  }
  
  .package-data .v-chip{
    color:#9e9e9e;
    margin-left: 0;
  }
  
  .package-data .v-chip .v-chip__content{
    padding-right: 6px;
  }
  
  .deal-thumbnail{
    width: 88px;
    height: 88px;
  }
  </style>
  