import cache from '@/util-modules/cache';
import { make } from 'vuex-pathify';

const state = {
    dialog: false,
    currentWholesaler: null
}

const mutations = { 
    ...make.mutations(state),
    currentWholesaler(state, currentWholesaler) {
        if (currentWholesaler === null) {
            state.currentWholesaler = null;
            cache.deleteCache("_oa_wholesaler");
            return;
        }

        if (window) {
            cache.setCache("_oa_wholesaler", currentWholesaler);
        }

        state.currentWholesaler = cache.getCache("_oa_wholesaler")
    }
}

const getters = {
    ...make.getters(state),
}

const actions = {
    ...make.actions(state),
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}