import { isNullOrUndefined } from '@/util-modules/object';
import { PackageSearchResult } from '@/model/searchpackage';

/**
 * @param {[]} packages
 */
export const sanitizePackages = (packages: PackageSearchResult[]) => {
  if (!packages) {
    return [];
  }
  return packages.filter(
    p =>
      p.packageWholesalerPrices &&
      p.packageWholesalerPrices.length > 0 &&
      !isNullOrUndefined(p.packageWholesalerPrices[0].grossPrice),
  );
};
