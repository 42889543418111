import { post } from '@/util-modules/XMLHttpClient';
import { AnyTypeToFix } from '@/model/AnyType';

export interface AgencyRequest {
  agencyName: string,
  agencyAbnAcn: string,
  agencyAffiliateProgram: string,
  agencyEmail: string,
  agencyPhone: string,
  agencyFax: string,
  agencyStreetAddress: string,
  agencyCitySuburb: string,
  agencyState: string,
  agencyPostcode: string,
  agencyCountry: string,
  accountManagerFirstName: string,
  accountManagerLastName: string,
  accountManagerEmail: string,
  accountManagerConfirmEmail: string,
  agencyPostalAddress: string
}

interface ZapierServiceContract {
  registerNewAgent: Function,
  sendContactUsForm: Function,
  sendBrochureForm: Function
}

const NEW_AGENT_REGISTRATION_DEV_HOOK = 'https://hooks.zapier.com/hooks/catch/1695883/obl85vo/'
const NEW_AGENT_REGISTRATION_HOOK = 'https://hooks.zapier.com/hooks/catch/1695883/objvmib/';
const NEW_AGENT_REGISTRATION_NZ_HOOK = 'https://hooks.zapier.com/hooks/catch/1695883/ohhb9au/';
const NEW_AGENT_REGISTRATION_UK_HOOK = 'https://hooks.zapier.com/hooks/catch/1695883/omnqet9/';

const CONTACT_US_DEV_HOOK = 'https://hooks.zapier.com/hooks/catch/1695883/omnliji/';
const CONTACT_US_HOOK = 'https://hooks.zapier.com/hooks/catch/1695883/obctz6c/';
const CONTACT_US_NZ_HOOK = 'https://hooks.zapier.com/hooks/catch/1695883/ohmde3d/';
const CONTACT_US_UK_HOOK = 'https://hooks.zapier.com/hooks/catch/1695883/omnlys0/';

const BROCHURE_FORM = 'https://hooks.zapier.com/hooks/catch/1695883/oueerfz/';

const _devZapierService: ZapierServiceContract = {
  registerNewAgent: (formData: AgencyRequest) => post(NEW_AGENT_REGISTRATION_DEV_HOOK, formData),
  sendContactUsForm: (formData: AnyTypeToFix) => post(CONTACT_US_DEV_HOOK, formData),
  sendBrochureForm: (formData: AnyTypeToFix) => post(BROCHURE_FORM, formData)
};

const _auZapierService: ZapierServiceContract = {
  registerNewAgent: (formData: AgencyRequest) => post(NEW_AGENT_REGISTRATION_HOOK, formData),
  sendContactUsForm: (formData: AnyTypeToFix) => post(CONTACT_US_HOOK, formData),
  sendBrochureForm: (formData: AnyTypeToFix) => post(BROCHURE_FORM, formData)
};

const _nzZapierService: ZapierServiceContract = {
  registerNewAgent: (formData: AgencyRequest) => post(NEW_AGENT_REGISTRATION_NZ_HOOK, formData),
  sendContactUsForm: (formData: AnyTypeToFix) => post(CONTACT_US_NZ_HOOK, formData),
  sendBrochureForm: (): Promise<Number> => Promise.resolve(0)
};

const _ukZapierService: ZapierServiceContract = {
  registerNewAgent: (formData: AgencyRequest) => post(NEW_AGENT_REGISTRATION_UK_HOOK, formData),
  sendContactUsForm: (formData: AnyTypeToFix) => post(CONTACT_US_UK_HOOK, formData),
  sendBrochureForm: (): Promise<Number> => Promise.resolve(0)
};

export function makeZapierService(region: String): ZapierServiceContract {
  if (process.env.NODE_ENV === 'development') {
    return _devZapierService;
  }
  switch(region) {
    case 'CO.NZ':
      return _nzZapierService;
    case 'COM':
      return _nzZapierService;
    case 'CO.UK':
      return _ukZapierService;
    default:
      return _auZapierService;
  }
}
