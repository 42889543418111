import { render, staticRenderFns } from "./ConfirmDialog.vue?vue&type=template&id=00b39f5a"
import script from "./ConfirmDialog.vue?vue&type=script&lang=js"
export * from "./ConfirmDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardActions } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VDialog } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
installComponents(component, {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VDialog,
  VFlex,
  VIcon,
  VLayout,
  VSpacer,
})
