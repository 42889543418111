<template>
  <v-container pa-0 fluid>

    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2020/03/hero-whitehaven-beach-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">Australia</h1>
                          <h3 class="white--text title mb-4">Big, bold and colourful, Australia is utterly unique.</h3>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Whitehaven Beach, Whitsundays Islands, QLD - Credit: Tourism Whitsundays</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex>
        <v-card flat tile class="pb-5">
          <v-container grid-list-xl fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 pt-0 mb-4 mt-3>
                <v-card class="pa-0 elevation-0 dash-rounded">
                  <v-card-text>
                    <span
                      class="subheading d-block mb-3"
                    >From sensational coastlines and countryside, to diverse nature and wildlife, Australia offers some of the most memorable experiences on earth.</span>
                    <span
                      class="subheading d-block mb-3"
                    >A place where vibrant, cosmopolitan cities fringe pristine beaches and harbours, and cultural attractions reflect the diversity of our population, visitors are welcomed to learn about Indigenous traditions and celebrate the Australian way of life.</span>
                    <span
                      class="subheading d-block mb-4"
                    >From the outback to the coast, to the icons and the undiscovered, each destination has its own charm and character.</span>
                    <v-divider class="my-4"></v-divider>
                    <h4 class="title mb-3">Australian Destinations</h4>
                    <v-layout row wrap>
                      <template v-for="(destination, index) in destinationsAustralia">
                        <v-flex xs12 sm6 lg4 :key="index" v-if="!destination.hidden">
                          <v-card class="elevation-0 v-card--btn" :to="`/destinations/australia${destination.path}`" :color="lightGrey">
                            <v-img aspect-ratio="1.5" :src="destination.image" cover gradient="to top, rgba(0,0,0,0.7), rgba(0,0,0,0) 35%">
                              <v-layout column fill-height class="lightbox white--text" ma-0>
                                <v-spacer></v-spacer>
                                <v-flex shrink>
                                  <div class="subheading d-block font-weight-medium">{{destination.name}} ({{destination.area}})</div>
                                  <!--<div class="body-1 text-truncate d-block" >{{destination.area}}</div>-->
                                </v-flex>
                              </v-layout>
                            </v-img>
                          </v-card>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    destinationsAustralia: [
      {
        name: 'Australian Capital Territory',
        area: 'ACT',
        path: '/australian-capital-territory/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-thumbnail-act.jpg',
        hidden: false,
      },
      {
        name: 'New South Wales',
        area: 'NSW',
        path: '/new-south-wales/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-thumbnail-nsw.jpg',
        hidden: false,
      },
      {
        name: 'Northern Territory',
        area: 'NT',
        path: '/northern-territory/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-thumbnail-nt.jpg',
        hidden: false,
      },
      {
        name: 'Queensland',
        area: 'QLD',
        path: '/queensland/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-thumbnail-qld.jpg',
        hidden: false,
      },
      {
        name: 'South Australia',
        area: 'SA',
        path: '/south-australia/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/08/destination-thumbnail-sa.jpg',
        hidden: false,
      },
      {
        name: 'Tasmania',
        area: 'TAS',
        path: '/tasmania/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-thumbnail-tas.jpg',
        hidden: false,
      },
      {
        name: 'Victoria',
        area: 'VIC',
        path: '/victoria/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-thumbnail-vic.jpg',
        hidden: false,
      },
      {
        name: 'Western Australia',
        area: 'WA',
        path: '/western-australia/',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-thumbnail-wa.jpg',
        hidden: false,
      },
    ],
  }),
  mounted: function() {
    document.documentElement.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
</style>
