export const initialState = () => ({
  results: [],
  page: 1,
  duration: undefined,
  idSearch: '',
  sortBy: '',
  lastSearch: '',
  // avoid race conditions
  nextRequestId: 1,
  lastestRequestId: 1,

  accomodationMerged: false,

  searchParms: null,

  parmsAccommodation: null,
  parmsCarscampers: null,
  parmsPackages: null,
  parmsSkiing: null,
  parmsToursActivities: null,
  parmsTraveltransfer: null,

  activeSearchTab: null,
  showSubItemsDrawer: false,
  itemShown: null,
  subItemShown: null,
  subItemDetailShown: null,
  subItemSupplierCurrency: null,
});
