import axios from "axios";
import { ResourceSearch, ResourceSearchResponse } from "@/components/dashboard/resources/model/resource";
import { Post, PostSearch, WpPostsResponse, PostsResponse, mapToPost } from "@/components/public/blog/model/post";
import { Category } from "@/components/public/blog/model/category";

const CMS_ENDPOINT = "https://cdn.anzcro.com.au/wp-json/wp";
const instanceAxios = axios.create({ baseURL: CMS_ENDPOINT });

export const cmsService = {
  getResources: (params: ResourceSearch): Promise<ResourceSearchResponse> =>
    instanceAxios.get("/v2/resources", {
      params: {
        ...params,
        per_page: 60,
      },
    }),
  getPostsWithMeta: async (params: PostSearch = {}): Promise<PostsResponse> => {
    const result: WpPostsResponse = await instanceAxios.get("/v2/posts", {
      params: Object.assign(
        {
          _embed: "",
          order: "desc",
          orderby: "date",
          per_page: 30,
        },
        params,
      ),
    });
    return {
      data: result.data.map(mapToPost),
      meta: {
        totalPosts: Number.parseInt(result.headers["x-wp-total"]),
        totalPostsPages: Number.parseInt(result.headers["x-wp-totalpages"]),
        page: params.page || 1,
        perPage: params.per_page || 10,
      },
    };
  },
  getCategories: async (params: object = {}): Promise<Category[]> => {
    const result = await instanceAxios.get("/v2/categories", { params: params });
    return result.data.map(x => <Category>x);
  },
};
