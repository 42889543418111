import Vue from 'vue';
import CoalBlackBtn from './components/CoalBlackBtn.vue';
import MapLocation from './components/MapLocation.vue';
import ConfirmDialog from './components/ConfirmDialog.vue';
import ProgressCircular from './components/ProgressCircular.vue';
import TextFieldUpperCase from './components/TextFieldUpperCase.vue';

Vue.component('map-location', MapLocation);
Vue.component('coal-black-btn', CoalBlackBtn);
Vue.component('confirm-dialog', ConfirmDialog);
Vue.component('progress-circular', ProgressCircular);
Vue.component('text-field-upper-case', TextFieldUpperCase);
