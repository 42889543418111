import { render, staticRenderFns } from "./DestinationIndexAustralia.vue?vue&type=template&id=5c2a6725"
import script from "./DestinationIndexAustralia.vue?vue&type=script&lang=js"
export * from "./DestinationIndexAustralia.vue?vue&type=script&lang=js"
import style0 from "./DestinationIndexAustralia.vue?vue&type=style&index=0&id=5c2a6725&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib'
installComponents(component, {
  VCard,
})
