<template>
  <v-text-field v-bind="$attrs" v-on="$listeners" v-model="textUp"></v-text-field>
</template>
<script>
export default {
  inheritAttrs: false,
  props: ['value'],
  data() {
    return {
      text: (this.value || '').toUpperCase(),
    };
  },
  computed: {
    textUp: {
      get() {
        return this.text;
      },
      set(text) {
        this.text = (text || '').toUpperCase();
      },
    },
  },
  watch: {
    text: {
      handler: function() {
        this.$emit('input', this.text);
      },
      immediate: true,
    },
  },
};
</script>

<style>
</style>
