<template>
  <iframe
    :src="location"
    width="100%"
    height="350"
    frameborder="0"
    style="border:0"
    allowfullscreen
  ></iframe>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    location() {
      return (
        "https://www.google.com/maps/embed/v1/place?key=AIzaSyCEjVVN8BYu3H6T1bhFvP6a7xVeHMsJoEY&q=" +
        encodeURIComponent(this.name)
      );
    }
  }
};
</script>

<style>
</style>
