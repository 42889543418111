<template>
  <v-container pa-0 fluid>
    <!-- Hero Section -->
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-hero-kaikoura.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">{{current}}</h1>
                          <h3 class="white--text title mb-4">{{currentArea}}</h3>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Fur Seals, Kaikoura - Credit: Kyle Mulinder</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- Main -->
    <v-layout row>
      <v-flex>
        <v-card flat tile class="pb-5">
          <v-container grid-list-xl class="destination-main">
            <v-layout row wrap mt-2>
              <!-- In Page Navigation -->
              <v-flex xs12>
                <v-toolbar
                  flat
                  dense
                  tabs
                  :color="lightGrey"
                  class="card-with-border dash-rounded hidden-xs-only content-nav-toolbar"
                >
                  <v-card flat height="48">
                    <v-icon size="16" color="grey" class="mx-3 my-3">arrow_downward</v-icon>
                  </v-card>
                  <v-divider vertical></v-divider>
                  <v-toolbar-items>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#introduction', scrollToOptions )"
                      class="ma-0 hidden-sm-and-down"
                    >Intro</v-btn>
                    <v-divider vertical class="hidden-sm-and-down"></v-divider>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#gallery', scrollToOptions )"
                      class="ma-0"
                    >Gallery</v-btn>
                    <v-divider vertical></v-divider>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#the-best-of', scrollToOptions )"
                      class="ma-0"
                    >The Best of</v-btn>
                    <v-divider vertical></v-divider>
                    <v-btn flat @click="$vuetify.goTo('#tips', scrollToOptions )" class="ma-0">
                      <span class="hidden-sm-and-down">Travel Agent&nbsp;</span>Tips
                    </v-btn>
                    <v-divider vertical></v-divider>
                    <v-btn flat @click="$vuetify.goTo('#climate', scrollToOptions )" class="ma-0">
                      Climate
                      <span class="hidden-sm-and-down">/Weather</span>
                    </v-btn>
                    <v-divider vertical></v-divider>
                    <v-btn flat @click="$vuetify.goTo('#map', scrollToOptions )" class="ma-0">Map</v-btn>
                  </v-toolbar-items>
                  <v-divider vertical></v-divider>
                  <v-card flat height="48" width="100%">&nbsp;</v-card>
                </v-toolbar>
                <v-card
                  flat
                  :color="lightGrey"
                  class="card-with-border v-card--btn hidden-sm-and-up content-nav-card"
                >
                  <v-layout row wrap ma-0>
                    <v-flex xs6 pa-0>
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#introduction', scrollToOptions )"
                        class="ma-0"
                      >Intro</v-btn>
                    </v-flex>
                    <v-flex xs6 pa-0 class="border-l">
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#gallery', scrollToOptions )"
                        class="ma-0"
                      >Gallery</v-btn>
                    </v-flex>
                    <v-flex xs12 pa-0>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs6 pa-0>
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#the-best-of', scrollToOptions )"
                        class="ma-0"
                      >The Best of</v-btn>
                    </v-flex>
                    <v-flex xs6 pa-0 class="border-l">
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#tips', scrollToOptions )"
                        class="ma-0"
                      >Tips</v-btn>
                    </v-flex>
                    <v-flex xs12 pa-0>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs6 pa-0>
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#climate', scrollToOptions )"
                        class="ma-0"
                      >Climate</v-btn>
                    </v-flex>
                    <v-flex xs6 pa-0 class="border-l">
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#map', scrollToOptions )"
                        class="ma-0"
                      >Map</v-btn>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <!-- Content -->
              <v-flex xs12 md9 lg7>
                <v-layout row wrap>
                  <!-- Destination Content -->
                  <v-flex xs12 id="content-wrapper">
                    <!-- Introduction -->
                    <v-layout row id="introduction" mt-3>
                      <v-flex>
                        <h4
                          class="title mb-3"
                        >Within the Kaikōura district, your clients will find some of the most breath-taking scenery in the country – a stunning coastline, a dramatic peninsula and some of the South Island’s highest mountains close to the ocean.</h4>
                        <p
                          class="subheading"
                        >A unique ocean environment provides a home to an astonishing variety of marine life. Kaikōura is New Zealand's leading eco-tourism destination with marine-based operators, cultural experiences and historic attractions, alongside both relaxing and exhilarating land-based activities.</p>
                      </v-flex>
                    </v-layout>

                    <!-- Gallery -->
                    <v-layout row wrap id="gallery" mt-3>
                      <v-flex xs12 pb-1>
                        <h4 class="title">Gallery</h4>
                      </v-flex>
                      <v-flex xs12>
                        <v-card flat dark class="mb-3">
                          <v-carousel
                            height="auto"
                            class="elevation-0 destination-gallery"
                            hide-delimiters
                          >
                            <v-carousel-item v-for="(item, index) in galleryItems" :key="index">
                              <v-img
                                :src="item.image"
                                gradient="to bottom, rgba(22,22,22,.35), rgba(22,22,22,0.1) 15%, rgba(22,22,22,0.1) 85%, rgba(22,22,22,0.65)"
                                aspect-ratio="1.5"
                              >
                                <v-layout column fill-height ma-0>
                                  <v-flex text-xs-right>
                                    <b>{{index + 1}}</b>
                                    / {{galleryItems.length}}
                                  </v-flex>
                                  <v-flex shrink><small class="d-block">{{item.caption}}</small></v-flex>
                                </v-layout>
                              </v-img>
                            </v-carousel-item>
                          </v-carousel>
                        </v-card>
                      </v-flex>
                    </v-layout>

                    <!-- The Best of -->
                    <v-layout row id="the-best-of" mt-3>
                      <v-flex>
                        <h4 class="title mb-3">The best of {{current}}</h4>
                        <ul class="subheading mb-3">
                          <li>
                            <b>Spot marine wildlife</b> – Whales, fur seals and dolphins live permanently in these deep coastal waters. Your clients can go whale watching all year round, swim alongside (or just watch) dusky dolphins or snorkel with playful New Zealand fur seals. Bird watching tours are great for spotting many seabird species, including the albatross.
                          </li>
                          <li>
                            <b>Take a scenic flight</b> – Suggest your clients take a spectacular scenic flight by helicopter or fixed wing aircraft to view whales, dolphins, the Kaikōura coastline and mountains.
                          </li>
                          <li>
                            <b>Sea kayaking</b> – Paddle around the Kaikōura Peninsular with experienced guides. The eco-friendly tours offer encounters with seals and the possibility to spot dolphins.
                          </li>
                          <li>
                            <b>Sample kai moana (seafood)</b> –  Kaikōura is also a great place to eat fresh seafood (in the Māori language "kai" means food, "kōura" means crayfish). Your clients can tuck into crayfish and other delicacies at one of the many restaurants and roadside food trucks.
                          </li>
                        </ul>
                      </v-flex>
                    </v-layout>

                    <!-- Travel Agent Tips -->
                    <v-layout row id="tips" mt-3>
                      <v-flex>
                        <v-card
                          class="elevation-0 pa-3 pa-sm4 card-with-border mb-3"
                          :color="offWhite"
                        >
                          <h4 class="title mb-3 mt-3">Travel Agent tips</h4>
                          <ul class="subheading mb-3">
                            <li>If your clients aren't seafaring types, there are many adventures to be had on dry land. Suggest they go on a wilderness 4WD ride, hike the Kaikoura Peninsula Walkway or visit a lavender farm. </li>
                          </ul>
                        </v-card>
                      </v-flex>
                    </v-layout>

                    <!-- Climate & Weather -->
                    <v-layout row wrap id="climate" mt-3>
                      <v-flex xs12>
                        <h4 class="title mb-3">Climate & Weather</h4>
                      </v-flex>
                      <v-flex xs12 sm6>
                        <v-layout row align-center>
                          <v-flex shrink>
                            <v-avatar size="120" class="summer" color="#ffc538">
                              <v-layout column>
                                <v-icon size="36">wb_sunny</v-icon>
                                <span class="d-block subheading font-weight-medium">December</span>
                                <span class="d-block body-1">Summer</span>
                              </v-layout>
                            </v-avatar>
                          </v-flex>
                          <v-flex>
                            <v-layout column text-xs-center>
                              <span class="d-block body-1 summer--text">Average High</span>
                              <span class="d-block subheading font-weight-medium mb-1">
                                19.1 °C
                                <span class="grey--text">/</span> 66.4 °F
                              </span>
                              <span class="d-block body-1 winter--text mt-1">Average Low</span>
                              <span class="d-block subheading font-weight-medium">
                                11.7 °C
                                <span class="grey--text">/</span> 53.1 °F
                              </span>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 sm6>
                        <v-layout row align-center>
                          <v-flex shrink>
                            <v-avatar size="120" class="winter" color="#bed9e7">
                              <v-layout column>
                                <v-icon size="36">ac_unit</v-icon>
                                <span class="d-block subheading font-weight-medium">June</span>
                                <span class="d-block body-1">Winter</span>
                              </v-layout>
                            </v-avatar>
                          </v-flex>
                          <v-flex>
                            <v-layout column text-xs-center>
                              <span class="d-block body-1 summer--text">Average High</span>
                              <span class="d-block subheading font-weight-medium mb-1">
                                11.6 °C
                                <span class="grey--text">/</span> 52.9 °F
                              </span>
                              <span class="d-block body-1 winter--text">Average Low</span>
                              <span class="d-block subheading font-weight-medium">
                                58 °C
                                <span class="grey--text">/</span> 42.4 °F
                              </span>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>

                    <!-- Map -->
                    <v-layout wrap row id="map" mt-3>
                      <v-flex xs12 pb-1>
                        <h4 class="title">Map</h4>
                      </v-flex>
                      <v-flex xs12>
                        <v-card flat class="v-card--btn">
                          <v-img
                            src="https://cdn.anzcro.com.au/wp-content/uploads/2019/12/map-thumbnail-kaikoura.jpg"
                            height="240"
                          >
                            <v-layout column fill-height ma-0 align-end>
                              <v-flex>&nbsp;</v-flex>
                              <v-flex shrink>
                                <v-btn
                                  :color="lightGrey"
                                  @click="destinationMapDialog=true;"
                                >Expand</v-btn>
                              </v-flex>
                            </v-layout>
                          </v-img>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>

              <!-- Other Destinations -->
              <v-flex xs12 md3 lg4 offset-lg1 id="other" mt-4>
                <h4 class="title mb-4 grey--text">Other Destinations</h4>
                <DestinationIndex :currentDestination="current" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- Destination Map Dialog -->
    <v-dialog v-model="destinationMapDialog" max-width="900px">
      <v-card class="pa-0">
        <v-layout row pa-3 align-center>
          <v-flex>
            <span class="d-block headline font-weight-bold">Map</span>
          </v-flex>
          <v-flex shrink>
            <v-btn
              :color="lightGrey"
              small
              fab
              class="elevation-0 ma-0 card-with-border"
              @click="destinationMapDialog=false;"
            >
              <v-icon size="24">close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-card-text class="pa-3">
          <v-responsive :aspect-ratio="800/600">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d635917.9342971453!2d173.07747146835524!3d-42.23463360761927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d3a21e62a91c943%3A0x400ef8684795660!2sKaikoura%20District%2C%20Canterbury%2C%20New%20Zealand!5e0!3m2!1sen!2sau!4v1575601000145!5m2!1sen!2sau" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
          </v-responsive>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import DestinationIndex from '@/components/public/destinations/components/DestinationIndex.vue';
export default {
  data: () => ({
    current: 'Kaikōura',
    currentArea: 'South Island, New Zealand',
    galleryItems: [
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-gallery-kaikoura-001.jpg',
        caption: 'Crayfish Food Truck, Kaikoura - Credit: Graeme Murray',
      },
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-gallery-kaikoura-002.jpg',
        caption: 'Kaikoura - Credit: Sara Orme',
      },
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2019/12/destination-gallery-kaikoura-003.jpg',
        caption: 'Goose Bay, Kaikoura - Credit: Graeme Murray',
      },
    ],
    destinationMapDialog: false,
    scrollToOptions: {
      easing: 'easeInOutCubic',
      duration: 300,
      offset: 50,
    },
  }),
  components: {
    DestinationIndex,
  },
  mounted: function() {
    document.documentElement.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
.container.destination-main {
  padding: 24px;
}

.content-nav-toolbar .v-toolbar__content {
  padding: 0 !important;
}

.content-nav-card .flex.border-l {
  border-left: solid 1px rgba(0, 0, 0, 0.12) !important;
}

#content-wrapper .title {
  line-height: 1.3 !important;
}

#content-wrapper li {
  margin-bottom: 16px;
}

#content-wrapper li:last-child {
  margin-bottom: 0;
}

.v-avatar.summer {
  border: solid 4px #ffaa00 !important;
}
.v-avatar.winter {
  border: solid 4px #adc9d7 !important;
}

@media (min-width: 600px) {
  .container.destination-main {
    padding: 24px 32px;
  }
}

@media (min-width: 960px) {
  .container.destination-main {
    padding: 24px;
  }
}
</style>
