import axios from 'axios';
import { HandledError } from '@/error/model/handled-error';
import errorSubject from '@/observers/subjects/error.subject';

axios.defaults.baseURL = '/api';
axios.defaults.withCredentials = true;

let appServiceOptions = {
  publishCustomsError: (type, payload) => {
    // eslint-disable-next-line no-console
    console.error(type, payload);
  },
  publishError: error => {
    // eslint-disable-next-line no-console
    console.error(error);
  },
  after401Error: () => {},
  publishMessages: msg => {
    // eslint-disable-next-line no-console
    console.error(msg);
  },
  publishTermsOfUse: () => {
    // eslint-disable-next-line no-console
    console.error('User has to agree to terms of use.');
  },
};

const publishAndReject = error => {
  const hasMessage = !!error.message;
  if (hasMessage) {
    appServiceOptions.publishError(error.message);
  } else if (process.env.NODE_ENV === 'production') {
    appServiceOptions.publishError('Unexpected Error. Please contact our Reservation Department.');
  } else {
    appServiceOptions.publishError(error.request || 'An unexpected error has occurred');
  }
  return Promise.reject(error);
};

const sessionInterceptor = config => {
  if (config.url === '/session') {
    config.url = '/web' + config.url;
  } else {
    config.url = '/pub' + config.url;
  }
  return config;
};

const _handleBlobErrorResponse = result => {
  const json = JSON.parse(result);
  if (json && json.message) {
    appServiceOptions.publishError(json.message);
  } else if (json && json.errorMessage) {
    appServiceOptions.publishError(json.errorMessage);
  } else {
    appServiceOptions.publishError('Unexpected Error. Please contact our Reservation Department.');
  }
};

const handleErrorInterceptor = (publishError = true) => error => {
  trackAnalytics(error);
  if (error.response) {
    switch (error.response.status) {
      case 401:
        if (publishError) {
          appServiceOptions.after401Error();
        }
        break;
      case 400: {
        const data = error.response.data;
        if (data && data.validationMessages && data.validationMessages.length > 0) {
          const message = data.validationMessages.map(({ description }) => description).join(',');
          return publishOrReject(publishError, message);
        } else if (data && data.errorMessage) {
          return publishOrReject(publishError, data.errorMessage);
        } else if (data && data.message) {
          return publishOrReject(publishError, data.message);
        }
        break;
      }
      case 403: {
        const data = error.response.data;
        if (data && data.errorCode && data.errorCode === 'TOU_NOT_ACCEPTED') {
          appServiceOptions.publishTermsOfUse();
          return Promise.reject(data.errorMessage);
        } else if (data && data.errorCode && data.errorCode === 'DEPOSIT_NOT_PAID') {
          // appServiceOptions.publishCustomsError(data.errorCode, data);
          return Promise.reject(data);
        } else if (data && data.errorMessage) {
          return publishOrReject(publishError, data.errorMessage);
        } else if (data && data.message) {
          return publishOrReject(publishError, data.message);
        } else {
          return publishOrReject(publishError, 'Access Denied');
        }
      }
      case 404: {
        const data = error.response.data;
        if (data && data.errorCode && data.errorCode === 'LOCK_VIOLATION') {
          return publishOrReject(publishError, data.errorMessage);
        }

        return publishOrReject(publishError, 'Request failed with status code 404');
      }
      case 423: {
        const data = error.response.data;
        if (data && data instanceof Blob) {
          data.text().then(_handleBlobErrorResponse);
        }
        return Promise.reject(error);
      }
      case 500: {
        const data = error.response.data;
        if (data && data.errorMessage) {
          return publishOrReject(publishError, data.errorMessage);
        } else if (data && data.message) {
          return publishOrReject(publishError, data.message);
        } else if (data && data instanceof Blob) {
          data.text().then(_handleBlobErrorResponse);
          return Promise.reject(error);
        }
        break;
      }
      default:
        break;
    }
  }
  return publishAndReject(error);
};

const publishOrReject = (publishError, message) => {
  if (publishError) {
    return publishAndReject(new HandledError(message));
  } else {
    return Promise.reject(new Error(message));
  }
};

const axiosWithoutErrorHandler = () => {
  const instance = axios.create({
    baseURL: '/api',
    withCredentials: true,
  });
  instance.interceptors.request.use(sessionInterceptor);
  instance.interceptors.response.use(function(response) {
    if (hasInfoMessages(response)) {
      appServiceOptions.publishMessages(processInfoMessages(response.data.infoMessages));
    }
    return response;
  }, handleErrorInterceptor(false));

  return instance;
};

axios.interceptors.request.use(sessionInterceptor);
axios.interceptors.response.use(function(response) {
  if (hasInfoMessages(response)) {
    appServiceOptions.publishMessages(processInfoMessages(response.data.infoMessages));
  }
  return response;
}, handleErrorInterceptor(true));

const appService = {
  /**
   *
   * @param {*} endpoint
   * @param {*} formData
   * @param {*} withOutErrorHandler, all errors will handle manualy
   */
  async get(endpoint, formData = {}, withOutErrorHandler = false) {
    const axiosInstance = withOutErrorHandler ? axiosWithoutErrorHandler() : axios;

    const response = await axiosInstance.get(endpoint, { params: formData });
    return response.data;
  },

  async post(endpoint, formData, withOutErrorHandler = false) {
    const headers = getXsrfToken();
    const axiosInstance = withOutErrorHandler ? axiosWithoutErrorHandler() : axios;
    const response = await axiosInstance.post(endpoint, formData, headers);
    return response.data;
  },

  async put(endpoint, formData, withOutErrorHandler = false) {
    const headers = getXsrfToken();
    const axiosInstance = withOutErrorHandler ? axiosWithoutErrorHandler() : axios;
    const response = await axiosInstance.put(endpoint, formData, headers);
    return response.data;
  },

  async delete(endpoint, formData = {}) {
    const headers = getXsrfToken();
    const response = await axios.delete(endpoint, { data: formData }, headers);
    return response.data;
  },

  async getBlob(endpoint, formData = {}, withOutErrorHandler = false) {
    const axiosInstance = withOutErrorHandler ? axiosWithoutErrorHandler() : axios;
    const response = await axiosInstance.get(endpoint, { params: formData, responseType: 'blob' });
    return response.data;
  },
};

export const configureAppService = ({
  publishError,
  after401Error,
  publishMessages,
  publishTermsOfUse,
  publishCustomsError,
}) => {
  Object.assign(appServiceOptions, {
    publishError,
    after401Error,
    publishMessages,
    publishTermsOfUse,
    publishCustomsError,
  });
};

function trackAnalytics(error) {
  if (error.response) {
    errorSubject.next({
      event: 'error',
      eventCategory: 'api_error',
      error_message: error.message,
      response_data: JSON.stringify(error.response.data),
      response_status: error.response.status,
      // response_headers: JSON.stringify(error.response.headers),
      error_config: JSON.stringify(error.config),
      error_config_url: error.config ? error.config.url : '',
      error_config_method: error.config ? error.config.method : '',
    });
  } else if (error.request) {
    errorSubject.next({
      event: 'error',
      eventCategory: 'api_error',
      request: JSON.stringify(error.request),
      error_config: JSON.stringify(error.config),
      error_config_url: error.config ? error.config.url : '',
      error_config_method: error.config ? error.config.method : '',
    });
  } else {
    errorSubject.next({
      event: 'error',
      eventCategory: 'api_error',
      error_message: error.message,
      error_config: JSON.stringify(error.config),
      error_config_url: error.config ? error.config.url : '',
      error_config_method: error.config ? error.config.method : '',
    });
  }
}

function hasInfoMessages(response) {
  return response.data && response.data.infoMessages && response.data.infoMessages.length > 0;
}

/**
 *
 * @param {[]} values
 */
export function processInfoMessages(values) {
  return (values || []).filter(({ id }) => id !== 'BOOKLINE_PRICE_CHANGE').map(({ description }) => description);
}

function getXsrfToken() {
  const xsrfToken = getCookie('XSRF-TOKEN');
  const headers = {
    'X-XSRF-TOKEN': xsrfToken,
  };
  return xsrfToken ? headers : undefined;
}

function getCookie(cname) {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export default appService;
