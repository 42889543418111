<template>
    <v-container pa-0 fluid>
        <v-layout row>
            <v-flex>
                <v-card flat tile dark class="hero-home-slider">
                    <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
                        <v-carousel-item>
                            <v-img
                                src="https://cdn.anzcro.com.au/wp-content/uploads/2024/07/hero-win-your-way-wa-scaled.jpg"
                                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.25) 15%, rgba(22,22,22,0.05) 65%"
                                class="home-hero">
                                <v-container fill-height>
                                    <v-layout dark column fill-height justify-space-between pt-5>
                                        <v-flex text-xs-center class="home-hero-cta">
                                            <v-layout row align-center fill-height class="fill-height-fix-ios">
                                                <v-flex>
                                                    <img src="https://cdn.anzcro.com.au/wp-content/uploads/2024/07/win-your-way-wa-stacked.png"
                                                        alt="Win your way to WA" class="title-graphic" width="480"
                                                        height="480" />
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <div class="hero-caption hidden-xs-only">
                                    <small>The Pinnacles, Nambung National Park</small>
                                </div>
                            </v-img>
                        </v-carousel-item>
                    </v-carousel>
                </v-card>
            </v-flex>
        </v-layout>
        <div class="gradient-bar"></div>

        <v-layout row>
            <v-flex mb-2>
                <v-container grid-list-lg fill-height>
                    <v-layout row wrap fill-height>
                        <v-flex xs12 py-0>
                            <v-card tile class="pa-0 elevation-0 deals-content">
                                <v-card-text class="pa-5">
                                    <v-layout row wrap align-center>
                                        <v-flex xs12 md8 py-0 my-2>
                                            <h2>Step into a dream in Western Australia.</h2>
                                            <p class="subheading my-0">An ancient land of otherworldly and dreamlike
                                                adventures.</p>
                                        </v-flex>
                                        <v-flex xs12 md4 py-0 my-0 text-xs-center>
                                            <v-btn block large class="ml-0 x-large elevation-0" dark :color="kiwiGreen"
                                                :href="form_url" target="_blank">Register Your Interest</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-card-text>
                            </v-card>
                            <v-card tile class="pa-0 elevation-0 mt-3">
                                <v-card-text class="pa-5">
                                    <v-layout row wrap align-center>
                                        <v-flex xs12 py-0 my-0>
                                            <h3>Experience otherworldly landscapes, four UNESCO-listed world heritage
                                                locations and boundless natural adventures. See bubble gum pink lakes,
                                                horizontal waterfalls, tall timber forests and the world’s oldest and
                                                largest outdoor art galleries.</h3>
                                            <p class="subheading mt-3">Western Australia is also closer than you think.
                                                Perth is the shortest flight from the UK and the only city with a direct
                                                flight. Qantas’ nonstop flights leave London at lunchtime, whizzing you
                                                overnight to Australia’s sunniest capital city, where you can dip your
                                                toes in the Indian Ocean by mid-afternoon.</p>
                                            <p class="subheading mt-3">This November, ANZCRO, in partnership with
                                                Tourism WA invites you to step into a dream in Western Australia. This
                                                incredible Fam opportunity invites two groups of UK Travel Agents to
                                                step into a dream and experience Western Australia for themselves. From
                                                the vivid and varied landscapes of Monkey Mia and the Coral Coast in the
                                                north to epic surf beaches and top-notch wines of the Margaret River
                                                Region in the South, this unique opportunity is available to eight
                                                UK-based Travel Agents.
                                            </p>
                                        </v-flex>
                                    </v-layout>
                                </v-card-text>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-flex>
        </v-layout>

        <v-layout row>
            <v-flex mb-3>
                <v-card flat color="#f4f4f4">
                    <v-container>
                        <v-layout row wrap>
                            <v-flex xs12>
                                <v-layout row wrap>
                                    <v-flex xs12 pb-3>
                                        <h3 class="home-section-title green-on-white text-xs-center mt-2 mb-0">
                                            <span>Flight Details</span>
                                        </h3>
                                        <span class="d-block subheading text-xs-center mt-1 mb-4">Famil includes return
                                            economy Qantas flights.</span>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap>
                                    <v-flex xs12 md6>
                                        <v-card tile dark :color="kiwiGreen" class="pa-0 mx-1 my-0 elevation-0">
                                            <v-card-text class="px-3 py-2">
                                                <span class="subheading font-weight-medium d-block">UK to
                                                    Australia</span>
                                            </v-card-text>
                                        </v-card>
                                        <v-card flat class="pa-0 mx-1 mb-4 pl-5" color="#fff">
                                            <v-responsive>
                                                <v-timeline align-center dense pt-0>
                                                    <v-timeline-item :color="kiwiGreen" small>
                                                        <v-layout row pt-1 align-center>
                                                            <v-flex xs6>
                                                                <span class="d-block">Depart</span>
                                                                <strong class="title">LHR</strong>
                                                            </v-flex>
                                                            <v-flex>
                                                                <span class="d-block">Sat 09 Nov 2024</span>
                                                                <strong class="title">11.50am</strong>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-timeline-item>
                                                    <v-timeline-item :color="kiwiGreen" small>
                                                        <v-layout row pt-1 align-center>
                                                            <v-flex xs6>
                                                                <span class="d-block">Arrive</span>
                                                                <strong class="title">PER</strong>
                                                            </v-flex>
                                                            <v-flex><span class="d-block">Sun 10 Nov 2024</span>
                                                                <strong class="title">12.25pm</strong>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-timeline-item>
                                                </v-timeline>
                                            </v-responsive>
                                        </v-card>
                                    </v-flex>
                                    <v-flex xs12 md6>
                                        <v-card tile dark :color="kiwiGreen" class="pa-0 mx-1 my-0 elevation-0">
                                            <v-card-text class="px-3 py-2">
                                                <span class="subheading font-weight-medium d-block">Australia to
                                                    UK</span>
                                            </v-card-text>
                                        </v-card>
                                        <v-card flat class="pa-0 mx-1 mb-4 pl-5" color="#fff">
                                            <v-responsive>
                                                <v-timeline align-center dense pt-0>
                                                    <v-timeline-item :color="kiwiGreen" small>
                                                        <v-layout row pt-1 align-center>
                                                            <v-flex xs6>
                                                                <span class="d-block">Depart</span>
                                                                <strong class="title">PER</strong>
                                                            </v-flex>
                                                            <v-flex>
                                                                <span class="d-block">Sun 17 Nov 2024</span>
                                                                <strong class="title">07.20pm</strong>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-timeline-item>
                                                    <v-timeline-item :color="kiwiGreen" small>
                                                        <v-layout row pt-1 align-center>
                                                            <v-flex xs6>
                                                                <span class="d-block">Arrive</span>
                                                                <strong class="title">LHR</strong>
                                                            </v-flex>
                                                            <v-flex><span class="d-block">Mon 18 Nov 2024</span>
                                                                <strong class="title">05.05am</strong>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-timeline-item>
                                                </v-timeline>
                                            </v-responsive>
                                        </v-card>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
            </v-flex>
        </v-layout>

        <v-layout row>
            <v-flex mb-3>
                <v-container grid-list-lg fill-height>
                    <v-layout row wrap fill-height>
                        <v-flex xs12 py-0 my-0>
                            <v-card tile class="pa-0 elevation-0">
                                <v-card-text class="pa-5">
                                    <v-layout row wrap align-center>
                                        <v-flex xs12 py-0 my-0>
                                            <v-carousel hide-delimiters height="auto" class="elevation-0">
                                                <v-carousel-item v-for="(item, index) in gallery_items" :key="index">
                                                    <v-img :src="item.src">
                                                        <v-container fill-height>
                                                            <v-layout dark fill-height justify-end align-end>
                                                                <v-flex text-xs-center text-sm-right><span
                                                                        class="d-inline-block py-1 px-2 black white--text caption">{{
                                                item.caption }}</span></v-flex>
                                                            </v-layout>
                                                        </v-container>
                                                    </v-img>
                                                </v-carousel-item>
                                            </v-carousel>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row wrap align-center>
                                        <v-flex xs12>
                                            <v-expansion-panel class="elevation-0 mt-3">
                                                <v-expansion-panel-content>
                                                    <template v-slot:header>
                                                        <div>
                                                            <h2>Itinerary – Group 1</h2>
                                                            <span class="kiwiGreen">Margaret River Region</span>
                                                        </div>
                                                    </template>
                                                    <v-card class="elevation-0 pa-4" color="#fafafa">
                                                        <v-layout row wrap>
                                                            <v-flex xs12 sm6 md6>
                                                                <p class="mb-0"><strong>Day 1 – Arrival Day</strong></p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0 pl-1">Stay: Perth Hotel</p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0"><strong>Day 2 – Perth to
                                                                        Pemberton</strong></p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0 pl-1">Stay: Karri Valley</p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0"><strong>Day 3 – Pemberton to Margaret
                                                                        River</strong></p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0 pl-1">Stay: Margaret River Township</p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0"><strong>Day 4 – Active Margaret
                                                                        River</strong></p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0 pl-1">Please note on this day, there will
                                                                    be a walking section of the Cape-to-Cape track. A
                                                                    medium to intermediate level of fitness is required.
                                                                </p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0 pl-1">Stay: Margaret River Township</p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0"><strong>Day 5 – Tasting Margaret
                                                                        River</strong></p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0 pl-1">Stay: North side of Margaret River
                                                                </p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0"><strong>Day 6 – Margaret River to
                                                                        Fremantle</strong></p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0 pl-1">Stay: Fremantle</p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0"><strong>Day 7 – Rottnest Island (Groups
                                                                        1 & 2)</strong></p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0 pl-1">Stay: Fremantle</p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0"><strong>Day 8 – Breakfast and Departure
                                                                        to Perth Airport</strong></p>
                                                            </v-flex>
                                                            <v-flex xs12 sm6 md5 offset-md1>
                                                                <img width="1080" height="1350" class="max-width-100"
                                                                    src="https://cdn.anzcro.com.au/wp-content/uploads/2024/07/itinerary-map_western_australia_famil-margaret-river.png" />
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-card>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-divider class=""></v-divider>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-expansion-panel class="elevation-0">
                                                <v-expansion-panel-content>
                                                    <template v-slot:header>
                                                        <div>
                                                            <h2>Itinerary – Group 2</h2>
                                                            <span class="kiwiGreen">Monkey Mia & the Coral Coast</span>
                                                        </div>
                                                    </template>
                                                    <v-card class="elevation-0 pa-4" color="#fafafa">
                                                        <v-layout row wrap>
                                                            <v-flex xs12 sm6 md6>
                                                                <p class="mb-0"><strong>Day 1 – Arrival Day</strong></p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0 pl-1">Stay: Perth Hotel</p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0"><strong>Day 2 – Perth to
                                                                        Cervantes</strong></p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0 pl-1">Stay: Kalbarri Edge Resort or
                                                                    similar</p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0"><strong>Day 3 – Cervantes Kalbarri to
                                                                        Monkey Mia</strong></p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0 pl-1">Stay: RAC Monkey Mia Dolphin Resort
                                                                </p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0"><strong>Day 4 – Explore Monkey
                                                                        Mia</strong></p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0 pl-1">Stay: RAC Monkey Mia Dolphin Resort
                                                                </p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0"><strong>Day 5 – Monkey Mia to
                                                                        Geraldton</strong></p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0 pl-1">Stay: Geraldton</p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0"><strong>Day 6 – Geraldton to
                                                                        Fremantle</strong></p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0 pl-1">Stay: Fremantle</p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0"><strong>Day 7 – Rottnest Island (Groups
                                                                        1 & 2)</strong></p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0 pl-1">Stay: Fremantle</p>
                                                                <v-divider class="my-2"></v-divider>
                                                                <p class="mb-0"><strong>Day 8 – Breakfast and Departure
                                                                        to Perth Airport</strong></p>
                                                            </v-flex>
                                                            <v-flex xs12 sm6 md5 offset-md1>
                                                                <img width="1080" height="1350" class="max-width-100"
                                                                    src="https://cdn.anzcro.com.au/wp-content/uploads/2024/07/itinerary-map_western_australia_famil-monkey-mia.png" />
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-card>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-divider class=""></v-divider>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-expansion-panel class="elevation-0">
                                                <v-expansion-panel-content>
                                                    <template v-slot:header>
                                                        <div>
                                                            <h2>How to Enter</h2>
                                                        </div>
                                                    </template>
                                                    <v-card class="elevation-0 py-4 px-3" color="#fafafa">
                                                        <p>To enter, you need to be a UK-based Travel Agent or Travel
                                                            Counsellor with at least two years’
                                                            experience.</p>
                                                        <p>You also need to complete one of these activities:</p>
                                                        <ul class="mb-3">
                                                            <li>Attend 1 of 2 webinars:
                                                                <ul>
                                                                    <li>First Session – Tuesday 3rd September, 5.45pm |
                                                                        <a target="_blank"
                                                                            href="https://events.teams.microsoft.com/event/8362254c-2a89-410f-bfee-055be914ab55@0404bc66-21ba-447c-8640-528d4cc08dc0">Register</a>
                                                                    </li>
                                                                    <li>Second Session – Thursday 5th September, 12pm |
                                                                        <a target="_blank"
                                                                            href="https://events.teams.microsoft.com/event/8fe7f14a-a453-4392-a8b8-c60358d23290@0404bc66-21ba-447c-8640-528d4cc08dc0">Register</a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li>OR attend an in-person training in London on the 10th of
                                                                September;</li>
                                                            <li>OR make a booking from July to September 30, 2024, which
                                                                includes a minimum two-night stay in
                                                                Western Australia. You will also earn a £100 Amazon
                                                                voucher.</li>
                                                        </ul>
                                                        <p>Any of these options and you will be entered into a draw to
                                                            win a place.</p>
                                                    </v-card>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-divider class=""></v-divider>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-expansion-panel class="elevation-0">
                                                <v-expansion-panel-content>
                                                    <template v-slot:header>
                                                        <div>
                                                            <h2>Terms and conditions</h2>
                                                        </div>
                                                    </template>
                                                    <v-card class="elevation-0 py-4 px-3" color="#fafafa">
                                                        <ol>
                                                            <li>Successful applicants need to complete ONE of the
                                                                following activities: attendance at a
                                                                dedicated Tourism WA Webinar held OR attendance at a
                                                                Western Australia in-person training in
                                                                London OR make a booking from July to 30 September 2024
                                                                which includes a minimum two-night stay
                                                                in Western Australia. The booking must have deposits
                                                                received by the close of business 30
                                                                September 2024.</li>
                                                            <li>Only applicable to UK-based ABTA/GTG/TTA Travel Agents
                                                                and Travel Counsellors who have worked in
                                                                a travel agency for at least two years.</li>
                                                            <li>Judges decision is final. Winners will be announced
                                                                on or before 4pm Monday 07 October. Prize
                                                                is non-transferable. </li>
                                                            <li>There is ONE (1) competition spot to be awarded to an
                                                                agent who is over 25 years of age. The
                                                                prize package includes Economy cabin flights from London
                                                                Heathrow Airport to Perth (direct
                                                                service) and Perth to London Heathrow Airport operated
                                                                by Qantas services only and a 8 night
                                                                land package courtesy of Tourism Western Australia and
                                                                ANZCRO.</li>
                                                            <li>Competition closes at 5pm GMT Monday 30 September 2024.
                                                            </li>
                                                            <li>The prize does not include travel insurance,
                                                                applicable travel Visa’s or any other expenses
                                                                of a personal nature including travel to and from London
                                                                Heathrow Airport.</li>
                                                            <li>The travel dates are Saturday, 9 November 2024,
                                                                departing from LHR at 11:50 am and arriving
                                                                in PER at 12:25 pm on 10 November 2024. On Sunday, 17
                                                                November 2024, departing from PER at 19:20
                                                                and arriving at LHR at 05:05 am on 18 November 2024.
                                                            </li>
                                                            <li>If there is a late-cancellation from offer of trip
                                                                between prize allocation and travel dates,
                                                                ANZCRO will impose a cancellation fee. </li>
                                                        </ol>
                                                    </v-card>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-flex>
                                    </v-layout>
                                </v-card-text>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-flex>
        </v-layout>

        <v-layout row>
            <v-flex mb-5>
                <v-card flat color="#f4f4f4">
                    <v-container>
                        <v-layout row wrap>
                            <v-flex xs10 offset-xs1>
                                <v-btn block large class="x-large elevation-0 mt-3 mb-5" dark :color="kiwiGreen"
                                    :href="form_url" target="_blank">Register Your Interest</v-btn>
                            </v-flex>
                            <v-flex xs12 py-0>
                                <h3 class="home-section-title green-on-white text-xs-center mt-5">
                                    <span>Presented by</span>
                                </h3>
                                <v-layout row wrap>
                                    <v-flex xs12 sm4 text-xs-center>
                                        <img width="280" class="mx-auto max-width-100"
                                            src="https://cdn.anzcro.com.au/wp-content/uploads/2024/07/qantas.png" />
                                    </v-flex>
                                    <v-flex xs12 sm4 text-xs-center>
                                        <img width="280" class="mx-auto max-width-100"
                                            src="https://cdn.anzcro.com.au/wp-content/uploads/2024/07/wa-walking-on-a-dream.png" />
                                    </v-flex>
                                    <v-flex xs12 sm4 text-xs-center>
                                        <img width="280" class="mx-auto max-width-100"
                                            src="https://cdn.anzcro.com.au/wp-content/uploads/2024/03/anzcro-logo.svg" />
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
            </v-flex>
        </v-layout>

    </v-container>
</template>
<script>
export default {
    data: () => ({
        gallery_items: [
            {
                src: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/promo-gallery-pinkys-beach-rottnest-island.jpg',
                caption: 'Pinky Beach, Rottnest Island'
            },
            {
                src: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/promo-gallery-winery-tour-margaret-river.jpg',
                caption: 'Winery Tour, Margaret River Region'
            },
            {
                src: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/promo-gallery-perth.jpg',
                caption: 'Perth, Australia\'s sunniest capital city'
            },
            {
                src: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/promo-gallery-monkey-mia.jpg',
                caption: 'Monkey Mia - Photo: Tourism Western Australia'
            },
            {
                src: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/promo-gallery-pinnacles-nanbumg-np.jpg',
                caption: 'The Pinnacles, Nambung National Park - Photo: Greg Snell'
            },
            {
                src: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/promo-gallery-bathers-beach-fremantle.jpg',
                caption: 'Bathers Beach, Fremantle - Photo: Tourism Western Australia'
            },
            {
                src: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/promo-gallery-cape-to-cape-margaret-river.jpg',
                caption: 'Cape to Cape Track, Margaret River - Photo: Tourism Western Australia'
            },
            {
                src: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/promo-gallery-kalbarri-skywalk.jpg',
                caption: 'Kalbarri Skywalk, Kalbarri National Park - Photo: Tourism Western Australia'
            },
        ],
        form_url: 'https://app4.vision6.com.au/em/forms/subscribe.php?db=864176&s=731868&a=74012&k=11sj-_r6pIfSzMENMXWJUeaRA0pD5c1IQL13UlzHgEs',
    }),
    mounted: function () {
        const el = document.scrollingElement || document.documentElement;
        el.scrollTop = 0;
    },
    methods: {
        top() {
            document.documentElement.scrollTop = 0;
        },
    },
};
</script>

<style>
.gradient-bar {
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear, left top, right top, from(#65b32e), color-stop(#d2d2d2), to(#30302f));
    background: linear-gradient(90deg, #65b32e, #d2d2d2, #30302f);
}

.deals-content {
    margin-top: -60px;
}

.package-data .v-chip {
    color: #9e9e9e;
    margin-left: 0;
}

.package-data .v-chip .v-chip__content {
    padding-right: 6px;
}

.deal-thumbnail {
    width: 88px;
    height: 88px;
}

.title-graphic {
    max-width: calc(100% - 56px);
    height: auto;
    ;
}

.max-width-100 {
    max-width: 100%;
    height: auto;
    ;
}

.v-btn--large.x-large {
    font-size: 18px !important;
    height: 64px !important;
}
</style>