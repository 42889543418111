import { make } from 'vuex-pathify';
const state = {
  message: ''
};

const getters = {
  ...make.getters(state),
};

const actions = {
  ...make.actions(state),
};

const mutations = {
  ...make.mutations(state),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
