import { make } from 'vuex-pathify';

const state = {
  error: '',
  customEror: '',
};

const actions = {
  ...make.actions(state),
};
const mutations = {
  ...make.mutations(state),
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
