<template>
  <!-- Content -->
  <v-content class="mt-5">
    <v-container fluid class="anzcro-in-app">
      <v-layout row wrap>
        <v-flex md6 offset-md3 sm8 offset-sm2 class="text-xs-center">
          <span class="group pa-2">
            <v-icon large class="icon-round-outline" color="#aaaaaa">done_outline</v-icon>
          </span>
          <h2 class="display-1 text-xs-center mt-4 mb-4">Verification Complete.</h2>
          <p class="subheading">You are ready to start selling.</p>
          <p>Log in and access our itinerary builder and thousands of Australia &amp; New Zealand travel products.</p>
        </v-flex>
        <v-flex md4 offset-md4 sm8 offset-sm2 mt-5>
          <v-card>
            <v-container fluid>
              <v-flex xs12>
                <v-form>
                  <v-layout row wrap>
                    <v-flex xs12 md12>
                      <v-subheader class="pl-0">Username / Email</v-subheader>
                      <v-text-field v-model="username" solo :rules="usernameRules" label required></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs12 md12>
                      <v-subheader class="pl-0">Password</v-subheader>
                      <v-text-field
                        v-model="password"
                        solo
                        :rules="passwordRules"
                        :append-icon="show1 ? 'visibility' : 'visibility_off'"
                        :type="show1 ? 'text' : 'password'"
                        name="password"
                        label
                        @click:append="show1 = !show1"
                        required
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap mt-3>
                    <v-flex xs12 md12>
                      <v-btn block class="black white--text" to="dashboard">Log In</v-btn>
                    </v-flex>

                    <v-flex xs12 md12>
                      <p class="mt-3">
                        Not registered yet?
                        <router-link to="/register">Register Now</router-link>|
                        <router-link to="/recover-password">Lost Password</router-link>
                      </p>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-flex>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
export default {
  data() {
    return {
      view: "Verified Login",
      show1: true,
      username: "",
      usernameRules: [v => !!v || "Username is required"],
      password: "",
      passwordRules: [v => !!v || "Password is required"]
    };
  }
};
</script>
