<template>
  <v-toolbar app fixed height="48" id="mainheader" class="py-3 public-header elevation-0">
    <router-link to="/">
      <a>
        <v-img src="https://cdn.anzcro.com.au/wp-content/uploads/2020/01/anzcro_logo-white.svg" class="mb-2 logo-header" alt="ANZCRO"></v-img>
      </a>
    </router-link>

    <v-divider inset vertical class="mx-4 nz-experts-divider hidden-xs-only hidden-md-only hidden-lg-only"></v-divider>

    <v-img contain src="https://cdn.anzcro.com.au/wp-content/uploads/2020/08/the-australian-new-zealand-holiday-experts-stacked.svg" alt="The Australian and New Zealand Holiday Experts" class="nz-experts hidden-xs-only hidden-md-only hidden-lg-only"></v-img>

    <v-spacer></v-spacer>

    <v-toolbar-items class="hidden-sm-and-down">
      <v-btn class="mx-1 white--text hidden-md-and-down" flat to="/">Home</v-btn>
      <v-btn class="mx-1 white--text" flat to="/deals/">Deals</v-btn>
      <v-btn class="mx-1 white--text" flat outline to="/register/">Register</v-btn>
      <v-btn class="mx-1 white--text hidden-md-and-down" flat to="/about/">About</v-btn>
      <v-btn class="mx-1 white--text hidden-md-and-down" flat to="/group-travel/">Groups</v-btn>
      <v-menu dark offset-y>
        <template v-slot:activator="{ on }">
          <v-btn class="mx-1 white--text" flat v-on="on">Destinations <v-icon dark>arrow_drop_down</v-icon></v-btn>
        </template>

        <v-list>
          <v-list-tile to="/destinations/australia/">
            <v-list-tile-title>Australia</v-list-tile-title>
          </v-list-tile>
          <v-list-tile to="/destinations/new-zealand/">
            <v-list-tile-title>New Zealand</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
      <v-btn class="mx-1 white--text" flat to="/the-insider/">The Insider</v-btn>
      <v-btn class="mx-1 white--text hidden-md-and-down" flat to="/contact/">Contact</v-btn>
      <v-menu dark offset-y >
        <template v-slot:activator="{ on }">
          <v-btn class="mx-1 white--text hidden-lg-and-up" flat v-on="on">More <v-icon dark>arrow_drop_down</v-icon></v-btn>
        </template>

        <v-list>
          <v-list-tile to="/group-travel/">
            <v-list-tile-title>Group Travel</v-list-tile-title>
          </v-list-tile>
          <v-list-tile to="/about/">
            <v-list-tile-title>About Us</v-list-tile-title>
          </v-list-tile>
          <v-list-tile to="/contact/">
            <v-list-tile-title>Contact Us</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar-items>

    <v-spacer></v-spacer>

    <v-toolbar-items>
      <v-btn v-if="isAuth" dark :color="kiwiGreen" to="/dashboard/home/" class="elevation-0">Dashboard</v-btn>
      <v-btn v-if="!isAuth" flat outline class="white--text elevation-0" to="/login/">Login</v-btn>
      <v-btn flat dark icon large class="ml-3 hidden-md-and-up" @click="toogleMenu">
        <v-icon>menu</v-icon>
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
export default {
  computed: {
    isAuth() {
      return !!this.$store.state.user;
    },
  },
  methods: {
    handleSCroll() {
      let header = document.querySelector('.v-toolbar');
      if (window.scrollY > 100 && !header.className.includes('v-toolbar--bgchange')) {
        header.classList.add('v-toolbar--bgchange', 'elevation-1');
      } else if (window.scrollY < 100) {
        header.classList.remove('v-toolbar--bgchange', 'elevation-1');
      }
    },
    toogleMenu() {
      this.$emit('toogleMenu');
    },
  },
  created() {
    window.addEventListener('scroll', this.handleSCroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleSCroll);
  },
};
</script>

<style>
.public-header.elevation-0.elevation-1{
  box-shadow:none !important;
}
.nz-experts{
  display:block;
  width:224px;
  height:24px;
  flex-grow: 0;
}
.theme--light.v-divider.nz-experts-divider {
    border-color: rgba(255,255,255, 0.24);
}
</style>
