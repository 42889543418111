import store from '@/vuex/index';
import { isNil } from 'ramda';

class RouteService {
  routes: string[];
  maxLength: number;
  router: any;

  constructor() {
    this.routes = [];
    this.maxLength = 10;
  }

  getPrevious() {
    if (this.routes.length < 1) {
      return null;
    }
    return this.routes[this.routes.length - 2];
  }

  get current() {
    if (this.routes.length === 0) {
      return null;
    }
    return this.routes[this.routes.length - 1];
  }

  configureRouter(router: any) {
    this.router = router;
  }

  add(route: string) {
    if (this.routes.length == this.maxLength) {
      this.routes.splice(0, 5);
    }
    this.routes.push(route);
  }

  // eslint-disable-next-line no-unused-vars
  validateStore(validate = (_store: any) => true, valid = () => {}, invalid = (_router: any, _store: any) => {}) {
    if (validate(store)) {
      valid();
    } else {
      invalid(this.router, store);
    }
  }
}
// this data will be disposed after enter the route
// check router.afterEach method
class TempData {
  tempDataKey = '$t$e$m$p$D$a$t$a';

  add(data: any) {
    localStorage.setItem(this.tempDataKey, JSON.stringify(data));
  }
  get(): any {
    const data = localStorage.getItem(this.tempDataKey);
    if (isNil(data)) return undefined;
    return JSON.parse(data);
  }
  remove() {
    localStorage.removeItem(this.tempDataKey);
  }
}

export const routeService = new RouteService();
export const tempData = new TempData();

export const configureRouteService = (router: any) => {
  routeService.configureRouter(router);
};
