<template>
  <v-container pa-0 fluid>
    <!-- Hero Section -->
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-hero-nt-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">{{current}}</h1>
                          <h3 class="white--text title mb-4">{{currentArea}}</h3>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Uluru - Credit: Tourism Australia</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- Main -->
    <v-layout row>
      <v-flex>
        <v-card flat tile class="pb-5">
          <v-container grid-list-xl class="destination-main">
            <v-layout row wrap mt-2>
              <!-- In Page Navigation -->
              <v-flex xs12>
                <v-toolbar
                  flat
                  dense
                  tabs
                  :color="lightGrey"
                  class="card-with-border dash-rounded hidden-xs-only content-nav-toolbar"
                >
                  <v-card flat height="48">
                    <v-icon size="16" color="grey" class="mx-3 my-3">arrow_downward</v-icon>
                  </v-card>
                  <v-divider vertical></v-divider>
                  <v-toolbar-items>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#introduction', scrollToOptions )"
                      class="ma-0 hidden-sm-and-down"
                    >Intro</v-btn>
                    <v-divider vertical class="hidden-sm-and-down"></v-divider>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#gallery', scrollToOptions )"
                      class="ma-0"
                    >Gallery</v-btn>
                    <v-divider vertical></v-divider>
                    <v-btn
                      flat
                      @click="$vuetify.goTo('#the-best-of', scrollToOptions )"
                      class="ma-0"
                    >Highlights</v-btn>

                  </v-toolbar-items>
                  <v-divider vertical></v-divider>
                  <v-card flat height="48" width="100%">&nbsp;</v-card>
                </v-toolbar>
                <v-card
                  flat
                  :color="lightGrey"
                  class="card-with-border v-card--btn hidden-sm-and-up content-nav-card"
                >
                  <v-layout row wrap ma-0>
                    <v-flex xs6 pa-0>
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#introduction', scrollToOptions )"
                        class="ma-0"
                      >Intro</v-btn>
                    </v-flex>
                    <v-flex xs6 pa-0 class="border-l">
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#gallery', scrollToOptions )"
                        class="ma-0"
                      >Gallery</v-btn>
                    </v-flex>
                    <v-flex xs12 pa-0>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs6 pa-0>
                      <v-btn
                        flat
                        block
                        @click="$vuetify.goTo('#the-best-of', scrollToOptions )"
                        class="ma-0"
                      >Highlights</v-btn>
                    </v-flex>
                    <v-flex xs6 pa-0 class="border-l">
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <!-- Content -->
              <v-flex xs12 md9 lg7>
                <v-layout row wrap>
                  <!-- Destination Content -->
                  <v-flex xs12 id="content-wrapper">
                    <!-- Introduction -->
                    <v-layout row id="introduction" mt-3>
                      <v-flex>
                        <h4
                          class="title mb-3"
                        >Tropical and lush in some places; red and rocky in others – the Northern Territory is like nowhere else in Australia. From Uluru to Arnhem Land the Northern Territory’s attractions are as diverse as its landscapes. Here you will find ancient Aboriginal rock art galleries, abundant and unique wildlife, rare South Sea pearls and world-famous natural attractions</h4>
                      </v-flex>
                    </v-layout>

                    <!-- Gallery -->
                    <v-layout row wrap id="gallery" mt-3>
                      <v-flex xs12 pb-1>
                        <h4 class="title">Gallery</h4>
                      </v-flex>
                      <v-flex xs12>
                        <v-card flat dark class="mb-3">
                          <v-carousel
                            height="auto"
                            class="elevation-0 destination-gallery"
                            hide-delimiters
                          >
                            <v-carousel-item v-for="(item, index) in galleryItems" :key="index">
                              <v-img
                                :src="item.image"
                                gradient="to bottom, rgba(22,22,22,.35), rgba(22,22,22,0.1) 15%, rgba(22,22,22,0.1) 85%, rgba(22,22,22,0.65)"
                                aspect-ratio="1.5"
                              >
                                <v-layout column fill-height ma-0>
                                  <v-flex text-xs-right>
                                    <b>{{index + 1}}</b>
                                    / {{galleryItems.length}}
                                  </v-flex>
                                  <v-flex shrink><small class="d-block">{{item.caption}}</small></v-flex>
                                </v-layout>
                              </v-img>
                            </v-carousel-item>
                          </v-carousel>
                        </v-card>
                      </v-flex>
                    </v-layout>

                    <!-- The Best of -->
                    <v-layout row id="the-best-of" mt-3>
                      <v-flex>
                        <h4 class="title mb-3">Northern Territory Highlights</h4>
                        <ul class="subheading mb-3">
                          <li>
                            <b>See sunset at Uluru</b> – Standing 350 metres (1150 feet) high, ringed by ghost gum trees and waterholes, Uluru is a giant sandstone rock and a spiritual meeting place for the local Aboriginal Anangu people. Uluru is at its most captivating at sunset when the rock's surface shifts through a gamut of colours. Spend an afternoon walking the 10.6 kilometre (6.6 mile) base trail before relaxing at one of the two designated sunset viewing areas.
                          </li>
                          <li>
                            <b>Walk the West MacDonnell Ranges</b> – Drive 110 kilometres (68 miles) beyond Alice Springs to the dramatic West MacDonnell Ranges (West Macs), a landscape characterised by ochre soil, cliffs and royal blue skies. Swimmable inland waterholes and semi-arid desert walks abound. Ormiston Gorge is a jewel in the West Macs crown. Its four hour Pound Walk leads visitors into an ancient valley, across a dry creek bed and past a shimmering waterhole flanked by ghost gums
                          </li>
                          <li>
                            <b>Swim at Mataranka Thermal Pool</b> – Further north, 420 kilometres (260 miles) shy of Darwin, luxurious 34° Celsius (93° Fahrenheit) waters that glow turquoise due to their mineral content lie inside Elsey National Park. The sandy-bottomed Mataranka Thermal Pool is alive with fish and freshwater turtles. Explore it with a snorkel in hand. The site's sister lagoon, Bitter Springs, is a 10 minute drive away.
                          </li>
                          <li>
                            <b>Canoe inside Nitmiluk National Park</b> – With a network of 13 gorges that cradle the Katherine River, Nitmiluk National Park is ideal to explore by canoe, thanks to its placid waters, scenic surrounds and low-lying rock shelves. Hire a canoe from Nitmiluk Tours and paddle your way through the ancient gorge system stopping for a picnic and swim. Or escape the busier gorges and take a guided canoeing tour.
                          </li>
                          <li>
                            <b>Take a scenic flight over Kakadu</b> – Dual World Heritage-listed Kakadu National Park is famous for its dramatic waterfalls, and hosts all six of the Top End's ecosystems: stone country, wetlands, savanna woodlands, tidal flats, hills and basins and floodplains. See it from above on a scenic flight.
                          </li>
                          <li>
                            <b>Eat at Darwin’s Mindil Market </b> – The lively Mindil Beach Sunset Market runs on Thursday and Sunday nights from April to October and features street performers, kids’ rides, musicians, craft stalls and an eclectic culinary range of local and international food stalls. Try local favourites such as a Thai papaya salad, or visit Go On Be Game, a stall serving kangaroo, crocodile and buffalo fillet burgers.
                          </li>
                          <li>
                            <b>Laze around Litchfield National Park</b> – About 115 kilometres (70 miles) from Darwin, Litchfield National Park's Buley Rockhole is a series of descending, connected swimming pools with clear waters. Secure a small site all to yourself and lounge about as the water cascades from one pool to the next. From here, continue on to the park's signature swimming hubs, Wangi and Florence Falls.
                          </li>
                          <li>
                            <b>Get an art fix on the Tiwi Islands</b> – Dubbed the Islands of Smiles, the Tiwis – 70 kilometres (43 miles) north of Darwin – are accessible via charter plane and ferry. Take a three day trip with Sail Darwin and visit the islands' key art centres. The Tiwis specialise in wooden sculptures, traditional painting and vibrant, modern fabric designs. Artists are often around to have a chat and share the stories behind their creations.
                          </li>
                        </ul>
                      </v-flex>
                    </v-layout>

                    <!-- Map
                    <v-layout wrap row id="map" mt-3>
                      <v-flex xs12 pb-1>
                        <h4 class="title">Map</h4>
                      </v-flex>
                      <v-flex xs12>
                        <v-card flat class="v-card--btn">
                          <v-img
                            src="https://cdn.anzcro.com.au/wp-content/uploads/2019/12/map-thumbnail-auckland.jpg"
                            height="240"
                          >
                            <v-layout column fill-height ma-0 align-end>
                              <v-flex>&nbsp;</v-flex>
                              <v-flex shrink>
                                <v-btn
                                  :color="lightGrey"
                                  @click="destinationMapDialog=true;"
                                >Expand</v-btn>
                              </v-flex>
                            </v-layout>
                          </v-img>
                        </v-card>
                      </v-flex>
                    </v-layout> -->
                  </v-flex>
                </v-layout>
              </v-flex>

              <!-- Other Destinations -->
              <v-flex xs12 md3 lg4 offset-lg1 id="other" mt-4>
                <h4 class="title mb-4 grey--text">Other Destinations</h4>
                <DestinationIndex :currentDestination="current" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- Destination Map Dialog -->
    <v-dialog v-model="destinationMapDialog" max-width="900px">
      <v-card class="pa-0">
        <v-layout row pa-3 align-center>
          <v-flex>
            <span class="d-block headline font-weight-bold">Map</span>
          </v-flex>
          <v-flex shrink>
            <v-btn
              :color="lightGrey"
              small
              fab
              class="elevation-0 ma-0 card-with-border"
              @click="destinationMapDialog=false;"
            >
              <v-icon size="24">close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-card-text class="pa-3">
          <v-responsive :aspect-ratio="800/600">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d817202.3861024495!2d174.30502620606845!3d-36.86170744554708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d47fb5a9ce6fb%3A0x500ef6143a29917!2sAuckland%2C%20New%20Zealand!5e0!3m2!1sen!2sau!4v1575248618684!5m2!1sen!2sau" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
          </v-responsive>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import DestinationIndex from '@/components/public/destinations/components/DestinationIndexAustralia.vue';
export default {
  data: () => ({
    current: 'Northern Territory',
    currentArea: 'Australia',
    galleryItems: [
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-nt-001.jpg',
        caption: 'Scenic Flight, Kakadu National Park - Credit: Tourism Australia',
      },
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-nt-002.jpg',
        caption: 'Saltwater Crocodile - Credit: Tourism Australia',
      },
      {
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2020/03/destination-gallery-nt-003.jpg',
        caption: 'Credit: Tourism Australia',
      },
    ],
    destinationMapDialog: false,
    scrollToOptions: {
      easing: 'easeInOutCubic',
      duration: 300,
      offset: 50,
    },
  }),
  components: {
    DestinationIndex,
  },
  mounted: function() {
    document.documentElement.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
.container.destination-main {
  padding: 24px;
}

.content-nav-toolbar .v-toolbar__content {
  padding: 0 !important;
}

.content-nav-card .flex.border-l {
  border-left: solid 1px rgba(0, 0, 0, 0.12) !important;
}

#content-wrapper .title {
  line-height: 1.3 !important;
}

#content-wrapper li {
  margin-bottom: 16px;
}

#content-wrapper li:last-child {
  margin-bottom: 0;
}

.v-avatar.summer {
  border: solid 4px #ffaa00 !important;
}
.v-avatar.winter {
  border: solid 4px #adc9d7 !important;
}

@media (min-width: 600px) {
  .container.destination-main {
    padding: 24px 32px;
  }
}

@media (min-width: 960px) {
  .container.destination-main {
    padding: 24px;
  }
}
</style>
