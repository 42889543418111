import { SearchResult } from '@/model/searchproduct';

export const sortAlphabetically = (a: SearchResult, b: SearchResult) => {
  if (a.name < b.name) {
    return -1;
  } else if (a.name > b.name) {
    return 1;
  }
  return 0;
};

export const sortLowToHigh = (a: SearchResult, b: SearchResult) => (a.lowestUnitPrice > b.lowestUnitPrice ? 1 : -1);

export const sortHigthToLow = (a: SearchResult, b: SearchResult) => (a.lowestUnitPrice > b.lowestUnitPrice ? -1 : 1);
